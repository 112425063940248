import { useMutation } from "@apollo/client";

import { getIsFinalSectionOfAssessment } from "../eca.utils";
import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";
import {
  getNumberOfCompletedSections,
  setCurrentAssessmentView,
  setNumberOfCompletedSections
} from "../eca.slice";
import { assessmentViews, sectionStatusTypes } from "../eca.constants";
import { useGetNextAssessmentSectionQuestions } from "../eca.hooks";
import { Container } from "./ECATimer.style";
import { Timer } from "suited/components/shared/feedback/Timer/Timer";
import { ASSESSMENT_SECTION_CLOSE, GET_ASSESSMENT } from "../eca.queries";
import { useParams } from "react-router-dom";
import { AssessmentID, GetAssessmentQuery } from "../eca.types";

export const ECATimer = () => {
  const { id, timeRemaining, totalSections } = useGetNextAssessmentSectionQuestions();
  const numberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);
  const { assessmentID } = useParams<AssessmentID>();

  const isFinalSectionOfAssessment = getIsFinalSectionOfAssessment(
    numberOfCompletedSections,
    totalSections
  );
  const dispatch = useAppDispatch();
  const [closeAssessmentSection] = useMutation(ASSESSMENT_SECTION_CLOSE, {
    onCompleted: () => {
      if (isFinalSectionOfAssessment) {
        dispatch(setCurrentAssessmentView(assessmentViews.END_OF_ASSESSMENT));
      } else {
        dispatch(setCurrentAssessmentView(assessmentViews.SECTION_INFO));
      }
      dispatch(setNumberOfCompletedSections(numberOfCompletedSections + 1));
    },
    update(cache) {
      const queryCache = {
        query: GET_ASSESSMENT,
        variables: { assessmentID }
      };
      const assessmentCache = cache.readQuery<GetAssessmentQuery, AssessmentID>(queryCache);
      const notCompleteIndex = assessmentCache?.GetAssessment?.sections?.findIndex((section) => {
        return section.status !== sectionStatusTypes.COMPLETE;
      });
      const updatedSections = assessmentCache?.GetAssessment?.sections?.map((section, index) => {
        if (index === notCompleteIndex) {
          return { ...section, status: sectionStatusTypes.COMPLETE };
        }

        return section;
      });

      cache.writeQuery({
        ...queryCache,
        data: {
          GetAssessment: {
            ...assessmentCache?.GetAssessment,
            sections: updatedSections
          }
        }
      });
    },
    fetchPolicy: "no-cache",
    variables: {
      id,
      closeType: "TIMER"
    }
  });

  const onTimerUpdate = (secondsRemaining: number) => {
    // auto-advance if time runs out and we are not on the last
    // page of the ECA assessment
    const shouldAutoAdvanceToNextSection = secondsRemaining === 0;
    if (shouldAutoAdvanceToNextSection) {
      window?.heap?.track("eca-section-finish-after-timer");
      closeAssessmentSection();
    }
  };

  // If we have an untimed section, the server will specifically return null.
  // This triggers logic for hiding the timer.
  if (timeRemaining === null) {
    return null;
  }

  return timeRemaining ? (
    <Container>
      <Timer duration={timeRemaining} onUpdate={onTimerUpdate} />
    </Container>
  ) : null;
};
