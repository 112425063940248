import { Stack, Box, Center, Grid, HStack } from "@suited/components";
import Skeleton from "react-loading-skeleton";

import { ECA_INSTRUCTIONS } from "../eca.constants";
import SuitedCalloutHeadline from "suited/components/shared/typography/SuitedCalloutHeadline";
import SuitedCallout from "suited/components/shared/typography/SuitedCallout";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { InstructionContent } from "../ECAInstructionsPage/ECAInstructionsPage.style";
import { LegalCopy } from "suited/components/shared/typography/SuitedLegalCopy";

export const ECALandingPageSkeleton = () => {
  return (
    <Box padding="xl" overflow="auto">
      <Center>
        <InstructionContent style={{ width: "100%" }}>
          <Stack space="xl">
            <SuitedCopyHeadline noMargin>
              <Skeleton />
            </SuitedCopyHeadline>
            <SuitedCopySubheadline noMargin noMarginTop important>
              <Skeleton />
            </SuitedCopySubheadline>
            <p>
              <Skeleton count={2} />
            </p>
            <p>
              <Skeleton count={3} />
            </p>
            <SuitedCopySubheadline noMargin>
              <Skeleton style={{ width: "15ch" }} />
            </SuitedCopySubheadline>
            <Grid space="xl">
              {ECA_INSTRUCTIONS.map(({ headline }) => {
                return (
                  <HStack align="flex-start" key={headline}>
                    <Box>
                      <Skeleton circle height={36} width={36} />
                    </Box>
                    <Box width="100%">
                      <Stack>
                        <SuitedCalloutHeadline noMargin>
                          <Skeleton />
                        </SuitedCalloutHeadline>
                        <SuitedCallout noMargin>
                          <Skeleton count={4} />
                        </SuitedCallout>
                      </Stack>
                    </Box>
                  </HStack>
                );
              })}
            </Grid>
            <Box>
              <Stack space="xl">
                <p>
                  <Skeleton count={4} />
                </p>
                <p>
                  <strong>
                    <Skeleton count={3} />
                  </strong>
                </p>
                <p>
                  <Skeleton count={2} />
                </p>
                <LegalCopy>
                  <Skeleton count={3} />
                </LegalCopy>
                <Center>
                  <Skeleton style={{ borderRadius: "5px" }} height={48} width={140} />
                </Center>
              </Stack>
            </Box>
          </Stack>
        </InstructionContent>
      </Center>
    </Box>
  );
};
