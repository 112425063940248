import { Box, HStack, Stack } from "@suited/components";
import { noop } from "@suited/utils";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { ResponsiveDialogModal } from "suited/components/shared/feedback/ResponsiveDialogModal/ResponsiveDialogModal";
import BasicDialogHeader from "suited/components/shared/layout/Dialogs/BasicDialogHeader";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { timeouts } from "suited/constants/interaction-constants";

export const RegistrationWarningModal = ({ isOpen, onCancel, onSubmit }) => {
  return (
    <ResponsiveDialogModal
      maxWidth={700}
      show={isOpen}
      onCancel={noop}
      closeDuration={timeouts.MODAL_DEFAULT_CLOSE_DURATION}
      iconButtonRow={<BasicDialogHeader onCancel={onCancel} />}
      shouldCloseOnEsc={false}
    >
      <Box width="85ch">
        <Stack space="xl">
          <Box>
            <SuitedCopySubheadline noMargin>Before You Move On</SuitedCopySubheadline>
          </Box>
          <Box>
            <Stack>
              <p>
                Our Terms of Service allow each candidate to have only one account on our platform.
                Each candidate should only complete the assessment one time. Your single account is
                used across all job applications that require Suited. Creating multiple accounts is
                prohibited, as it compromises the integrity and fairness of our services.
              </p>
              <p>
                Attempting to take the assessment under multiple different accounts could result in
                your account being restricted or partner firms being notified about the violation of
                our terms. If you need to change the email address associated with your account, you
                can do so under "User Settings
              </p>
              <p>By continuing, you agree that this is your only account.</p>
            </Stack>
          </Box>
          <Box>
            <HStack justify="flex-end" space="sm">
              <Box>
                <SuitedButton purpose="default" onClick={onCancel}>
                  Cancel
                </SuitedButton>
              </Box>
              <Box>
                <SuitedButton purpose="primary" data-analytics-id="register" onClick={onSubmit}>
                  Submit
                </SuitedButton>
              </Box>
            </HStack>
          </Box>
        </Stack>
      </Box>
    </ResponsiveDialogModal>
  );
};
