import { useQuery } from "@apollo/client";

import { Box, Stack, HStack } from "@suited/components";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { useAppSelector } from "suited/util/hooks/reduxHooks";
import { getCurrentEmail } from "../userSettings.slice";
import { GET_USER_SETTINGS } from "../GraphQL/queries";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { FadeInWrapper } from "../userSettings.styles";

type Props = {
  onClose: () => void;
};

export const ChangeEmailNotification = (props: Props) => {
  const { onClose } = props;
  const currentEmail = useAppSelector(getCurrentEmail);
  const { data } = useQuery(GET_USER_SETTINGS);
  const previousEmail = data?.GetUser?.email;

  return (
    <FadeInWrapper>
      <Stack space="lg" width="85ch">
        <Box>
          <SuitedCopySubheadline noMargin>Change Email</SuitedCopySubheadline>
        </Box>
        <Box>
          <Stack>
            <p>
              We have started the transition from <strong>{previousEmail}</strong> to{" "}
              <strong>{currentEmail}</strong>. Please check your inbox for email address{" "}
              <strong>{currentEmail}</strong> for a validation link to confirm your email change
              request. After confirming, we will finalize the change.
            </p>
          </Stack>
        </Box>
        <Box>
          <HStack justify="flex-end" space="sm">
            <Box>
              <SuitedButton type="submit" purpose="default" onClick={onClose}>
                Okay
              </SuitedButton>
            </Box>
          </HStack>
        </Box>
      </Stack>
    </FadeInWrapper>
  );
};
