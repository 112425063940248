import React from "react";
import { SuitedFormLabelEl } from "./SuitedFormLabel.style";

const SuitedFormLabel: React.FunctionComponent<React.LabelHTMLAttributes<HTMLLabelElement>> = (
  props
) => {
  return (
    <SuitedFormLabelEl {...props} data-test="component-suited-form-label">
      {props.children}
    </SuitedFormLabelEl>
  );
};

export default SuitedFormLabel;
