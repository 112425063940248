import { useForm } from "react-hook-form";
import moment from "moment";

import { Box, Stack, HStack } from "@suited/components";
import { transformValues } from "@suited/utils";
import { Dictionary } from "suited/components/shared/types/utility";
import { MultiSelectItemOption, SubmitItemVariables } from "../eca.types";
import { StyledInput, Label, MultiSelectLeadingText, MultiSelectUnsearchableText } from "./MultiSelect.style";
import { getMultiSelectDefaultValues } from "./MultiSelectItem.utils";

type Props = {
  itemID: string;
  label: string;
  options: MultiSelectItemOption[];
  onSubmit: (data: SubmitItemVariables) => void;
};

export const MultiSelectItem = ({ itemID, options, label, onSubmit }: Props) => {
  const defaultValues = getMultiSelectDefaultValues(options);
  const { register, handleSubmit, setValue, getValues, reset, watch } = useForm<
    Dictionary<boolean>
  >({ defaultValues });

  const watchedValues = watch();

  const exclusiveOption = options.find((option) => option.isExclusive);

  return (
    <Box>
      <Stack space="sm">
        <MultiSelectLeadingText>{label}</MultiSelectLeadingText>
        {options.map(({ id, label, isExclusive, isSelected }) => {
          const exclusiveOptionId = exclusiveOption?.id;
          const isExclusiveOptionSelected = exclusiveOptionId && watchedValues[exclusiveOptionId];

          const isFormFieldDisabled = !!isExclusiveOptionSelected && !isExclusive;

          return (
            <HStack key={id} space="sm" align="center">
              <StyledInput
                id={id}
                type="checkbox"
                value={isSelected.toString()}
                data-label={label}
                {...register(id, { disabled: isFormFieldDisabled })}
                onChange={(event) => {
                  setValue(id, event.target.checked);
                  const values = getValues();

                  const isExclusiveOptionSelected = exclusiveOption && values[exclusiveOption.id];
                  if (isExclusiveOptionSelected) {
                    const valuesToReset = transformValues(
                      values,
                      (key) => !!(key === exclusiveOption?.id)
                    );
                    reset(valuesToReset);
                  }
                  handleSubmit((formValues) => {
                    const values = options
                      .filter((option) => !!formValues[option.id])
                      .map((option) => ({ id: option.id }));
                    const timestamp = moment().toISOString();
                    onSubmit({ id: itemID, values, timestamp });
                  })();
                }}
              />
              <Label htmlFor={id}>
                <MultiSelectUnsearchableText data-content={label} />
              </Label>
            </HStack>
          );
        })}
      </Stack>
    </Box>
  );
};
