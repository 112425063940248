import styled from "styled-components";

import SuitedDialogWindow from "suited/components/shared/layout/Dialogs/SuitedDialogWindow";
import {
  Content,
  FormEl,
  IconButtonRow,
  StyledPaper
} from "suited/components/shared/layout/Dialogs/SuitedDialogWindow.style";
import { responsiveTargets } from "suited/constants/style-constants";

export const SuitedDialogWindowEl = styled(SuitedDialogWindow)<{ zIndex?: number }>`
  ${StyledPaper} {
    background: transparent;
    overflow: hidden;
    max-width: 100%;
    @media ${responsiveTargets.phone} {
      max-height: 100%;
      max-width: 100%;
    }
  }

  ${FormEl} {
    display: flex;
    min-height: 0px;
  }

  ${IconButtonRow} {
    display: none;
  }

  ${Content} {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    left: 0;
    max-width: 100%;
  }

  &.ReactModal__Content {
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    width: 100%;
  }
`;
