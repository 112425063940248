export const FEATURE_FLAG_KEY = "featureFlags";
export const FEATURE_FLAG_VISIBILITY_KEY = "isFeatureFlagNavVisible";
export const FEATURE_FLAG_URL_PARAM_NAME = "ff";

export const featureFlags = {
  LATERAL: "LATERAL"
}
export const featureFlagDefaultConfig = {
  [featureFlags.LATERAL]: false
};

