import { MultiSelectItemOption } from "../eca.types";

export const getMultiSelectDefaultValues = (option: MultiSelectItemOption[]) =>
  option.reduce(
    (acc, { id, isSelected }) => ({
      ...acc,
      [id]: isSelected
    }),
    {}
  );
