import { useAppSelector } from "suited/util/hooks/reduxHooks";
import { getNumberOfCompletedSections } from "../lateral.slice";
import { Box, HStack, Stack } from "@suited/components";
import { Container } from "./SectionProgressContainer";
import { colors } from "suited/constants/style-constants";

export const SectionProgress = ({ sectionData }) => {
  const numberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);

  const { itemGroups, totalSections } = sectionData?.GetNextAssessmentSectionQuestions || {};
  let totalItems = 0, itemsAnswered = 0;
  itemGroups?.forEach(group => {
    totalItems += group.items.length;
    group.items.forEach(item => {
      item.options.forEach(option => {
        if (option.isSelected) {
          itemsAnswered++;
        }
      });
    })
  });
  const sectionComplete = itemsAnswered === totalItems;

  return (
    <Container>
      <Box
        padding="xs"
        bg={sectionComplete ? colors.color5.base : colors.color3.tint1}
        maxWidth="300px"
        borderRadius="0px 0px 2px 2px"
        style={{
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.5)",
          fontSize: "16px"
        }}
      >
        <Stack space="xs">
          <HStack justify="space-between">
            <Box>Section {numberOfCompletedSections + 1} of {totalSections}</Box>
          </HStack>
          <HStack justify="space-between">
            <Box>Answered {itemsAnswered} of {totalItems}</Box>
          </HStack>
        </Stack>
      </Box>
    </Container>
  );
};