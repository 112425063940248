import {  CSSProperties } from 'react';
import clsx from 'clsx';

import { omit } from '@suited/utils';
import { BaseComponentProps, SpacingOptions } from '../../types';
import { getContentAreaProps } from '../../utils';
import { CONTENT_AREA_PROPERTIES } from '../../constants';
import './Wrap.css';

interface Props extends BaseComponentProps {
  space?: SpacingOptions;
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
}

export const Wrap = ({
  children,
  className,
  style,
  space = 'md',
  align = 'center',
  justify = 'flex-start',
  ...props
}: Props) => {
  const classNames = clsx('ws-l-wrap', className);
  const rest = omit(props, CONTENT_AREA_PROPERTIES);
  const styles = {
    ...style,
    ...getContentAreaProps(props),
    '--wrap-justify': justify,
    '--wrap-align': align,
    '--wrap-space': `var(--perfect-fifth-${space})`,
  };

  return (
    <div className={classNames} style={styles} {...rest}>
      <div>{children}</div>
    </div>
  );
};
