import React, { useState, useReducer } from "react";
import { timeouts } from "suited/constants/interaction-constants";
import reducer from "./reducer";
import { TRecaptchaProviderState, TRecaptchaProviderValue } from "./types";
import { useLoadRecaptchaScripts, useRecaptchaScriptTimeout } from "./hooks";
import RecaptchaReloadDialog from "./components/RecaptchaReloadDialog";

export const initialState: TRecaptchaProviderState = {
  error: false,
  grecaptcha: null,
  loaded: false,
  loading: false
};

const RecaptchaContext = React.createContext<TRecaptchaProviderValue | undefined>(undefined);

interface IRecaptchaProviderProps {
  init?: TRecaptchaProviderState;
  children: React.ReactNode;
}

/**
 * The `RecaptchaProvider` manually loads the Google Recaptcha Script
 * in order to respond to network events mainly script loading errors.
 * When Google Recaptcha fails to load, it will display a message to
 * the user.
 */
const RecaptchaProvider = (props: IRecaptchaProviderProps) => {
  const [showReloadDialog, setShowReloadDialog] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, props.init || initialState);
  const { loading } = state;
  const { RECAPTCHA_SCRIPT_LOAD_TIMEOUT } = timeouts;

  function onScriptError() {
    dispatch({ type: "SCRIPT_ERROR" });
    setShowReloadDialog(true);
  }

  function onScriptLoadStart() {
    dispatch({ type: "SCRIPT_LOAD_START" });
  }

  function onScriptLoad() {
    dispatch({ type: "SCRIPT_LOAD" });
  }

  function onScriptReload() {
    dispatch({ type: "SCRIPT_RELOAD" });
    setShowReloadDialog(false);
  }

  function onScriptTimeout() {
    dispatch({ type: "SCRIPT_TIMEOUT" });
    setShowReloadDialog(true);
  }

  // Handles the event where the scripts loaded successfully
  // but for some reason the widget failed to initialize
  // we want to handle that case as well and inform the user
  // that we could not validate them as a human.
  function onWidgetError() {
    dispatch({ type: "WIDGET_ERROR" });
  }

  function onReloadDialogCancel() {
    setShowReloadDialog(false);
  }

  function onReloadDialogConfirm() {
    reloadScripts();
  }

  const { reloadScripts } = useLoadRecaptchaScripts({
    onScriptError,
    onScriptLoadStart,
    onScriptLoad,
    onScriptReload,
    TEST: state.TEST
  });

  useRecaptchaScriptTimeout(loading, onScriptTimeout, RECAPTCHA_SCRIPT_LOAD_TIMEOUT);

  const value: TRecaptchaProviderValue = {
    ...state,
    onWidgetError
  };

  return (
    <RecaptchaContext.Provider value={value}>
      <RecaptchaReloadDialog
        onCancel={onReloadDialogCancel}
        onConfirm={onReloadDialogConfirm}
        show={showReloadDialog}
      />
      {props.children}
    </RecaptchaContext.Provider>
  );
};

export { RecaptchaContext };
export default RecaptchaProvider;
