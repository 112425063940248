import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { SuitedAccordion } from "suited/components/shared/layout/SuitedAccordion";
import { SuitedAltCopy } from "suited/components/shared/typography/SuitedAltCopy";

import { ListContainer } from "../shared/Main.style";
import { Ignored, Pending } from "./InviteStates";
import { GET_CANDIDATE_INVITATIONS } from "../GraphQL/queries";
import { TCompanyInvite } from "../types";

const Invitations = () => {
  const { data, loading, error } = useQuery(GET_CANDIDATE_INVITATIONS);
  const [collapsed, setCollapsed] = useState(true);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  // filtered items to pass into presentation components
  // TODO: Refactor the useQuery here to feed in the `status` variable instead of getting all invitations and then filtering.
  const pendingInvites: TCompanyInvite[] = data?.GetCandidateInvitations.filter(
    (invite: TCompanyInvite) => invite.status === "PENDING"
  );
  const ignoredInvites: TCompanyInvite[] = data?.GetCandidateInvitations.filter(
    (invite: TCompanyInvite) => invite.status === "IGNORED"
  );

  return (
    <React.Fragment>
      {error ? (
        <React.Fragment>
          <SuitedAltCopy>
            There appears to be an issue getting company invitations and associations. Our dev team
            has been notified.
          </SuitedAltCopy>
          <SuitedAltCopy>Please try refreshing the page to resolve the issue.</SuitedAltCopy>
        </React.Fragment>
      ) : (
        <ListContainer>
          <Pending loading={loading} pendingInvites={pendingInvites} />
          {ignoredInvites?.length > 0 ? (
            <SuitedAccordion
              minWidth={440}
              id={"ignored-invitations"}
              label="Ignored Invitations"
              collapsed={collapsed}
              onToggle={handleToggle}
            >
              <Ignored ignoredInvites={ignoredInvites} />
            </SuitedAccordion>
          ) : null}
        </ListContainer>
      )}
    </React.Fragment>
  );
};

export default Invitations;
