import { useContext, useState, useEffect } from "react";
import { RecaptchaContext } from "./RecaptchaProvider";
import { TRecaptchaProviderValue } from "./types";
import { createRecaptchaScript } from "./utils";

/**
 * Hook for using the Recaptcha Provider Context.
 */
export function useRecaptcha(): TRecaptchaProviderValue {
  const recaptchaState = useContext(RecaptchaContext);

  if (recaptchaState === undefined) {
    throw new Error();
  }

  return recaptchaState;
}

/**
 * Hook for loading the Google Recaptcha script and responding to network events.
 * @returns A function for trying to reload the scripts again.
 */
export function useLoadRecaptchaScripts(options: {
  onScriptError: () => void;
  onScriptLoadStart: () => void;
  onScriptLoad: () => void;
  onScriptReload: () => void;
  TEST?: boolean;
}) {
  const { onScriptError, onScriptLoadStart, onScriptLoad, onScriptReload } = options;
  const [reloads, setReloads] = useState<number>(0);
  const [startLoadingScript, setStartLoadingScript] = useState<boolean>(true);

  function startLoadingScriptEffectCallback() {
    if (startLoadingScript) {
      const script = createRecaptchaScript(onScriptError);

      window.onRecaptchaLoadCallback = onScriptLoad;

      document.head.appendChild(script);

      setStartLoadingScript(false);

      // in tests, we do not want to actually load recaptcha
      if (!options.TEST) onScriptLoadStart();
    }
  }

  function reloadScripts() {
    onScriptReload();
    setReloads(reloads + 1);
    setStartLoadingScript(true);
  }

  useEffect(startLoadingScriptEffectCallback, [
    startLoadingScript,
    onScriptError,
    onScriptLoadStart,
    onScriptLoad,
    onScriptReload,
    options.TEST
  ]);

  return {
    reloads,
    reloadScripts
  };
}

/**
 * Executes a callback function after the loading of the
 * recaptcha scripts takes longer than a given amount of time.
 * At this current time there is no real way of knowing if
 * the recaptcha script failed to load. We can only wait for it
 * not to load after a reasonable amount of time.
 * @param isStillLoading A boolean indicating if the script is still loading.
 * @param timeout The callback function to execute after `ms` milliseconds.
 * @param ms The nunber of milliseconds to wait before calling the `timeout` function.
 */
export function useRecaptchaScriptTimeout(
  isStillLoading: boolean,
  timeout: () => void,
  ms: number
) {
  // @TODO - get this passing linter for hooks dependencies
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isStillLoading) {
        timeout();
      }
    }, ms);

    return () => clearTimeout(timer);
  }, [isStillLoading]); // eslint-disable-line
}
