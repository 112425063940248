import styled from "styled-components";

import {
  typography,
  sizes,
  responsiveTargets,
  colors,
  responsiveRanges
} from "../../../constants/style-constants";
import CopyrightNotice from "../../CopyrightNotice";
import SuitedLink from "../../shared/buttons/SuitedLink";

export const SplashContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex: 1 1 auto;
  position: relative;
  background: ${colors.background.light.backdrop};
  overflow: hidden;
`;

export const SuitedSplashContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
`;

export const SuitedSplashScroll = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
  padding-top: ${sizes.gridUnits.times8};
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;

  @media ${responsiveRanges.phoneAndTablet} {
    padding-top: ${sizes.gridUnits.times1};
  }
`;

export const SuitedSplashContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  position: relative;
  margin: auto;
  @media ${responsiveTargets.phone} {
    align-items: flex-start;
    flex: 0 0 auto;
    flex-wrap: wrap;
    width: 100%;
  }
  @media ${responsiveTargets.tablet} {
    flex-wrap: wrap;
  }
  @media ${responsiveTargets.laptop} {
    flex-wrap: nowrap;
  }
`;

export const SuitedSplashCallout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  width: calc(100% - ${sizes.gridUnits.times2} * 2);
  max-width: ${sizes.containerWidths.md};
  margin-right: 0;

  @media ${responsiveTargets.laptop} {
    align-items: flex-start;
    align-self: flex-start;
    width: 33vw;
    margin-right: ${sizes.gridUnits.times8};
  }
`;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  height: ${sizes.gridUnits.times6};
  margin-top: ${sizes.gridUnits.times1};
  margin-right: ${sizes.gridUnits.times2};
  margin-bottom: ${sizes.gridUnits.times1};
  @media ${responsiveTargets.laptop} {
    position: relative;
    width: 240px;
    height: ${sizes.gridUnits.times9};
    margin: 0;
  }
`;

export const Callout = styled.div`
  margin-top: ${sizes.gridUnits.times2};
  margin-bottom: ${sizes.gridUnits.times2};
  color: ${typography.color.light.emphasis};
  font-family: ${typography.family.body};
  font-size: 32px;
  font-weight: ${typography.weight.body.medium};
  text-transform: uppercase;
  text-align: center;
  @media ${responsiveTargets.phone} {
    margin-top: ${sizes.gridUnits.times1};
    font-size: ${typography.sizes.copy.subheading.base};
  }
  @media ${responsiveTargets.laptop} {
    margin-top: ${sizes.gridUnits.times4};
    font-size: ${typography.sizes.copy.title};
    text-align: start;
  }
`;

export const SuitedSplashFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  margin-top: ${sizes.gridUnits.times5};
  height: ${sizes.gridUnits.times5};
  background: ${colors.black};
  padding: 0 ${sizes.gridUnits.times2};
  z-index: 2;
  @media ${responsiveTargets.phone} {
    flex-direction: column;
    justify-content: center;
  }
`;

// @ts-ignore
export const StyledCopyrightNotice = styled(CopyrightNotice)`
  font-family: ${typography.family.body};
  color: ${typography.color.light.disabled};
  font-size: ${typography.sizes.form.base.hint};
  font-weight: ${typography.weight.body.light};
`;

export const SuitedSplashFooterLink = styled(SuitedLink)`
  font-weight: ${typography.weight.body.regular};
  margin-right: ${sizes.gridUnits.times2};
  &:last-of-type {
    margin-right: 0;
  }
`;
