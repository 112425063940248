import { Box, Center, Stack } from "@suited/components";
import { colors } from "suited/constants/style-constants";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { CardSubheadline } from "./SuitedUploaderCardStates.style";

interface Props {
  onClear: () => void;
  primaryErrorMessage?: string;
  secondaryErrorMessage?: string;
  message?: string;
  error?: string;
}

const Error = ({ primaryErrorMessage, secondaryErrorMessage, onClear, ...props }: Props) => {
  return (
    <Box
      padding="xxs"
      borderWidth="2px"
      borderRadius="4px"
      minWidth="50ch"
      maxWidth="65ch"
      height="240px"
      data-test="component-suited-uploader-card-error"
      borderColor={colors.shades.times1}
      bg={colors.tints.mid}
      {...props}
    >
      <Stack space="lg">
        <Center>
          <Stack space="sm">
            <CardSubheadline>{primaryErrorMessage}</CardSubheadline>
            <CardSubheadline data-test="message">{secondaryErrorMessage}</CardSubheadline>
          </Stack>
        </Center>
        <Center>
          <Box>
            <SuitedButton onClick={onClear}>Try again</SuitedButton>
          </Box>
        </Center>
      </Stack>
    </Box>
  );
};

export default Error;
