import styled, { css } from "styled-components";

import { typography, sizes, colors } from "suited/constants/style-constants";
import { SuitedAltIconButton } from "suited/components/shared/buttons/SuitedAltIconButton";
import { SuitedValidatedTextInput } from "suited/components/shared/inputs/SuitedValidatedTextInput";
import { SuitedTextInputEl } from "suited/components/shared/inputs/SuitedAltTextInput";

interface StyleProps {
  isStrongPassword?: boolean;
  overDark?: boolean;
}

export const StyledSuitedValidatedTextInput = styled(SuitedValidatedTextInput)<StyleProps>`
  width: 100%;
  ${SuitedTextInputEl} {
    margin-bottom: 0;
    padding-right: ${sizes.gridUnits.times5};
    ${(props) => (props.isStrongPassword ? getBorderStyle() : null)}
  }
`;

export const SuitedShowPasswordInputEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
`;

export const StyledSuitedIconButton = styled(SuitedAltIconButton)`
  margin-left: 0;
  height: ${sizes.gridUnits.times4};
  width: ${sizes.gridUnits.times4};
  color: ${(props) => (props.overDark ? typography.color.light.hint : typography.color.dark.hint)};
`;

export const IconButtonWrapper = styled.div<{ invalid?: boolean }>`
  position: relative;
  right: calc(${sizes.gridUnits.times5} - 2px);
  top: ${(props) => (props.invalid ? 0 : "12px")};
  z-index: 1;
`;

const getBorderStyle = () => {
  return css`
    border: 1px solid ${colors.status.success.shade1};
    box-shadow: inset 0px 0px 0px 2px ${colors.status.success.shade1};
    &:not(:disabled):hover,
    &:not(:disabled):focus {
      border: 1px solid ${colors.status.success.shade1};
      box-shadow: inset 0px 0px 0px 2px ${colors.status.success.shade1};
    }
    &:not(:disabled):focus {
      border: 1px solid ${colors.status.success.shade1};
      box-shadow: inset 0px 0px 0px 2px ${colors.status.success.shade1};
      outline: none;
    }
  `;
};
