import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../../configureStore";
import { assessmentViews } from "./eca.constants";

export const ECA_STATE_KEY = "eca";

const ecaSlice = createSlice({
  name: ECA_STATE_KEY,
  initialState: {
    currentAssessmentView: assessmentViews.SECTION_INFO,
    numberOfCompletedSections: 0,
    totalNumberOfSections: undefined
  },
  reducers: {
    setCurrentAssessmentView: (state, action) => {
      state.currentAssessmentView = action.payload;
    },
    setNumberOfCompletedSections: (state, action) => {
      state.numberOfCompletedSections = action.payload;
    },
    setTotalNumberOfSections: (state, action) => {
      state.totalNumberOfSections = action.payload;
    }
  }
});

// Actions
export const { setCurrentAssessmentView, setNumberOfCompletedSections, setTotalNumberOfSections } =
  ecaSlice.actions;

// Selectors
export const getCurrentAssessmentView = (state: RootState) => state.eca.currentAssessmentView;
export const getNumberOfCompletedSections = (state: RootState) =>
  state.eca.numberOfCompletedSections;
export const getTotalNumberOfSections = (state: RootState) => state.eca.totalNumberOfSections;

// Reducer
export const ecaReducer = ecaSlice.reducer;
