import { HttpResponse, graphql } from "msw";

import { getMockUser } from "suited/components/CandidateHome/Education/education.mocks";
import { db } from "suited/components/CandidateHome/Education/education.models";

export const workPreferencesMockHandlers = [
  graphql.query("GetUserLocationPreferences", () => {
    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  }),

  graphql.query("GetGeoData", () => {
    return HttpResponse.json({
      data: {
        GetGeoData: [
          { label: "Los Angeles, CA", lat: 40, lng: 40 },
          { label: "Miami, FL", lat: 40, lng: 40 },
          { label: "Phoenix, AZ", lat: 40, lng: 40 }
        ]
      }
    });
  }),

  graphql.mutation("UpdateUserLocationPreferences", ({ variables }) => {
    const { id } = getMockUser();

    db.GetUser.delete({
      where: {
        id: {
          equals: id
        }
      }
    });

    db.WorkStyle.delete({
      where: {
        preferredStyle: {
          equals: variables.preferredStyle
        }
      }
    });

    const locations = variables.locations.map((location) =>
      db.Location.create({ label: location.label })
    );

    db.GetUser.create({
      settings: db.settings.create({
        diversityOptIn: db.DiversityOptIn.create({
          optIn: true
        })
      }),
      userProfile: db.UserProfileData.create({
        schools: db.School.getAll(),
        personalData: db.PersonalData.create(),
        workPreferences: db.WorkPreference.create({
          locations,
          noPreference: variables.noPreference,
          workStyle: db.WorkStyle.create({
            preferredStyle: variables.preferredStyle,
            __typename: "WorkPreferences"
          })
        })
      })
    });

    return HttpResponse.json({
      data: {
        id: ""
      }
    });
  })
];
