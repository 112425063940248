import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { SuitedAccordion } from "suited/components/shared/layout/SuitedAccordion";
import { SuitedAltCopy } from "suited/components/shared/typography/SuitedAltCopy";

import { ListContainer } from "../shared/Main.style";
import { Connected, Disconnected } from "./AssociationStates";
import { GET_COMPANY_CANDIDATE_ASSOCIATIONS } from "../GraphQL/queries";
import { TCompanyAssociation } from "../types";

const Associations: React.FunctionComponent = () => {
  const { data, loading, error } = useQuery(GET_COMPANY_CANDIDATE_ASSOCIATIONS);
  const [collapsed, setCollapsed] = useState(true);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const connectedAssociations: TCompanyAssociation[] = data?.GetCompanyCandidateAssociations.filter(
    (association: TCompanyAssociation) => association.status === "ACTIVE"
  );
  const disconnectedAssociations: TCompanyAssociation[] =
    data?.GetCompanyCandidateAssociations.filter(
      (association: TCompanyAssociation) => association.status === "INACTIVE"
    );

  return (
    <React.Fragment>
      {error ? (
        <React.Fragment>
          <SuitedAltCopy>
            There appears to be an issue getting company connections. Our dev team has been
            notified.
          </SuitedAltCopy>
          <SuitedAltCopy>Please try refreshing the page to resolve the issue.</SuitedAltCopy>
        </React.Fragment>
      ) : (
        <ListContainer>
          <Connected loading={loading} connectedAssociations={connectedAssociations} />
          {disconnectedAssociations?.length > 0 ? (
            <SuitedAccordion
              minWidth={440}
              id={"disconnected-associations"}
              label="Disconnected"
              collapsed={collapsed}
              onToggle={handleToggle}
            >
              <Disconnected disconnectedAssociations={disconnectedAssociations} />
            </SuitedAccordion>
          ) : null}
        </ListContainer>
      )}
    </React.Fragment>
  );
};

export default Associations;
