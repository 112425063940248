import { Box, Stack } from "@suited/components";
import { QuestionCopy } from "../eca.styles";
import { MultiSelectItem } from "../MultiSelectItem/MultiSelectItem";
import type { MultiSelectItemProps, SubmitItemVariables } from "../eca.types";

type Props = {
  leadingText: string;
  items: MultiSelectItemProps[];
  onSubmit: (data: SubmitItemVariables) => void;
};

export const MultiSelectItemGroup = ({ leadingText, items, onSubmit }: Props) => {
  return (
    <Box>
      <Stack space="xl">
        {leadingText && (
          <Box>
            <QuestionCopy>{leadingText}</QuestionCopy>
          </Box>
        )}
        <Box>
          <Stack space="xl">
            {items.map(({ id, label, options }) => (
              <MultiSelectItem
                key={id}
                itemID={id}
                label={label}
                options={options}
                onSubmit={onSubmit}
              />
            ))}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
