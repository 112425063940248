import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowsFromLine as falArrowsFromLine,
  faCheck as falCheck,
  faCheckCircle as falCheckCircle,
  faChevronDown as falChevronDown,
  faCircle as falCircle,
  faCircleQuestion as falCircleQuestion,
  faCircleXmark as falCircleXmark,
  faClipboardCheck as falClipboardCheck,
  faClipboardList as falClipboardList,
  faClock as falClock,
  faCloudDownload as falCloudDownload,
  faCommentsAlt as falCommentsAlt,
  faEnvelope as falEnvelope,
  faEnvelopeOpen as falEnvelopeOpen,
  faExclamationTriangle as falExclamationTriangle,
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faHeart as falHeart,
  faLeaf as falLeaf,
  faLockAlt as falLockAlt,
  faMagnifyingGlass as falMagnifyingGlass,
  faMobile as falMobile,
  faRedo as falRedo,
  faSignOutAlt as falSignOutAlt,
  faTimes as falTimes,
  faUser as falUser,
  faUserCog as falUserCog,
  faXmarkLarge as falXmarkLarge
} from "@fortawesome/pro-light-svg-icons";
import { faInfoCircle, faPlus as farPlus } from "@fortawesome/pro-regular-svg-icons";
import {
  faAcorn as fasAcorn,
  faCaretDown as fasCaretDown,
  faCaretRight as fasCaretRight,
  faCheckCircle as fasCheckCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faLockAlt as fasLockAlt,
  faQuestionCircle as fasQuestionCircle,
  faTimes as fasTimes
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faInfoCircle,
  falClock,
  falRedo,
  falHeart,
  falUser,
  falClipboardCheck,
  falLeaf,
  falLockAlt,
  falCheckCircle,
  falExclamationTriangle,
  fasLockAlt,
  fasCheckCircle,
  falCommentsAlt,
  falCircleQuestion,
  falMobile,
  falClipboardList,
  falUserCog,
  falEnvelope,
  falEnvelopeOpen,
  falCloudDownload,
  falSignOutAlt,
  fasQuestionCircle,
  fasExclamationTriangle,
  fasCaretDown,
  fasCaretRight,
  fasAcorn,
  falEye,
  falEyeSlash,
  falCheck,
  falTimes,
  fasTimes,
  falCircle,
  falCircleXmark,
  falXmarkLarge,
  falArrowsFromLine,
  falMagnifyingGlass,
  falChevronDown,
  farPlus
);
