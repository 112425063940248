import * as React from "react";
import slugify from "slugify";

import { CheckboxItem } from "./SuitedCheckbox.style";

interface Props {
  value: string | boolean | number;
  children: React.ReactNode;
  name?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  className?: string;
}

const SuitedCheckbox = (props: Props) => {
  const { checked, indeterminate, disabled } = props;
  const labelId = props.name
    ? slugify(`${props.name}`, { lower: true })
    : slugify(`${props.value}`, { lower: true });

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    if (props.onChange) props.onChange(e);
  };

  return (
    <CheckboxItem className={props.className} data-test="component-suited-checkbox">
      <input
        ref={(input) => {
          if (input) {
            input.checked = checked || false;
            input.indeterminate = indeterminate || false;
            input.disabled = disabled || false;
          }
        }}
        type="checkbox"
        name={props.name}
        value={`${props.value}`}
        onChange={handleChange}
        id={labelId}
        checked={props.checked}
        disabled={props.disabled}
        data-test="checkbox-input"
      />
      <label htmlFor={labelId} data-test="checkbox-label">
        {props.children}
      </label>
    </CheckboxItem>
  );
};

export default SuitedCheckbox;
