import styled from "styled-components";

import { sizes, colors, typography } from "../../constants/style-constants";
import ButtonRowCentered from "../shared/layout/ButtonRowCentered";

export const ThankYouRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0 5px 0;
`;

export const Error = styled.h4`
  color: ${colors.status.error.base};
`;

export const StyledButtonRowCentered = styled(ButtonRowCentered)`
  margin-top: ${sizes.gridUnits.times3};
`;

export const ConfirmationText = styled.p`
  color: ${typography.color.light.base};
  margin-bottom: 1rem;
`;

export const ResendEmailHelperText = styled.p`
  color: ${typography.color.light.base};
  margin-bottom: 1.85rem;
`;
