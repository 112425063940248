import styled from "styled-components";

import { responsiveTargets, sizes } from "../constants/style-constants";

const SuitedContentArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  width: 100%;
  @media ${responsiveTargets.phone} {
    padding: ${sizes.gridUnits.times5} ${sizes.gridUnits.times2};
    padding-top: ${sizes.gridUnits.times9};
    &:last-child {
      padding-bottom: ${sizes.gridUnits.times15};
    }
  }
  @media ${responsiveTargets.tablet} {
    max-width: ${sizes.containerWidths.lg};
    padding: ${sizes.gridUnits.times6} ${sizes.gridUnits.times5};
    padding-top: ${sizes.gridUnits.times9};
  }
  @media ${responsiveTargets.laptop} {
    padding-top: ${sizes.gridUnits.times9};
  }
  @media ${responsiveTargets.desktop} {
    max-width: ${sizes.containerWidths.xl};
    padding: ${sizes.gridUnits.times7} ${sizes.gridUnits.times10};
    padding-top: ${sizes.gridUnits.times10};
  }
  @media ${responsiveTargets.jumbo} {
    padding-top: ${sizes.gridUnits.times14};
  }
`;

export default SuitedContentArea;
