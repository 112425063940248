import { includes as _includes, get as _get } from "lodash";

import { getUserProfile } from "./authUtil";
import { TUserProfileCompletionData } from "graphql/User/types";
import { AccountTypes } from "suited/constants/user.constants";

export function getProfileObj() {
  let profileObj;
  try {
    profileObj = getUserProfile();
    profileObj = JSON.parse(profileObj);
  } catch (e) {
    console.error("getProfileObj: Error parsing profile object");
    console.error(e);
    return;
  }
  return profileObj;
}

export function isUserAdmin() {
  let isAdmin = false;
  let userProfile = getProfileObj();
  if (userProfile) {
    // check the Auth0-based profile to see if this user is an admin
    let groups = _get(userProfile, "app_metadata.authorization.groups");
    isAdmin = groups && _includes(groups, "Intel Portal Admins");
  }
  return isAdmin;
}

type ProfileCompleteOptions = TUserProfileCompletionData & {
  isCandideAssessmentComplete: boolean;
  completedResume: boolean;
};

export const isProfileComplete = (args: ProfileCompleteOptions) => {
  return Object.values(args).every(Boolean);
};

export const isCampusUser = (accountType: string | undefined) => {
  return accountType === AccountTypes.CAMPUS;
};

export default { getProfileObj, isUserAdmin };
