// @ts-nocheck
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash/debounce";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";
import Async from "react-select/async";

import { Box, Center, Flex, HStack, Stack } from "@suited/components";
import { omit } from "@suited/utils";

import {
  CustomLoadingMessageComponent,
  CustomNoOptionsMessageComponent
} from "suited/components/GeneralSurvey/GeneralDropdown.style";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { RadioItem } from "suited/components/shared/buttons/SuitedRadioButton.style";
import { UnstyledButton } from "suited/components/shared/buttons/UnstyledButton";
import StyledReactSelect from "suited/components/shared/inputs/select/common.style";
import { Divider } from "suited/components/shared/layout/Divider";
import SuitedCopyCaption from "suited/components/shared/typography/SuitedCopyCaption";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { timeouts } from "suited/constants/interaction-constants";
import { DEFAULT_REQUIRED_ERROR_MESSAGE } from "suited/constants/validation.constants";

import {
  GET_GEOGRAPHIC_LOCATION_DATA,
  GET_USER_LOCATION_PREFERENCES,
  UPDATE_USER_LOCATION_PREFERENCES
} from "../workPreferences.queries";
import type {
  GetGeoDataQuery,
  LocationItem,
  LocationPreferencesQuery,
  UpdateLocationPreferencesMutation
} from "../workPreferences.types";
import { createCompoundKey, removeDefaultEventStyle } from "../workPreferences.utils";
import {
  DEFAULT_REMOTE_WORK_PREFERENCE,
  LocationPreferences,
  OPTIONS_LOCATION_PREFERENCES
} from "./WorkPreferences.constants";
import {
  CloseIconContainer,
  CustomDropdownIconContainer,
  FormLabel,
  LocationPreferenceRow,
  WorkPreferencesContent
} from "./WorkPreferences.style";
import {
  getDefaultLocationPreferenceValue,
  getLocationDropdownValue,
  isFormValid
} from "./WorkPreferences.utils";

export const WorkPreferences = () => {
  const { data, loading } = useQuery<LocationPreferencesQuery>(GET_USER_LOCATION_PREFERENCES);
  const [getGeographicLocationData] = useLazyQuery<GetGeoDataQuery>(GET_GEOGRAPHIC_LOCATION_DATA);

  const WAIT = 300;
  const debouncedGetGeographicLocationData = debounce(
    async (value: string, callback: (arg: any) => void) => {
      const response = await getGeographicLocationData({
        variables: { address: value }
      });

      const options: { label: string; value: string }[] =
        response?.data?.GetGeoData?.map((location) => ({
          ...location,
          label: location.label,
          value: location.label
        })) || [];

      callback(options);
    },
    WAIT
  );

  const [locationDraggingId, setLocationDraggingId] = useState("");
  const [dragOverLocationId, setDragOverLocationId] = useState("");

  const workPreferences = data?.GetUser?.userProfile?.workPreferences;
  const locations = useMemo(() => workPreferences?.locations || [], [workPreferences]);
  const workPreference = workPreferences?.workStyle?.preferredStyle;

  const [setLocationPreferences, { loading: isSetLocationPreferencesLoading }] =
    useMutation<UpdateLocationPreferencesMutation>(UPDATE_USER_LOCATION_PREFERENCES, {
      refetchQueries: [{ query: GET_USER_LOCATION_PREFERENCES }],
      update(cache, _, { variables }) {
        const queryCache = {
          query: GET_USER_LOCATION_PREFERENCES,
          variables
        };
        const userCache = cache.readQuery<LocationPreferencesQuery>(queryCache);

        cache.writeQuery({
          ...queryCache,
          data: {
            GetUser: {
              ...userCache?.GetUser
            }
          }
        });

        reset({
          locationPreference: getDefaultLocationPreferenceValue({
            workPreference: DEFAULT_REMOTE_WORK_PREFERENCE,
            locations: variables.locations
          }),
          locations: variables?.locations
        });

        // Send Heap analytics events
        const hasProfileAlreadyBeenCompleted =
          data?.GetUser?.userProfileCompletion?.completedWorkPreferences;
        if (!hasProfileAlreadyBeenCompleted) {
          variables?.locations?.forEach((location: LocationItem) => {
            window?.heap?.track("wp-preferred-location", { location: location.label });
          });
        }
      }
    });

  const locationPreference = getDefaultLocationPreferenceValue({ workPreference, locations });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm({
    mode: "all",
    defaultValues: {
      locationPreference: locationPreference,
      locations: workPreferences?.workStyle?.locations
    }
  });

  const values = useWatch({ control });

  useEffect(() => {
    setValue("locationPreference", locationPreference);
  }, [setValue, locationPreference]);

  useEffect(() => {
    setValue("locations", locations);
  }, [setValue, locations]);

  const locationRefs = useRef(
    locations.reduce((acc, { label, lat, lng }) => {
      const id = createCompoundKey({ label, lat, lng });
      return {
        ...acc,
        [id]: createRef()
      };
    }, {})
  );
  const userHasAtLeastOneLocationSaved = values.locations?.length;
  const isValid = isFormValid(values);
  const isSaveButtonDisabled = isSetLocationPreferencesLoading || !isDirty || !isValid;

  return (
    <Box padding="xl" overflow="auto">
      <Center>
        <WorkPreferencesContent>
          <Stack space="xl">
            <SuitedCopyHeadline noMargin>Location Preferences</SuitedCopyHeadline>
            <Divider />
            <SuitedCopySubheadline noMargin>Instructions</SuitedCopySubheadline>
            <Box data-testid="instructions">
              <Stack>
                <p>Please add your location preferences in the fields below.</p>
                <p>
                  Your personal work preferences will help prospective employers identify the roles
                  most suitable to your goals.
                </p>
              </Stack>
            </Box>
            <Divider />
            {!loading ? (
              <Stack space="xl">
                <Box>
                  <Stack space="lg">
                    <Box>
                      <Stack>
                        <FormLabel id="new-location">Where do you want to work?</FormLabel>
                        <Controller
                          name="locationPreference"
                          control={control}
                          render={({ field: { onChange, ref, value, ...props } }) => {
                            return (
                              <Select
                                aria-labelledby="visibility"
                                classNamePrefix="react_select"
                                label="Location Preferences"
                                placeholder="Select location preference"
                                data-testid="suited-single-select-select-input"
                                isClearable={false}
                                options={OPTIONS_LOCATION_PREFERENCES}
                                value={getLocationDropdownValue({
                                  locationPreference: values.locationPreference,
                                  locations: values.locations
                                })}
                                onChange={(option: SingleValue<{ label: string; value: string }>) =>
                                  onChange(option?.value)
                                }
                                styles={StyledReactSelect}
                                inputRef={ref}
                                {...props}
                              />
                            );
                          }}
                        />
                      </Stack>
                    </Box>

                    {values.locationPreference === LocationPreferences.CHOOSE_LOCATION && (
                      <Box>
                        <Stack>
                          <SuitedCopyCaption noMargin style={{ fontSize: "0.8rem" }}>
                            Add your location preferences. Choose as many as you like. Drag and
                            reorder your preferences as needed.
                          </SuitedCopyCaption>
                          {/* Add dynamic key based on locations length to clear input */}
                          {/* See https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select */}
                          <Async
                            key={`new-location-${values.locations?.length}`}
                            data-testid="new-location"
                            aria-labelledby="new-location"
                            styles={StyledReactSelect}
                            isClearable={false}
                            loadOptions={debouncedGetGeographicLocationData}
                            noOptionsMessage={CustomNoOptionsMessageComponent}
                            placeholder="Enter city or zip code"
                            components={{
                              DropdownIndicator: () => (
                                <CustomDropdownIconContainer>
                                  <FontAwesomeIcon icon={["fal", "magnifying-glass"]} />
                                </CustomDropdownIconContainer>
                              ),

                              LoadingMessage: CustomLoadingMessageComponent
                            }}
                            isOptionDisabled={(option: { value: string; label: string }) => {
                              return values.locations?.some(
                                (location) => location.label === option.value
                              );
                            }}
                            onChange={(option: LocationItem) => {
                              setValue(
                                "locations",
                                [...(values.locations as LocationItem[]), option],
                                {
                                  shouldDirty: true
                                }
                              );
                            }}
                          />
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Box>

                {values.locationPreference === LocationPreferences.CHOOSE_LOCATION && (
                  <Box>
                    <Stack space="lg">
                      {values.locations?.map(({ label, lat, lng }, index: number) => {
                        const id = createCompoundKey({ label, lat, lng });
                        const currentRef = locationRefs.current[id];
                        return (
                          <div
                            ref={currentRef}
                            key={id}
                            data-testid="location-preference"
                            style={{ position: "relative" }}
                          >
                            <Box
                              draggable
                              height="60px"
                              style={{
                                position: "relative",
                                cursor: !!locationDraggingId ? "grabbing" : "grab"
                              }}
                              onDragStart={(e) => {
                                setLocationDraggingId(id as string);

                                removeDefaultEventStyle(e);
                              }}
                              onDragLeave={(e) => {
                                e.preventDefault();

                                removeDefaultEventStyle(e);
                              }}
                              onDrag={(e) => {
                                e.preventDefault();

                                removeDefaultEventStyle(e);
                              }}
                              onDragEnter={(e) => {
                                e.preventDefault();

                                removeDefaultEventStyle(e);
                              }}
                              onDragOver={(e) => {
                                e.preventDefault();

                                removeDefaultEventStyle(e);

                                if (dragOverLocationId !== id) {
                                  setDragOverLocationId(id);
                                }
                              }}
                              onDrop={(e) => {
                                e.preventDefault();

                                removeDefaultEventStyle(e);

                                setDragOverLocationId("");
                                setLocationDraggingId("");
                                if (locationDraggingId !== id) {
                                  const currentDraggingItemIndex = values.locations?.findIndex(
                                    (locationListItem) => {
                                      const { label, lat, lng } = locationListItem;
                                      const id = createCompoundKey({ label, lat, lng });
                                      return id === locationDraggingId;
                                    }
                                  );

                                  // Swap location when rearranged
                                  const clonedLocationsList = [
                                    ...(values.locations as LocationItem[])
                                  ];
                                  const draggingElement =
                                    clonedLocationsList[currentDraggingItemIndex as number];

                                  clonedLocationsList[currentDraggingItemIndex as number] =
                                    clonedLocationsList[index];
                                  clonedLocationsList[index] = draggingElement;

                                  setValue("locations", clonedLocationsList, {
                                    shouldDirty: true
                                  });
                                }
                              }}
                            >
                              <LocationPreferenceRow
                                padding="md"
                                isDraggingOver={id === dragOverLocationId}
                              >
                                <HStack space="lg">
                                  <Box>
                                    <FontAwesomeIcon size="lg" icon={["fal", "arrows-from-line"]} />
                                  </Box>
                                  <Box>
                                    <HStack space="sm">
                                      <p>{index + 1}.</p>
                                      <p>{label}</p>
                                    </HStack>
                                  </Box>
                                </HStack>
                              </LocationPreferenceRow>
                            </Box>
                            <CloseIconContainer>
                              <UnstyledButton
                                data-testid="close-button"
                                onClick={() => {
                                  const filteredLocations = values.locations?.filter((location) => {
                                    const { label, lat, lng } = location;
                                    const locationId = createCompoundKey({
                                      label,
                                      lat,
                                      lng
                                    });
                                    return locationId !== id;
                                  });

                                  setValue("locations", filteredLocations as LocationItem[], {
                                    shouldDirty: true
                                  });
                                }}
                              >
                                <FontAwesomeIcon size="lg" icon={["fal", "xmark-large"]} />
                              </UnstyledButton>
                            </CloseIconContainer>
                          </div>
                        );
                      })}
                    </Stack>
                  </Box>
                )}
              </Stack>
            ) : null}
          </Stack>
          <Flex justify="flex-end">
            <Box padding="md">
              <SuitedButton
                purpose="primary"
                alignRight
                disabled={isSaveButtonDisabled}
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                onClick={() => {
                  handleSubmit((values) => {
                    if (values.locationPreference === LocationPreferences.CHOOSE_LOCATION) {
                      setLocationPreferences({
                        variables: {
                          locations: values.locations.map((location) =>
                            omit(location, ["__typename", "value"])
                          ),
                          preferredStyle: DEFAULT_REMOTE_WORK_PREFERENCE
                        }
                      });
                      return;
                    }

                    setLocationPreferences({
                      variables: {
                        locations: [],
                        preferredStyle: DEFAULT_REMOTE_WORK_PREFERENCE
                      }
                    });
                  })();
                }}
              >
                Save
              </SuitedButton>
            </Box>
          </Flex>
        </WorkPreferencesContent>
      </Center>
    </Box>
  );
};
