import styled from "styled-components";

import { SuitedButton, ISuitedButtonProps } from "suited/components/shared/buttons/SuitedButton";
import { sizes, typography } from "suited/constants/style-constants";

interface Props extends ISuitedButtonProps {}

const StyledSuitedButton = styled(SuitedButton)`
  height: ${sizes.touch.minimum};
  font-size: ${typography.sizes.form.compact.button};
  padding: 0 ${sizes.gridUnits.times1};
`;

export const SuitedCompactButton = (props: Props) => {
  const { children, ...passthroughProps } = props;
  return (
    <StyledSuitedButton {...passthroughProps} data-testid="component-compact-button">
      {props.children}
    </StyledSuitedButton>
  );
};
