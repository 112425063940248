import _get from "lodash/get";
import _omit from "lodash/omit";
import { ApolloClientInstance, OmitWithTypename, PickWithTypename } from "../../../types";
import { USER_PROFILE_COMPLETION } from "./graphql";
import { TCandidateProfileCompletionQueryData } from "./types";
import { TUserProfileCompletionData, ECACompletionData } from "../../../graphql/User/types";
import { assessmentStatusTypes } from "../eca/eca.constants";

/**
 * Retrieves the user data needed from Apollo's cache
 * to determine if a user's profile is completed.
 * @param client The Apollo Client instance.
 * @param optimisticSurveyCompletion A boolean indicating whether or
 * not to optimistically set the status of the users survey
 * answers to `complete`.
 */
function getUserCompletionCache(
  client: ApolloClientInstance,
  optimisticSurveyCompletion?: boolean
): TCandidateProfileCompletionQueryData {
  try {
    let cache: TCandidateProfileCompletionQueryData = client.readQuery({
      query: USER_PROFILE_COMPLETION
    });

    if (cache && optimisticSurveyCompletion) {
      cache = {
        ...cache,
        GetUserSurveyAnswers: {
          ...cache.GetUserSurveyAnswers,
          status: "complete"
        }
      };
    }

    return cache;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Checks to see if a Candidate has completed their profile.
 *
 * If the Candidates profile is complete we fire the
 * analytics events for Google Analytics, Facebook and LinkedIn.
 * @param client The Apollo Client instance.
 * @param optimisticSurveyCompletion Whether or not to use the optimistic cache.
 *
 */
export function checkIfProfileComplete(
  client: ApolloClientInstance,
  optimisticSurveyCompletion?: boolean
): boolean {
  const cache = getUserCompletionCache(client, optimisticSurveyCompletion);

  if (cache) {
    // Suited Assessment
    const suitedAssessmentIsComplete: boolean =
      _get(cache, "GetUserSurveyAnswers.status", false) === "complete";

    // Assessments
    const uncleanedAssessmentCache = _get(cache, "GetCandidateAssessments");
    // Dont try and map over results if we don't have any.
    if (!uncleanedAssessmentCache) return false;
    const cleanedAssessmentCache = uncleanedAssessmentCache.map(
      (assessment: PickWithTypename<ECACompletionData, "status" | "id">) =>
        _omit(assessment, "__typename")
    );
    const assessmentsAreComplete = cleanedAssessmentCache.every(
      (assessment: Pick<ECACompletionData, "status">) =>
        assessment.status === assessmentStatusTypes.COMPLETE
    );

    // Profile forms
    const uncleanedProfileCache: TUserProfileCompletionData | {} = _get(
      cache,
      "GetUser.userProfileCompletion",
      {}
    );
    const cleanedProfileCache: OmitWithTypename<TUserProfileCompletionData> | {} = _omit(
      uncleanedProfileCache,
      "__typename"
    );
    const profileFormsStatuses: boolean[] = Object.values(cleanedProfileCache);
    const profileFormsAreComplete = profileFormsStatuses.every((value) => value === true);

    const profileIsCompleted =
      suitedAssessmentIsComplete && profileFormsAreComplete && assessmentsAreComplete;

    return profileIsCompleted;
  }

  return false;
}

/**
 * Imperatively adds a tracking pixel using an HTML image tag.
 * @param src The source of the tracker url.
 * @param id The id of the tracker node.
 */
export function imperativelyAddTracker(src: string, id: string): HTMLImageElement {
  const tracker = document.createElement("img");

  tracker.setAttribute("id", id);
  tracker.setAttribute("height", "1");
  tracker.setAttribute("width", "1");
  tracker.setAttribute("style", "display: none;");
  tracker.setAttribute("src", src);

  window.document.body.appendChild(tracker);

  return tracker;
}
