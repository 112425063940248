import React, { CSSProperties } from 'react';
import clsx from 'clsx';

import { SpacingOptions } from '../../types';
import './Text.css';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  size?: SpacingOptions;
  weight?: CSSProperties['fontWeight'];
  align?: CSSProperties['textAlign'];
  color?: CSSProperties['color'];
  family?: CSSProperties['fontFamily'];
  transform?: CSSProperties['textTransform'];
  decoration?: CSSProperties['textDecoration'];
  fontStyle?: CSSProperties['fontStyle'];
  wordBreak?: CSSProperties['wordBreak'];
  lineHeight?: CSSProperties['lineHeight'];
  letterSpacing?: CSSProperties['letterSpacing'];
  textOverflow?: CSSProperties['textOverflow'];
  whiteSpace?: CSSProperties['whiteSpace'];
  bold?: boolean;
  center?: boolean;
  italic?: boolean;
  underline?: boolean;
  as?: React.ElementType;
}

export const Text = ({
  children,
  className,
  style,
  size = 'md',
  weight = 'normal',
  align = 'initial',
  color = '#000',
  family = 'initial',
  transform = 'none',
  decoration = 'none',
  fontStyle = 'normal',
  wordBreak = 'normal',
  lineHeight = 'normal',
  letterSpacing = 'normal',
  textOverflow = 'clip',
  whiteSpace = 'normal',
  bold = false,
  center = false,
  italic = false,
  underline = false,
  as = 'div',
  ...props
}: Props) => {
  const C = as;
  const classNames = clsx('ws-c-text', className);
  const styles = {
    ...style,
    '--text-font-size': `var(--minor-third-${size})`,
    '--text-align': center ? 'center' : align,
    '--text-font-weight': bold ? 'bold' : weight,
    '--text-color': color,
    '--text-font-style': italic ? 'italic' : fontStyle,
    '--text-font-family': family,
    '--text-transform': transform,
    '--text-decoration': underline ? 'underline' : decoration,
    '--text-word-break': wordBreak,
    '--text-line-height': lineHeight,
    '--text-letter-spacing': letterSpacing,
    '--text-overflow': textOverflow,
    '--text-white-space': whiteSpace,
  };
  return (
    <C className={classNames} style={styles} {...props}>
      {children}
    </C>
  );
};
