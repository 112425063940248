import { uuidv4 } from "@suited/utils";
import { IResume, TResumeReducerAction } from "./types";
import { initialState } from "./constants";

export const reducer = (state: IResume, action: TResumeReducerAction) => {
  switch (action.type) {
    case "delete":
      return initialState;
    case "error":
      return { ...state, error: action.error, isUploading: false };
    case "uploadComplete":
      return {
        ...state,
        uploadedFile: action.uploadedFile,
        isUploading: false,
        uploaderId: uuidv4()
      };
    case "uploading":
      return { ...state, isUploading: true };
    case "updateId":
      return { ...state, uploaderId: uuidv4() };
  }
};
