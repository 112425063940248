// @ts-nocheck
import React from "react";
import Select, { ActionMeta } from "react-select";

import {
  InputContainer,
  LabelContainer,
  StyledReactSelect,
  StyledOverDarkReactSelect
} from "suited/components/shared/inputs/select/common.style";
import { SuitedAltInputLabel } from "suited/components/shared/typography/SuitedAltInputLabel";
import ISelectOption from "suited/components/shared/types/ISelectOption";
import { ISuitedSelectBaseProps } from "suited/components/shared/inputs/select/common.types";
import { portalConfig } from "suited/components/shared/inputs/select/constants";

export interface ISuitedSingleSelectProps extends ISuitedSelectBaseProps {
  /** When the text is over a dark background, it has a light appearance. */
  overDark?: boolean;
  /** Override default margin */
  noMargin?: boolean;
  /** The selected value(s) */
  value: ISelectOption;
  /** The options that populate the dropdown selector */
  options: ISelectOption[];
  /** onChange is a state setter for sending the selected option to the controlling component, generally a useState */
  onChange: (value: ISelectOption) => void;
  children?: React.ReactNode;
}

/**
 * ## What
 * `<SuitedSingleSelect>` is a controlled(gets its value state from parent component) component that renders a labeled styled single-select select input. This component is a wrapper for ["React Select"](https://react-select.com/home)
 * It renders the value stored in an external state. The value is initialized from the `value` prop when it mounts.
 *
 * ## When
 * This component is used whenever a dropdown is needed and only one value can be selected.
 *
 * ## Not
 * This should not be used if you need a multi select dropdown.
 * If you want to support selecting multiple values, use the `SuitedMultiSelect` component instead.
 *
 * ## Example
 * In Candidate App, it's used on the education form.
 * In Recruiter App, it's used on the candidate list filters.
 */
export const SuitedSingleSelect = (props: ISuitedSingleSelectProps) => {
  const options = props.options ? props.options : [];

  const handleChange = (selectedValue: ISelectOption, { action }: ActionMeta<string>) => {
    switch (action) {
      case "select-option":
        return props.onChange(selectedValue);
      case "clear":
        return props.onChange(undefined);
    }
  };

  const getMenuPosition = () => {
    return props.usePortal === true
      ? props.menuPosition !== undefined
        ? props.menuPosition
        : portalConfig.DEFAULT_PORTAL_MENU_POSITION
      : undefined;
  };

  const getMenuPlacement = () => {
    return props.usePortal === true
      ? props.menuPlacement !== undefined
        ? props.menuPlacement
        : portalConfig.DEFAULT_PORTAL_MENU_PLACEMENT
      : undefined;
  };

  const getMenuPortalTarget = () => {
    return props.usePortal === true
      ? props.menuPortalTarget !== undefined
        ? props.menuPortalTarget
        : portalConfig.DEFAULT_PORTAL_MENU_TARGET
      : undefined;
  };

  const getStyles = () => {
    return props.styles
      ? props.styles
      : props.overDark
      ? StyledOverDarkReactSelect
      : StyledReactSelect;
  };

  return (
    <InputContainer
      noMargin={props.noMargin}
      className={props.className}
      data-testid="suited-single-select-root-el"
    >
      {props.label ? (
        <LabelContainer overDark={props.overDark}>
          <SuitedAltInputLabel
            htmlFor={props.name}
            overDark={props.overDark}
            data-testid="suited-single-select-label"
          >
            {props.label}
          </SuitedAltInputLabel>
          {props.children}
        </LabelContainer>
      ) : null}
      <Select
        data-testid="suited-single-select-select-input"
        classNamePrefix="react_select"
        isMulti={false}
        overDark={props.overDark}
        menuPosition={getMenuPosition()}
        menuPlacement={getMenuPlacement()}
        menuPortalTarget={getMenuPortalTarget()}
        minMenuHeight={props.minMenuHeight}
        maxMenuHeight={props.maxMenuHeight}
        styles={getStyles()}
        placeholder={props.isDisabled ? "" : props.placeholder}
        name={props.name}
        inputId={props.name}
        value={props.value}
        options={options}
        onChange={handleChange}
        isClearable={props.isClearable ? true : false}
        isDisabled={props.isDisabled}
        isSearchable={props.isSearchable}
        closeMenuOnSelect={props.closeMenuOnSelect}
        noOptionsMessage={props.noOptionsMessage}
      />
    </InputContainer>
  );
};
