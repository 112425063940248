import React from "react";
import styled from "styled-components";

import { typography, sizes } from "../../../constants/style-constants";

const Label = styled.label`
  padding: ${sizes.gridUnits.divide4} 0;
  color: ${typography.color.dark.base};
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.form.compact.label};
  font-weight: ${typography.weight.body.light};
  text-transform: uppercase;
  line-height: 1;
`;

type Props = {
  children: React.ReactNode;
};

const SuitedDetailLabel = (props: Props) => {
  return (
    <Label {...props} data-test="component-suited-detail-label">
      {props.children}
    </Label>
  );
};

export default SuitedDetailLabel;
