import clsx from 'clsx';

import { omit } from '@suited/utils';
import { SpacingOptions, BaseComponentProps } from '../../types';
import { getContentAreaProps } from '../../utils';
import { CONTENT_AREA_PROPERTIES } from '../../constants';
import './Box.css';

type StyleProps = Pick<
  React.CSSProperties,
  'borderWidth' | 'borderColor' | 'borderStyle' | 'borderRadius' | 'overflow'
>;

interface Props extends BaseComponentProps, StyleProps {
  padding?: SpacingOptions;
  bg?: React.CSSProperties['backgroundColor'];
}

export const Box = ({
  children,
  className,
  style,
  padding = 'none',
  borderWidth = '0px',
  borderColor = '#000',
  borderStyle = 'solid',
  borderRadius = '0px',
  bg = 'initial',
  overflow = 'visible',
  ...props
}: Props) => {
  const classNames = clsx('ws-l-box', className);
  const rest = omit(props, CONTENT_AREA_PROPERTIES);
  const styles = {
    ...style,
    ...getContentAreaProps(props),
    '--box-padding': `var(--perfect-fifth-${padding})`,
    '--box-border-width': borderWidth,
    '--box-border-color': borderColor,
    '--box-border-style': borderWidth ? borderStyle : 'none',
    '--box-border-radius': borderRadius,
    '--box-background-color': bg,
    '--box-overflow': overflow,
  };
  return (
    <div className={classNames} style={styles} {...rest}>
      {children}
    </div>
  );
};
