import React, { FunctionComponent } from "react";

import { IconName } from "@fortawesome/fontawesome-common-types";
import Icon, { IconWeight } from "../typography/Icon";
import { Button, A } from "./SuitedIconButton.style";

interface Props {
  /**
   * The name of a version-supported FontAwesome Pro icon
   * (see https://fontawesome.com/icons?d=gallery)
   **/
  iconName: IconName;
  /**
   * Click handler for the majority of use cases. When present, it renders a button in markup.
   */
  onClickButton?: () => void;
  // @TODO unit tests for href prop
  /**
   * In some rare cases (file downloads), the button should be a link. When present, it renders and anchor in markup.
   */
  href?: string;
  /**
   * A FontAwesome icon weight: "solid" | "regular" | "light"
   * @default "light"
   **/
  iconWeight?: IconWeight;
  /**
   * This string gets applied to the rendered button's `alt` and `aria-label` attributes.
   * @default "icon-button"
   **/
  tooltip?: string;
  disabled?: boolean;
  /**
   * Number of milliseconds between click-event and `onClick` handler fired.
   * This allows the button :active transition to show before navigation, for example.
   * @default 200
   **/
  delay?: number;
  /**
   * Opt-in to event bubbling for special use cases
   */
  bubbleEvents?: boolean;
  /**
   * @TODO needs unit tests
   * Button type can be specified, e.g. for form submit behavior
   */
  type?: "button" | "submit" | "reset" | undefined;
  /**
   * Styled-Component support
   */
  className?: string;

  style?: React.CSSProperties;
}

const SuitedIconButton: FunctionComponent<Props> = (props) => {
  const {
    iconName,
    onClickButton,
    iconWeight = "light",
    tooltip = "icon-button",
    disabled,
    delay = 200,
    type,
    className,
    ...passthroughProps
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!props.bubbleEvents) e.stopPropagation();
    if (disabled) return;
    delay
      ? window.setTimeout(() => {
          onClickButton && onClickButton();
        }, delay)
      : onClickButton && onClickButton();
  };

  if (!!onClickButton) {
    return (
      <Button
        className={className}
        {...passthroughProps}
        type={type || "button"}
        title={tooltip}
        aria-label={tooltip}
        onClick={handleClick}
        onKeyDown={(e) => e.stopPropagation()}
        disabled={disabled}
        data-test="component-suited-icon-button"
      >
        <Icon name={iconName} weight={iconWeight} data-test="toggle-icon" />
      </Button>
    );
  } else {
    return (
      <A
        className={className}
        {...passthroughProps}
        title={tooltip}
        aria-label={tooltip}
        href={!disabled ? props.href : "#"}
        disabled={disabled}
        data-test="component-suited-icon-button"
      >
        <Icon name={iconName} weight={iconWeight} data-test="toggle-icon" />
      </A>
    );
  }
};

export default SuitedIconButton;
