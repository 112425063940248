/**
 * Ensures a valid structure for a Candidate's Ethnicity information i.e.
 * making sure that a candidate cannot select `prefer not to disclose` and
 * an ethnicity.
 */
export function validateEthnicity(
  newEthnicities: string[],
  currentEthnicities: string[]
): string[] {
  const preferNotToDiscloseValue = "Prefer not to disclose";
  const indexOfPreferNotToDiscloseValue = currentEthnicities.indexOf(preferNotToDiscloseValue);
  const userAlreadyPreferredNotToDisclose = indexOfPreferNotToDiscloseValue > -1;
  const userSelectedPreferToNotToDisclose = newEthnicities.indexOf(preferNotToDiscloseValue) > -1;

  if (userAlreadyPreferredNotToDisclose)
    return newEthnicities.filter(item => item !== preferNotToDiscloseValue);
  else if (userSelectedPreferToNotToDisclose) return [preferNotToDiscloseValue];
  else return [...newEthnicities];
}
