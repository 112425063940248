import { gql } from "@apollo/client";

export const GET_CANDIDATE_ASSESSMENTS = gql`
  query GetCandidateAssessments {
    GetCandidateAssessments {
      id
      title
      status
      sections {
        id
        status
        timerMultiplier
      }
    }
  }
`;

export const GET_ASSESSMENT = gql`
  query GetAssessment($assessmentID: String!) {
    GetAssessment(assessmentID: $assessmentID) {
      id
      title
      status
      sections {
        id
        status
        timerMultiplier
      }
    }
  }
`;

export const GET_NEXT_ASSESSMENT_SECTION_INFORMATION = gql`
  query GetNextAssessmentSectionInformation($assessmentID: String!) {
    GetNextAssessmentSectionInformation(assessmentID: $assessmentID) {
      id
      status
      completedSections
      totalSections
      timerDuration
      timerRemaining
      instructions
      isInformational
      sampleItemGroups {
        id
        leadingText
        itemType
        items {
          id
          type
          label
          ... on MatrixTableItem {
            value
          }
          ... on MultiSelectItem {
            options {
              id
              label
              isExclusive
              isSelected
            }
          }
          ... on TextInputItem {
            value
          }
        }
        ... on MatrixTableItemGroup {
          headers {
            label
            value
          }
        }
      }
    }
  }
`;

export const GET_NEXT_ASSESSMENT_SECTION_QUESTIONS = gql`
  query GetNextAssessmentSectionQuestions($assessmentID: String!) {
    GetNextAssessmentSectionQuestions(assessmentID: $assessmentID) {
      id
      status
      completedSections
      totalSections
      timeRemaining
      itemGroups {
        id
        leadingText
        itemType
        items {
          id
          type
          label
          ... on MatrixTableItem {
            value
          }
          ... on MultiSelectItem {
            options {
              id
              label
              isExclusive
              isSelected
            }
          }
          ... on MultipleChoiceItem {
            options {
              id
              label
              isExclusive
              isSelected
            }
          }
          ... on TextInputItem {
            value
          }
        }
        ... on MatrixTableItemGroup {
          headers {
            label
            value
          }
        }
      }
    }
  }
`;

export const SUBMIT_ITEM_RESPONSE = gql`
  mutation SubmitItemResponse($id: String!, $values: [ItemResponseInput!]!, $timestamp: String!) {
    SubmitItemResponse(id: $id, values: $values, timestamp: $timestamp) {
      status
    }
  }
`;

export const ASSESSMENT_SECTION_CLOSE = gql`
  mutation AssessmentSectionClose($id: String!, $closeType: String!) {
    AssessmentSectionClose(id: $id, closeType: $closeType)
  }
`;

export const CREATE_DEFAULT_ASSESSMENTS = gql`
  mutation CreateDefaultAssessments {
    CreateDefaultAssessments {
      id
      title
      status
      sections {
        id
        status
        timerMultiplier
      }
    }
  }
`;
