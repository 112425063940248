import styled from "styled-components";
import { sizes, colors, typography } from "../../../constants/style-constants";
import SuitedIconButton from "../buttons/SuitedIconButton";

export const StyledSuitedIconButton = styled(SuitedIconButton)`
  flex: 0 0 auto;
  margin-left: 0;
  color: ${typography.color.light.base};
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    color: ${typography.color.light.emphasis};
    background-color: ${colors.tints.times1};
    transform: scale(1);
    border: 1px solid ${colors.tints.times2};
    box-shadow: inset 0px 0px 0px 2px ${colors.tints.times2};
  }
  &:focus:not([disabled]) {
    border: 1px solid ${colors.color1.shade1};
    box-shadow: inset 0px 0px 0px 2px ${colors.color1.shade1};
  }
  &:active:not([disabled]),
  &:hover:not([disabled]):active,
  &:focus:not([disabled]):active {
    color: ${typography.color.light.emphasis};
    background-color: ${colors.tints.times2};
    border: 1px solid ${colors.color1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.color1.base};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  margin: 0;
  padding-left: ${sizes.gridUnits.times1};
  flex: 0 0 auto;
  height: ${sizes.gridUnits.times5};
  background-color: ${colors.background.dark.backdrop};
  color: ${typography.color.light.emphasis};
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.nav.heading};
`;

export const Title = styled.div`
  flex: 1 1 auto;
  margin-left: ${sizes.gridUnits.times1};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
