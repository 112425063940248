import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
`;

export const FadeInWrapper = styled.div`
  animation: ${fadeInAnimation} ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;
