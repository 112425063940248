import styled from "styled-components";

import { colors, typography, sizes } from "../constants/style-constants";

export const ContainerBox = styled.div`
  align-self: stretch;
`;

export const FormLabelName = styled.span``;

export const FormInputs = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const FormFieldLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  ${typography.styles.label} padding-bottom: ${sizes.gridUnits.times1};
  text-align: left;
`;

export const SecondaryText = styled(FormFieldLabel)`
  ${typography.styles.hint}; /* */
  margin-top: -${sizes.gridUnits.divide2};
  padding-bottom: ${sizes.gridUnits.times1};
  color: ${typography.color.dark.hint};
`;

interface DropDownProps {
  completed: boolean;
}
export const FormDropdownContainer = styled.div<DropDownProps>`
  .Select-control {
    border: 1px solid
      ${(props) => (props.completed ? colors.background.light.backdrop : colors.status.error.base)};
    border-radius: 4px;
    height: 55px;
    background: ${colors.background.light.tertiary};
    &:hover {
      -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }
  }
  .Select.has-value.is-clearable.is-searchable.Select--single {
    color: ${typography.color.dark.hint};
    width: 100%;
  }
  .Select.has-value.Select--single {
    > .Select-control {
      .Select-value {
        .Select-value-label {
          background-color: ${colors.background.light.backdrop};
          border: 1px solid rgba(0, 126, 255, 0.24);
          border-radius: 4px;
          color: ${colors.accent1.base};
          display: inline-block;
          line-height: 1.4;
          margin-left: 5px;
          margin-top: 7px;
          vertical-align: top;
          padding: 8px 10px;
          font-size: 16px;
        }
      }
    }
  }
  .Select.has-value.is-pseudo-focused.Select--single {
    > .Select-control {
      .Select-value {
        .Select-value-label {
          background-color: ${colors.background.light.backdrop};
          border: 1px solid rgba(0, 126, 255, 0.24);
          border-radius: 4px;
          color: ${colors.accent1.base};
        }
      }
    }
  }
  .Select-placeholder {
    color: ${typography.color.dark.hint};
    font-weight: ${typography.weight.body.light};
    padding: 10px 0px 0px 20px;
    font-size: 16px;
    text-align: left;
  }
  .Select-value-label {
    color: ${typography.color.dark.hint};
    font-weight: ${typography.weight.body.light};
    padding: 5px 0px 0px 10px;
    font-size: 16px;
  }
  .Select-input {
    height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    font-size: 16px;
    width: auto;
    > input {
      width: 100%;
      margin: 6px;
      color: ${colors.accent1.base};
    }
  }
  .Select-arrow {
    border-color: $border_color_1;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    position: relative;
  }
  .Select-clear {
    display: inline-block;
    font-size: 30px;
    line-height: 1;
  }
  .Select-clear-zone,
  .Select-arrow-zone,
  .Select-control > *:last-child {
    padding-right: 15px;
  }

  .Select.is-searchable.is-open {
    > .Select-control {
      cursor: text;
      overflow: visible;
    }
  }
  .Select.is-open {
    > .Select-control {
      .Select-arrow {
        .Select.is-focused {
          > .Select-control {
            background: ${colors.background.light.tertiary};
          }
        }
      }
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background: ${colors.background.light.tertiary};
      border: 1px solid ${colors.background.light.backdrop};
      transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
    }
  }
  .Select.is-focused {
    &:not(.is-open) {
      > .Select-control {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        background: ${colors.background.light.tertiary};
        border: 1px solid ${colors.background.light.backdrop};
        transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
      }
    }
  }
  .Select-menu-outer {
    font-size: 14px;
  }
  .Select-multi-value-wrapper {
    margin: 5px;
  }
`;

export const FormResponse = styled.h5``;
