import styled from "styled-components";

import { typography, sizes, responsiveRanges } from "../../constants/style-constants";
import SuitedFormSubheadline from "../shared/typography/SuitedFormSubheadline";
import SuitedFormInstructions from "../shared/typography/SuitedFormInstructions";

export const QuestionHeaderContainer = styled.div`
  @media ${responsiveRanges.phoneAndTablet} {
    padding-top: ${sizes.gridUnits.times4};
  }
`;

export const QuestionLabel = styled(SuitedFormSubheadline)`
  position: relative;
  font-size: ${typography.sizes.copy.heading.tablet};
  text-transform: none;
  text-align: left;
  font-weight: ${typography.weight.heading.medium};
  @media ${responsiveRanges.phone} {
    font-size: ${typography.sizes.copy.subheading.tablet};
  }

  &:before {
    content: attr(data-number) ".";
    position: absolute;
    top: -${sizes.gridUnits.times5};
    left: 0;
    right: 0;
    width: 100%;
    font-family: ${typography.family.accent};
    font-size: ${typography.sizes.copy.heading.tablet};
    font-weight: ${typography.weight.accent.light};
    color: ${typography.color.dark.hint};
    @media ${responsiveRanges.phone} {
      font-size: ${typography.sizes.copy.subheading.tablet};
    }
  }
`;

export const QuestionDescription = styled(SuitedFormInstructions)``;
