import React from "react";

import { SuitedCopyEl } from "./SuitedAltCopy.style";

export interface ISuitedCopyProps {
  /** display light text on dark backgrounds */
  overDark?: boolean;
  /** noMargin is a boolean that if true, will set the CSS property `margin-bottom: 0 !important`. */
  noMargin?: boolean;
  /** Styled-Component compatible */
  className?: string;
  children: React.ReactNode;
}

/**
 * ## What
 * `<SuitedCopy>` is a text decorator used for any paragraph content or other large body of text.
 *
 * ## When
 * Generally this component is used right after a `<SuitedCopyHeadline>` component. It is used inside views with a large amount of text content, or as instructions for forms in a view or a dialog.
 *
 * ## Not
 * This should not be used to label figures such as images, charts, diagrams. It should not be used to label inputs or controls.
 *
 * ## Example
 * In Candidate App, instructions for a form use this component.
 */
export const SuitedAltCopy = (props: ISuitedCopyProps) => {
  return (
    <SuitedCopyEl {...props} data-testid="component-suited-copy">
      {props.children}
    </SuitedCopyEl>
  );
};
