import React from "react";

import { Box } from "@suited/components";
import { noop } from "@suited/utils";

import AssessmentDialog from "suited/components/CandidateHome/Assessment/AssessmentDialog/AssessmentDialog";
import { colors } from "suited/constants/style-constants";

type Props = {
  children: React.ReactNode;
  show?: boolean;
};

export const FocusMode = ({ children, show = true }: Props) => {
  return (
    <AssessmentDialog
      show={show}
      doNotCloseOnEsc
      onCancel={noop}
      zIndex={5}
      renderAssessment={() => (
        <Box
          id="focus-mode-container"
          overflow="auto"
          height="100vh"
          width="100vw"
          bg={colors.color6.tint1}
        >
          <Box width="100%">{children}</Box>
        </Box>
      )}
    />
  );
};
