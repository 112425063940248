import { getFormattedTimerDuration } from "suited/components/shared/feedback/Timer/Timer.utils";

const pluralizeUnitOfTime = (str: string, num: number) => {
  return num !== 1 ? `${str}s` : str;
};

export const getTimeAllotedToSectionCopy = (duration: number = 0) => {
  const formattedTimerDuration = getFormattedTimerDuration(duration);
  const formattedUnitOfTime = pluralizeUnitOfTime("minute", parseFloat(formattedTimerDuration));

  return `${formattedTimerDuration} ${formattedUnitOfTime}`;
};

export const getECAPercentComplete = (completedSections: number = 0, totalSections: number = 1) => {
  const MULTIPLIER = 100;
  return Math.round((completedSections / totalSections) * MULTIPLIER);
};

export const getIsFinalSectionOfAssessment = (
  completedSections: number = 0,
  totalSections: number = 0
) => {
  // If there's only 1 section left, that means we are on the final section of assessment.
  return totalSections - completedSections === 1;
};
