import { assessmentItemTypes } from "../eca.constants";
import type {
  ItemGroupProps,
  MatrixTableItemProps,
  MultiSelectItemProps,
  TextInputItemProps
} from "../eca.types";

export const getIsEveryQuestionAnsweredInSection = (itemGroups: ItemGroupProps[] = []) => {
  return itemGroups.every((itemGroup) => {
    if (itemGroup.itemType === assessmentItemTypes.MULTISELECT) {
      return (itemGroup.items as MultiSelectItemProps[]).every((item) => {
        return item.options.some((option) => option.isSelected);
      });
    }
    return (itemGroup.items as TextInputItemProps[] | MatrixTableItemProps[]).every(
      (item) => !!item.value
    );
  });
};
