import { Box, Stack } from "@suited/components";
import { QuestionCopy } from "../eca.styles";
import { TextInputItem } from "../TextInputItem/TextInputItem";
import type { SubmitItemVariables, TextInputItemProps } from "../eca.types";

interface Props {
  leadingText: string;
  items: TextInputItemProps[];
  onSubmit: (data: SubmitItemVariables) => void;
}

export const TextInputItemGroup = ({ leadingText, items, onSubmit }: Props) => {
  return (
    <Stack space="lg">
      {leadingText && <QuestionCopy>{leadingText}</QuestionCopy>}
      <Box>
        <Stack space="xl">
          {items.map(({ id, label, value }) => (
            <TextInputItem key={id} id={id} label={label} value={value} onSubmit={onSubmit} />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};
