import { useEffect } from "react";
import { useQuery, FetchPolicy } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";
import {
  AssessmentID,
  GetAssessmentQuery,
  GetNextAssessmentSectionQuestionsQuery,
  GetNextAssessmentSectionInformationQuery,
  GetCandidateAssessmentsQuery
} from "./eca.types";
import {
  GET_ASSESSMENT,
  GET_NEXT_ASSESSMENT_SECTION_QUESTIONS,
  GET_NEXT_ASSESSMENT_SECTION_INFORMATION,
  GET_CANDIDATE_ASSESSMENTS
} from "./eca.queries";
import {
  getNumberOfCompletedSections,
  setNumberOfCompletedSections,
  setTotalNumberOfSections
} from "./eca.slice";
import { APOLLO_DEFAULT_FETCH_POLICY } from "suited/constants/apollo.constants";

type Options = {
  fetchPolicy?: FetchPolicy;
  skip?: boolean;
};

export function useGetCandidateAssessments({
  fetchPolicy = APOLLO_DEFAULT_FETCH_POLICY
}: Options = {}) {
  const { data: candidateAssessmentsData, loading: isGetCandidateAssessmentsLoading } =
    useQuery<GetCandidateAssessmentsQuery>(GET_CANDIDATE_ASSESSMENTS, { fetchPolicy });
  const assessments = candidateAssessmentsData?.GetCandidateAssessments || [];
  return {
    assessments,
    isGetCandidateAssessmentsLoading,
    assessmentID: assessments.find(Boolean)?.id || ""
  };
}

export function useGetCandidateAssessmentById(
  assessmentID: string,
  { fetchPolicy = APOLLO_DEFAULT_FETCH_POLICY }: Options = {}
) {
  const { data, loading: isGetAssessmentLoading } = useQuery<GetAssessmentQuery>(GET_ASSESSMENT, {
    skip: !assessmentID,
    variables: { assessmentID },
    fetchPolicy
  });

  const sections = data?.GetAssessment?.sections;

  return {
    assessment: data?.GetAssessment,
    sections,
    isGetAssessmentLoading
  };
}

export function useGetNextAssessmentSectionQuestions({
  fetchPolicy = APOLLO_DEFAULT_FETCH_POLICY,
  skip
}: Options = {}) {
  const { assessmentID } = useGetCandidateAssessments();
  const { loading, error, data } = useQuery<GetNextAssessmentSectionQuestionsQuery, AssessmentID>(
    GET_NEXT_ASSESSMENT_SECTION_QUESTIONS,
    {
      fetchPolicy,
      skip: !assessmentID || skip,
      variables: { assessmentID }
    }
  );

  const values = data?.GetNextAssessmentSectionQuestions;

  return {
    loading,
    error,
    data,
    id: values?.id,
    itemGroups: values?.itemGroups || [],
    timeRemaining: values?.timeRemaining,
    completedSections: values?.completedSections,
    totalSections: values?.totalSections
  };
}

export function useGetNextAssessmentSectionInformation({
  fetchPolicy = APOLLO_DEFAULT_FETCH_POLICY,
  skip
}: Options = {}, assessmentId: string | undefined = undefined) {
  const assessmentID = assessmentId === undefined ? useGetCandidateAssessments()?.assessmentID : assessmentId;
  const { loading, error, data, refetch } = useQuery<
    GetNextAssessmentSectionInformationQuery,
    AssessmentID
  >(GET_NEXT_ASSESSMENT_SECTION_INFORMATION, {
    fetchPolicy,
    skip: !assessmentID || skip,
    variables: { assessmentID }
  });

  const values = data?.GetNextAssessmentSectionInformation;

  return {
    loading,
    error,
    data,
    refetch,
    id: values?.id,
    instructions: values?.instructions || [],
    sampleItemGroups: values?.sampleItemGroups || [],
    timerDuration: values?.timerDuration,
    timerRemaining: values?.timerRemaining,
    completedSections: values?.completedSections,
    totalSections: values?.totalSections,
    isInformational: values?.isInformational
  };
}

export function useHydrateStatusBar() {
  const dispatch = useAppDispatch();
  const previousNumberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);
  const { assessmentID, isGetCandidateAssessmentsLoading } = useGetCandidateAssessments();
  const { sections, isGetAssessmentLoading } = useGetCandidateAssessmentById(assessmentID);

  const { id } = useGetNextAssessmentSectionInformation({ fetchPolicy: "network-only" });

  useEffect(() => {
    // Used for cases when the page is refreshed and we need to "rehydrate"
    // the ECA progress bar's state
    const numberOfCompletedSections = sections?.findIndex((section) => section.id === id) || 0;

    // Prevent "jump" in progress bar when race condition occurs. Prevents number of completed from jumping for example, from 2 back to 1, then back to 2 again.
    if (numberOfCompletedSections > previousNumberOfCompletedSections) {
      dispatch(setNumberOfCompletedSections(numberOfCompletedSections));
    }

    if (sections?.length) {
      dispatch(setTotalNumberOfSections(sections?.length));
    }
  }, [dispatch, sections, id, previousNumberOfCompletedSections]);

  return { sections, isGetCandidateAssessmentsLoading, isGetAssessmentLoading };
}
