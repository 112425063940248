import { useEffect } from "react";
import axios from "axios";
import queryString from "query-string";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History, Location } from "history";
import { setAuthToken } from "suited/components/Login";
import { storeUserId } from "suited/util/authUtil";

interface Props {
  history: History;
  location: Location;
  setAuthToken: (token: string) => void;
}

const UserEmailChangeConfirmed = (props: Props) => {
  // @TODO - get this to pass linter for hooks dependencies
  useEffect(() => {
    const initialize = async () => {
      const email = queryString.parse(props.location.search).email;
      const id = queryString.parse(props.location.search).id;
      const res = await axios.request({
        method: "POST",
        url: `auth/confirm-change-email-auth`,
        data: {
          email: decodeURIComponent(email as string),
          id
        }
      });

      if (res.status === 200) {
        // store the userId in cookie
        storeUserId(res.data.userId);
        // authenticate
        props.setAuthToken(res.data.token);
      }

      await props.history.push("/home/user-settings");
    };

    initialize();
  }, []); // eslint-disable-line

  return <div />;
};

const mapDispatchToProps = (dispatch) => ({
  setAuthToken: (token: string) => {
    dispatch(setAuthToken(token));
  }
});

export default withRouter(connect(null, mapDispatchToProps)(UserEmailChangeConfirmed));
