import { ApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import axios from "axios";

import { NullOrUndefined } from "../types";
import { TUserId } from "../graphql/User/types";

const queryUser = gql`
  {
    GetUser {
      id
    }
  }
`;

function logErrorToSlack(error, info, client: ApolloClient<object>) {
  let userData: NullOrUndefined<TUserId> = null;

  try {
    userData = client.readQuery({
      query: queryUser
    });
  } catch (err) {
    if (err instanceof Error) {
      axios.post("/client/error", {
        errorMsg: `GraphQL Query Error: ${err.message} \nURL: ${window.location.href}`,
        info,
        platform: navigator.platform ? navigator.platform : ""
      });
    }
  } finally {
    axios.post("/client/error", {
      errorMsg: `${error.toString()} \nURL: ${window.location.href} \nUser: ${
        userData && userData.GetUser.id ? userData.GetUser.id : "Not authenticated"
      }`,
      info,
      platform: navigator.platform ? navigator.platform : ""
    });
  }
}

export default logErrorToSlack;
