import * as React from "react";

import { FormFieldLabel, SecondaryText } from "../Forms";
import { ContainerBox } from "../Forms";
import {
  MultiSelectContainer,
  Item,
  MultiSelectOptionContainer,
  OptionLabel
} from "./GeneralMultiSelect.style";

interface MultiSelectOption {
  value: string;
  label: string;
}

interface MultiSelectProps {
  values: string[];
  name: string;
  label: string;
  secondaryText?: string;
  options: MultiSelectOption[];
  onChange(value: string[], name: string): void;
  rows?: number;
  completed: boolean;
  disabled?: boolean;
}
interface MultiSelectState {
  completed: boolean;
}

function toggleValue(values: string[], value: string) {
  let newValues: string[];
  if (values.indexOf(value) > -1) {
    newValues = values.filter((item) => item !== value);
  } else {
    newValues = values.concat([value]);
  }
  return newValues;
}

export default class GeneralMultiSelect extends React.Component<
  MultiSelectProps,
  MultiSelectState
> {
  constructor(props) {
    super(props);
    this.state = {
      completed: this.props.completed
    };
  }
  static getDerivedStateFromProps(nextProps) {
    return {
      completed: nextProps.values && nextProps.values.length > 0 ? true : nextProps.completed
    };
  }
  render() {
    const { name, values = [], options, onChange, rows = 2, label } = this.props;
    return (
      <ContainerBox data-testid={name}>
        <FormFieldLabel>{label}</FormFieldLabel>
        {this.props.secondaryText ? (
          <SecondaryText> {this.props.secondaryText}</SecondaryText>
        ) : null}
        <MultiSelectContainer completed={this.state.completed}>
          {options.map((option, i) => {
            const checked = values ? values.indexOf(option.value) > -1 : values;
            const onClick = () => {
              if (this.props.disabled) return;
              onChange(toggleValue(values, option.value), name);
            };
            const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
              if (this.props.disabled) return;
              if (e.keyCode === 32) {
                e.preventDefault();
                onChange(toggleValue(values, option.value), name);
              }
            };
            return (
              <Item key={`${name}-${i}`} rows={rows}>
                <MultiSelectOptionContainer
                  onClick={onClick}
                  onKeyDown={onKeyDown}
                  checked={checked}
                  data-selected={checked}
                  tabIndex={0}
                  disabled={this.props.disabled}
                >
                  <OptionLabel>{option.label}</OptionLabel>
                </MultiSelectOptionContainer>
              </Item>
            );
          })}
        </MultiSelectContainer>
      </ContainerBox>
    );
  }
}
