import { useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, Stack, Center } from "@suited/components";
import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";
import { assessmentViews, sectionStatusTypes } from "../eca.constants";
import {
  getNumberOfCompletedSections,
  setCurrentAssessmentView,
  setNumberOfCompletedSections
} from "../eca.slice";
import { getIsFinalSectionOfAssessment } from "../eca.utils";
import { getIsEveryQuestionAnsweredInSection } from "./NextButton.utils";
import { useGetCandidateAssessments, useGetNextAssessmentSectionQuestions } from "../eca.hooks";
import { ASSESSMENT_SECTION_CLOSE, GET_ASSESSMENT } from "../eca.queries";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { TimeLeftWarningModal } from "../TimeLeftWarningModal/TimeLeftWarningModal";
import { AssessmentID, GetAssessmentQuery } from "../eca.types";
import { TimeRemainingMessage } from "./NextButton.style";

export const NextButton = () => {
  const [isTimeLeftWarningModalOpen, setIsTimeLeftWarningModalOpen] = useState(false);
  const numberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);
  const { assessmentID } = useGetCandidateAssessments();
  const { id, itemGroups, totalSections, timeRemaining } = useGetNextAssessmentSectionQuestions();

  const isFinalSectionOfAssessment = getIsFinalSectionOfAssessment(
    numberOfCompletedSections,
    totalSections
  );

  const dispatch = useAppDispatch();
  const [closeAssessmentSection, { loading }] = useMutation(ASSESSMENT_SECTION_CLOSE, {
    onCompleted: () => {
      if (isFinalSectionOfAssessment) {
        dispatch(setCurrentAssessmentView(assessmentViews.END_OF_ASSESSMENT));
      } else {
        dispatch(setCurrentAssessmentView(assessmentViews.SECTION_INFO));
      }
      dispatch(setNumberOfCompletedSections(numberOfCompletedSections + 1));
    },

    update(cache) {
      const queryCache = {
        query: GET_ASSESSMENT,
        variables: { assessmentID }
      };

      const assessmentCache = cache.readQuery<GetAssessmentQuery, AssessmentID>(queryCache);
      const notCompleteIndex = assessmentCache?.GetAssessment?.sections?.findIndex((section) => {
        return section.status !== sectionStatusTypes.COMPLETE;
      });
      const updatedSections = assessmentCache?.GetAssessment?.sections?.map((section, index) => {
        if (index === notCompleteIndex) {
          return { ...section, status: sectionStatusTypes.COMPLETE };
        }

        return section;
      });

      cache.writeQuery({
        ...queryCache,
        data: {
          GetAssessment: {
            ...assessmentCache?.GetAssessment,
            sections: updatedSections
          }
        }
      });
    },
    fetchPolicy: "no-cache",
    variables: {
      id,
      closeType: "MANUAL"
    }
  });

  const isEveryQuestionAnsweredInSection = getIsEveryQuestionAnsweredInSection(itemGroups);
  const isUserFinishedEarly =
    isEveryQuestionAnsweredInSection && timeRemaining && timeRemaining > 0;

  const handleNextButtonClick = () => {
    if (isUserFinishedEarly) {
      setIsTimeLeftWarningModalOpen(true);
    } else {
      closeAssessmentSection();
    }
  };
  return (
    <>
      <Stack space="xl">
        {!isEveryQuestionAnsweredInSection ? (
          <TimeRemainingMessage>
            You still have time! Please attempt to answer every question before moving on.
          </TimeRemainingMessage>
        ) : null}
        <Center>
          <Box>
            <SuitedButton
              purpose="primary"
              disabled={!isEveryQuestionAnsweredInSection || loading}
              onClick={handleNextButtonClick}
            >
              Next
            </SuitedButton>
          </Box>
        </Center>
      </Stack>
      <TimeLeftWarningModal
        isOpen={isTimeLeftWarningModalOpen}
        onCancel={() => {
          setIsTimeLeftWarningModalOpen(false);
        }}
        closeAssessmentSection={closeAssessmentSection}
      />
    </>
  );
};
