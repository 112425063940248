import _get from "lodash/get";

import { Center, Stack } from "@suited/components";

import { Email, NewEmail } from "./UserEmailChangeForm.style";
import Icon from "suited/components/shared/typography/Icon";
import { TUser } from "../../../../graphql/User/types";

type Props = {
  user: TUser;
};

export const UserEmailChangeDisplay = ({ user }: Props) => {
  return (
    <Stack space="none">
      <Email noMargin>{user.email}</Email>
      <Center>
        <Icon name="long-arrow-down" weight="light" size="2x" />
      </Center>
      <NewEmail noMargin>{_get(user, "settings.changeEmail.newEmail", "target email")}</NewEmail>
    </Stack>
  );
};
