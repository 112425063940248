import { TransitionState, ISuitedAssessment } from "./types";

export const ANIM_DELAY = 500;
export const ANIM_SPEED = 300;

export const initialState: ISuitedAssessment = {
  currentQuestionIndex: 0,
  errors: {},
  transition: TransitionState.NONE
};
