import moment from "moment";

import ISelectOption from "suited/components/shared/types/ISelectOption";

export const UNDERGRAD_TOOLTIP =
  "Please report cumulative GPA for all courses of study rather than major-specific GPA. \n\nIf you attended a non-U.S. university, please convert to a 4.0 scale using the online calculator provided by World Education Services (Grade Conversion Guide), which can be found at: \n\nhttps://applications.wes.org/igpa-calculator/.";

export const GRADUATE_TOOLTIP = `Please report cumulative GPA for this graduate degree program. \n\nIf GPA is not applicable to your graduate degree program, or if you do not yet have a GPA to report, enter "N/A".`;

export const UNLISTED_SCHOOL_VALUE = "Other - my school is not listed";

export const UNLISTED_SCHOOL_OPTION: ISelectOption = {
  label: "Other - my school is not listed",
  value: "Other - my school is not listed"
};

export const INDUSTRY_CONTEXTS = {
  LAW: "law",
  FINANCE: "finance_banking_investment"
} as const;

export const JURIS_DOCTOR = "Juris Doctor (JD)";

export const NEW_SCHOOL_ID = "NEW_SCHOOL";

const OPTIONS_DEGREE_BASE = [
  "Associates",
  "Bachelors",
  "Masters",
  "Doctorate",
  "Professional"
].map((label) => ({ label, value: label }));

export const OPTIONS_DEGREE = [
  ...OPTIONS_DEGREE_BASE,
  { label: "Juris Doctor (JD)", value: "Juris Doctor (JD)" }
];

export const OPTIONS_DEGREE_LAW_CONTEXT = [
  { label: "Juris Doctor (JD)", value: "Juris Doctor (JD)" },
  ...OPTIONS_DEGREE_BASE
];

export const OPTIONS_MAJOR = [
  "Accounting",
  "Actuarial Science",
  "Aerospace Engineering",
  "African-American Studies",
  "Agricultural/Biological Engineering and Bioengineering",
  "Agricultural Economics",
  "American Studies",
  "Anthropology",
  "Applied Mathematics",
  "Art",
  "Art History",
  "Biology",
  "Biomedical Engineering",
  "Business Administration/Management",
  "Business Communications",
  "Business Education",
  "Chemical Engineering",
  "Chemistry",
  "Cinematography and Film/Video Production",
  "Civil Engineering",
  "Classics",
  "Commerce",
  "Communications Studies/Speech Communication and Rhetoric",
  "Computer and Information Science",
  "Computer Engineering",
  "Creative Writing",
  "Criminology",
  "Digital Communications and Media/Multimedia",
  "East Asian Studies",
  "Economics",
  "Education",
  "Electrical Engineering",
  "Engineering",
  "English",
  "Entrepreneurship",
  "Environmental Science",
  "Fashion Design",
  "Finance",
  "Financial Engineering",
  "Financial Mathematics",
  "Food Science",
  "Foreign Affairs",
  "French",
  "Geology",
  "German",
  "Government",
  "Graphic Design",
  "History",
  "History and Literature",
  "Hospitality",
  "Human Development",
  "Human Environmental Sciences",
  "Industrial Economics",
  "Industrial Engineering",
  "Information Technology",
  "International Affairs",
  "International Business",
  "International Relations",
  "International Studies",
  "Journalism",
  "Law",
  "Law & Economics",
  "Linguistics",
  "Management Information Systems",
  "Management Science",
  "Managerial Economics",
  "Marketing",
  "Mass Communication",
  "Mathematics",
  "Mechanical Engineering",
  "Microeconomics",
  "Molecular Biology",
  "Music",
  "Oceanography",
  "Operations Management",
  "Organizational Behavior Studies",
  "Petroleum Engineering",
  "Philosophy",
  "Photography",
  "Physical Therapy",
  "Physics",
  "Physiological Psychology",
  "Political Science",
  "Pre-Law / Law",
  "Psychology",
  "Public Policy Analysis",
  "Public Relations",
  "Quantitative Economics",
  "Radio and Television",
  "Social Psychology",
  "Sociology",
  "Spanish",
  "Sport and Leisure Studies",
  "Statistics",
  "Strategy",
  "Theatre",
  "Urban Planning"
].map((label) => ({ label, value: label }));

export const OPTIONS_GPA = [
  "N/A",
  "4.0",
  "3.9",
  "3.8",
  "3.7",
  "3.6",
  "3.5",
  "3.4",
  "3.3",
  "3.2",
  "3.1",
  "3.0",
  "2.9",
  "2.8",
  "2.7",
  "2.6",
  "2.5",
  "2.4",
  "2.3",
  "2.2",
  "2.1",
  "2.0",
  "1.9",
  "1.8",
  "1.7",
  "1.6",
  "1.5",
  "1.4",
  "1.3",
  "1.2",
  "1.1",
  "1.0"
].map((label) => ({ label, value: label }));

export const OPTIONS_MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
].map((label) => ({ label, value: label }));

let years = [] as ISelectOption[];
for (let i = 1975; i <= moment().year() + 10; i++) {
  years.push({
    label: i.toString(),
    value: i.toString()
  });
}
export const OPTIONS_YEARS = years.reverse();
