import { factory, oneOf, manyOf, primaryKey, nullable } from "@mswjs/data";
import { faker } from "@faker-js/faker";
import moment from "moment";
import { OPTIONS_MAJOR } from "./education.constants";

const majorOptions = OPTIONS_MAJOR.map((option) => option.value);

export const db = factory({
  SchoolObj: {
    __typename: () => "SchoolObj",
    id: primaryKey(faker.datatype.uuid),
    name: String,
    status: () => "APPROVED"
  },
  School: {
    __typename: () => "School",
    id: primaryKey(faker.datatype.uuid),
    degree: String,
    major: nullable(() => faker.helpers.arrayElement(majorOptions)),
    gpa: String,
    graduationDate: () => moment(faker.date.past()).format(),
    school: String,
    schoolObj: oneOf("SchoolObj")
  },
  PersonalData: {
    __typename: () => "PersonalInformation",
    id: primaryKey(faker.datatype.uuid),
    birthYear: () => 2022,
    gender: () => "Male",
    veteran: () => "No",
    lgbtq: () => "No",
    firstGenCollege: () => "No",
    ethnicity: () => "Hispanic or Latino"
  },
  Location: {
    __typename: () => "WorkPreference",
    id: primaryKey(faker.datatype.uuid),
    lat: () => 12.3,
    lng: () => -45.6,
    label: String
  },
  WorkStyle: {
    __typename: () => "WorkPreference",
    id: primaryKey(faker.datatype.uuid),
    preferredStyle: () => "FULLY_REMOTE",
    alternative_values: () => "OFFICE"
  },
  WorkPreference: {
    __typename: () => "WorkPreference",
    id: primaryKey(faker.datatype.uuid),
    noPreference: () => false,
    locations: manyOf("Location"),
    workStyle: oneOf("WorkStyle")
  },
  UserProfileData: {
    __typename: () => "UserProfileData",
    id: primaryKey(faker.datatype.uuid),
    schools: manyOf("School"),
    personalData: oneOf("PersonalData"),
    workPreferences: oneOf("WorkPreference")
  },
  UserProfileCompletionData: {
    __typename: () => "UserProfileCompletionData",
    id: primaryKey(faker.datatype.uuid),
    completedSchools: () => false,
    completedPersonalInformation: () => false
  },
  DiversityOptIn: {
    __typename: () => "DiversityOptIn",
    id: primaryKey(faker.datatype.uuid),
    optIn: Boolean
  },
  settings: {
    __typename: () => "UserSettings",
    id: primaryKey(faker.datatype.uuid),
    visibility: () => "public",
    diversityOptIn: oneOf("DiversityOptIn")
  },
  GetUser: {
    __typename: () => "User",
    id: primaryKey(faker.datatype.uuid),
    userProfile: oneOf("UserProfileData"),
    userProfileCompletion: oneOf("UserProfileCompletionData"),
    settings: oneOf("settings")
  }
});
