import React from "react";
import styled from "styled-components";

import {
  sizes,
  typography,
  responsiveTargets,
  boxShadows,
  containerWidths,
  colors
} from "../../../constants/style-constants";

const Div = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: ${sizes.gridUnits.times2} auto ${sizes.gridUnits.times8} auto;
  padding: ${sizes.gridUnits.times8};
  max-width: ${containerWidths.xl};
  background-color: ${colors.background.light.secondary};
  box-shadow: ${boxShadows.depth1};
  font-family: ${typography.family.body};
  color: ${typography.color.dark.base};
  & > h1,
  & > h2,
  & > h3,
  & > p {
    max-width: 100%;
    color: ${typography.color.dark.base};
    margin: 0;
    margin-bottom: ${sizes.gridUnits.times2};
  }
  & > h1 {
    ${typography.styles.heading};
  }
  & > h2 {
    ${typography.styles.subheading};
  }
  & > h3 {
    ${typography.styles.body} font-weight: ${typography.weight.body.medium};
  }
  & > p {
    ${typography.styles.body} strong {
      ${typography.styles.emphasis};
    }
    em {
      ${typography.styles.emphasis};
    }
  }
  & > h1 {
    font-size: ${typography.sizes.copy.title};
  }
  & > h2 {
    font-size: ${typography.sizes.copy.heading.base};
  }
  & > h3 {
    font-size: ${typography.sizes.copy.subheading.base};
  }
  & > p {
    font-size: ${typography.sizes.copy.body.base};
    margin-bottom: ${typography.sizes.copy.body.base};
  }
  /* @TODO - reconcile primary/legacy font family and font weight */
  @media ${responsiveTargets.phone} {
    margin: 0;
    padding: ${sizes.gridUnits.times2};
    width: 100%;
    max-width: 100%;
    &:not(:first-child) {
      border-top: 1px solid rgba(0, 0, 0, 0.08);
    }
    &:last-child {
      padding-bottom: ${sizes.gridUnits.times8};
    }
  }
  @media ${responsiveTargets.tablet} {
    flex: 0 0 auto;
    margin: 0 ${sizes.gridUnits.times2} ${sizes.gridUnits.times2} ${sizes.gridUnits.times2};
    padding: ${sizes.gridUnits.times4};
    width: auto;
    &:first-child {
      margin-top: ${sizes.gridUnits.times2};
    }
    &:last-child {
      margin-bottom: ${sizes.gridUnits.times8};
    }
  }
  @media ${responsiveTargets.desktop} {
    flex: 0 0 auto;
    margin: ${sizes.gridUnits.times2} auto ${sizes.gridUnits.times4} auto;
    padding: ${sizes.gridUnits.times8};
    width: ${containerWidths.xl};
    max-width: ${containerWidths.xl};
  }
`;

type Props = {
  className?: string;
  children?: React.ReactNode;
};

/**
 * Paper is a responsive layout wrapper that appears as a piece of paper in the workspace.
 * It applies padding appropriate to the current responsive media query target.
 */
const Paper = ({ className, children }: Props) => {
  return (
    <Div className={className} data-test="component-paper">
      {children}
    </Div>
  );
};

export default Paper;
