import { useRef, useEffect } from "react";

// Pattern picked up from the answer here: https://stackoverflow.com/questions/55075604/react-hooks-useeffect-only-on-update
export function useInitialMountEffect(onMountFunction: () => void) {
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      onMountFunction();
      isInitialMount.current = false;
    }
  });
}
