import { Box, Stack } from "@suited/components";
import { QuestionCopy } from "suited/components/CandidateHome/eca/eca.styles";
import type {
  MultipleChoiceItemProps,
  SubmitItemVariables
} from "suited/components/CandidateHome/eca/eca.types";
import { MultipleChoiceItem } from "../MultipleChoiceItem/MultipleChoiceItem";

type Props = {
  leadingText: string;
  items: MultipleChoiceItemProps[];
  onSubmit: (data: SubmitItemVariables) => void;
};

export const MultipleChoiceItemGroup = ({ leadingText, items, onSubmit }: Props) => {
  return (
    <Box>
      <Stack space="xxxl">
        {leadingText && (
          <Box>
            <QuestionCopy>{leadingText}</QuestionCopy>
          </Box>
        )}
        <Box>
          <Stack space="xxxl">
            {items.map(({ id, label, options }) => (
              <MultipleChoiceItem
                key={id}
                itemID={id}
                label={label}
                options={options}
                onSubmit={onSubmit}
              />
            ))}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
