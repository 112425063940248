import { Box, Stack } from "@suited/components";

import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";

import { FadeInWrapper } from "../UserSettings/userSettings.styles";

type Props = {
  secondaryEmail: string;
};

export const ConfirmSecondaryEmailNotification = ({ secondaryEmail }: Props) => {
  return (
    <FadeInWrapper>
      <Stack space="lg">
        <Box>
          <SuitedCopySubheadline noMargin>Verify Secondary Email</SuitedCopySubheadline>
        </Box>
        <Box>
          <Stack>
            <p>
              We've initiated the verification process for your new secondary email address:
              <strong> {secondaryEmail}</strong>
            </p>
            <p>To complete the process:</p>
            <Stack space="none">
              <p>
                1. Check the inbox of <strong>{secondaryEmail}</strong> for a validation email.
              </p>
              <p>2. Click the validation link in the email.</p>
              <p>3. Return to this page and refresh to finalize the changes.</p>
            </Stack>
            <p>
              You can safely close this window now. To complete the process later, simply follow the
              link in the verification email when you're ready.
            </p>
            <p>
              If you don't see the email in your inbox, please check your spam folder. You can also
              request a new verification email from your account settings if needed.
            </p>
          </Stack>
        </Box>
      </Stack>
    </FadeInWrapper>
  );
};
