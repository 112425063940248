import styled, { css } from "styled-components";
import { typography, animationCurves, sizes, colors } from "suited/constants/style-constants";

interface StyleProps {
  error?: any;
  touched?: boolean;
  noMargin?: boolean;
}

const getErrorBorderStyle = () => {
  return css`
    &:not(:disabled) {
      border: 1px solid ${colors.status.error.shade1};
    }
    &:not(:disabled):hover,
    &:not(:disabled):focus {
      border: 1px solid ${colors.status.error.base};
      box-shadow: inset 0px 0px 0px 2px ${colors.status.error.shade1};
    }
    &:not(:disabled):focus {
      border: 1px solid ${colors.status.error.base};
      box-shadow: inset 0px 0px 0px 2px ${colors.status.error.shade1};
      outline: none;
    }
  `;
};

export const StyledTextInput = styled.input<StyleProps>`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: ${sizes.gridUnits.times5};
  box-sizing: border-box;
  padding: ${typography.margins.input};
  background-color: ${(props) => (props.touched ? colors.tints.times4 : colors.shades.divide4)};
  border: 1px solid ${colors.shades.times1};
  box-shadow: none;
  color: ${typography.color.dark.base};
  font-size: ${typography.sizes.form.base.input};
  font-family: ${typography.family.body};
  font-weight: ${typography.weight.body.medium};
  letter-spacing: ${typography.tracking.loose};
  letter-spacing: 0.115em;
  z-index: 1;
  transition: background-color 300ms ${animationCurves.easeOutQuart},
    border-color 300ms ${animationCurves.easeOutQuart},
    box-shadow 300ms ${animationCurves.easeOutQuart};
  &::placeholder {
    color: ${typography.color.dark.disabled};
    font-family: ${typography.family.body};
    font-weight: ${typography.weight.body.light};
    letter-spacing: ${typography.tracking.loose};
  }
  &:hover,
  &:focus {
    background-color: ${colors.tints.times4};
    border-color: ${colors.shades.times2};
    box-shadow: inset 0px 0px 0px 2px ${colors.shades.times2};
  }
  &:focus {
    background-color: ${colors.background.light.primary};
    border-color: ${colors.accent1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.shades.divide2};
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 2px transparent;
    text-overflow: ellipsis;
    &::placeholder {
      color: transparent;
    }
    &:hover,
    &:focus {
      background-color: ${colors.shades.divide2};
      border-color: transparent;
      box-shadow: inset 0px 0px 0px 2px transparent;
    }
  }
  /* @TODO - use touched in a meaningful way even w/o validation */
  border-color: ${(props) => (props.touched ? colors.shades.times2 : colors.shades.times1)};
  ${(props) => (props.error ? getErrorBorderStyle() : null)}
`;
