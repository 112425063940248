import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";

interface Props {
  sitekey: string;
  onErrored?: () => void;
  onChange: (value: any) => void;
  forwardRef: React.Ref<ReCAPTCHA>;
  className?: string;
  grecaptcha?: ReCAPTCHA;
}

(window as any).recaptchaOptions = {
  useRecaptchaNet: true
};

const SuitedReCaptcha = (props: Props) => {
  const [key] = useState<string>();

  return (
    <ReCaptchaEl className={props.className} data-testid="component-recaptcha">
      <ReCAPTCHA
        key={key}
        ref={props.forwardRef}
        size="invisible"
        sitekey={props.sitekey}
        onErrored={props.onErrored}
        onChange={props.onChange}
        grecaptcha={props.grecaptcha}
        data-testid="recaptcha"
      />
    </ReCaptchaEl>
  );
};

const ReCaptchaEl = styled.div`
  .grecaptcha-badge {
    bottom: 40px !important;
    z-index: 1;
  }
`;

export default SuitedReCaptcha;
