import { Box, HStack, Stack } from "@suited/components";

import Icon from "suited/components/shared/typography/Icon";

import { ValidationWarning } from "../education.styles";
import { IValidationMessageProps } from "../education.types";
import {
  doesSchoolDataHaveJurisDoctorDegree,
  doesSchoolDataHaveUndergradDegree
} from "../education.utils";

export const LawValidationError = ({ schoolFormData, isAnyFormDirty }: IValidationMessageProps) => {
  const hasError =
    (isAnyFormDirty && !doesSchoolDataHaveJurisDoctorDegree(schoolFormData)) ||
    !doesSchoolDataHaveUndergradDegree(schoolFormData);
  return hasError ? (
    <Stack>
      {!doesSchoolDataHaveJurisDoctorDegree(schoolFormData) ? (
        <Box data-testid="validation">
          <ValidationWarning>
            <HStack space="xs">
              <Icon name="exclamation-triangle" />
              <div>Please enter a Juris Doctor (JD) degree.</div>
            </HStack>
          </ValidationWarning>
        </Box>
      ) : null}
      {!doesSchoolDataHaveUndergradDegree(schoolFormData) ? (
        <Box data-testid="validation">
          <ValidationWarning>
            <HStack space="xs">
              <Icon name="exclamation-triangle" />
              <div>You must have at least one undergraduate degree.</div>
            </HStack>
          </ValidationWarning>
        </Box>
      ) : null}
    </Stack>
  ) : null;
};
