import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import SuitedListItemLabelValuePair from "suited/components/shared/typography/SuitedTileLabelValue";

import { Box, Stack } from "@suited/components";
import { AssociationHelperText } from "../Association.style";
import {
  ListSecondaryEl,
  ListHeader,
  ListContainer,
  StyledSuitedListItemPrimaryValue,
  StyledSuitedCompactButton
} from "../../shared/InviteStates.style";
import dateFormat from "../../utils/dateFormat";
import { TCompanyAssociation, TCompanyAssociationMutation } from "../../types";
import { UPDATE_COMPANY_CANDIDATE_ASSOCIATION } from "../../GraphQL/mutations";
import { GET_COMPANY_CANDIDATE_ASSOCIATIONS } from "../../GraphQL/queries";
import { ListItem } from "../../shared/ListItem";

interface Props {
  disconnectedAssociations: TCompanyAssociation[];
}

export const Disconnected = ({ disconnectedAssociations }: Props) => {
  return (
    <ListSecondaryEl>
      <ListHeader>
        <Stack space="xxxs">
          <SuitedCopySubheadline noMargin>Disconnected</SuitedCopySubheadline>
          <AssociationHelperText>
            Disconnected means you have removed yourself from the candidate list for that particular
            company. You can reconnect whenever you would like to.
          </AssociationHelperText>
        </Stack>
      </ListHeader>
      <Box style={{ marginTop: "1rem" }}>
        <DisconnectedList disconnectedList={disconnectedAssociations} />
      </Box>
    </ListSecondaryEl>
  );
};

interface IDisconnectedListProps {
  disconnectedList: TCompanyAssociation[];
}

const DisconnectedList = ({ disconnectedList }: IDisconnectedListProps) => {
  const [loadingItems, setLoadingItems] = useState<string[]>([]);

  // Connecting to a disconnected association only needs to refetch the associations view.
  const [updateAssociationItemConnect, { loading }] = useMutation(
    UPDATE_COMPANY_CANDIDATE_ASSOCIATION,
    {
      refetchQueries: () => [{ query: GET_COMPANY_CANDIDATE_ASSOCIATIONS }],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        const completedData: TCompanyAssociationMutation = data.UpdateCompanyCandidateAssociation;
        const filteredItems = loadingItems.filter((item) => {
          return item !== completedData._id;
        });
        setLoadingItems(filteredItems);
      }
    }
  );

  const handleReconnectClick = (id: string) => {
    setLoadingItems([...loadingItems, id]);
    updateAssociationItemConnect({ variables: { id, status: "ACTIVE" } });
  };

  return (
    <ListContainer>
      {disconnectedList.map((item: TCompanyAssociation) => {
        const dateReceived = new Date(item.createdAt);
        const dateDisconnected = new Date(item.updatedAt);
        const formattedDate = dateFormat(dateReceived);
        const formattedDisconnectedDate = dateFormat(dateDisconnected);
        return (
          <ListItem
            key={item._id}
            title={
              <StyledSuitedListItemPrimaryValue>
                {item.companyName}
              </StyledSuitedListItemPrimaryValue>
            }
            detail={
              <React.Fragment>
                <SuitedListItemLabelValuePair label={"Received On"} value={formattedDate} />
                <SuitedListItemLabelValuePair
                  label={"Disconnected On"}
                  value={formattedDisconnectedDate}
                />
                <StyledSuitedCompactButton
                  onClick={() => handleReconnectClick(item._id)}
                  disabled={loadingItems.includes(item._id)}
                >
                  {loading ? "Reconnecting" : "Reconnect"}
                </StyledSuitedCompactButton>
              </React.Fragment>
            }
          />
        );
      })}
    </ListContainer>
  );
};
