import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../../configureStore";
import { AssessmentViews } from "./lateral.constants";

export const LATERAL_STATE_KEY = "lateral";

const lateralSlice = createSlice({
  name: LATERAL_STATE_KEY,
  initialState: {
    currentAssessmentView: AssessmentViews.SECTION_INFO,
    numberOfCompletedSections: 0,
    totalNumberOfSections: undefined
  },
  reducers: {
    setCurrentAssessmentView: (state, action) => {
      state.currentAssessmentView = action.payload;
    },
    setNumberOfCompletedSections: (state, action) => {
      state.numberOfCompletedSections = action.payload;
    },
    setTotalNumberOfSections: (state, action) => {
      state.totalNumberOfSections = action.payload;
    }
  }
});

// Actions
export const { setCurrentAssessmentView, setNumberOfCompletedSections, setTotalNumberOfSections } = lateralSlice.actions;

// Selectors
export const getCurrentAssessmentView = (state: RootState) => state.lateral.currentAssessmentView;
export const getNumberOfCompletedSections = (state: RootState) =>
  state.lateral.numberOfCompletedSections;
export const getTotalNumberOfSections = (state: RootState) => state.lateral.totalNumberOfSections;
//
// Reducer
export const lateralReducer = lateralSlice.reducer;
