import React from "react";

import SuitedFullBleedImage from "../../shared/layout/SuitedFullBleedImage";
import { typography } from "../../../constants/style-constants";
import {
  SplashContainer,
  SuitedSplashContainer,
  SuitedSplashFooter,
  SuitedSplashContent,
  StyledCopyrightNotice,
  SuitedSplashFooterLink,
  SuitedSplashCallout,
  Callout,
  Logo,
  SuitedSplashScroll,
} from "./SuitedSplashPage.style";

import logoWhite from "../../../img/logo-white.svg";

interface Props {
  showLogo: boolean;
  image: string;
  placeholderImage: string;
  imageAlt: string;
  calloutCopy?: string;
  children?: React.ReactNode;
  className?: string;
}

const SuitedSplashPage = (props: Props) => {
  return (
    <SplashContainer className={props.className} data-test="component-splash-page">
      <SuitedFullBleedImage
        src={props.image}
        placeholder={props.placeholderImage}
        alt={props.imageAlt}
        data-test="bg"
      />
      <SuitedSplashContainer>
        <SuitedSplashScroll data-test="scroll">
          <SuitedSplashContent data-test="content-block">
            <SuitedSplashCallout data-test="callout-block">
              {props.showLogo ? <Logo src={logoWhite} data-test="logo" /> : null}
              {props.calloutCopy ? (
                <Callout data-test="callout">{props.calloutCopy}</Callout>
              ) : null}
            </SuitedSplashCallout>
            {props.children}
          </SuitedSplashContent>
          <SuitedSplashFooter data-test="footer">
            <StyledCopyrightNotice data-test="copyright" />
            <div>
              <SuitedSplashFooterLink
                target="_blank"
                href="http://wellsuited.com/terms"
                color={`${typography.color.light.disabled}`}
                data-test="terms">
                Terms of Service
              </SuitedSplashFooterLink>
              <SuitedSplashFooterLink
                target="_blank"
                href="http://wellsuited.com/privacy"
                color={`${typography.color.light.disabled}`}
                data-test="privacy">
                Privacy Policy
              </SuitedSplashFooterLink>
            </div>
          </SuitedSplashFooter>
        </SuitedSplashScroll>
      </SuitedSplashContainer>
    </SplashContainer>
  );
};

export default SuitedSplashPage;
