import styled, { css, keyframes } from "styled-components";

import { TransitionState } from "./types";
import { ANIM_SPEED } from "./constants";
import { QuestionLabel } from "./QuestionHeader.style";
import {
  animationCurves,
  sizes,
  typography,
  responsiveRanges,
  responsiveTextStyles
} from "../../constants/style-constants";

const animSpeedSeconds = ANIM_SPEED / 1000;

interface QuestionContainerProps {
  type: string;
  transition: TransitionState;
}

const AnimFromLeft = keyframes`
  from {
    transform: translate(-100%, 0%) scale(0.75, 0.50);
  }
  to {
    transform: translate(0%, 0%) scale(1);
  }
`;

const AnimFromRight = keyframes`
  from {
    transform: translate(100%, 0%) scale(0.75, 0.50);
  }
  to {
    transform: translate(0%, 0%) scale(1);
  }
`;

const AnimToLeft = keyframes`
  from {
    transform: translate(0%, 0%) scale(1);
  }
  to {
    transform: translate(-100%, 0%) scale(0.75, 0.50);
  }
`;

const AnimToRight = keyframes`
  from {
    transform: translate(0%, 0%) scale(1);
  }
  to {
    transform: translate(100%, 0%) scale(0.75, 0.50);
  }
`;

// prettier-ignore
export const QuestionContainer = styled.div<QuestionContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  flex: 0 0 auto;
  position: relative;
  margin: auto;
  padding: ${sizes.gridUnits.times9} ${sizes.gridUnits.times3};
  max-width: 640px;
  min-width: 568px;
  @media ${responsiveRanges.phoneAndTablet} {
    padding: ${sizes.gridUnits.times5};
  }
  @media ${responsiveRanges.phone} {
    width: 100%;
    padding: ${sizes.gridUnits.times5} ${sizes.gridUnits.times3};
    min-width: 0%;
  }
  @media ${responsiveRanges.tablet} {
    max-width: ${sizes.containerWidths.lg};
    padding: ${sizes.gridUnits.times5};
  }

  ${props => {
    switch (props.transition) {
      case TransitionState.FROM_LEFT:
        return css`
          animation: ${AnimFromLeft} ${animSpeedSeconds}s ${animationCurves.easeOutQuart};
        `;
      case TransitionState.FROM_RIGHT:
        return css`
          animation: ${AnimFromRight} ${animSpeedSeconds}s ${animationCurves.easeOutQuart};
        `;
      case TransitionState.TO_LEFT:
        return css`
          animation: ${AnimToLeft} ${animSpeedSeconds}s ${animationCurves.easeInQuart};
        `;
      case TransitionState.TO_RIGHT:
        return css`
          animation: ${AnimToRight} ${animSpeedSeconds}s ${animationCurves.easeInQuart};
        `;
      default:
        return "";
    }
  }}

  ${props =>
    props.type === "statement"
      ? css`
          ${QuestionLabel} {
            ${typography.styles.body} /* */
            ${responsiveTextStyles.body} /* */
            &:before {
              content: none;
            }
          }
          ${QuestionInputContainer} {
            margin-top: ${sizes.gridUnits.times8};
            @media ${responsiveRanges.phone} {
              margin-top: ${sizes.gridUnits.times4};
            };
          }
        `
      : ""}
`;

export const QuestionInputContainer = styled.div`
  margin-top: ${sizes.gridUnits.times5};
  width: 100%;
  @media ${responsiveRanges.phone} {
    margin-top: ${sizes.gridUnits.times3};
  }
`;
