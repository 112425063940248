import gql from "graphql-tag";

export const GET_SURVEY_DEFINITION = gql`
  query {
    GetSurveyDefinition {
      id
      fields {
        id
        type
        label
        description
        ... on Question {
          properties
          validations
        }
        ... on Group {
          fields {
            id
            type
            description
            label
            properties
            validations
          }
        }
      }
    }
  }
`;

export const GET_USER_SURVEY_ANSWERS = gql`
  query {
    GetUserSurveyAnswers {
      status
      updatedAt
      answers {
        questionId
        value
        multiValue
      }
    }
  }
`;
