import React from "react";
import _get from "lodash/get";
import { filesize } from "filesize";
import mime from "mime-to-extensions";

import { Box } from "@suited/components";
import SuitedUploaderLayoutManager from "./SuitedUploaderLayoutManager";
import ResumeUploadCompleteCard from "suited/components/CandidateHome/Resume/ResumeUploadCompleteCard";
import { UploaderFile, UploaderError, SuitedUploaderLayoutMode } from "./common";
import { StyledInput, StyledSuitedUploaderDropzone } from "./SuitedUploader.style";

type DefaultContent = {
  emptyCardMessage: string;
  draggingCardMessage: string;
  draggingOverCardMessage: string;
  progressCardMessage: string;
  errorCardMessage: string;
  completeCardComponent: (props: any) => JSX.Element | Element;
};

interface Props {
  file?: UploaderFile;
  serverError?: UploaderError;
  content?: DefaultContent;
  isUploading: boolean;
  acceptMimeTypes: string[]; // MIME types
  maxFilesize: number; // bytes
  onUpload: (file: File) => void;
  onUploadSuccess: () => void;
  onClearFile: () => void;
  onDownload: () => void;
  minUploadDuration?: number;
}

interface State {
  mode: SuitedUploaderLayoutMode;
  dragAvailable: boolean;
  progress: number;
  progressDone: boolean;
  progressError: boolean;
  primaryErrorMessage: string;
  secondaryErrorMessage: string;
  minUploadDurationTimeout: any | null;
}

const initialState: State = {
  mode: "empty",
  dragAvailable: true,
  progress: -1,
  progressDone: false,
  progressError: false,
  primaryErrorMessage: "",
  secondaryErrorMessage: "",
  minUploadDurationTimeout: null
};

const defaultContent = {
  emptyCardMessage: "Drag and drop your file here or upload a file below.",
  draggingCardMessage: "Drag it over here…",
  draggingOverCardMessage: "Drop it!",
  progressCardMessage: "Uploading…",
  errorCardMessage: "There was an error.",
  completeCardComponent: ResumeUploadCompleteCard
};

const getExtensionsList = (mimeTypeList: string[]) => {
  const allExtensions = [].concat(
    ...mimeTypeList.map((mimetype) => {
      return mime.allExtensions(mimetype);
    })
  );
  if (allExtensions.length === 1) return allExtensions[0];
  const last = allExtensions.pop();
  return `${allExtensions.join(", ")}${allExtensions.length > 1 ? "," : ""} or ${last}`;
};

class SuitedUploader extends React.Component<Props, State> {
  private fileInput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  private MIN_UPLOAD_DURATION: number = 0;
  private FILE_TOO_BIG_PRIMARY_MESSAGE = `Oops! Looks like that file is too big.`;
  private FILE_TOO_BIG_SECONDARY_MESSAGE = `Please upload a file smaller than ${filesize(
    this.props.maxFilesize
  )}.`;
  private FILE_WRONG_TYPE_PRIMARY_MESSAGE = `Oops! Looks like that's the wrong file type.`;
  private FILE_WRONG_TYPE_SECONDARY_MESSAGE = `Please upload a ${getExtensionsList(
    this.props.acceptMimeTypes
  )}.`;
  state = {
    ...initialState,
    mode: (this.props.file && this.props.file.filename
      ? "complete"
      : "empty") as SuitedUploaderLayoutMode
  };

  componentDidUpdate(prevProps) {
    // We use a smidgeon of derived state here to manage animated transitions.
    // We combine that with a mostly-managed-plus-a-key "pattern".
    // I commented below to indicate API where key-incrementing is required.
    if (this.props.isUploading !== prevProps.isUploading) {
      if (this.props.isUploading) {
        // start upload: change mode to "progress"
        this.setState({ mode: "progress" });
      } else {
        // end upload
        if (!this.state.minUploadDurationTimeout) {
          this.setProgressToDoneOrError();
        }
      }
    }
  }

  setProgressToDoneOrError = () => {
    if (!this.props.isUploading && this.props.file && this.props.file.filename) {
      this.setState({ mode: "progress", progressDone: true });
    } else if (!this.props.isUploading && this.props.serverError && this.props.serverError.code) {
      this.setState({ mode: "progress", progressError: true });
    }
  };

  isDragAvailable = () => {
    return ["empty", "dragging", "draggingOver"].includes(this.state.mode);
  };

  handleDropzoneDrop = (files: FileList | null): any => {
    if (!files) return;
    if (!this.isDragAvailable()) return;
    this.handleDropUpload(files[0]);
  };
  handleDropzoneDragOver = () => {
    if (!this.isDragAvailable()) return;
    this.setState({ mode: "draggingOver" });
  };
  handleDropzoneDragLeave = () => {
    if (!this.isDragAvailable()) return;
    this.setState({ mode: "dragging" });
  };
  handleDropzoneFrameDrop = () => {
    if (!this.isDragAvailable()) return;
    this.setState((prevState) => {
      return prevState.mode !== "progress" ? { mode: "empty" } : { mode: prevState.mode };
    });
  };
  handleDropzoneFrameDragEnter = () => {
    if (!this.isDragAvailable()) return;
    this.setState({ mode: "dragging" });
  };
  handleDropzoneFrameDragLeave = () => {
    if (!this.isDragAvailable()) return;
    this.setState({ mode: "empty" });
  };

  handleDropUpload = (file) => {
    this.handleUpload(file);
  };

  handleClickUpload = () => {
    if (this.fileInput && this.fileInput.current) this.fileInput.current.click();
  };

  handleFileSystemUpload = (event) => {
    this.handleUpload(_get(event, "target.files[0]", null));
  };

  handleUpload = (file) => {
    // immediately alert user if selected file MIME type is not on `acceptMimeTypes` list
    if (!this.props.acceptMimeTypes.includes(file.type)) {
      this.setState({
        mode: "error",
        primaryErrorMessage: this.FILE_WRONG_TYPE_PRIMARY_MESSAGE,
        secondaryErrorMessage: this.FILE_WRONG_TYPE_SECONDARY_MESSAGE
      });
      return;
    }
    // immediately alert user if selected file is larger than `maxFileSize`
    if (file.size > this.props.maxFilesize) {
      this.setState({
        mode: "error",
        primaryErrorMessage: this.FILE_TOO_BIG_PRIMARY_MESSAGE,
        secondaryErrorMessage: this.FILE_TOO_BIG_SECONDARY_MESSAGE
      });
      return;
    }
    // if no errors, send it to upload. Timeout ensures we stay in progress for a minimum amount of time.
    this.setState({
      mode: "progress",
      minUploadDurationTimeout: window.setTimeout(
        this.minUploadDurationCallback,
        this.props.minUploadDuration || this.MIN_UPLOAD_DURATION
      )
    });
    // this handler should:
    // - update `isUploading` prop to `true`
    // - fire off create request (container component will update props and key on response)
    this.props.onUpload(file);
  };

  handleCompleteClear = () => {
    this.handleClear();
  };

  handleCompleteDownload = () => {
    this.props.onDownload();
  };

  handleErrorClear = () => {
    this.handleClear();
  };

  handleClear = () => {
    if (this.fileInput && this.fileInput.current) this.fileInput.current.value = "";
    this.setState({ ...initialState, mode: "empty" });
  };

  handleFinishedTransitionProgressToDone = () => {
    this.setState({ mode: "complete", progressDone: false });
  };

  handleFinishedTransitionProgressToError = () => {
    this.setState({ mode: "error", progressDone: false });
  };

  handleFinishedTransitionModeToComplete = () => {
    // this handler should:
    // -  increment this component's `key` to remount.
    this.props.onUploadSuccess();
  };

  handleFinishedTransitionModeToEmpty = () => {
    // this handler should:
    // - update props to clear `file` and `error`
    // - increment this component's `key` to remount.
    // - fire off delete request
    if (this.props.file) {
      this.props.onClearFile();
    }
  };

  minUploadDurationCallback = () => {
    this.setProgressToDoneOrError();
    this.setState({ minUploadDurationTimeout: null });
  };

  render() {
    const { content = defaultContent } = this.props;
    const { completeCardComponent = ResumeUploadCompleteCard } = content;
    return (
      <Box>
        <form encType="multipart/form-data">
          <StyledInput
            ref={this.fileInput}
            type="file"
            accept={this.props.acceptMimeTypes.join(", ")}
            id="uploader"
            name="uploader"
            onChange={(e) => {
              this.handleFileSystemUpload(e);
            }}
          />
        </form>
        <StyledSuitedUploaderDropzone
          active={["dragging", "draggingOver"].includes(this.state.mode)}
          onDragDrop={this.handleDropzoneDrop}
          onDragOver={this.handleDropzoneDragOver}
          onDragLeave={this.handleDropzoneDragLeave}
          onFrameDrop={this.handleDropzoneFrameDrop}
          onFrameDragEnter={this.handleDropzoneFrameDragEnter}
          onFrameDragLeave={this.handleDropzoneFrameDragLeave}
        />
        <SuitedUploaderLayoutManager
          mode={this.state.mode}
          emptyCardMessage={content?.emptyCardMessage}
          onClickUpload={this.handleClickUpload}
          draggingCardMessage={content?.draggingCardMessage}
          draggingOverCardMessage={content?.draggingOverCardMessage}
          progressCardMessage={content?.progressCardMessage}
          progress={this.state.progress}
          progressDone={this.state.progressDone}
          progressError={this.state.progressError}
          onFinishedTransitionProgressToDone={this.handleFinishedTransitionProgressToDone}
          onFinishedTransitionProgressToError={this.handleFinishedTransitionProgressToError}
          completeCardComponent={completeCardComponent}
          completeCardData={{
            filename: _get(this.props, "file.filename", "-"),
            filesize: _get(this.props, "file.filesize", 0),
            uploadedOn: _get(this.props, "file.updatedAt", "-")
          }}
          onCompleteClear={this.handleCompleteClear}
          onCompleteDownload={this.handleCompleteDownload}
          primaryErrorMessage={this.state.primaryErrorMessage}
          secondaryErrorMessage={this.state.secondaryErrorMessage}
          onErrorClear={this.handleErrorClear}
          onFinishedTransitionModeToComplete={this.handleFinishedTransitionModeToComplete}
          onFinishedTransitionModeToEmpty={this.handleFinishedTransitionModeToEmpty}
        />
      </Box>
    );
  }
}

export default SuitedUploader;
