import owasp from "owasp-password-strength-test";

export const OWASP_CONFIG = { allowPassphrases: false, maxLength: 20 };

// NOTE: Need to do this check for jest testing to work
if (owasp && typeof owasp.config === "function") {
  owasp.config(OWASP_CONFIG);
}

export const OwaspPasswordErrorCodes = {
  MIN_LENGTH: 0,
  MAX_LENGTH: 1,
  NO_MORE_THAN_TWO_CHARACTERS_REPEATED: 2,
  LOWERCASE_LETTER: 3,
  UPPERCASE_LETTER: 4,
  NUMBER_REQUIRED: 5,
  SPECIAL_CHARACTER_REQUIRED: 6
};

export const checkOwaspError =
  (errorType: number) =>
  (failedTests: number[]): boolean =>
    !failedTests.some((failedTest) => failedTest === errorType);

export const isPasswordMinimumLength = checkOwaspError(OwaspPasswordErrorCodes.MIN_LENGTH);

export const isPasswordUnderMaxLength = checkOwaspError(OwaspPasswordErrorCodes.MAX_LENGTH);

export const passwordHasLowercaseLetter = checkOwaspError(OwaspPasswordErrorCodes.LOWERCASE_LETTER);

export const passwordHasUppercaseLetter = checkOwaspError(OwaspPasswordErrorCodes.UPPERCASE_LETTER);

export const passwordHasNumber = checkOwaspError(OwaspPasswordErrorCodes.NUMBER_REQUIRED);

export const passwordHasNoMoreThanTwoCharactersRepeated = checkOwaspError(
  OwaspPasswordErrorCodes.NO_MORE_THAN_TWO_CHARACTERS_REPEATED
);

export const passwordHasSpecialCharacter = checkOwaspError(
  OwaspPasswordErrorCodes.SPECIAL_CHARACTER_REQUIRED
);
