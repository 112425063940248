import styled from "styled-components";

import { typography, sizes } from "suited/constants/style-constants";
import Icon from "suited/components/shared/typography/Icon";
import { transitions } from "suited/constants/style-transitions";

export const CardSubheadline = styled.div`
  color: ${typography.color.dark.base};
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.form.base.button};
  font-weight: ${typography.weight.body.light};
  line-height: normal;
  text-align: center;
`;

export const IconTransitionGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${sizes.gridUnits.times8};
  height: ${sizes.gridUnits.times6};
  margin-right: ${sizes.gridUnits.times2};
`;

export const TransitionIcon = styled(Icon)<{ timeout: number }>`
  position: absolute;
  margin: auto;
  color: ${typography.color.dark.hint};
  ${(props) => transitions.crossFade(props.timeout)}
`;
