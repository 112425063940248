import * as React from "react";
import styled from "styled-components";

import { typography, sizes, responsiveTargets } from "../../../constants/style-constants";

const Instructions = styled.div`
  margin: 0 0 ${sizes.gridUnits.divide2} 0;
  ${typography.styles.body} font-size: ${typography.sizes.form.instructions};
  @media ${responsiveTargets.phone} {
    font-size: ${typography.sizes.copy.body.phone};
  }
  @media ${responsiveTargets.tablet} {
    font-size: ${typography.sizes.copy.body.tablet};
  }
  @media ${responsiveTargets.laptop} {
    font-size: ${typography.sizes.copy.body.laptop};
  }
  @media ${responsiveTargets.desktop} {
    font-size: ${typography.sizes.copy.body.desktop};
  }

  strong {
    font-weight: ${typography.weight.body.bold};
  }
  ul {
    margin-top: ${sizes.gridUnits.times1};
    margin-right: ${sizes.gridUnits.times2};
    margin-bottom: ${sizes.gridUnits.times1};
    margin-left: ${sizes.gridUnits.times4};
    li {
      list-style-type: disc;
      &:not(:last-child) {
        margin-bottom: ${sizes.gridUnits.times1};
      }
    }
  }
`;

type Props = {
  children: React.ReactNode;
};

const SuitedFormInstructions = (props: Props) => {
  return (
    <Instructions {...props} data-test="component-suited-form-instructions">
      {props.children}
    </Instructions>
  );
};

export default SuitedFormInstructions;
