import styled from "styled-components";

import {
  colors,
  typography,
  sizes,
  animationCurves,
  responsiveRanges
} from "../../../constants/style-constants";
import SuitedFormInstructions from "../../shared/typography/SuitedFormInstructions";
import SuitedCompactTextInput from "../../shared/inputs/SuitedCompactTextInput";
import SuitedCallout from "../../shared/typography/SuitedCallout";
import { hexToRgba } from "../../../util/cssColorTansforms";

export const ChooseText = styled(SuitedFormInstructions)`
  text-align: center;
`;

export const PointsRemaining = styled.div`
  ${typography.styles.label} margin: ${sizes.gridUnits.times2} 0;
  color: ${typography.color.dark.emphasis};
  font-family: ${typography.family.bodyEmphasis};
  font-weight: ${typography.weight.body.medium};
  text-align: center;
`;

interface PointsRemainingProps {
  warning?: boolean;
  className?: string;
}

// prettier-ignore
export const PointsRemainingNumber = styled.span<PointsRemainingProps>`
  color: ${props => (props.warning ? colors.status.error.base : "inherit")};
`;

interface AllocationQuestionsContainerProps {
  disabled?: boolean;
}

export const AllocationQuestionsContainer = styled.div<AllocationQuestionsContainerProps>`
  pointer-events: ${ (props) => props.disabled ? "none" : null };
  display: flex;
  flex-flow: column nowrap;
  color: ${typography.color.dark.hint};
  outline: 0;
  font-size: 18px;
  position: relative;
`;

export const StyledSuitedTextInput = styled(SuitedCompactTextInput)`
  flex: 0 0 auto;
  width: ${sizes.gridUnits.times10};
  > input {
    text-align: center;
    background-color: ${hexToRgba(colors.color4.base, 0.04)};
  }
`;

export const QuestionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: ${sizes.gridUnits.times2};
  background-color: transparent;
  transition: background-color 300ms ${animationCurves.easeOutQuart};
  &:hover,
  &:focus-within {
    background-color: ${hexToRgba(colors.color4.base, 0.04)};
    ${StyledSuitedTextInput} {
      > input {
        background-color: ${colors.background.light.fourthOrder};
        border: 1px solid ${colors.accent1.base};
        box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
      }
    }
  }
`;

export const QuestionText = styled(SuitedCallout)`
  margin-right: ${sizes.gridUnits.times8};
  margin-bottom: 0;
  line-height: 1.2;
  @media ${responsiveRanges.phone} {
    margin-right: ${sizes.gridUnits.times2};
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${sizes.gridUnits.times5};
`;
