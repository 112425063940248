import styled, { css } from "styled-components";

import { colors } from "suited/constants/style-constants";
import {
  SuitedAltTextInput,
  ISuitedTextInputProps,
  SuitedTextInputEl
} from "suited/components/shared/inputs/SuitedAltTextInput";

const getErrorBorderStyle = () => {
  return css`
    &:not(:disabled) {
      border: 1px solid ${colors.status.error.shade1};
    }
    &:not(:disabled):hover,
    &:not(:disabled):focus {
      border: 1px solid ${colors.status.error.base};
      box-shadow: inset 0px 0px 0px 2px ${colors.status.error.shade1};
    }
    &:not(:disabled):focus {
      border: 1px solid ${colors.status.error.base};
      box-shadow: inset 0px 0px 0px 2px ${colors.status.error.shade1};
      outline: none;
    }
  `;
};

interface StyleProps extends ISuitedTextInputProps {
  inputKey: string;
  hasError: boolean;
}

export const StyledSuitedTextInput = styled(SuitedAltTextInput)<StyleProps & ISuitedTextInputProps>`
  ${SuitedTextInputEl} {
    margin-bottom: 0;
    ${(props) => (props.hasError ? getErrorBorderStyle() : null)}
  }
`;
