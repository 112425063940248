import {
  TButtonType,
  ISuitedButtonState,
  IAction,
  IViewportOffset,
  ICenterOffset,
  TButtonPurpose
} from "./SuitedButton.types";

export const buttonPurposeTypes: { [key: string]: TButtonType } = {
  default: "button",
  primary: "submit",
  cancel: "reset"
};

export const initialState: ISuitedButtonState = {
  willAnimate: false,
  isAnimating: false,
  centerOffsetX: 0,
  centerOffsetY: 0,
  scale: 0,
  isHandlingClick: false,
  isDelayingOnClick: false
};

export const reducer = (state: ISuitedButtonState, action: IAction): ISuitedButtonState => {
  switch (action.type) {
    case "INIT_ANIMATE":
      if (!action.value || !action.event) return state;
      const { offsetX, offsetY } = action.value;
      return {
        ...state,
        willAnimate: true,
        isAnimating: false,
        centerOffsetX: offsetX,
        centerOffsetY: offsetY,
        scale: 0,
        event: action.event,
        isHandlingClick: true
      };
    case "RUN_ANIMATE":
      return {
        ...state,
        willAnimate: false,
        isAnimating: true,
        scale: 10,
        isHandlingClick: false
      };
    case "RESET_ANIMATE":
      return {
        ...state,
        willAnimate: false,
        isAnimating: false,
        scale: 0
      };
    case "DID_HANDLE_CLICK":
      return {
        ...state,
        isHandlingClick: false
      };
    case "DELAY_ONCLICK":
      return {
        ...state,
        isDelayingOnClick: true
      };
    case "COMPLETED_ONCLICK":
      return {
        ...state,
        isDelayingOnClick: false
      };
  }
};

export const getViewportOffset = (
  elRef: React.RefObject<HTMLElement>
): DOMRect | IViewportOffset => {
  return elRef && elRef.current
    ? elRef.current.getBoundingClientRect()
    : {
        left: 0,
        top: 0,
        width: 0,
        height: 0
      };
};

export const getSanitizedEvent = (
  event: React.MouseEvent,
  viewportOffset: IViewportOffset
): React.MouseEvent => {
  if (!event.clientX || event.clientX === 0) {
    // synthetic click
    event = {
      ...event,
      clientX: viewportOffset.left + viewportOffset.width / 2,
      clientY: viewportOffset.top + viewportOffset.height / 2
    };
  }
  return event;
};

export const calculateCenterOffset = (params: {
  pointerEvent: React.MouseEvent;
  viewportOffset: IViewportOffset;
}): ICenterOffset => {
  const { pointerEvent, viewportOffset } = params;
  return {
    offsetX: pointerEvent.clientX - viewportOffset.left - viewportOffset.width / 2,
    offsetY: pointerEvent.clientY - viewportOffset.top - viewportOffset.height / 2
  };
};

export const getClickAnimIsDark = (purpose: TButtonPurpose, overDark: boolean): boolean => {
  if (!purpose) return true;
  const lookup = {
    default: overDark ? false : true,
    primary: true,
    secondary: overDark ? false : true,
    cancel: overDark ? false : true
  };
  return lookup[purpose];
};
