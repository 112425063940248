import { useContext } from "react";
import { CandidateConfigContext } from "../CandidateConfig.component";
import { TIndustryContext } from "../CandidateConfig.types";
import { TUseIndustryContextHook } from "./types";

export const useIndustryContext: TUseIndustryContextHook = () => {
  const [config, setConfig] = useContext(CandidateConfigContext);
  const { industryContext } = config;
  const setIndustryContext = (value: TIndustryContext) => {
    setConfig({
      ...config,
      industryContext: value
    });
  };
  return [industryContext, setIndustryContext];
};
