import styled, { keyframes } from "styled-components";
import { colors, typography, sizes, animationCurves } from "../../../constants/style-constants";

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: stretch;
  flex: 1 0 100vh;
  position: relative;
  background-color: ${colors.black};
  overflow: hidden;
`;

export const PlaceholderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: blur(8px);
  background-color: ${colors.black};
  z-index: 0;
`;

const fadeIn = keyframes`
  from { opacity: 0.0; }
  to { opacity: 1.0; }
`;

const blurIn = keyframes`
  from { filter: blur(8px); }
  to { filter: blur(0px); }
`;

export const FullImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  /* @TODO - the blurIn transition is preferred, but until we pay off some debt
  /* in the routing of the app, the blurIn transition between routes that share
  /* a BG image feels pretty bad. So we are going with no-transition for now.
  animation: ${blurIn} 500ms ${animationCurves.easeOutQuart} forwards;
  */
`;

export const ErrorAlt = styled.div`
  margin: auto;
  padding: ${sizes.gridUnits.times3};
  background: ${colors.tints.mid};
  color: ${typography.color.dark.hint};
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.copy.value};
  font-weight: ${typography.weight.body.light};
  line-height: normal;
  text-align: center;
  z-index: 0;
  opacity: 0;
  animation: ${fadeIn} 500ms ${animationCurves.easeOutQuart} 3.5s forwards;
  strong {
    font-weight: ${typography.weight.body.medium};
  }
`;
