import { useAppSelector } from "suited/util/hooks/reduxHooks";
import {
  getNumberOfCompletedSections,
  getTotalNumberOfSections
} from "suited/components/CandidateHome/eca/eca.slice";
import { getECAPercentComplete } from "suited/components/CandidateHome/eca/eca.utils";
import { DEFAULT_FILL } from "./ECAProgressBar.constants";
import { useHydrateStatusBar } from "../eca.hooks";
import { FixedFooter } from "./ECAProgressBar.style";
import { ProgressBar } from "suited/components/shared/feedback/ProgressBar/ProgressBar";

export const ECAProgressBar = () => {
  useHydrateStatusBar();

  const numberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);
  const totalNumberOfSections = useAppSelector(getTotalNumberOfSections);

  const ecaProgressPercentage = getECAPercentComplete(
    numberOfCompletedSections,
    totalNumberOfSections
  );

  const progress = ecaProgressPercentage === 0 ? DEFAULT_FILL : ecaProgressPercentage;
  const displayText = ecaProgressPercentage === 0 ? 0 : progress;

  return (
    <FixedFooter>
      <ProgressBar progress={progress} displayText={displayText} />
    </FixedFooter>
  );
};
