type Theme = any;

const theme: Theme = {
  mobileBreakpoint: "414px",
  tabletBreakpoint: "892px",

  /* Light Blue */
  colorPrimary: "#1389E1",

  /* Main Gray */
  colorSecondary: "#919ead",

  /* Light Gray */
  colorTertiary: "#fafafa",

  /* Medium Light Gray */
  colorMediumGray: "#d3d7dc",

  /* Medium Gray Blue */
  colorMediumBlueGray: "#6d92bd",

  /* Dark Gray Blue */
  colorDarkBlueGray: "#4d708d",

  /* Medium Blue */
  colorQuaternary: "#0667d0",

  // Font Light Gray
  colorFontLightGray: "#828a96",
  colorFontBlueGray: "#919ead",

  /*Dark Blue*/
  colorQuinary: "#2b415b",
  colorQuinaryLink: "#243342",
  colorQuinaryHover: "#1c2732",

  /*Dark Gray*/
  colorSenary: "#91a6bb",

  /* Purple */
  colorSeptenary: "#8E44AD",

  /* Light Gray Blue */
  colorOctonary: "#c8d1d7",

  /*Box Color*/
  boxColor: "#D9E3EA",

  /*Background Color*/
  mainBackgroundColor: "#EBF1F5",

  /*MainBorderColor*/
  colorBorder: "#CED0DA",

  /* Border + opacity */
  colorBorderOpacity: "rgba(145, 158, 173, 0.4)",

  /* Button borders */
  buttonSuccessBorderColor: "rgb(0, 170, 109)",
  buttonErrorBorderColor: "#ca3b0e",

  colorButton: "#00c57f",
  colorHighlightGreen: "#00c57f",
  backgroundColorButtonDefault: "#ffffff",
  backgroundColorButtonSuccess: "#00c57f",
  backgroundColorButtonWarning: "#EB4511",

  ErrorMessage: "#EB4511",
  SuccessMessage: "#38DF7B",
  WarningMessage: "#FDCD2B",

  fontPrimary: "'Source Sans Pro', sans-serif;",
  fontSecondary: "Courier, sans-serif;"
};

export default theme;
