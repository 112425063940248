import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { typography, sizes, colors } from "../../../constants/style-constants";

export const NavLabel = styled.div`
  font-family: ${typography.family.heading};
  font-weight: ${typography.weight.heading.ultrabold};
  text-transform: uppercase;
  letter-spacing: ${typography.tracking.slight};
`;

interface IStatus {
  completed: boolean;
}

export const Status = styled.div<IStatus>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 ${sizes.gridUnits.times9};
  border-top: 1px solid ${(props) => (props.completed ? colors.tints.times3 : "transparent")};
  border-bottom: 1px solid ${(props) => (props.completed ? colors.shades.times1 : "transparent")};
  background: ${(props) => (props.completed ? colors.accent1.base : "transparent")};
`;

type NavItemProps = {
  $isCompleted: boolean;
};

export const NavIcon = styled(FontAwesomeIcon)<NavItemProps>`
  ${(props) =>
    props.$isCompleted &&
    `
     color: ${colors.white}
  `}
`;
