import styled from "styled-components";

import SuitedUploaderDropzone, { SuitedUploaderDropzoneProps } from "./SuitedUploaderDropzone";

export const StyledInput = styled.input`
  display: none;
`;

interface StyledDropzoneProps extends SuitedUploaderDropzoneProps {
  active: boolean;
}
export const StyledSuitedUploaderDropzone = styled(SuitedUploaderDropzone)<StyledDropzoneProps>`
  display: ${(props) => (props.active ? "block" : "none")};
`;
