import React from "react";
import { CSSTransition } from "react-transition-group";

import { Indeterminate, Determinate, Complete, Error, Div } from "./SuitedLinearProgress.style";

interface Props {
  className?: string;
  value?: number;
  complete?: boolean;
  error?: boolean;
  onDidTransitionToComplete?: () => void;
  onDidTransitionToError?: () => void;
  onDidTransitionToProgress?: () => void;
  changeDuration?: number;
  loopDuration?: number;
  transitionDuration?: number;
  completeText?: React.ReactNode;
  errorText?: React.ReactNode;
}

export interface ISuitedLinearProgressProps extends Props {}

const SuitedLinearProgress = (props: Props) => {
  const {
    className,
    complete,
    error,
    changeDuration = 150,
    loopDuration = 4000,
    transitionDuration = 400,
    completeText = "Complete!",
    errorText = "Error!",
    onDidTransitionToComplete,
    onDidTransitionToError,
    ...passthroughProps
  } = props;

  const handleDidTransitionToComplete = (_, isAppearing: boolean): void => {
    if (!isAppearing && props.onDidTransitionToComplete) {
      props.onDidTransitionToComplete();
    }
  };

  const handleDidTransitionToError = (_, isAppearing: boolean): void => {
    if (!isAppearing && props.onDidTransitionToError) {
      props.onDidTransitionToError();
    }
  };

  const handleDidTransitionToProgress = (_, isAppearing: boolean): void => {
    if (!isAppearing && props.onDidTransitionToProgress) {
      props.onDidTransitionToProgress();
    }
  };

  const testID = "component-suited-linear-progress";

  return (
    <Div className={className} data-test={testID} data-testid={testID}>
      <CSSTransition
        unmountOnExit={true}
        in={props.error}
        timeout={transitionDuration}
        onEntered={handleDidTransitionToError}
      >
        <Error
          transitionDuration={transitionDuration}
          loopDuration={loopDuration}
          data-test="error"
        >
          {errorText}
        </Error>
      </CSSTransition>
      <CSSTransition
        unmountOnExit={true}
        in={props.complete && !props.error}
        timeout={transitionDuration}
        onEntered={handleDidTransitionToComplete}
      >
        <Complete
          transitionDuration={transitionDuration}
          loopDuration={loopDuration}
          data-test="complete"
        >
          {completeText}
        </Complete>
      </CSSTransition>
      {props.value !== undefined && props.value >= 0 ? (
        <CSSTransition
          unmountOnExit={true}
          in={!props.complete && !props.error}
          timeout={transitionDuration}
          onEntered={handleDidTransitionToProgress}
        >
          <Determinate
            {...passthroughProps}
            value={props.value < 100 ? props.value : 100}
            changeDuration={changeDuration}
            loopDuration={loopDuration}
            transitionDuration={transitionDuration}
            data-test="progress-determinate"
          />
        </CSSTransition>
      ) : (
        <CSSTransition
          in={!props.complete && !props.error}
          unmountOnExit={true}
          timeout={transitionDuration}
          onEntered={handleDidTransitionToProgress}
        >
          <Indeterminate
            {...passthroughProps}
            transitionDuration={transitionDuration}
            loopDuration={loopDuration}
            data-test="progress-indeterminate"
          />
        </CSSTransition>
      )}
    </Div>
  );
};

export default SuitedLinearProgress;
