import styled from "styled-components";

import SuitedLink from "suited/components/shared/buttons/SuitedLink";
import { SuitedValidatedTextInput } from "suited/components/shared/inputs/SuitedValidatedTextInput";
import Icon from "suited/components/shared/typography/Icon";
import SuitedInputErrorMessage from "suited/components/shared/typography/SuitedInputErrorMessage";
import { ErrorText, Errors } from "suited/components/shared/typography/SuitedInputErrorMessage";
import { StyledSuitedInputStatusMessageText } from "suited/components/shared/typography/SuitedInputStatusMessage";
import { colors, responsiveTargets, sizes, typography } from "suited/constants/style-constants";

import { ErrorProps } from "./RegistrationForm.types";

export const RegisterFormWrapper = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
  flex-wrap: wrap;
  @media ${responsiveTargets.desktop} {
    flex-wrap: nowrap;
  }
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorMessageListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorSection = styled.div<ErrorProps>`
  display: ${(props) => (props.visible ? "block" : "none")};
  margin: ${sizes.gridUnits.times1} 0;
`;

export const TermsContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${sizes.gridUnits.times3};
  padding-bottom: ${sizes.gridUnits.times4};
  input[type="checkbox"] {
    margin-right: ${sizes.gridUnits.times1};
  }
`;

export const RegisterTerms = styled.div`
  font-size: 15px;
`;

export const RegisterFormFieldButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${sizes.gridUnits.times3} 0;
`;

export const StyledSuitedLink = styled(SuitedLink)`
  font-size: inherit;
`;

export const RegisterFormColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @media ${responsiveTargets.desktop} {
    padding: 0 ${sizes.gridUnits.times2};
    width: 50%;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

export const StyledErrorMessage = styled(SuitedInputErrorMessage)`
  height: auto;
  ${ErrorText} {
    overflow: initial;
    white-space: normal;
  }
  ${Errors} {
    height: auto;
  }
  display: inline;
  margin: 0;
`;

export const StyledIcon = styled(Icon)`
  align-self: flex-start;
  color: ${colors.tints.most};
  padding-right: ${sizes.gridUnits.times1};
  padding-top: ${sizes.gridUnits.divide2};
`;

export const StyledSuitedValidatedTextInput = styled(SuitedValidatedTextInput)`
  ${StyledSuitedInputStatusMessageText} {
    white-space: normal;
    overflow: auto;
    text-overflow: unset;
  }
  padding-bottom: ${sizes.gridUnits.times1};
`;

export const SuitedExplainer = styled.p`
  color: ${typography.color.light.base};
  margin-bottom: 0.5rem;
`;

export const RegistrationPageCopy = styled.p`
  color: ${typography.color.light.base};
`;

export const AlreadyRegisteredNotification = styled.p`
  color: ${colors.white};
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

export const PasswordRequirementsHeading = styled.p`
  font-size: 0.8rem;
  color: ${typography.color.light.base};
`;
