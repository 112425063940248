import styled, { css } from "styled-components";

import { colors, animationCurves, sizes, typography } from "suited/constants/style-constants";
import { TButtonPurpose, IPurposeStyles } from "./SuitedButton.types";
import { hexToRgba } from "suited/util/cssColorTransforms";

interface Props {
  purpose?: TButtonPurpose;
  overDark?: boolean;
  alignLeft?: boolean;
  alignRight?: boolean;
}

export const SuitedButtonEl = styled.button<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 0 0 auto;
  position: relative;
  padding: 0 ${sizes.gridUnits.times6};
  margin: 0;
  width: auto;
  min-width: ${sizes.touch.base};
  height: ${sizes.touch.base};
  font-family: ${typography.family.bodyEmphasis};
  font-weight: ${typography.weight.body.medium};
  font-size: ${typography.sizes.form.base.button};
  letter-spacing: ${typography.tracking.loose};
  line-height: 1;
  outline: none;
  border-radius: ${sizes.gridUnits.times3};
  border: 2px solid transparent;
  box-shadow: 0px 0px 0px 1px transparent;
  cursor: pointer;
  transition-property: transform, color, background-color, border-color, box-shadow;
  transition-duration: 200ms, 200ms, 400ms, 400ms, 400ms;
  transition-timing-function: ${animationCurves.easeOutQuart};
  overflow: hidden;
  ${(props) =>
    props.alignLeft
      ? css`
          align-self: flex-start;
          margin-left: -${sizes.gridUnits.divide2};
        `
      : ""}
  ${(props) =>
    props.alignRight
      ? css`
          align-self: flex-end;
          margin-right: -${sizes.gridUnits.divide2};
        `
      : ""}
  &:hover:not([disabled]), &:focus:not([disabled]) {
    transform: scale(1.025);
  }
  &:focus:not([disabled]) {
    transform: scale(1.05);
    z-index: 1;
  }
  &:active:not([disabled]) {
    transform: scale(1);
  }
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  ${(props) => getPurposeStyles(props.purpose || "default", props.overDark ? true : false)}
`;

export const ClickAnim = styled.div<{ isDark?: boolean }>`
  position: absolute;
  width: ${sizes.gridUnits.times12};
  height: ${sizes.gridUnits.times6};
  border-radius: ${sizes.gridUnits.times3};
  transform: scale(0);
  opacity: 1;
  background-color: ${(props) => (props.isDark ? colors.black : colors.white)};
  &.active {
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: 500ms, 300ms;
    transition-delay: 0ms, 200ms;
    transition-timing-function: ease-in, ${animationCurves.easeOutQuart};
  }
`;

const buttonPurposeStyles: IPurposeStyles = {
  default: css`
    color: ${typography.color.dark.base};
    background-color: transparent;
    border-color: ${typography.color.dark.hint};
    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:active:not([disabled]) {
      color: ${typography.color.dark.emphasis};
      box-shadow: 0px 0px 0px 1px ${typography.color.dark.base};
    }
    &:disabled {
      color: ${typography.color.dark.base};
      /* border-color: ${typography.color.dark.disabled}; */
      border-style: dotted;
    }
  `,
  primary: css`
    color: ${typography.color.light.base};
    background-color: ${colors.accent1.base};
    border-color: transparent;
    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:active:not([disabled]) {
      color: ${typography.color.light.emphasis};
      border-color: ${typography.color.dark.base};
      box-shadow: 0px 0px 0px 1px ${typography.color.dark.base};
    }
    &:disabled {
      color: ${typography.color.dark.base};
      background-color: ${hexToRgba(colors.accent1.base, 0.45)};
      border-color: ${colors.accent1.base};
      border-style: dotted;
    }
  `,
  secondary: css`
    color: ${typography.color.dark.base};
    background-color: ${typography.color.light.disabled};
    border-color: transparent;
    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:active:not([disabled]) {
      color: ${typography.color.dark.emphasis};
      border-color: ${typography.color.dark.base};
      box-shadow: 0px 0px 0px 1px ${typography.color.dark.base};
    }
    &:disabled {
      color: ${typography.color.dark.base};
      background-color: ${colors.tints.times5};
      border-color: ${typography.color.light.disabled};
      border-style: dotted;
    }
  `,
  cancel: css`
    color: ${typography.color.dark.hint};
    background-color: transparent;
    border-color: ${colors.shades.times1};
    text-transform: lowercase;
    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:active:not([disabled]) {
      color: ${typography.color.dark.base};
      border-color: ${typography.color.dark.hint};
      box-shadow: 0px 0px 0px 1px ${typography.color.dark.hint};
    }
    &:disabled {
      color: ${typography.color.dark.hint};
      border-style: dotted;
    }
  `
};

const buttonPurposeStylesOnDark: IPurposeStyles = {
  default: css`
    color: ${typography.color.light.base};
    border-color: ${typography.color.light.hint};
    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:active:not([disabled]) {
      color: ${typography.color.light.emphasis};
      box-shadow: 0px 0px 0px 1px ${typography.color.light.base};
    }
    &:disabled {
      color: ${typography.color.light.base};
    }
  `,
  primary: css`
    &:disabled {
      color: ${typography.color.light.base};
    }
  `,
  secondary: css`
    color: ${typography.color.light.base};
    background-color: ${typography.color.dark.hint};
    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:active:not([disabled]) {
      color: ${typography.color.light.emphasis};
      border-color: ${typography.color.light.base};
      box-shadow: 0px 0px 0px 1px ${typography.color.light.base};
    }
    &:disabled {
      color: ${typography.color.light.base};
      background-color: ${typography.color.dark.disabled};
      border-color: ${typography.color.dark.disabled};
    }
  `,
  cancel: css`
    color: ${typography.color.light.hint};
    border-color: ${colors.tints.times1};
    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:active:not([disabled]) {
      color: ${typography.color.light.base};
      border-color: ${typography.color.light.hint};
      box-shadow: 0px 0px 0px 1px ${typography.color.light.hint};
    }
    &:disabled {
      color: ${typography.color.light.hint};
    }
  `
};

const getPurposeStyles = (purpose: TButtonPurpose, overDark: boolean) => {
  if (overDark)
    return css`
      ${buttonPurposeStyles[purpose]}
      ${buttonPurposeStylesOnDark[purpose]}
    `;
  return buttonPurposeStyles[purpose];
};
