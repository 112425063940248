import styled from "styled-components";
import ReactToolTip from "react-tooltip";

import { colors, typography } from "suited/constants/style-constants";

// @ts-ignore
export const StyledReactToolTip = styled(ReactToolTip)`
  font-family: ${typography.family.body};
  border-radius: 0 !important;
  margin-left: 2px !important;
  background-color: ${colors.background.dark.tertiary} !important;
  opacity: 1 !important;
`;
