import styled from "styled-components";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

import {
  sizes,
  colors,
  typography,
  responsiveTargets,
  responsiveRanges
} from "../../constants/style-constants";
import { SuitedCopyHeadlineEl } from "../shared/typography/SuitedCopyHeadline";
import { ImageContainer, FullImage } from "../shared/layout/SuitedFullBleedImage.style";
import { SuitedSplashFooter } from "../shared/layout/SuitedSplashPage.style";
import { Errors, ErrorText } from "suited/components/shared/typography/SuitedInputErrorMessage";
import { SuitedCopyEl } from "../shared/typography/SuitedCopy";
import { SuitedCopySubheadlineEl } from "../shared/typography/SuitedCopySubheadline";
import { CheckboxItem } from "../shared/inputs/SuitedCheckbox.style";
import { SuitedLinkAEl, StyledLink } from "../shared/buttons/SuitedLink.style";

export const AuthenticationTemplateEl = styled.div`
  background: yellow;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 1 100%;
  position: relative;
  background-color: ${colors.color3.base};
  overflow: auto;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${sizes.gridUnits.times18};
    background-color: ${colors.color1.base};

    @media ${responsiveRanges.phoneAndTablet} {
      height: ${sizes.gridUnits.times9};
    }
  }
  &:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: ${sizes.containerWidths.sm};
    height: 100%;
    background: ${colors.black};

    @media ${responsiveRanges.phoneAndTablet} {
      width: ${sizes.gridUnits.times9};
    }
    @media ${responsiveTargets.desktop} {
      width: 30vw;
    }
    @media ${responsiveTargets.jumbo} {
      width: 33.33vw;
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: ${sizes.containerWidths.sm};
  height: ${sizes.gridUnits.times18};
  z-index: 1;
  img {
    width: ${sizes.gridUnits.times6};
  }

  @media ${responsiveRanges.phoneAndTablet} {
    width: ${sizes.gridUnits.times9};
    height: ${sizes.gridUnits.times9};
    img {
      width: ${sizes.gridUnits.times4};
    }
  }
  @media ${responsiveTargets.desktop} {
    width: 30vw;
  }
  @media ${responsiveTargets.jumbo} {
    width: ${sizes.gridUnits.times20};
  }
`;

export const Image = styled.div`
  display: flex;
  position: absolute;
  top: ${sizes.gridUnits.times9};
  width: 41.11vw;
  height: calc(${sizes.containerWidths.xl} - ${sizes.containerWidths.nav});
  max-height: 85vh;
  background-color: ${colors.color2.base};
  transform: scaleX(-1);
  overflow: hidden;

  ${ImageContainer} {
    flex: 1 1 auto;
    background-color: transparent;
    ${FullImage} {
      object-position: left 60%;
    }
  }

  @media ${responsiveRanges.phone} {
    left: 0;
    width: 100%;
    max-height: 75vw;
    z-index: 1;
    ${ImageContainer} {
      ${FullImage} {
        object-position: left -100vw;
      }
    }
  }
  @media ${responsiveRanges.tablet} {
    left: 0;
    width: 100%;
    max-height: 82vw;
    z-index: 1;
    ${ImageContainer} {
      ${FullImage} {
        object-position: left -95vw;
      }
    }
  }
  @media ${responsiveRanges.laptop} {
    left: auto;
    right: 0;
    width: 50vw;
    max-height: 85vh;
  }
  @media ${responsiveTargets.desktop} {
    left: calc(15vw + ${sizes.containerWidths.lg});
    right: ${sizes.gridUnits.times15};
    width: 33.33vw;
    max-width: ${sizes.containerWidths.xl};
    height: 80vh;
    /* max-height: 1720px; */
    max-height: 780px;
    ${ImageContainer} {
      ${FullImage} {
        object-position: left 80%;
      }
    }
  }
  @media ${responsiveTargets.jumbo} {
    /* width: 44vw;
    left: calc(33.33vw + ${sizes.containerWidths.lg}); */
    width: 40vw;
    left: calc(25vw + 560px);
    ${ImageContainer} {
      ${FullImage} {
        object-position: left 75%;
      }
    }
  }
`;

export const CallToAction = styled(SuitedButton)`
  @media ${responsiveRanges.phoneAndTablet} {
    position: absolute;
    top: calc(${sizes.gridUnits.times1} + ${sizes.gridUnits.divide2});
    right: ${sizes.gridUnits.times2};
    margin-right: 0;
    z-index: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  width: calc(100% - ${sizes.gridUnits.times8});
  max-width: 800px; /* auth content is only container that uses this width */
  min-height: ${sizes.containerWidths.lg};
  margin-top: ${sizes.gridUnits.times8};
  margin-bottom: ${sizes.gridUnits.times10};
  padding: ${sizes.gridUnits.times9} ${sizes.gridUnits.times12};
  background-color: ${colors.color4.base};
  z-index: 1;

  @media ${responsiveRanges.phone} {
    margin-top: ${sizes.gridUnits.times20};
    padding: ${sizes.gridUnits.times5} ${sizes.gridUnits.times4};
    width: calc(100% - ${sizes.gridUnits.times2});
  }
  @media ${responsiveRanges.tablet} {
    margin-top: ${sizes.containerWidths.nav};
    padding: ${sizes.gridUnits.times10} ${sizes.gridUnits.times8};
    width: calc(100% - ${sizes.gridUnits.times8});
  }
  @media ${responsiveTargets.desktop} {
    margin-left: 15vw;
  }
  @media ${responsiveTargets.jumbo} {
    /* margin-left: 33.33vw; */
    margin-left: 25vw;
  }

  ${SuitedCopyHeadlineEl} {
    color: ${typography.color.light.base};
  }
  ${SuitedCopySubheadlineEl} {
    color: ${typography.color.light.base};
  }
  ${SuitedCopyEl} {
    color: ${typography.color.light.base};
    &.end {
      margin-bottom: calc(${typography.margins.copy.base} * 2);
    }
    strong {
      color: ${typography.color.light.emphasis};
    }
  }
  ${Errors} {
    margin-left: 0;
  }
  ${ErrorText} {
    color: ${typography.color.light.hint};
    margin-top: ${sizes.gridUnits.divide2};
    padding-top: 0;
    padding-bottom: 0;
  }
  ${CheckboxItem} {
    input[type="checkbox"] ~ label {
      color: ${typography.color.light.base};
    }
    input[type="checkbox"]:hover:not([disabled]) ~ label:after,
    input[type="checkbox"]:focus:not([disabled]) ~ label:after {
      color: ${colors.accent1.base};
      box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
    }
  }
  ${SuitedLinkAEl}, ${StyledLink} {
    color: ${typography.color.light.base};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  margin-bottom: ${sizes.gridUnits.times8};
`;

export const Logo = styled.div`
  width: ${sizes.gridUnits.times18};
  img {
    width: 100%;
  }
  @media ${responsiveRanges.phoneAndTablet} {
    position: absolute;
    top: calc(${sizes.gridUnits.times9} + ${sizes.gridUnits.times6});
    left: ${sizes.gridUnits.times4};
    z-index: 1;
  }
  @media ${responsiveRanges.tablet} {
    top: calc(${sizes.gridUnits.times9} + ${sizes.gridUnits.times10});
    left: ${sizes.gridUnits.times8};
  }
`;

export const Wrap = styled.div`
  align-self: stretch;
`;

export const Footer = styled(SuitedSplashFooter)`
  background: ${colors.color2.base};
`;

export const Spacer = styled.div`
  flex: 1 1 auto;
`;

export const InstructionsCopy = styled.p`
  color: ${typography.color.light.base};
  margin-bottom: 1.75rem;
`;
