import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import debounce from "lodash/debounce";

import { Box, Stack } from "@suited/components";
import type { Dictionary } from "suited/components/shared/types/utility";
import { Input } from "./TextInputItem.style";
import { QuestionCopy } from "../eca.styles";
import type { SubmitItemVariables } from "../eca.types";

interface Props {
  id: string;
  label: string;
  value: string;
  onSubmit: (data: SubmitItemVariables) => void;
}

export const TextInputItem = ({ id, label, value, onSubmit }: Props) => {
  const { register, handleSubmit, setValue } = useForm<Dictionary<string>>({
    defaultValues: { [id]: value }
  });

  const WAIT = 500;

  // We have to memoize the debounced callback function to get past the ESLint
  // unknown dependencies error. See https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks.
  const debouncedHandler = useMemo(() => debounce(onSubmit, WAIT), [onSubmit]);
  const debouncedSubmit = useCallback(
    (formValues) => {
      const valueToSubmit = formValues[id];
      const values = [{ value: valueToSubmit }];
      const timestamp = moment().toISOString();
      return debouncedHandler({ id, values, timestamp });
    },
    [debouncedHandler, id]
  );

  return (
    <Box>
      <Stack key={id} space="xxs">
        <QuestionCopy>
          <label htmlFor={id}>{label}</label>
        </QuestionCopy>
        <Input
          id={id}
          type="text"
          {...register(id, {
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(id, event.target.value);
              handleSubmit(debouncedSubmit)();
            }
          })}
        />
      </Stack>
    </Box>
  );
};
