import styled from "styled-components";
import assessmentCompleteHero from "suited/img/hero/hero-assessment-complete-2.jpg";

export const StyledBackgroundImg = styled.span`
  min-height: 35vh;
  max-height: 35vh;
  background-image: url(${assessmentCompleteHero});
  background-size: cover;
  overflow: hidden;
`;
