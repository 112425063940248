import styled from "styled-components";

import { typography, colors, sizes, animationCurves } from "../../constants/style-constants";
import { FormFieldLabel } from "../Forms";

interface FormProps {
  hasValue: boolean;
}

export const OverflowFormFieldLabel = styled(FormFieldLabel)`
  overflow: visible;
`;

export const SelectWrapper = styled.div`
  margin-bottom: ${sizes.gridUnits.times3};
  .Select.is-focused .Select-value-label {
    border: none;
    color: ${typography.color.dark.base};
    padding-right: 5px;
    padding-left: 5px;
  }
  &[data-disabled] {
    cursor: not-allowed;
  }
`;

export const SelectWrapperMulti = styled.div<FormProps>`
  margin-bottom: ${sizes.gridUnits.times3};
  .Select-control {
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.hasValue ? "flex-start" : "space-between")};
    align-items: center;
    .Select-arrow {
      top: 4px;
    }
    .Select-multi-value-wrapper {
      display: flex;
      flex-wrap: wrap;
      .Select-value.is-focused {
        margin-top: 0px;
      }
      .Select-value {
        justify-content: center;
        display: flex;
        align-items: center;
        margin-top: 0px;
        background-color: ${colors.background.light.backdrop};
        border-radius: 4px;
        color: ${colors.accent1.base};

        .Select-value-label {
          color: ${colors.accent1.base};
          padding: 5px;
        }
        .Select-value-icon {
          cursor: pointer;
          border-bottom-left-radius: 2px;
          border-top-left-radius: 2px;
          border-right: 1px solid #c2e0ff;
          border-right: 1px solid rgba(0, 126, 255, 0.24);
          padding: 7px 5px 3px;
          font-size: 20px;
        }
      }
    }
  }
  &[data-disabled] {
    cursor: not-allowed;
  }
`;

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "auto",
    minHeight: `${sizes.gridUnits.times5}`,
    paddingLeft: `${typography.margins.input}`,
    marginBottom: 0,
    backgroundColor: state.isDisabled ? colors.tints.times2 : colors.background.light.primary,
    borderRadius: 0,
    borderStyle: state.isDisabled ? "dashed" : "solid",
    borderWidth: state.isDisabled ? 2 : 1,
    borderColor: state.isFocused ? `${colors.accent1.base}` : `${colors.shades.times1}`,
    transform: state.isFocused ? "scale(1.025)" : "scale(1)",
    boxShadow: state.isFocused ? `inset 0px 0px 0px 2px ${colors.accent1.base}` : "none",
    color: state.isDisabled ? `${typography.color.dark.base}` : `${typography.color.dark.disabled}`,
    fontSize: `${typography.sizes.form.base.input}`,
    fontFamily: `${typography.family.body}`,
    fontWeight: state.isDisabled ? "300" : "400",
    flexWrap: "nowrap",
    zIndex: 1,
    boxSizing: "border-box",
    transition: `transform 300ms ${animationCurves.easeOutQuart},
      background-color 300ms ${animationCurves.easeOutQuart},
      border-color 300ms ${animationCurves.easeOutQuart},
      box-shadow 300ms ${animationCurves.easeOutQuart}`,
    "&:hover": {
      ...provided["&:hover"],
      transform: "scale(1.025)",
      borderColor: state.isFocused ? `${colors.accent1.base}` : `${colors.shades.times2}`,
      boxShadow: state.isFocused
        ? `inset 0px 0px 0px 2px ${colors.accent1.base}`
        : `inset 0px 0px 0px 2px ${colors.shades.times2}`
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: 0,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.base.input}`,
    color: `${typography.color.dark.hint}}`,
    fontWeight: `${typography.weight.body.light}`,
    fontStyle: "italic",
    whiteSpace: "nowrap"
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
    borderRadius: 0,
    fontSize: `${typography.sizes.form.base.input}`
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.base.input}`,
    color: state.isSelected
      ? `${typography.color.light.emphasis}`
      : `${typography.color.dark.base}`,
    backgroundColor: state.isSelected
      ? `${colors.accent1.base}`
      : state.isFocused
      ? `${colors.shades.divide2}`
      : `${colors.background.light.primary}`,
    "&:active": {
      color: `${typography.color.light.base}`,
      backgroundColor: `${colors.accent1.base}`
    }
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: `${colors.background.light.primary}`,
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: `${colors.shades.times3}`,
    padding: 0,
    marginLeft: 0,
    marginRight: `calc(${typography.margins.input} / 2)`,
    fontFamily: `${typography.family.body}`,
    color: `${typography.color.dark.base}`,
    fontSize: `${typography.sizes.form.base.input}`
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    whiteSpace: "normal"
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    marginLeft: 2,
    borderRadius: 0,
    color: `${typography.color.dark.hint}}`,
    "&:hover": {
      color: `${typography.color.dark.base}`,
      backgroundColor: `${colors.shades.divide2}`
    },
    "&:active": {
      color: `${typography.color.dark.emphasis}`,
      backgroundColor: `${colors.shades.times1}`
    }
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    alignItems: "flex-start"
  }),
  indicatorSeparator: (_) => ({
    display: "none"
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.base.input}`,
    color: `${typography.color.dark.hint}}`,
    // not sure why, but I had to override fontFamily in the input tag underneath the input container. -EH
    input: {
      fontFamily: `${typography.family.body}`,
      fontSize: `${typography.sizes.form.base.input}`,
      color: `${typography.color.dark.hint}}`
    }
  }),
  loadingMessage: (provided) => ({
    ...provided,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.instructions}`,
    fontStyle: "italic",
    fontWeight: `${typography.weight.body.light}`,
    color: `${typography.color.dark.hint}}`
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.instructions}`,
    fontStyle: "italic",
    fontWeight: `${typography.weight.body.light}`,
    color: `${typography.color.dark.hint}}`
  }),
  group: (provided) => ({
    ...provided,
    borderTop: `1px solid ${colors.shades.divide2}`,
    paddingTop: `${sizes.gridUnits.times1}`
  }),
  groupHeading: (provided) => ({
    ...provided,
    paddingTop: `${sizes.gridUnits.times1}`,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.base.label}`,
    fontWeight: `${typography.weight.body.light}`,
    color: `${typography.color.dark.hint}}`
  })
};

export const CustomLoadingMessageComponent = () => (
  <div
    style={{
      textAlign: "center",
      padding: "0.5rem",
      fontFamily: typography.family.body,
      fontSize: typography.sizes.form.instructions,
      fontStyle: "italic",
      fontWeight: typography.weight.body.light,
      color: typography.color.dark.hint
    }}
  >
    Loading...
  </div>
);

export const CustomNoOptionsMessageComponent = () => (
  <div style={{ padding: "0.5rem" }}>No options. Please type a new search.</div>
);
