import { useMutation, useQuery } from "@apollo/client";

import {
  AssessmentID,
  GetNextAssessmentSectionQuestionsQuery,
  SubmitItemResponse,
  SubmitItemVariables
} from "../../eca/eca.types";
import { GET_NEXT_ASSESSMENT_SECTION_QUESTIONS, SUBMIT_ITEM_RESPONSE } from "../../eca/eca.queries";
import { getUpdatedGetNextAssessmentSectionQuestionsCacheData } from "../../eca/SectionQuestionsItemGroups/SectionQuestionsItemGroups.utils";
import { useGetCandidateAssessments } from "../../eca/eca.hooks";
import { ItemGroupsList } from "../../eca/ItemGroupsList/ItemGroupsList";
import { AssessmentIds } from "../lateral.constants";

export const LateralQuestionsItemGroups = () => {
  const { assessments } = useGetCandidateAssessments();

  const assessmentID =
    assessments.find((assessment) => assessment.id === AssessmentIds.LATERAL_LAW)?.id || "";

  const { data } = useQuery<GetNextAssessmentSectionQuestionsQuery, AssessmentID>(
    GET_NEXT_ASSESSMENT_SECTION_QUESTIONS,
    {
      skip: !assessmentID,
      variables: { assessmentID }
    }
  );
  const itemGroups = data?.GetNextAssessmentSectionQuestions?.itemGroups || [];

  const [sendItemResponseToServer] = useMutation<SubmitItemResponse, SubmitItemVariables>(
    SUBMIT_ITEM_RESPONSE,
    {
      fetchPolicy: "no-cache",
      update(cache, _, { variables: formValues }) {
        const queryCache = {
          query: GET_NEXT_ASSESSMENT_SECTION_QUESTIONS,
          variables: { assessmentID }
        };
        const questionCache = cache.readQuery<GetNextAssessmentSectionQuestionsQuery, AssessmentID>(
          queryCache
        );

        cache.writeQuery({
          ...queryCache,
          data: getUpdatedGetNextAssessmentSectionQuestionsCacheData(
            questionCache as GetNextAssessmentSectionQuestionsQuery,
            formValues as SubmitItemVariables
          )
        });
      }
    }
  );
  const submitItemResponse = (formValues: SubmitItemVariables) => {
    sendItemResponseToServer({ variables: formValues });
  };

  return <ItemGroupsList itemGroups={itemGroups} onSubmit={submitItemResponse} />;
};
