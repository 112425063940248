export const debounce = {
  typeAhead: 350,
  typeSlow: 600,
  validation: 100,
  textOnly: 0
};

export const pollingIntervals = {
  EMAIL_CHANGE_STATUS: 1500,
  EXPORTS_LIST: 5000,
  EXPORT_LIST_COMPLETE: 30000
};

export const timeouts = {
  CLIPBOARD_COPY: 2000,
  MODAL_DEFAULT_CLOSE_DURATION: 400,
  TRANSITION_MANAGER_DURATION: 300,
  CARD_EXPAND_CONTAINER_TRANSITION_DURATION: 400,
  CARD_EXPAND_CONTENT_TRANSITION_DURATION: 300,
  EDIT_IN_PLACE_TRANSITION_DURATION: 150,
  NAVIGATION_EXPAND_COLLAPSE_TRANSITION_DURATION: 300,
  SHELF_EXPAND_COLLAPSE_TRANSITION_DURATION: 300,
  BUTTON_CLICK_ANIMATION_DURATION: 250,
  ERROR_MESSAGE_DISPLAY: 3000,
  DEFAULT_PROGRESS_COMPLETION_DURATION: 500,
  NO_DELAY: 0,
  GRAPHQL_POLL_INTERVAL: 5000,
  REQUEST_TIMED_OUT: 60000,
  LONG_REQUEST_TIMED_OUT: 120000,
  RECAPTCHA_SCRIPT_LOAD_TIMEOUT: 3000,
  CLICK_ANIMATION_DURATION: 200,
  OPACITY_FADE_DURATION: 200,
  TRANSITION_DELAY: 100,
  ANIMATION_TRANSITION: 300,
  EMAIL_CHANGE_POLLING_MAX_DURATION: 120000
};
