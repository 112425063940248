import { HttpResponse, graphql } from "msw";

import { db } from "./userSettings.models";

export const getMockUserSettingsDetails = () => {
  return db.GetUser.getAll()[0];
};

export const getMockUserPasswordDetails = () => {
  return db.userPassword.getAll()[0];
};

export const userSettingsMockHandlers = [
  graphql.query("GetUserSettings", () => {
    return HttpResponse.json({
      data: {
        GetUser: getMockUserSettingsDetails()
      }
    });
  }),

  graphql.mutation("UpdateUserName", ({ variables }) => {
    const { id } = getMockUserSettingsDetails();
    const firstName = variables.firstName;
    const lastName = variables.lastName;

    db.GetUser.update({
      where: {
        id: {
          equals: id
        }
      },
      data: {
        firstName,
        lastName
      }
    });

    return HttpResponse.json({
      data: {
        UpdateUser: {
          id,
          firstName,
          lastName,
          __typename: "User"
        }
      }
    });
  }),

  graphql.mutation("UpdateUserPassword", ({ variables }) => {
    const { id: userId } = getMockUserSettingsDetails();
    const userPasswordDetails = getMockUserPasswordDetails();
    const id = userPasswordDetails?.id;
    const password = variables.password;

    if (!id) {
      db.userPassword.create({ password: "" });
    }

    db.userPassword.update({
      where: {
        id: {
          equals: id
        }
      },
      data: {
        password
      }
    });

    return HttpResponse.json({
      data: {
        UpdateUser: {
          id: userId,
          __typename: "User"
        }
      }
    });
  })
];
