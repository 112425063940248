import * as React from "react";
import { fill as _fill } from "lodash";

import { InputProps } from "./index";
import {
  RatingContainer,
  LabelContainer,
  RatingLowLabel,
  RatingHighLabel,
  RatingList,
  RatingOptionContainer,
  RatingOption,
} from "./Rating.style";
import { Phone } from "../../shared/responsive/Responsive";
import Media from "react-media";
import { responsiveTargets } from "../../../constants/style-constants";

interface RatingProps extends InputProps {
  value: string;
  lowLabel: string;
  highLabel: string;
  onChange(value: string): void;
  disabled?: boolean;
}

export default class Rating extends React.Component<RatingProps> {
  pressedOne = false;
  timer: any;

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    if (this.props.active) {
      if (e.keyCode === 37) {
        let val = 1;
        if (typeof this.props.value === "number") {
          val = this.props.value > 1 ? this.props.value - 1 : 10;
        }
        this.props.onChange(val.toString());
      } else if (e.keyCode === 39) {
        let val = 1;
        if (typeof this.props.value === "number") {
          val = this.props.value < 10 ? this.props.value + 1 : 1;
        }
        this.props.onChange(val.toString());
      } else if (e.keyCode >= 48 && e.keyCode <= 57) {
        clearTimeout(this.timer);
        if (e.keyCode === 48 && this.pressedOne) {
          this.pressedOne = false;
          this.timer = setTimeout(() => {
            this.props.onChange("10");
          }, 400);
        } else if (e.keyCode === 49) {
          this.pressedOne = true;
          this.timer = setTimeout(() => {
            this.pressedOne = false;
            this.props.onChange("1");
          }, 400);
        } else if (e.keyCode >= 50) {
          this.pressedOne = false;
          const num = e.keyCode - 48;
          this.timer = setTimeout(() => {
            this.props.onChange(num.toString());
          }, 400);
        }
      }
    }
  };

  render() {
    const { name, value, lowLabel, highLabel, onChange, disabled } = this.props;
    return (
      <RatingContainer disabled={disabled}>
        <TabletAndAbove>
          <LabelContainer>
            <RatingLowLabel>{lowLabel}</RatingLowLabel>
            <RatingHighLabel>{highLabel}</RatingHighLabel>
          </LabelContainer>
        </TabletAndAbove>
        <Phone>
          <LabelContainer>
            <RatingLowLabel>{lowLabel}</RatingLowLabel>
          </LabelContainer>
        </Phone>
        <RatingList>
          {_fill(new Array(10), 0).map((_, i) => {
            const val = (i + 1).toString();
            const checked = value === val;
            const onClick = () => {
              onChange(val);
            };
            const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
              if (e.keyCode === 32) {
                e.preventDefault();
                onChange(val);
              }
            };
            return (
              <RatingOptionContainer key={i}>
                <RatingOption
                  key={`${name}-${i}`}
                  checked={checked}
                  onClick={onClick}
                  onKeyDown={onKeyDown}
                  tabIndex={0}>
                  {val}
                </RatingOption>
              </RatingOptionContainer>
            );
          })}
        </RatingList>
        <Phone>
          <LabelContainer>
            <RatingHighLabel>{highLabel}</RatingHighLabel>
          </LabelContainer>
        </Phone>
      </RatingContainer>
    );
  }
}

const TabletAndAbove: React.FunctionComponent<{
  children?: ((matches: boolean) => React.ReactNode) | React.ReactElement<any>;
  onChange?: (match: boolean) => void;
}> = (props) => {
  return (
    <Media query={`${responsiveTargets.tablet}`} onChange={props.onChange}>
      {props.children}
    </Media>
  );
};
