import clsx from 'clsx';

import { omit } from '@suited/utils';
import { getContentAreaProps } from '../../utils';
import { BaseComponentProps } from '../../types';
import { CONTENT_AREA_PROPERTIES } from '../../constants';
import './Center.css';

interface Props extends BaseComponentProps {}

export const Center = ({ children, className, style, ...props }: Props) => {
  const classNames = clsx('ws-l-center', className);
  const rest = omit(props, CONTENT_AREA_PROPERTIES);
  const styles = { ...style, ...getContentAreaProps(props) };
  return (
    <div className={classNames} style={styles} {...rest}>
      {children}
    </div>
  );
};
