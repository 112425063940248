import styled from "styled-components";

import Paper from "./Paper";
import { sizes, responsiveTargets } from "../../../constants/style-constants";

export const SplashPaper = styled(Paper)`
  margin: 0;
  z-index: 1;
  &:last-child {
    margin: 0;
  }
  @media ${responsiveTargets.phone} {
    flex: 0 1 ${sizes.containerWidths.xxs};
    max-width: ${sizes.containerWidths.xxs};
    &:not(:first-child) {
      border-top: none;
    }
  }
  @media ${responsiveTargets.tablet} {
    flex: 0 1 ${sizes.containerWidths.md};
    max-width: ${sizes.containerWidths.md};
  }
`;
