import styled from "styled-components";
import { typography, sizes } from "suited/constants/style-constants";
import { ISuitedInputLabelProps } from "./";

export const StyledSuitedInputLabel = styled.label<ISuitedInputLabelProps>`
  ${typography.styles.label};
  ${({ noMargin, overDark }) => `
    margin-bottom: ${noMargin ? sizes.gridUnits.zero : sizes.gridUnits.divide2};
    color: ${overDark ? typography.color.light.base : typography.color.dark.base};
  `}
`;

StyledSuitedInputLabel.displayName = "StyledSuitedInputLabel";
