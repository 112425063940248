import { factory, oneOf, primaryKey } from "@mswjs/data";
import { faker } from "@faker-js/faker";
import moment from "moment";

export const db = factory({
  changeEmail: {
    id: primaryKey(faker.datatype.uuid),
    __typename: () => "EmailChangeStatus",
    emailStatus: () => "CANCELLED",
    initiatedAt: () => moment(faker.date.past()).format(),
    isChangeWindowOpen: () => false,
    newEmail: faker.internet.email
  },
  settings: {
    __typename: () => "UserSettings",
    id: primaryKey(faker.datatype.uuid),
    changeEmail: oneOf("changeEmail"),
    visibility: () => "public"
  },
  GetUser: {
    __typename: () => "User",
    id: primaryKey(faker.datatype.uuid),
    email: faker.internet.email,
    firstName: faker.name.firstName,
    lastName: faker.name.lastName,
    settings: oneOf("settings")
  },

  // Note that this data model does not represent a
  // real "entity" for a query. We are using this for 
  // integration testing purposes since the UpdateUserPassword
  // query doesn't return the password.
  userPassword: {
    id: primaryKey(faker.datatype.uuid),
    password: String
  }
});
