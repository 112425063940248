import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import SuitedListItemLabelValuePair from "suited/components/shared/typography/SuitedTileLabelValue";

import { Box, Stack } from "@suited/components";
import { AssociationHelperText } from "../../Associations/Association.style";
import {
  ListSecondaryEl,
  ListHeader,
  ListContainer,
  StyledSuitedListItemPrimaryValue,
  StyledSuitedCompactButton
} from "../../shared/InviteStates.style";
import dateFormat from "../../utils/dateFormat";
import { TCompanyInvite, TCompanyInviteMutation } from "../../types";
import { UPDATE_CANDIDATE_INVITATION } from "../../GraphQL/mutations";
import {
  GET_CANDIDATE_INVITATIONS,
  GET_COMPANY_CANDIDATE_ASSOCIATIONS
} from "../../GraphQL/queries";
import { ListItem } from "../../shared/ListItem";

interface IIgnoredProps {
  ignoredInvites: TCompanyInvite[];
}

export const Ignored = (props: IIgnoredProps) => {
  const { ignoredInvites } = props;

  return (
    <ListSecondaryEl>
      <ListHeader>
        <Stack space="xxxs">
          <SuitedCopySubheadline noMargin={true}>Ignored</SuitedCopySubheadline>
          <AssociationHelperText>
            Ignored Invites are invitations you have acted on and placed into an ignored state.
            Recruiters will still see this as pending.
          </AssociationHelperText>
        </Stack>
      </ListHeader>
      <Box style={{ marginTop: "1rem" }}>
        <IgnoredList ignoredList={ignoredInvites} />
      </Box>
    </ListSecondaryEl>
  );
};

interface IIgnoredListProps {
  ignoredList: TCompanyInvite[];
}

const IgnoredList: React.FunctionComponent<IIgnoredListProps> = (props) => {
  const { ignoredList } = props;
  const [loadingItems, setLoadingItems] = useState<string[]>([]);

  // Accepting an invite needs to refetch both the invitations and associations view.
  const [updateIgnoredItemAccept, { loading: loadingAccept }] = useMutation(
    UPDATE_CANDIDATE_INVITATION,
    {
      refetchQueries: () => [
        { query: GET_CANDIDATE_INVITATIONS },
        { query: GET_COMPANY_CANDIDATE_ASSOCIATIONS }
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        const completedData: TCompanyInviteMutation = data.UpdateCandidateInvitation;
        const filteredItems = loadingItems.filter((item) => {
          return item !== completedData._id;
        });
        setLoadingItems(filteredItems);
      }
    }
  );

  const handleConnectClick = (id: string) => {
    setLoadingItems([...loadingItems, id]);
    updateIgnoredItemAccept({ variables: { id, status: "ACCEPTED" } });
  };

  return (
    <ListContainer>
      {ignoredList.map((item: TCompanyInvite) => {
        const dateReceived = new Date(item.createdAt);
        const dateIgnored = new Date(item.updatedAt);
        const formattedReceivedDate = dateFormat(dateReceived);
        const formattedIgnoredDate = dateFormat(dateIgnored);
        return (
          <ListItem
            key={item._id}
            title={
              <StyledSuitedListItemPrimaryValue style={{ textOverflow: "ellipsis" }}>
                {item.companyName}
              </StyledSuitedListItemPrimaryValue>
            }
            detail={
              <React.Fragment>
                <SuitedListItemLabelValuePair label={"Received On"} value={formattedReceivedDate} />
                <SuitedListItemLabelValuePair label={"Ignored On"} value={formattedIgnoredDate} />
                <StyledSuitedCompactButton
                  onClick={() => handleConnectClick(item._id)}
                  disabled={loadingItems.includes(item._id)}
                >
                  {loadingAccept ? "Connecting..." : "Connect"}
                </StyledSuitedCompactButton>
              </React.Fragment>
            }
          />
        );
      })}
    </ListContainer>
  );
};
