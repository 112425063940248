import { IUserRegistrationState } from "../../UserRegistration.types";
import { initialState } from "./RegistrationForm.constants";
import { IAction } from "./RegistrationForm.types";

export const change = (targetName: string, value: any): IAction => ({
  type: "change",
  payload: { field: targetName, value }
});

export function reducer(
  state: IUserRegistrationState = initialState,
  action: IAction
): IUserRegistrationState {
  switch (action.type) {
    case "change": {
      const { field, value } = action.payload || {};
      if (field) return { ...state, [field]: value };
      return { ...state };
    }
    case "SET_INVALID_INDUSTRY_CONTEXT":
      return {
        ...state,
        hasInvalidIndustryContext: true,
        industryContext: ""
      };
    default: {
      throw new Error(`Unrecognized type: ${action.type}`);
    }
  }
}
