import { Box, Stack } from "@suited/components";
import { noop } from "@suited/utils";

import { assessmentItemTypes } from "../eca.constants";
import type {
  MatrixTableItemGroupProps,
  MatrixTableItemProps,
  MultiSelectItemProps,
  TextInputItemProps,
  ItemGroupProps,
  SubmitItemVariables,
  MultipleChoiceItemProps
} from "../eca.types";
import { MatrixTableItemGroup } from "../MatrixTableItemGroup/MatrixTableItemGroup";
import { MultiSelectItemGroup } from "../MultiSelectItemGroup/MultiSelectItemGroup";
import { TextInputItemGroup } from "../TextInputItemGroup/TextInputItemGroup";
import { MultipleChoiceItemGroup } from "../MultipleChoiceItemGroup/MultipleChoiceItemGroup";

type Props = {
  itemGroups: ItemGroupProps[];
  onSubmit?: (args: SubmitItemVariables) => void;
};

export const ItemGroupsList = ({ itemGroups, onSubmit = noop }: Props) => {
  return (
    <Stack space="xxxl">
      {itemGroups.map((itemGroup) => {
        const { id, itemType, leadingText, items } = itemGroup;
        return (
          <Box key={id}>
            {
              {
                [assessmentItemTypes.MATRIXTABLE]: (
                  <MatrixTableItemGroup
                    leadingText={leadingText}
                    headers={(itemGroup as MatrixTableItemGroupProps).headers}
                    items={items as MatrixTableItemProps[]}
                    onSubmit={onSubmit}
                  />
                ),
                [assessmentItemTypes.MULTISELECT]: (
                  <MultiSelectItemGroup
                    leadingText={leadingText}
                    items={items as MultiSelectItemProps[]}
                    onSubmit={onSubmit}
                  />
                ),
                [assessmentItemTypes.TEXTINPUT]: (
                  <TextInputItemGroup
                    leadingText={leadingText}
                    items={items as TextInputItemProps[]}
                    onSubmit={onSubmit}
                  />
                ),
                [assessmentItemTypes.MULTIPLE_CHOICE]: (
                  <MultipleChoiceItemGroup
                    leadingText={leadingText}
                    items={items as MultipleChoiceItemProps[]}
                    onSubmit={onSubmit}
                  />
                )
              }[itemType]
            }
          </Box>
        );
      })}
    </Stack>
  );
};
