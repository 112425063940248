import React from "react";

import { SuitedCopyCaptionEl } from "./SuitedAltCopyCaption.style";

export interface ISuitedCopyCaptionProps {
  /** display light text on dark backgrounds */
  overDark?: boolean;
  /** noMargin is a boolean that if true, will set the CSS property `margin-bottom: 0 !important`. */
  noMargin?: boolean;
  /** Styled-Component compatible */
  className?: string;
  children: React.ReactNode;
}

/**
 * ## What
 * `<SuitedCopyCaption>` is a text decorator used to caption any figure such as an image, chart, or diagram.
 *
 * ## When
 * Generally this component is used right after a figure.
 *
 * ## Not
 * It should not be used for quotations or for emphasizing text that appears elsewhere.
 *
 * ## Example
 * In a legacy version of Recruiter App, we used this component to label visualizations.
 * In Candidate App, we currently use it to elaborate on radio option labels in the Demographic Information form.
 */
export const SuitedAltCopyCaption = (props: ISuitedCopyCaptionProps) => {
  return (
    <SuitedCopyCaptionEl {...props} data-testid="component-suited-copy-caption">
      {props.children}
    </SuitedCopyCaptionEl>
  );
};
