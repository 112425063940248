import { useState } from "react";

import { Box, Flex, HStack } from "@suited/components";
import { useInterval, padNumber, noop } from "@suited/utils";
import { Container, Number } from "./Timer.style";
import { MILLISECONDS_PER_SECOND } from "./Timer.constants";
import { getTimerData } from "./Timer.utils";

type Props = {
  duration: number | undefined;
  onUpdate?: (secondsRemaining: number) => void;
};

export const Timer = ({ duration = 0, onUpdate = noop }: Props) => {
  const [secondsRemaining, setSecondsRemaining] = useState(duration);

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      }
      onUpdate(secondsRemaining - 1);
    },
    // If seconds remaining reaches 0, then cancel interval
    secondsRemaining > 0 ? MILLISECONDS_PER_SECOND : null
  );

  const { minutes, seconds } = getTimerData(secondsRemaining);

  const [minutesTensPlace, minutesOnesPlace] = padNumber(minutes).split("");
  const [secondsTensPlace, secondsOnesPlace] = padNumber(seconds).split("");

  return duration ? (
    <Container padding="xxs" bg="#333" maxWidth="300px" borderRadius="0px 0px 2px 2px">
      <HStack space="xs">
        <Box>
          <HStack space="xxxxs">
            <Flex align="center">
              <Number data-testid="timer-minutes-tens-place">{minutesTensPlace}</Number>
            </Flex>
            <Flex align="center">
              <Number data-testid="timer-minutes-ones-place">{minutesOnesPlace}</Number>
            </Flex>
          </HStack>
        </Box>
        <Box>
          <HStack space="xxxxs">
            <Number data-testid="timer-seconds-tens-place">{secondsTensPlace}</Number>
            <Number data-testid="timer-seconds-ones-place">{secondsOnesPlace}</Number>
          </HStack>
        </Box>
      </HStack>
    </Container>
  ) : null;
};
