/* eslint-disable */

// Ported for Typescript and modern hooks from https://www.npmjs.com/package/react-progress-button
import React, { useEffect, useState } from "react";
import "./ProgressButton.css";

const STATE = {
  LOADING: "loading",
  DISABLED: "disabled",
  SUCCESS: "success",
  ERROR: "error",
  NOTHING: ""
} as const;

type StateKeys = keyof typeof STATE;
export type StateValues = typeof STATE[StateKeys];

type Callback = (args?: any) => void;
type MaybeCallback = (args?: any) => void | undefined | null;

type Props = {
  classNamespace?: string;
  controlled?: boolean;
  durationError?: number;
  durationSuccess?: number;
  onClick?: Callback;
  onError?: Callback;
  onSuccess?: Callback;
  shouldAllowClickOnLoading?: boolean;
  state?: StateValues;
  children: React.ReactNode;
  type?: undefined;
  form?: string;
};

export const ProgressButton = ({
  classNamespace = "pb-",
  controlled = false,
  durationError = 1200,
  durationSuccess = 500,
  onClick = () => {},
  onError = () => {},
  onSuccess = () => {},
  shouldAllowClickOnLoading = false,
  state = STATE.NOTHING,
  children,
  type = undefined,
  form = undefined,
  ...rest
}: Props) => {
  const [currentState, setCurrentState] = useState(state);
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const handleClick = (e: React.MouseEvent) => {
    const shouldAllowClick =
      (shouldAllowClickOnLoading || currentState !== STATE.LOADING) &&
      currentState !== STATE.DISABLED;

    if (controlled && shouldAllowClick) {
      onClick(e);
      return true;
    }

    if (shouldAllowClick) {
      loading();
      const ret = onClick(e);
      handlePromise(ret);
    } else {
      e.preventDefault();
    }
  };

  const handlePromise = (promise: any) => {
    if (promise && promise.then && promise.catch) {
      promise
        .then(() => {
          success();
        })
        .catch((err: Error) => {
          error(undefined, err);
        });
    }
  };

  const loading = () => {
    setCurrentState(STATE.LOADING);
  };

  const notLoading = () => {
    setCurrentState(STATE.NOTHING);
  };

  const disable = () => {
    setCurrentState(STATE.DISABLED);
  };

  const success = (callback?: MaybeCallback, dontRemove?: MaybeCallback) => {
    setCurrentState(STATE.SUCCESS);
    timeout = setTimeout(() => {
      if (!dontRemove) {
        setCurrentState(STATE.NOTHING);
      }
      callback = callback || onSuccess;
      if (typeof callback === "function") {
        callback();
      }
    }, durationSuccess);
  };

  const error = (callback?: MaybeCallback, err?: Error) => {
    setCurrentState(STATE.ERROR);
    timeout = setTimeout(() => {
      setCurrentState(STATE.NOTHING);
      callback = callback || onError;
      if (typeof callback === "function") {
        callback(err);
      }
    }, durationError);
  };

  useEffect(() => {
    switch (state) {
      case STATE.SUCCESS:
        success();
        break;
      case STATE.ERROR:
        error();
        break;
      case STATE.LOADING:
        loading();
        break;
      case STATE.DISABLED:
        disable();
        break;
      case STATE.NOTHING:
        notLoading();
        break;
      default:
        return;
    }
    return () => {
      clearTimeout(timeout as any);
    };
  }, [state]);

  return (
    <div className={`${classNamespace}container ${currentState}`} {...rest}>
      <button
        disabled={state === STATE.DISABLED}
        type={type}
        form={form}
        className={`${classNamespace}button`}
        onClick={handleClick}
      >
        <span>{children}</span>
        <svg className={`${classNamespace}progress-circle`} viewBox="0 0 41 41">
          <path d="M38,20.5 C38,30.1685093 30.1685093,38 20.5,38" />
        </svg>
        <svg className={`${classNamespace}checkmark`} viewBox="0 0 70 70">
          <path d="m31.5,46.5l15.3,-23.2" />
          <path d="m31.5,46.5l-8.5,-7.1" />
        </svg>
        <svg className={`${classNamespace}cross`} viewBox="0 0 70 70">
          <path d="m35,35l-9.3,-9.3" />
          <path d="m35,35l9.3,9.3" />
          <path d="m35,35l-9.3,9.3" />
          <path d="m35,35l9.3,-9.3" />
        </svg>
      </button>
    </div>
  );
};
