import { useContext } from "react";
import { ProfileContext } from "./ProfileCompleteProvider";
import { TProfileContextValue } from "./types";

/**
 * Helper hook for using the profile context with just a single import.
 */
export const useProfile = (): TProfileContextValue => {
  const profileContext = useContext(ProfileContext);

  if (profileContext === undefined) {
    throw new Error(`useProfile must be used within a ProfileCompleteProvider`);
  }

  return profileContext;
};
