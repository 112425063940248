import * as React from "react";
import styled from "styled-components";
import { InputProps } from "./index";
import Confirm from "./Confirm";
import ErrorMessage from "../ErrorMessage";
import { typography, colors } from "../../../constants/style-constants";

const CHARACTER_LIMIT = 64;

const TextInput = styled.input`
  color: ${colors.accent1.base};
  border: 1px solid ${colors.background.light.backdrop};
  display: inline-block;
  background: ${colors.background.light.tertiary};
  border-radius: 4px;
  outline: 0;
  text-indent: auto;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 44px;
  font-size: 14px;
  &:hover {
    color: ${colors.accent1.base};
    transition: all 0.3s ease-in-out;
  }
  &:focus,
  &:active {
    color: ${colors.accent1.base};
    text-indent: 0;
    background: white;
  }
  &:focus + label,
  &:active + label {
    transform: translateY(-100%);
    font-size: 14px;
    top: 20px;
    left: -15px;
    background-color: transparent;
    color: ${typography.color.dark.hint};
    :before {
      border-radius: 5px;
    }
  }
  + label {
    color: ${typography.color.light.base};
    display: inline-block;
    position: absolute;
    transform: translateX(0);
    top: 25px;
    left: 5px;
    padding: 10px 15px;
    text-shadow: 0 1px 0 rgba(19, 74, 70, 0.4);
    transition: all 0.3s ease-in-out;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow: hidden;
    font-size: 14px;
    background-color: ${typography.color.dark.hint};
  }
  &:before {
    top: 6px;
    left: 5px;
    right: 5px;
    bottom: 6px;
    background: #377d6a;
  }
  &:after {
    top: 0;
    bottom: 0;
    background: #377d6a;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }
`;

interface TextProps extends InputProps {
  value: string;
  onChange(value: string): void;
  onSubmit(): void;
  disabled: boolean;
}

interface TextState {
  value: string;
  error: string;
}

export default class Text extends React.Component<TextProps, TextState> {
  input: HTMLInputElement | null = null;

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      error: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.active && this.props.active) {
      if (this.input) this.input.focus();
    } else if (prevProps.active && !this.props.active) {
      if (this.input) this.input.blur();
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
      error: this.getError(e.target.value),
    });
  };

  onRef = (el) => {
    this.input = el;
  };

  getError(value): string {
    const usedValue = value ? value : this.state.value;
    if (usedValue && usedValue.length > CHARACTER_LIMIT) {
      return `Please limit your answer to ${CHARACTER_LIMIT} characters.`;
    }
    return "";
  }

  handleSubmit = () => {
    this.props.onChange(this.state.value);
    this.props.onSubmit();
  };

  render() {
    const { active, name, disabled } = this.props;
    const value = this.state.value;
    const error = this.state.error;

    return (
      <div className="row box col-xs-12">
        <TextInput
          ref={this.onRef}
          type="text"
          name={name}
          value={value || ""}
          onChange={this.onChange}
          className="row box col-xs-12"
          disabled={disabled}
        />
        {value && error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {active && value && !error ? <Confirm onClick={this.handleSubmit.bind(this)} /> : null}
      </div>
    );
  }
}
