import axios from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Login from "./Login.component";
import { login, stateKey } from "./Login.reducer";
import { UTMParams } from "./Login.types";

interface Props {
  authenticating: boolean;
  error: string;
  notConfirmed: boolean;
  notConfirmedEmail: string;
  login(email: string, password: string, invitationCode: string, utm: UTMParams): void;
  location: any;
  history?: any;
  requestId: string;
}

interface State {
  email: string;
  password: string;
  invitationCode: string;
  invitationId: string;
  newConfirmationEmailRequested: boolean;
  navigateToRecruiterLogin: boolean;
  utm: UTMParams;
}

class LoginPage extends React.Component<Props, State> {
  state = {
    email: "",
    password: "",
    newConfirmationEmailRequested: false,
    invitationCode: new URLSearchParams(this.props.location.search).get("code") || "", // @TODO: Make sure that .search isn't undefined if no search params
    invitationId: new URLSearchParams(this.props.location.search).get("id") || "", // @TODO: Make sure that .search isn't undefined if no search params
    navigateToRecruiterLogin: false,
    utm: {
      source: new URLSearchParams(this.props.location.search).get("utm_source") || "",
      medium: new URLSearchParams(this.props.location.search).get("utm_medium") || "",
      campaign: new URLSearchParams(this.props.location.search).get("utm_campaign") || "",
      content: new URLSearchParams(this.props.location.search).get("utm_content") || "",
      term: new URLSearchParams(this.props.location.search).get("utm_term") || "",
      brand: new URLSearchParams(this.props.location.search).get("utm_brand") || ""
    }
  };

  handleChangeEmail = (value: any) => {
    this.setState({
      email: value
    });
  };

  handleChangePassword = (value: any) => {
    this.setState({
      password: value
    });
  };

  onSubmit = () => {
    this.setState({ newConfirmationEmailRequested: false });
    this.props.login(
      this.state.email,
      this.state.password,
      this.state.invitationCode,
      this.state.utm
    );
  };

  handleClickRecruiter = () => {
    return axios
      .get("/company/login")
      .then(function (response) {
        let redirectTo = response.data.redirectTo;
        if (redirectTo) {
          window.location.href = redirectTo;
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  requestNewConfirmationEmail = () => {
    axios
      .post(
        "/request-new-confirmation-email",
        {
          email: this.props.notConfirmedEmail,
          code: this.state.invitationCode,
          requestId: this.props.requestId
        },
        { headers: { "Content-Type": "application/json; charset=utf-8" } }
      )
      .then(() => {
        this.props.history.push(
          `/registration-thank-you?email=${encodeURIComponent(
            this.props.notConfirmedEmail
          )}&requestId=${this.props.requestId}`
        );
      })
      /* 
         @TODO: Alert user if request for new confirmation email fails for whatever reason
         This could be system-wide error handling, at this feature's most complete.
         Otherwise, could be a message (this.state.newConfirmationEmailRequestedError: true, message: "Failed"
         and button remains on the screen -- TWS & EHOCH
      */
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const redirectParam = searchParams.get("redirect");
    const queryParams = redirectParam?.split("?")[1] || "";
    const registrationUrl = `/register${queryParams ? `?${queryParams}` : ""}`;

    return (
      <Login
        email={this.state.email}
        password={this.state.password}
        authenticating={this.props.authenticating}
        error={this.props.error}
        notConfirmed={this.props.notConfirmed}
        invitationCode={this.state.invitationCode}
        invitationId={this.state.invitationId}
        registrationUrl={registrationUrl}
        onChangeEmail={this.handleChangeEmail}
        onChangePassword={this.handleChangePassword}
        onSubmit={this.onSubmit}
        onClickRecruiter={this.handleClickRecruiter}
        requestNewConfirmationEmail={this.requestNewConfirmationEmail}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  authenticating: state[stateKey].isAuthenticating,
  error: state[stateKey].authenticationFailed ? "Invalid email address or password." : "",
  notConfirmed: state[stateKey].notConfirmed,
  notConfirmedEmail: state[stateKey].notConfirmedEmail,
  requestId: state[stateKey].requestId
});

const mapDispatchToProps = (dispatch) => ({
  login: (email: string, password: string, invitationCode: string, utm: UTMParams) => {
    dispatch(login(email, password, invitationCode, utm));
  }
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
