import React, { createContext, useState, useEffect } from "react";
import { defaultConfig } from "./CandidateConfig.constants";
import { TCandidateConfig, TCandidateConfigContextState } from "./CandidateConfig.types";

/* 
  To use this you need to use this import statement: import { CandidateConfigContext } from "suited/components/CandidateHome/CandidateConfig";
  Then use the context(you need to array destructure here): const [featureFlags, setFeatureFlags] = useContext(CandidateConfigContext);
  Then you can access the feature flag object from featureFlags
*/
/**
 * The purpose of the CandidateConfigContext is to store and centrally provide all of the IMMUTABLE data from a `GetUser` query.
 * - feature flags (configures features)
 * - industry context (configures content)
 * - user ID (the one user property that never changes, key to the Apollo cache for all the MUTABLE data)
 */
export const CandidateConfigContext = createContext<TCandidateConfigContextState>([
  defaultConfig,
  () => {}
]);

interface Props {
  config: TCandidateConfig;
  children?: React.ReactNode;
}

const CandidateConfig: React.FunctionComponent<Props> = (props) => {
  const [state, setState] = useState<TCandidateConfig>(props.config);

  // update the state when the config prop changes
  useEffect(() => {
    setState(props.config);
  }, [props.config]);

  return (
    <CandidateConfigContext.Provider value={[state, setState]}>
      {props.children}
    </CandidateConfigContext.Provider>
  );
};

export default CandidateConfig;
