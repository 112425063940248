import * as React from "react";
import _debounce from "lodash/debounce";

import {
  HTMLInputAttributeTextType,
  HTMLInputAttributeAutocomplete,
} from "../types/htmlAttributeTypes";
import { Div, SuitedTextInputLabelEl, StyledInput } from "./SuitedTextInput.style";

interface Props {
  /**
   * input name for the form, also populates `id`
   */
  name: string;
  /**
   * input type hint
   * "text" | "email" | "number" | "password" | "search" | "tel"
   */
  type: HTMLInputAttributeTextType;
  /**
   * parent's change handler. this should update `value`
   */
  onInputChange: (value: any, targetName: string) => void;
  /**
   * Use this to re-mount the input to refresh its value from `value`
   */
  inputKey?: string;
  /**
   * Optional initial value. Prop updates do nothing without a change to `inputKey`.
   */
  value?: string | number;
  /**
   * optional label to appear above the input
   */
  label?: string;
  /**
   * if true, shows asterisk in label
   */
  required?: boolean;
  /**
   * if present, shows placeholder text
   */
  placeholder?: string;
  /**
   * optional HTML attribute to hint browser autocomplete feature.
   * see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Values
   */
  autoComplete?: HTMLInputAttributeAutocomplete;
  /**
   * if present, focuses the input on mount
   */
  autoFocus?: boolean;
  /**
   * if present, delays `onChange` by x milliseconds
   * @default 0
   */
  debounceTime?: number;
  /**
   * optionally handle focus events
   */
  onInputFocus?: (value: any, targetName: string) => void;
  /**
   * optionally handle blur events
   */
  onInputBlur?: (value: any, targetName: string) => void;
  /**
   * for Styled Component compatibility
   */
  className?: string;
  // implicit html attributes for input element
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  title?: string;
  children?: React.ReactNode;
}
export interface ISuitedTextInputProps extends Props {}

interface State {
  touched: boolean;
}

const initialState = {
  touched: false,
};

class SuitedTextInput extends React.Component<Props, State> {
  state = {
    ...initialState,
    touched: this.props.value || this.props.value === 0 ? true : false,
  };

  handleFocus = (event: React.FormEvent<any>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;
    if (this.props.onInputFocus) this.props.onInputFocus(value, name);
  };

  handleBlur = (event: React.FormEvent<any>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;
    this.setState({ touched: true }, () => {
      if (this.props.onInputBlur) this.props.onInputBlur(value, name);
    });
  };

  immediateHandleChange = (value: any, targetName: string) => {
    this.setState(
      {
        touched: true,
      },
      () => {
        this.props.onInputChange(value, targetName);
      }
    );
  };

  debouncedHandleChange = _debounce((value: any, targetName: string) => {
    this.immediateHandleChange(value, targetName);
  }, this.props.debounceTime);

  handleChange = (event: React.FormEvent<any>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;
    this.props.debounceTime
      ? this.debouncedHandleChange(value, name)
      : this.immediateHandleChange(value, name);
  };

  render() {
    const {
      inputKey,
      name,
      value,
      type,
      placeholder,
      autoComplete,
      children,
      label,
      required,
      debounceTime,
      onInputChange,
      onInputFocus,
      onInputBlur,
      className,
      ...passthroughProps
    } = this.props;
    return (
      <Div className={this.props.className}>
        {this.props.label ? (
          <SuitedTextInputLabelEl htmlFor={this.props.name} data-test="label">
            {this.props.label ? this.props.label : null}
            {this.props.required ? <span>*</span> : null}
          </SuitedTextInputLabelEl>
        ) : null}
        <StyledInput
          key={this.props.inputKey}
          id={this.props.name}
          name={this.props.name}
          defaultValue={
            this.props.value || this.props.value === 0 ? this.props.value.toString() : ""
          }
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          type={this.props.type}
          placeholder={this.props.placeholder || ""}
          autoComplete={this.props.autoComplete}
          {...passthroughProps}
          data-test="input"
        />
      </Div>
    );
  }
}

export default SuitedTextInput;
