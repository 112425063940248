import styled from "styled-components";

import { typography, sizes, colors, animationCurves } from "suited/constants/style-constants";
import { transitions } from "suited/constants/style-transitions";

export const Disclosure = styled.div<{ disclosed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 auto;
  font-family: ${typography.family.body};
  border-left: ${sizes.gridUnits.divide2} solid
    ${(props) => (props.disclosed ? colors.shades.times1 : "transparent")};
  transition: border-color 300ms ${animationCurves.easeOutQuart};
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colors.accent1.base};
  }
`;

export const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 0 ${sizes.touch.base};
  width: ${sizes.touch.base};
  height: ${sizes.touch.base};
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: ${sizes.touch.base};
  padding-right: ${sizes.gridUnits.times2};
  cursor: pointer;
  color: ${colors.accent1.base};
  font-size: ${typography.sizes.form.base.button};
  transition: background-color 300ms ${animationCurves.easeOutQuart},
    transform 150ms ${animationCurves.easeOutQuart};
  transform: scale(1);
  &:hover {
    background-color: ${colors.background.light.secondary};
  }
  &:active {
    transform: scale(0.975);
    transition: transform 100ms ${animationCurves.easeOutQuart};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 auto;
  overflow: visible;
  padding-right: ${sizes.gridUnits.times2};
`;

export const Content = styled.div<{ timeout: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 auto;
  margin-left: ${sizes.gridUnits.times2};
  transform-origin: top left;
  ${(props) => transitions.simpleDisclosure(props.timeout)}
`;
