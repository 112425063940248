import clsx from 'clsx';

import { omit } from '@suited/utils';
import { BaseComponentProps, SpacingOptions } from '../../types';
import { getContentAreaProps } from '../../utils';
import { CONTENT_AREA_PROPERTIES } from '../../constants';
import './Grid.css';

interface Props extends BaseComponentProps {
  min?: string;
  space?: SpacingOptions;
}

export const Grid = ({
  children,
  className,
  style,
  min = '250px',
  space = 'md',
  ...props
}: Props) => {
  const classNames = clsx('ws-l-grid', className);
  const rest = omit(props, CONTENT_AREA_PROPERTIES);
  const styles = {
    ...style,
    ...getContentAreaProps(props),
    '--grid-min': min,
    '--grid-space': `var(--perfect-fifth-${space})`,
  };

  return (
    <div className={classNames} style={styles} {...rest}>
      {children}
    </div>
  );
};
