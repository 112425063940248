import React, { useState } from "react";
import { FetchResult, useQuery, useMutation, useApolloClient } from "@apollo/client";

import { mapQuestions, mapAnswers, getPercentComplete } from "./utils";
import { GET_SURVEY_DEFINITION, GET_USER_SURVEY_ANSWERS } from "./GraphQL/queries";
import { SET_ANSWER_MULTI, SET_ANSWER_SINGLE, SET_COMPLETE_SURVEY } from "./GraphQL/mutations";
import { QuestionSchema, Answers, Answer, Errors } from "./types";
import Loading from "../Loading";
import SuitedAssessment from "./SuitedAssessment";
import { Redirect } from "react-router-dom";
import { useProfile } from "suited/components/CandidateHome/ProfileCompleteProvider";
import logErrorToSlack from "suited/util/logErrorToSlack";
import RetryDialog from "./RetryDialog";
import { AssessmentPageContainer } from "./index.style";

interface AssessmentPageProps {
  onClose: () => void;
}

const SuitedAssessmentComponent: React.FunctionComponent<AssessmentPageProps> = (props) => {
  const { profileAssessmentWasCompleted } = useProfile();
  const client = useApolloClient();
  const [waitForRetry, setWaitForRetry] = useState<boolean>(false);
  const [questions, setQuestions] = useState<QuestionSchema[]>([]);

  const {
    data: assessmentData,
    loading: assessmentLoading,
    error: assessmentError
  } = useQuery(GET_SURVEY_DEFINITION, {
    onCompleted: (assessmentData) => {
      setQuestions(assessmentData ? mapQuestions(assessmentData.GetSurveyDefinition.fields) : []);
    },
    onError: (error) => {
      setWaitForRetry(true);
      const infoMessage = "GET_SURVEY_DEFINITION GraphQL Error in SuitedAssessmentComponent";
      logErrorToSlack(error.message, infoMessage, client);
    }
  });

  const {
    data: answerData,
    loading: answerLoading,
    error: answerError
  } = useQuery(GET_USER_SURVEY_ANSWERS, {
    onError: (error) => {
      setWaitForRetry(true);
      const infoMessage = "GET_USER_SURVEY_ANSWERS GraphQL Error in SuitedAssessmentComponent";
      logErrorToSlack(error.message, infoMessage, client);
    }
  });

  const [setAnswerSingle] = useMutation(SET_ANSWER_SINGLE, {
    refetchQueries: [{ query: GET_USER_SURVEY_ANSWERS }]
  });

  const [setAnswerMulti] = useMutation(SET_ANSWER_MULTI, {
    refetchQueries: [{ query: GET_USER_SURVEY_ANSWERS }]
  });

  const [completeSurvey] = useMutation(SET_COMPLETE_SURVEY, {
    refetchQueries: [{ query: GET_USER_SURVEY_ANSWERS }]
  });

  const submit = async () => {
    const response = await completeSurvey();
    return response.data.SetUserSurveyComplete.invalidAnswers;
  };

  const onSubmit = async (): Promise<Errors> => {
    const invalidAnswers = await submit();
    if (invalidAnswers && invalidAnswers.length > 0) {
      const errors = {};
      invalidAnswers.forEach((entry) => {
        // errors[entry.questionId] = entry.reason;
        // Going to assume all of these are unanswered questions for now
        errors[entry.questionId] = "This question is required.";
      });
      return errors;
    } else {
      profileAssessmentWasCompleted();
      return {};
    }
  };

  const setAnswer = (id: string, value: any): Promise<FetchResult<Answer>> => {
    const args = {
      variables: {
        id,
        value
      }
    };
    if (Array.isArray(value)) {
      return setAnswerMulti(args);
    } else {
      return setAnswerSingle(args);
    }
  };

  const clearRetry = () => {
    setTimeout(() => {
      setWaitForRetry(false);
    }, 500);
  };

  if (answerLoading || assessmentLoading) {
    return (
      <AssessmentPageContainer>
        <Loading />
      </AssessmentPageContainer>
    );
  }
  /* 
    This will be returned on errors in the survey or answers query.
    The user experience for this is that they will get this dialog on errors.
    Then they will be able to retry by clicking the retry button.
  */
  if (assessmentError || answerError || waitForRetry || questions.length === 0) {
    return <RetryDialog onCompleted={clearRetry} onClose={props.onClose} />;
  }
  const answers: Answers =
    answerData.GetUserSurveyAnswers !== null
      ? mapAnswers(answerData.GetUserSurveyAnswers.answers)
      : {};

  const complete = answerData && answerData.status === "complete";
  const percentComplete = assessmentData && answerData ? getPercentComplete(questions, answers) : 0;

  if (complete) {
    return <Redirect to="home/suited-assessment/personality" />;
  } else {
    return (
      <AssessmentPageContainer>
        <SuitedAssessment
          questions={questions}
          answers={answers}
          percentComplete={percentComplete}
          setAnswer={setAnswer}
          submit={onSubmit}
          onClose={props.onClose}
        />
      </AssessmentPageContainer>
    );
  }
};

export default SuitedAssessmentComponent;
