import styled from "styled-components";

import { sizes, colors, typography } from "suited/constants/style-constants";
import { SuitedAltIconButton } from "suited/components/shared/buttons/SuitedAltIconButton";

export const ButtonContainerEl = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.color3.base};
`;

export const NotificationContainer = styled.div`
  position: relative;
`;

export const Notification = styled.div`
  height: ${sizes.gridUnits.times2};
  width: ${sizes.gridUnits.times2};
  background-color: ${colors.accent1.base};
  border-radius: 50%;
  border: 1px solid ${colors.background.dark.backdrop};
  position: absolute;
  top: 5px;
  left: 30px;
  text-align: center;
  font-family: ${typography.family.monospace};
  font-size: ${typography.sizes.nav.item};
  color: ${colors.white};
  pointer-events: none;
`;

export const StyledSuitedIconButton = styled(SuitedAltIconButton)`
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    transform: scale(1);
  }
`;
