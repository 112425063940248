import styled from "styled-components";

import { typography, colors, sizes, responsiveRanges } from "suited/constants/style-constants";
import SuitedIconButton from "../shared/buttons/SuitedIconButton";
import { Determinate } from "../shared/loading/SuitedLinearProgress.style";

export const SuitedAssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  font-family: ${typography.family.body};
`;

export const HeaderIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: ${colors.background.dark.primary};
  padding: 1rem 2rem;
`;

export const IconLogoImage = styled.img`
  height: 30px;
  width: 30px;
`;

export const SuitedAssessmentHeaderButton = styled(SuitedIconButton)`
  height: 35px;
  width: 35px;
  margin-right: 1rem;
  color: ${typography.color.light.base};
  background-color: ${colors.background.dark.tertiary};
  border-radius: 50%;
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    color: ${typography.color.light.emphasis};
    background-color: ${colors.background.dark.tertiary};
    border: 1px solid ${colors.color1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.color1.base};
  }
  &:active:not([disabled]),
  &:hover:not([disabled]):active,
  &:focus:not([disabled]):active {
    color: ${typography.color.light.emphasis};
    background-color: ${colors.background.dark.tertiary};
    border: 1px solid ${colors.color1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.color1.base};
  }
`;

export const SuitedAssessmentContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 auto;
  background: ${colors.background.light.backdrop};
  /* when we want to allow scrolling in only one direction, we must set the base overflow rule to hidden first */
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  position: relative;
`;

export const SuitedAssessmentProgressIndicator = styled(Determinate)<{
  timeout: number;
}>`
  margin: 0;
  box-shadow: none;
  background: ${colors.background.light.sixthOrder};
  &:after {
    background: ${colors.accent1.base};
  }
`;

export const SuitedAssessmentFooter = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  background: ${colors.background.dark.primary};
  font-family: ${typography.family.body};
  font-weight: ${typography.weight.body.regular};
  color: ${typography.color.light.base};
  z-index: 1;
  overflow: hidden;
`;

export const SuitedAssessmentFooterButton = styled(SuitedIconButton)`
  color: ${typography.color.light.base};
  background-color: ${colors.tints.times2};
  border-radius: 50%;
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    color: ${typography.color.light.emphasis};
    background-color: ${colors.tints.times2};
    transform: scale(1.1);
    border: 1px solid ${colors.color1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.color1.base};
  }
  &:active:not([disabled]),
  &:hover:not([disabled]):active,
  &:focus:not([disabled]):active {
    color: ${typography.color.light.emphasis};
    background-color: ${colors.tints.times2};
    transform: scale(1);
    border: 1px solid ${colors.color1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.color1.base};
  }
`;

export const SuitedAssessmentFooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
`;

export const SuitedAssessmentFooterProgress = styled.div`
  font-family: ${typography.family.heading};
  font-weight: ${typography.weight.heading.medium};
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: ${typography.tracking.slight};
  @media ${responsiveRanges.phoneAndTablet} {
    font-size: ${typography.sizes.copy.subheading.phone};
  }
  @media ${responsiveRanges.laptopAndAbove} {
    font-size: ${typography.sizes.copy.subheading.tablet};
  }
`;

export const Percent = styled.div`
  margin-bottom: ${sizes.gridUnits.divide2};
  font-family: ${typography.family.monospace};
  font-weight: ${typography.weight.accent.light};
  font-size: 16px;
`;

