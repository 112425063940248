import { HttpResponse, http } from "msw";

import { db } from "./registration.models";

export const registrationMockHandlers = [
  http.post("/auth/register", ({ request }) => {
    // @ts-ignore
    const { utm, firstName, lastName, ...rest } = request.body;
    db.RegistrationAPICalls.create({
      ...rest,
      firstname: firstName,
      lastname: lastName,
      utm: db.UTM.create(utm)
    });
    return HttpResponse.json({
      email: "test-user@wellsuited.com",
      firstName: "Test",
      industryContext: "finance_banking_investment",
      lastName: "User",
      redirectUrl:
        "/registration-thank-you?email=test-user%40wellsuited.com&requestId=d70e5309-6cd1-42a4-bf55-0b8a5e68c1bc",
      suitedCandidateId: "e5ed9fce-1e74-48b2-a217-00a95327ad2f"
    });
  }),

  http.get("/auth/industry-contexts", () => {
    return HttpResponse.json({
      data: [
        { id: "finance_banking_investment", name: "Investment Banking" },
        { id: "law", name: "Law" }
      ]
    });
  })
];
