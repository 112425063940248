// @ts-nocheck
import React, { useState, Fragment } from "react";
import { Box, Stack } from "@suited/components";
import { useGetNextAssessmentSectionInformation } from "../eca.hooks";
import { ItemGroupsList } from "../ItemGroupsList/ItemGroupsList";
import { getSampleQuestionType } from "./SampleItemGroupsList.utils";
import {
  SAMPLE_QUESTION_TYPES,
  SAMPLE_QUESTIONS_ANSWERS,
  CORRECT_ANSWER_PREFIX,
  WRONG_ANSWER_PREFIX,
  CORRECT_ANSWER_FEEDBACK_KEY,
  WRONG_ANSWER_FEEDBACK_KEY
} from "./SampleItemGroupsList.constants";
import { Alert } from "suited/components/shared/feedback/Alert/Alert";
import { ErrorAlert } from "suited/components/shared/feedback/Alert/ErrorAlert";

export const SampleItemGroupsList = () => {
  const { sampleItemGroups } = useGetNextAssessmentSectionInformation();
  const sampleItemGroup = sampleItemGroups.find(Boolean);

  const itemFeedbackById = new Map();
  sampleItemGroup?.items?.forEach((item) => {
    itemFeedbackById.set(
      item.id,
      new Map([
        [CORRECT_ANSWER_FEEDBACK_KEY, []],
        [WRONG_ANSWER_FEEDBACK_KEY, []]
      ])
    );
  });

  const [feedback, setFeedback] = useState(itemFeedbackById);

  return (
    <Box>
      <Stack space="xl">
        <ItemGroupsList
          itemGroups={sampleItemGroups}
          onSubmit={(response) => {
            const id = response.id;
            const sampleQuestionType = getSampleQuestionType(sampleItemGroup);

            const values = response.values;
            const clonedFeedback = new Map(feedback);

            if (sampleQuestionType === SAMPLE_QUESTION_TYPES.MULTI_SELECT) {
              sampleItemGroup?.items?.find(Boolean)?.options?.forEach((option) => {
                clonedFeedback.set(
                  option.id,
                  new Map([
                    [CORRECT_ANSWER_FEEDBACK_KEY, []],
                    [WRONG_ANSWER_FEEDBACK_KEY, []]
                  ])
                );
              });
            }

            values.forEach(({ id: itemId, value }) => {
              const answers = SAMPLE_QUESTIONS_ANSWERS[sampleQuestionType];
              const index = sampleItemGroup?.items?.findIndex((item) => item.id === id);
              const answer = answers[index]?.answer;
              const feedbackText = answers[index]?.feedback;
              const wrongAnswerFeedback = `${WRONG_ANSWER_PREFIX} ${feedbackText}`;
              const correctAnswerFeedback = CORRECT_ANSWER_PREFIX;

              if (sampleQuestionType === SAMPLE_QUESTION_TYPES.MULTI_SELECT) {
                const option = sampleItemGroup?.items
                  ?.find(Boolean)
                  ?.options.find((option) => option.id === itemId);
                const optionId = option?.id;

                const isCorrect = answer === option?.label;
                if (isCorrect) {
                  clonedFeedback.get(optionId).set(WRONG_ANSWER_FEEDBACK_KEY, []);
                  clonedFeedback
                    .get(optionId)
                    .set(CORRECT_ANSWER_FEEDBACK_KEY, correctAnswerFeedback);
                } else {
                  clonedFeedback.get(optionId).set(CORRECT_ANSWER_FEEDBACK_KEY, []);
                  clonedFeedback.get(optionId).set(WRONG_ANSWER_FEEDBACK_KEY, wrongAnswerFeedback);
                }
              } else {
                // For all question types

                const isCorrect = answer === value;
                if (isCorrect) {
                  clonedFeedback.get(id).set(WRONG_ANSWER_FEEDBACK_KEY, []);
                  clonedFeedback.get(id).set(CORRECT_ANSWER_FEEDBACK_KEY, correctAnswerFeedback);
                } else {
                  clonedFeedback.get(id).set(CORRECT_ANSWER_FEEDBACK_KEY);
                  clonedFeedback.get(id).set(WRONG_ANSWER_FEEDBACK_KEY, wrongAnswerFeedback);
                }
              }
            });

            setFeedback(clonedFeedback);
          }}
        />
        {[...feedback].map(([key, entry], index) => {
          const correctAnswerFeedback = entry.get(CORRECT_ANSWER_FEEDBACK_KEY);
          const wrongAnswerFeedback = entry.get(WRONG_ANSWER_FEEDBACK_KEY);
          const hasCorrectAnswer = correctAnswerFeedback && correctAnswerFeedback?.length > 0;
          const hasWrongAnswer = wrongAnswerFeedback && wrongAnswerFeedback?.length > 0;
          return (
            <Fragment key={key}>
              {(hasCorrectAnswer || hasWrongAnswer) && (
                <Box
                  style={{
                    marginTop: index > 0 ? "var(--perfect-fifth-md)" : "var(--perfect-fifth-xl)"
                  }}
                >
                  <Stack>
                    {hasCorrectAnswer && (
                      <Box>
                        <Alert isChecked space="md" message={correctAnswerFeedback} />
                      </Box>
                    )}
                    {hasWrongAnswer && (
                      <Box>
                        <ErrorAlert space="md" message={wrongAnswerFeedback} />
                      </Box>
                    )}
                  </Stack>
                </Box>
              )}
            </Fragment>
          );
        })}
      </Stack>
    </Box>
  );
};
