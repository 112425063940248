import { setupWorker } from "msw/browser";
import { handlers } from "./handlers";
import { AppConfig } from "../config";

export const worker = setupWorker(...handlers);

// See config options for onUnhandledRequest here:  https://mswjs.io/docs/api/setup-worker/start#onunhandledrequest.
// We want to give users the option to enable or disable logging unhandled requests.
export const workerConfigOptions = {
  onUnhandledRequest: AppConfig.VITE_LOG_MSW_UNHANDLED_REQUESTS === "true" ? "warn" : "bypass"
};
