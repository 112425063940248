import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import useDeepCompareEffect from "use-deep-compare-effect";

import { Box, HStack, Stack } from "@suited/components";

import { EIndustryIds, useIndustryContext } from "suited/components/CandidateConfig";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { Alert } from "suited/components/shared/feedback/Alert/Alert";
import { Divider } from "suited/components/shared/layout/Divider";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { timeouts } from "suited/constants/interaction-constants";

import { EducationInstructions } from "../EducationInstructions/EducationInstructions";
import { InvestmentBankingValidationError } from "../InvestmentBankingValidationError/InvestmentBankingValidationError";
import { LawValidationError } from "../LawValidationError/LawValidationError";
import { SchoolForm } from "../SchoolForm/SchoolForm";
import { INDUSTRY_CONTEXTS } from "../education.constants";
import { GET_USER_EDUCATION, UPDATE_SCHOOLS } from "../education.queries";
import type { SchoolEntry, SchoolFormProps } from "../education.types";
import {
  cleanSchoolProps,
  convertSchoolAPIDataToFormData,
  createUpdateSchoolsPayloadEntry,
  doesSchoolDataHaveJurisDoctorDegree,
  doesSchoolDataHaveUndergradDegree,
  getDefaultSchoolFormFields,
  isFormValid
} from "../education.utils";
import { EducationContent, StyledSuitedButton } from "./EducationPage.style";

export const EducationPage = () => {
  const { data, loading: isLoadingUserEducation } = useQuery(GET_USER_EDUCATION);
  const schools = data?.GetUser?.userProfile?.schools || [];

  const [industryContext] = useIndustryContext();
  const [isAddingSchool, setIsAddingSchool] = useState(false);
  const [formState, setFormState] = useState(new Map());

  const schoolList = [...formState].map(([_, value]) => value.values);

  useDeepCompareEffect(() => {
    const values = schools.map((school: SchoolEntry) => {
      return [
        school.id,
        {
          isDirty: false,
          isValid: true,
          values: convertSchoolAPIDataToFormData(school)
        }
      ];
    });

    setFormState(new Map([...values]));
  }, [setFormState, schools]);

  const [updateSchools, { loading: isLoadingUpdateSchools }] = useMutation(UPDATE_SCHOOLS, {
    refetchQueries: [{ query: GET_USER_EDUCATION }],
    awaitRefetchQueries: true
  });

  const handleDeleteSchool = (id: string) => {
    const schoolDataToUpdate = schoolList
      .map(createUpdateSchoolsPayloadEntry)
      .filter((school) => school.id !== id)
      .map(cleanSchoolProps);

    return updateSchools({
      variables: { schools: schoolDataToUpdate }
    });
  };

  const handleCancelAddSchool = (id: string) => {
    const filteredSchoolsMap = new Map([...formState]);
    filteredSchoolsMap.delete(id);

    setIsAddingSchool(false);
    setFormState(filteredSchoolsMap);
  };

  const handleUpdateSchool = ({ isDirty, isValid, values, id }) => {
    if (isValid) setIsAddingSchool(false);
    setFormState(new Map([...formState, [id, { isDirty, isValid, values }]]));
  };

  const saveSchool = () => {
    const formattedSchoolList = schoolList
      .map(createUpdateSchoolsPayloadEntry)
      .map((school) => {
        return {
          ...school,
          school: school.unlistedSchool ? school.unlistedSchool : school.school
        };
      })
      .map(cleanSchoolProps);

    updateSchools({
      variables: {
        schools: formattedSchoolList
      },
      onCompleted: () => {
        setIsAddingSchool(false);
      }
    });
  };

  const userHasUndergraduateDegree = doesSchoolDataHaveUndergradDegree(schools);
  const userHasJurisDoctorDegree = doesSchoolDataHaveJurisDoctorDegree(schools);

  const normalizedSchoolList = schoolList.map((school: SchoolFormProps) => {
    return createUpdateSchoolsPayloadEntry(school);
  });
  const selectedDegrees = schoolList.map((school: SchoolFormProps) => school.degree.value);
  const isEducationPageValid = isFormValid[industryContext.id](selectedDegrees);
  const isAnyFormDirty = [...formState].some(([_, form]) => form.isDirty);
  const isAnyFormInvalid = [...formState].some(([_, form]) => !form.isValid);
  const isSaveButtonDisabled =
    isLoadingUpdateSchools ||
    isLoadingUserEducation ||
    !isEducationPageValid ||
    !isAnyFormDirty ||
    isAnyFormInvalid;

  return (
    <Box padding="xl" overflow="auto">
      <EducationContent>
        <Stack space="xl">
          <SuitedCopyHeadline noMargin>Education</SuitedCopyHeadline>
          <Divider />
          <SuitedCopySubheadline noMargin>Instructions</SuitedCopySubheadline>
          <EducationInstructions industryContext={industryContext} />
          <Divider />
          <SuitedCopySubheadline noMargin>Required Information</SuitedCopySubheadline>
          <Box>
            <Stack>
              {isLoadingUserEducation ? (
                <Skeleton height={25} />
              ) : (
                <>
                  <Alert message="Undergraduate Degree" isChecked={userHasUndergraduateDegree} />
                  {industryContext.id === INDUSTRY_CONTEXTS.LAW && (
                    <Alert
                      message="Juris Doctor (JD) Degree"
                      isChecked={userHasJurisDoctorDegree}
                    />
                  )}
                </>
              )}
            </Stack>
          </Box>
          <Box>
            <Stack space="xl">
              {isLoadingUserEducation ? (
                <Box>
                  <Stack space="lg">
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
                      <Skeleton height={40} inline />
                      <Skeleton height={40} inline />
                    </div>
                  </Stack>
                </Box>
              ) : (
                <>
                  {schoolList.map((school: SchoolEntry, index) => {
                    const lastIndex = schoolList.length - 1;
                    const showDivider = index < lastIndex;
                    return (
                      <div key={school.id}>
                        <Stack space="xl">
                          <SchoolForm
                            id={school.id}
                            handleDeleteSchool={handleDeleteSchool}
                            handleCancelAddSchool={handleCancelAddSchool}
                            handleUpdateSchool={handleUpdateSchool}
                            previouslySavedSchools={schools}
                            schoolData={school}
                            isLastForm={lastIndex === index}
                            isLoadingUpdateSchools={isLoadingUpdateSchools}
                            isLoadingUserEducation={isLoadingUserEducation}
                          />
                          {showDivider && (
                            <Box>
                              <Divider />
                            </Box>
                          )}
                        </Stack>
                      </div>
                    );
                  })}
                </>
              )}
            </Stack>
          </Box>
          <Box>
            <Stack space="xl">
              {!isLoadingUserEducation && (
                <>
                  {(() => {
                    switch (industryContext.id) {
                      case EIndustryIds.LAW:
                        return (
                          <LawValidationError
                            schoolFormData={normalizedSchoolList}
                            isAnyFormDirty={isAnyFormDirty}
                          />
                        );
                      case EIndustryIds.INVESTMENT_BANKING:
                        return (
                          <InvestmentBankingValidationError
                            schoolFormData={normalizedSchoolList}
                            isAnyFormDirty={isAnyFormDirty}
                          />
                        );
                      default:
                        return (
                          <InvestmentBankingValidationError
                            schoolFormData={normalizedSchoolList}
                            isAnyFormDirty={isAnyFormDirty}
                          />
                        );
                    }
                  })()}
                </>
              )}
              <HStack justify="space-between">
                <Box>
                  {!isLoadingUserEducation ? (
                    <StyledSuitedButton
                      purpose="default"
                      alignLeft
                      disabled={isAddingSchool}
                      onClick={() => {
                        const schoolFormFields = getDefaultSchoolFormFields();
                        setIsAddingSchool(true);
                        setFormState(
                          new Map([
                            ...formState,
                            [
                              schoolFormFields.id,
                              {
                                isDirty: false,
                                isValid: false,
                                values: convertSchoolAPIDataToFormData(schoolFormFields)
                              }
                            ]
                          ])
                        );
                      }}
                      delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                    >
                      {schools.length === 0 ? "Add School" : "Add Another School"}
                    </StyledSuitedButton>
                  ) : null}
                </Box>
                <Box>
                  <SuitedButton
                    alignRight
                    formButton
                    type="submit"
                    purpose="primary"
                    disabled={isSaveButtonDisabled}
                    delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                    data-test="submit-button"
                    onClick={saveSchool}
                  >
                    Save
                  </SuitedButton>
                </Box>
              </HStack>
            </Stack>
          </Box>
        </Stack>
      </EducationContent>
    </Box>
  );
};
