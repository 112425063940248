import * as React from "react";
import styled from "styled-components";

import { typography } from "../../../constants/style-constants";

const SuitedFormSubheadlineEl = styled.div`
  font-size: ${typography.sizes.copy.subheading.base};
  ${typography.styles.subheading};
`;

type Props = {
  children: React.ReactNode;
};

const SuitedFormSubheadline = (props: Props) => {
  return (
    <SuitedFormSubheadlineEl {...props} data-test="component-suited-form-subheadline">
      {props.children}
    </SuitedFormSubheadlineEl>
  );
};

export default SuitedFormSubheadline;
