import { factory, primaryKey, oneOf } from "@mswjs/data";
import { faker } from "@faker-js/faker";

export const db = factory({
  UTM: {
    id: primaryKey(faker.datatype.uuid),
    source: String,
    medium: String,
    campaign: String,
    content: String,
    term: String,
    brand: String
  },
  RegistrationAPICalls: {
    id: primaryKey(faker.datatype.uuid),
    email: String,
    password: String,
    firstname: String,
    lastname: String,
    industryContext: String,
    termsAndPrivacyAgreed: Boolean,
    recaptchaValue: String,
    invitationCode: String,
    autopilotSessionId: String,
    utm: oneOf("UTM")
  }
});
