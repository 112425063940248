import * as React from "react";
import styled from "styled-components";

import { InputProps } from "./index";
import Confirm from "./Confirm";
import { typography, responsiveMaxSizes, colors } from "../../../constants/style-constants";

const MultiSelectContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  font-family: ${typography.family.body};
`;

const ChooseText = styled.div`
  color: ${typography.color.dark.hint};
  font-family: ${typography.family.body};
  font-size: 16px;
  flex-basis: 100%;
  padding-left: 22px;
  margin: 0px 0px 5px 0px;
`;

interface ItemProps {
  rows: number;
}

const Item =
  styled.div <
  ItemProps >
  `
  width: ${props => 1 / props.rows * 100}%;
  @media (max-width: ${responsiveMaxSizes.tablet}) {
      width: 100%;
  }
`;
const ItemPadding = styled.div`
  padding: 5px;
`;

interface MultiSelectOptionContainerProps {
  checked?: boolean;
  onClick?: () => void;
  isChild: boolean;
  disabled?: boolean;
}
const LetterBox = styled.div``;
const LetterIndexStyle =
  styled.span <
  any >
  `
  font-family: ${typography.family.monospace};
  background: ${props => (props.checked ? "white" : colors.accent1.base)};
  color: ${props => (props.checked ? colors.accent1.base : "white")};
  border: 1px solid ${typography.color.dark.hint};
  border-radius: 4px;
  padding: 5px 10px 6px 10px;
  font-weight: ${typography.weight.body.bold};
  top: 15px;
  left: 23px;
  position: absolute;
  margin: 0px 0px 0px -10px;
  transition: all .3s ease-in;
`;

const MultiSelectOptionContainer =
  styled.div <
  MultiSelectOptionContainerProps >
  `
  pointer-events: ${ (props) => props.disabled ? "none" : null };
  color: ${props => (props.checked ? "white" : typography.color.dark.hint)};
  background: ${props => (props.checked ? colors.accent1.base : colors.background.light.tertiary)};
  border: 1px solid ${props => (props.checked ? "white" : colors.background.light.backdrop)};
  border-radius: 4px;
  cursor: pointer;
  transition: all .3s ease-in;
  padding: 20px ;
  outline: 0;
  font-size: 18px;
  position: relative;
  &:hover {
    transition: all .3s ease-in;
    color: ${typography.color.light.base};
    background: ${props => (props.checked ? colors.accent1.base : colors.accent1.base)};
    opacity: 0.8;
    ${LetterIndexStyle} {
      color: ${props => (props.checked ? colors.accent1.base : colors.accent1.base)};
      background-color: white;
      transition: all .3s ease-in;
    }
  }
`;

const LetterIndex = ({ index, checked = false }) => {
  return <LetterIndexStyle checked={checked}>{String.fromCharCode(65 + index)}</LetterIndexStyle>;
};

const OptionLabel = styled.div`
  width: 100%;
  @media (max-width: ${responsiveMaxSizes.phone}) {
    margin: 0px 0px 0px 15px;
  }
  @media (max-width: ${responsiveMaxSizes.tablet}) {
    margin: 0px 0px 0px 10px;
  }
`;

function toggleValue(values: string[], value: string) {
  let newValues: string[];
  if (values.indexOf(value) > -1) {
    newValues = values.filter(item => item !== value);
  } else {
    newValues = values.concat([value]);
  }
  return newValues;
}

interface MultiSelectOption {
  value: string;
  label: string;
}

interface MultiSelectProps extends InputProps {
  values: string[];
  options: MultiSelectOption[];
  onChange(value: string[]): void;
  onSubmit(): void;
  rows?: number;
  disabled?: boolean;
}

export default class MultiSelect extends React.Component<MultiSelectProps> {
  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = e => {
    if (this.props.active) {
      const optionIndex = e.keyCode - 65;
      if (optionIndex >= 0 && optionIndex < this.props.options.length) {
        const option = this.props.options[optionIndex];
        this.props.onChange(toggleValue(this.props.values, option.value));
      }
    }
  };

  render() {
    const { active, name, values, options, onChange, onSubmit, rows = 2, disabled } = this.props;
    return (
      <div>
        <ChooseText className="col-xs-12">Choose as many as you like</ChooseText>
        <MultiSelectContainer className="row box start-xs col-xs-12 center-sm">
          {options.map((option, i) => {
            const checked = values.indexOf(option.value) > -1;
            const onClick = () => {
              onChange(toggleValue(values, option.value));
            };
            return (
              <Item key={`${name}-${i}`} rows={rows}>
                <ItemPadding>
                  <MultiSelectOptionContainer
                    className="row middle-xs col-xs-12"
                    isChild={true}
                    onClick={onClick}
                    checked={checked}
                    disabled={disabled}>
                    <LetterBox className="col-xs-2">
                      <LetterIndex index={i} checked={checked} />
                    </LetterBox>
                    <OptionLabel className="col-xs start-xs">{option.label}</OptionLabel>
                  </MultiSelectOptionContainer>
                </ItemPadding>
              </Item>
            );
          })}
        </MultiSelectContainer>
        {active && values && values.length > 0 ? <Confirm onClick={onSubmit} /> : null}
      </div>
    );
  }
}
