import Skeleton from "react-loading-skeleton";

import { Box, Stack, Center } from "@suited/components";
import { Content } from "./SectionQuestionsPage.style";
import { Container } from "../ECATimer/ECATimer.style";
import { ECATimer } from "../ECATimer/ECATimer";
import { SectionQuestionsItemGroups } from "../SectionQuestionsItemGroups/SectionQuestionsItemGroups";
import { NextButton } from "../NextButton/NextButton";
import { useGetNextAssessmentSectionQuestions } from "../eca.hooks";
import { QuestionSkeleton } from "../QuestionSkeleton/QuestionSkeleton";

export const SectionQuestionsPage = () => {
  const { loading } = useGetNextAssessmentSectionQuestions({ fetchPolicy: "network-only" });
  const SKELETONS_TO_GENERATE = 10;

  return (
    <>
      {loading ? (
        <Container>
          <Skeleton height={45} width={132} />
        </Container>
      ) : (
        <ECATimer />
      )}
      <Center>
        <Content padding="xxl">
          <Stack space="xxl">
            <Box>
              <Stack space="xxxl">
                {loading ? (
                  <Box width="85ch">
                    <Stack space="xxl">
                      {Array(SKELETONS_TO_GENERATE)
                        .fill(null)
                        .map(() => (
                          <Box>
                            <QuestionSkeleton />
                          </Box>
                        ))}
                    </Stack>
                  </Box>
                ) : (
                  <SectionQuestionsItemGroups />
                )}
              </Stack>
            </Box>
            <Center>
              {loading ? (
                <Skeleton style={{ borderRadius: "5px" }} height={48} width={140} />
              ) : (
                <NextButton />
              )}
            </Center>
          </Stack>
        </Content>
      </Center>
    </>
  );
};
