import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

import { Box } from "@suited/components";
import UserSettingsView from "./UserSettingsView";
import { UPDATE_USER_SETTINGS_VISIBILITY } from "./GraphQL/mutations";
import { GET_USER_SETTINGS } from "./GraphQL/queries";

const UserSettings = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { data, loading, refetch: usetSettingsRefetch } = useQuery(GET_USER_SETTINGS);
  const [updateUserSettingsVisibility] = useMutation(UPDATE_USER_SETTINGS_VISIBILITY);

  const handleLogout = async () => {
    await dispatch({ type: "ACTION_AUTH_LOGOUT" });
    // Clear the Apollo cache
    await client.clearStore();
  };

  const handleUpdateProfileVisibility = (option: string) => {
    updateUserSettingsVisibility({
      variables: { visibility: option },
      refetchQueries: [{ query: GET_USER_SETTINGS }]
    });
  };

  return (
    <Box padding="xl" overflow="auto">
      <UserSettingsView
        user={data?.GetUser}
        userSettings={data?.GetUser?.settings}
        userLoading={loading}
        userSettingsRefetch={usetSettingsRefetch}
        onLogout={handleLogout}
        onUpdateProfileVisibility={handleUpdateProfileVisibility}
      />
    </Box>
  );
};

export default UserSettings;
