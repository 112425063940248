import * as React from "react";
import styled from "styled-components";

const CopyrightContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default class CopyrightNotice extends React.Component<any> {
  render() {
    const year = new Date().getFullYear();
    const copyright = `© Suited ${year}. All rights reserved.`;
    return <CopyrightContainer className={this.props.className}>{copyright}</CopyrightContainer>;
  }
}
