import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

import { ButtonColumn } from "./common.style";

interface Props {
  confirmText: string;
  cancelText?: string;
  onCancel: () => void;
  onConfirm: () => void;
  disabled?: boolean;
}

/* This component is a very simple confirm footer that can be used for use 
  cases that do not require async side effects*/
const SuitedBasicConfirmFooter = (props: Props) => {
  return (
    <ButtonColumn>
      <SuitedButton onClick={props.onConfirm} purpose="primary" disabled={props.disabled}>
        {props.confirmText}
      </SuitedButton>
      <SuitedButton purpose="cancel" onClick={props.onCancel}>
        {props.cancelText ? props.cancelText : "cancel"}
      </SuitedButton>
    </ButtonColumn>
  );
};

export default SuitedBasicConfirmFooter;
