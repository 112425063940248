import React from "react";
import { SuitedAltIcon } from "suited/components/shared/typography/SuitedAltIcon";
import { SuitedToolTip } from "suited/components/shared/layout/SuitedToolTip";

import {
  AccordionEl,
  AccordionToggleButton,
  AccordionContent,
  StyledSuitedCopy
} from "./SuitedAccordion.style";

interface ISuitedAccordionProps {
  id: string;
  label: string;
  collapsed: boolean;
  onToggle: (id: string) => void;
  minWidth?: number;
  children: React.ReactNode;
}

export const SuitedAccordion = (props: ISuitedAccordionProps) => {
  const { children, collapsed, id, label, onToggle, minWidth = 0 } = props;

  return (
    <AccordionEl minWidth={minWidth}>
      <AccordionToggleButton
        onClick={() => onToggle(id)}
        active={!collapsed}
        title="Button to show and hide content within this item"
        data-tip
        data-for={`expand-collapse-toggle-tooltip-${id}`}
      >
        <SuitedAltIcon name={collapsed ? "caret-right" : "caret-down"} />
        <StyledSuitedCopy noMargin={true}>{label}</StyledSuitedCopy>
      </AccordionToggleButton>
      <SuitedToolTip
        id={`expand-collapse-toggle-tooltip-${id}`}
        type="dark"
        effect="solid"
        place="right"
      >
        {collapsed ? "Expand to show content." : "Collapse to hide content."}
      </SuitedToolTip>
      <AccordionContent collapsed={collapsed}>{children}</AccordionContent>
    </AccordionEl>
  );
};
