import styled from "styled-components";

import { typography } from "../../../../constants/style-constants";
import SuitedCopySubheadline from "../../../shared/typography/SuitedCopySubheadline";

export const Email = styled(SuitedCopySubheadline)`
  text-transform: lowercase;
`;

export const NewEmail = styled(SuitedCopySubheadline)`
  color: ${typography.color.dark.disabled};
  text-transform: lowercase;
`;
