import { Box, HStack } from "@suited/components";

import Icon from "suited/components/shared/typography/Icon";

import { MessageContainer, ValidationWarning } from "../education.styles";
import { IValidationMessageProps } from "../education.types";
import { doesSchoolDataHaveUndergradDegree } from "../education.utils";

export const InvestmentBankingValidationError = ({
  schoolFormData,
  isAnyFormDirty
}: IValidationMessageProps) => {
  return (
    <>
      {isAnyFormDirty && !doesSchoolDataHaveUndergradDegree(schoolFormData) && (
        <Box>
          <MessageContainer data-testid="validation">
            <ValidationWarning>
              <HStack space="xs">
                <Icon name="exclamation-triangle" />
                <div>You must have at least one undergraduate degree.</div>
              </HStack>
            </ValidationWarning>
          </MessageContainer>
        </Box>
      )}
    </>
  );
};
