import React from "react";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

import { Stack } from "@suited/components";
import { colors } from "suited/constants/style-constants";
import SuitedLink from "./shared/buttons/SuitedLink";
import AuthenticationTemplate from "./AuthenticationTemplate/AuthenticationTemplate";
import ButtonRowCentered from "./shared/layout/ButtonRowCentered";
import { timeouts } from "../constants/interaction-constants";
import logErrorToSlack from "../util/logErrorToSlack";
import { ApolloClientInstance } from "../types";

interface Props {
  client: ApolloClientInstance;
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: any;
  info: any;
}

export default class ErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
  state = { hasError: false, error: "", info: "" };

  reloadPage() {
    window.location.reload();
  }

  handleSendFeedback() {
    const front = (window as any).FrontChat;

    if (front) {
      front("show");
    } else {
      window.open("mailto:support@wellsuited.com", "_blank");
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: error, info: info });
    logErrorToSlack(error, info, this.props.client);
  }

  render() {
    if (this.state.hasError) {
      return (
        <AuthenticationTemplate headline="Something Went Wrong">
          <Stack>
            <div>
              <p style={{ color: colors.white }}>
                We're awfully sorry about that. Please{" "}
                <SuitedLink onClick={this.handleSendFeedback} tooltip="Send Feedback">
                  let us know
                </SuitedLink>{" "}
                that something is broken.
              </p>
              <p style={{ color: colors.white }}>
                Most times, reloading the page will get you unstuck. If that doesn't work, please{" "}
                <SuitedLink onClick={this.handleSendFeedback} tooltip="Send Feedback">
                  contact us
                </SuitedLink>{" "}
                with the steps that you took that led to this error page.
              </p>
            </div>
            <div>
              <ButtonRowCentered>
                <SuitedButton
                  purpose="primary"
                  onClick={this.reloadPage}
                  title="Reload Page"
                  delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                >
                  Reload
                </SuitedButton>
              </ButtonRowCentered>
            </div>
          </Stack>
        </AuthenticationTemplate>
      );
    }
    return this.props.children;
  }
}
