import { useCallback, useMemo } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation } from "@apollo/client";
import debounce from "lodash/debounce";

import { Box, Stack, Center, Grid, HStack } from "@suited/components";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { timeouts } from "suited/constants/interaction-constants";
import { InstructionContent } from "suited/components/SuitedAssessment/SuitedAssessmentLanding.style";
import { LegalCopy } from "suited/components/shared/typography/SuitedLegalCopy";
import { CREATE_DEFAULT_ASSESSMENTS } from "../../eca/eca.queries";
import { DEBOUNCE_WAIT_TIME } from "../../eca/ReasonableAccommodationsModal/ReasonableAccomodationsModal.constants";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import { instructions } from "./instructions";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import SuitedCalloutHeadline from "suited/components/shared/typography/SuitedCalloutHeadline";
import SuitedCallout from "suited/components/shared/typography/SuitedCallout";
import { StyledFontAwesomeIcon } from "suited/components/shared/icons/StyledFontAwesomeIcon";

export const LateralInstructionsPage = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const [createAssessment, { loading }] = useMutation(CREATE_DEFAULT_ASSESSMENTS, {
    onCompleted: () => {
      const sectionContentRoute = `${match.url}/content`;
      history.push(sectionContentRoute);
    }
  });

  // We have to memoize the debounced callback function to get past the ESLint
  // unknown dependencies error. See https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks.
  const debouncedHandler = useMemo(
    () => debounce(createAssessment, DEBOUNCE_WAIT_TIME),
    [createAssessment]
  );
  const startAssessment = useCallback(() => debouncedHandler(), [debouncedHandler]);

  return (
    <Box padding="xl" overflow="auto">
      <Center>
        <InstructionContent>
          <Stack>
            <Box>
              <Stack space="xl">
                <SuitedCopyHeadline noMargin>Lateral Law Assessment</SuitedCopyHeadline>
                <p>
                  Welcome to the Suited lateral law assessment. Your individual responses to
                  questions or prompts will not be shared with any firm. Instead, the results will
                  be summarized to help firms understand you more holistically.
                </p>
                <SuitedCopySubheadline noMargin>INSTRUCTIONS</SuitedCopySubheadline>
                <Grid space="xl">
                  {instructions.map(({ headline, icon, copy }) => {
                    return (
                      <HStack align="flex-start" key={headline}>
                        <StyledFontAwesomeIcon size="3x" icon={icon as IconProp} />
                        <Stack>
                          <SuitedCalloutHeadline noMargin>{headline}</SuitedCalloutHeadline>
                          <SuitedCallout noMargin>{copy}</SuitedCallout>
                        </Stack>
                      </HStack>
                    );
                  })}
                </Grid>
              </Stack>
            </Box>

            <LegalCopy>
              This assessment is intellectual property created and administered by Suited. By
              proceeding to take this assessment,{" "}
              <strong>you agree to not disclose, redistribute, or reproduce the contents</strong>,
              in whole or in part, without the prior express written consent of Suited.
            </LegalCopy>
            <Box>
              <Center>
                <SuitedButton
                  purpose="primary"
                  disabled={loading}
                  onClick={startAssessment}
                  delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                  data-analytics-id="lateral-assessment-start"
                >
                  Begin
                </SuitedButton>
              </Center>
            </Box>
          </Stack>
        </InstructionContent>
      </Center>
    </Box>
  );
};
