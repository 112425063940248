import React from "react";

import { FormFieldLabel, FormLabelName, ContainerBox } from "../Forms";
import {
  SingleSelectContainer,
  Options,
  SingleSelectOptionContainer,
  Input
} from "./GeneralSingleSelect.style";

interface SingleSelectOption {
  value: string;
  label: string;
}

interface SingleSelectProps {
  value: string;
  label: string;
  name: string;
  options: SingleSelectOption[];
  onChange(value: string, name: string): void;
  completed: boolean;
  disabled?: boolean;
}
interface SingleSelectState {
  completed: boolean;
}
export default class GeneralSingleSelect extends React.Component<
  SingleSelectProps,
  SingleSelectState
> {
  constructor(props) {
    super(props);
    this.state = {
      completed: this.props.completed
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      completed: nextProps.value ? true : nextProps.completed
    };
  }

  render() {
    const { name, label, value, options, onChange } = this.props;
    return (
      <ContainerBox data-testid={name}>
        <SingleSelectContainer completed={this.state.completed}>
          <FormFieldLabel>{label}</FormFieldLabel>
          <Options>
            {options.map((option) => {
              const checked = value === option.value;
              const onClick = () => {
                if (this.props.disabled) return;
                onChange(option.value, name);
              };
              const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
                if (this.props.disabled) return;
                if (e.keyCode === 32) {
                  e.preventDefault();
                  onChange(option.value, name);
                }
              };
              return (
                <SingleSelectOptionContainer
                  key={option.label}
                  onClick={onClick}
                  onKeyDown={onKeyDown}
                  checked={checked}
                  tabIndex={0}
                  disabled={this.props.disabled}
                >
                  <Input type="checkbox" />
                  <label>
                    <FormLabelName data-selected={checked}>{option.label}</FormLabelName>
                  </label>
                </SingleSelectOptionContainer>
              );
            })}
          </Options>
        </SingleSelectContainer>
      </ContainerBox>
    );
  }
}
