import { TReactRecaptchaOptions } from "types";

export function createRecaptchaScript(onerror: () => void): HTMLScriptElement {
  const recaptchaScript = document.createElement("script");

  recaptchaScript.async = true;
  recaptchaScript.defer = true;
  recaptchaScript.src = getURL();
  recaptchaScript.onerror = onerror;

  return recaptchaScript;
}

//https://github.com/dozoisch/react-google-recaptcha/blob/master/src/recaptcha-wrapper.js#L7
function getOptions(): TReactRecaptchaOptions {
  return (typeof window !== "undefined" && window.recaptchaOptions) || { useRecaptchaNet: false };
}

//https://github.com/dozoisch/react-google-recaptcha/blob/master/src/recaptcha-wrapper.js#L11
function getURL(): string {
  const dynamicOptions = getOptions();
  const hostname = dynamicOptions.useRecaptchaNet ? "recaptcha.net" : "www.google.com";
  return `https://${hostname}/recaptcha/api.js?onload=onRecaptchaLoadCallback`;
}
