// @ts-nocheck
import React, { createContext, useReducer } from "react";
import { IAssessment } from "../graphql";

interface IState {
  assessments: IAssessment[];
}

interface IContextProps {
  state: IState;
  dispatch: ({ type }: { type: string; payload: IAssessment | IAssessment[] }) => void;
}

const AssessmentContext = createContext({} as IContextProps);

const initialState = {
  assessments: []
};

const getAssessmentById = (
  assessments: IAssessment[],
  assessmentId: string
): IAssessment | undefined => {
  return assessments.find((assessment) => assessment.assessmentId === assessmentId);
};

const getRestAssessmentsById = (
  assessments: IAssessment[],
  assessmentId: string
): IAssessment[] => {
  return assessments.filter((assessment) => assessment.assessmentId !== assessmentId);
};

const reducer = (state, action) => {
  const assessment = getAssessmentById(state.assessments, action.payload.assessmentId);
  const assessments = getRestAssessmentsById(state.assessments, action.payload.assessmentId);
  switch (action.type) {
    case "completed":
      return {
        ...state,
        assessments: [...assessments, { ...assessment, completed: true }] as IAssessment[]
      };

    case "addAssessments":
      return { ...state, assessments: [...action.payload] as IAssessment[] };

    // In TEST mode, DevToolsPanel can override an Essential Competencies Assessment
    case "TEST_OVERRIDE":
      return {
        ...state,
        assessments: [...assessments, { ...action.payload }]
      };
  }
};

function AssessmentContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <AssessmentContext.Provider value={value}>{props.children}</AssessmentContext.Provider>;
}

const AssessmentContextConsumer = AssessmentContext.Consumer;

export { AssessmentContextProvider };
