import {
  Container,
  StyledProgressBar,
  PercentComplete,
  PercentCompleteContainer
} from "./ProgressBar.style";

type Props = {
  progress: number;
  displayText?: number;
};

export const ProgressBar = ({ progress, displayText }: Props) => {
  return (
    <Container>
      <StyledProgressBar max="100" value={progress} />
      <PercentCompleteContainer width={progress}>
        <PercentComplete>{`${displayText ?? progress}%`}</PercentComplete>
      </PercentCompleteContainer>
    </Container>
  );
};
