import styled from "styled-components";
import { sizes } from "../../../constants/style-constants";

const ButtonRowCentered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  margin: ${sizes.gridUnits.times1} 0;
`;

export default ButtonRowCentered;
