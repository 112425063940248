export const LocationPreferences = {
  NO_PREFERENCE: "No Preference",
  CHOOSE_LOCATION: "Choose Location"
};

export const OPTIONS_LOCATION_PREFERENCES = [
  {
    label: LocationPreferences.NO_PREFERENCE,
    value: LocationPreferences.NO_PREFERENCE
  },
  { label: LocationPreferences.CHOOSE_LOCATION, value: LocationPreferences.CHOOSE_LOCATION }
];

export const DEFAULT_REMOTE_WORK_PREFERENCE = "NO_PREFERENCE";

export const TIMEOUT_OPTIONS = {
  interval: 1000,
  timeout: 10000
};
