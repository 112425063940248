import { assessmentStatusTypes, sectionStatusTypes } from "suited/components/CandidateHome/eca/eca.constants";
import {
  useGetCandidateAssessmentById,
  useGetCandidateAssessments
} from "suited/components/CandidateHome/eca/eca.hooks";
import { AssessmentIds } from "../lateral.constants";
import { LateralInstructionsPage } from "../LateralInstructionsPage/LateralInstructionsPage";
import { LateralLandingPageSkeleton } from "./LateralLandingPageSkeleton";
import { LateralAssessmentCompleted } from "../LateralAssessmentCompleted/LateralAssessmentCompleted";
import { LateralInProgressPage } from "../LateralInProgressPage/LateralInProgressPage";

export const LateralLandingPage = () => {
  const { assessments, isGetCandidateAssessmentsLoading } = useGetCandidateAssessments();
  const assessmentID =
    assessments.find((assessment) => assessment.id === AssessmentIds.LATERAL_LAW)?.id || "";
  const { assessment, isGetAssessmentLoading } = useGetCandidateAssessmentById(assessmentID, {
    fetchPolicy: "network-only"
  });

  const isAssessmentComplete = assessment?.status === assessmentStatusTypes.COMPLETE;
  const isAssesmentPartiallyCompleted = assessment?.sections?.some(
    (section) => section.status === sectionStatusTypes.COMPLETE
  );

  if (!isAssessmentComplete && isAssesmentPartiallyCompleted) {
    return <LateralInProgressPage />;
  }

  if (isGetAssessmentLoading || isGetCandidateAssessmentsLoading) {
    return <LateralLandingPageSkeleton />;
  }

  return isAssessmentComplete ? <LateralAssessmentCompleted /> : <LateralInstructionsPage />;
};
