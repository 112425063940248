import { colors, typography } from "suited/constants/style-constants";
import { TStyledSuitedInputStatusMessageProps } from "./SuitedInputStatusMessage.types";

/**
 * Returns the correct message color depending on the
 * `statusType` of the message and whether or not
 * `overDark` is enabled.
 */
export function getSuitedInputStatusMessageColor(
  props: TStyledSuitedInputStatusMessageProps
): string {
  const isErrorMessage = props.statusType === "error";
  if (props.overDark) {
    return isErrorMessage ? typography.color.light.base : typography.color.light.emphasis;
  } else {
    return isErrorMessage ? colors.status.error.base : colors.status.success.base;
  }
}
