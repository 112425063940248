import React, { useState } from "react";

import { SuitedInputErrorMessage } from "suited/components/shared/typography/SuitedInputStatusMessage";
import {
  ISuitedTextInputProps,
  SuitedTextInputContainerEl
} from "suited/components/shared/inputs/SuitedAltTextInput";
import { StyledSuitedTextInput } from "./SuitedValidatedTextInput.style";

export interface ISuitedValidatedTextInputProps extends ISuitedTextInputProps {
  /** The `error` prop takes a string for the error message to send when the input does not pass validation. */
  error?: string;
  /** The `invalid` prop is a boolean for flagging when it is necessary to display the error message */
  invalid?: boolean;
  /** When the text is over a dark background, it has a light appearance. */
  overDark?: boolean;
  children?: React.ReactNode;
}

/**
 * ## What
 * `SuitedValidatedTextInput` is a component that is used when a text input needs to have some sort of validation.
 *
 * ## When
 * Any place where a text input needs to have something specific in it and/or is required.
 *
 * ## Not
 * This should not be used if a text input does not need validation or is not required.
 *
 * ## Example
 * In Candidate App, it's used on the registration, login, and user settings.
 * In Recruiter App, it's used on the login form.
 */
export const SuitedValidatedTextInput = (props: ISuitedValidatedTextInputProps) => {
  const {
    children,
    onInputChange,
    onInputFocus,
    onInputBlur,
    inputKey,
    className,
    ...passthroughProps
  } = props;
  const [touched, setTouched] = useState<boolean>(props.value !== "" ? true : false);
  const [invalid, setInvalid] = useState<boolean>(
    props.invalid !== undefined ? props.invalid : false
  );
  const [lastError, setLastError] = useState<string>(props.error ? props.error : "");

  const getDefaultErrorMessage = () => {
    if (props.invalid !== undefined) return "";
    return props.required
      ? "This field is required. Please enter a value."
      : "This field is not valid. Please try again.";
  };

  // when `invalid` is not managed, a required input with value "" is derived to be invalid
  const deriveIsInvalid = () => {
    return props.invalid !== undefined ? props.invalid : props.required && invalid ? true : false;
  };

  // this sets the state for invalid
  const isInvalid = (value: any) => {
    return props.invalid !== undefined
      ? props.invalid
      : props.required && value === ""
      ? true
      : false;
  };

  const handleFocus = (value: any, targetName: string) => {
    if (props.onInputFocus) props.onInputFocus(value, targetName);
  };

  const handleBlur = (value: any, targetName: string) => {
    setTouched(true);
    setInvalid(isInvalid(value));
    if (props.onInputBlur) {
      props.onInputBlur(value, targetName);
    }
  };

  const handleChange = (value: any, targetName: string) => {
    setTouched(true);
    setInvalid(isInvalid(value));
    setLastError(props.error || "");
    props.onInputChange(value, targetName);
  };

  const errorMessageId = `${props.name}-error-message`;
  const getErrorMessageText = () => {
    return props.error
      ? props.error
      : lastError !== ""
      ? lastError || ""
      : getDefaultErrorMessage();
  };

  return (
    <SuitedTextInputContainerEl
      {...props}
      className={props.className}
      data-testid="component-suited-validated-text-input"
    >
      <StyledSuitedTextInput
        {...passthroughProps}
        inputKey={inputKey ? inputKey : ""}
        onInputChange={handleChange}
        onInputFocus={handleFocus}
        onInputBlur={handleBlur}
        hasError={touched && deriveIsInvalid()}
      />
      {children}

      {/* @ts-ignore */}
      {touched && deriveIsInvalid() ? (
        <>
          {/* @ts-ignore */}
          <SuitedInputErrorMessage
            id={errorMessageId}
            overDark={props.overDark}
            message={getErrorMessageText()}
            show={touched && deriveIsInvalid()}
          />
        </>
      ) : null}
    </SuitedTextInputContainerEl>
  );
};

