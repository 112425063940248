import styled from "styled-components";

import { colors, typography } from "../../../constants/style-constants";
import SuitedDetailPrimaryValue from "../../shared/typography/SuitedDetailPrimaryValue";

export const StyledSuitedDetailPrimaryValue = styled(SuitedDetailPrimaryValue)`
  flex: 0 0 auto;
  padding-bottom: 0;
  z-index: 1;
`;

export const FileLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  word-break: break-all;
  &:hover {
    color: ${typography.color.dark.emphasis};
  }
  &:active {
    color: ${colors.accent1.base};
  }
`;
