import styled from "styled-components";

import { colors, typography, animationCurves, sizes } from "../../../constants/style-constants";

export const CheckboxItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  margin-left: -${sizes.gridUnits.divide2};

  input[type="checkbox"] {
    position: absolute;
    height: ${sizes.touch.minimum};
    width: ${sizes.touch.minimum};
    margin: 0;

    /* visually hide the radio button, and set it up to be positioned over the styled radio button replacement*/
    appearance: none;
    background-color: transparent;
    opacity: 0.00001;
    z-index: 2;

    &:disabled {
      cursor: not-allowed;
    }
  }

  input[type="checkbox"] ~ label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-height: ${sizes.touch.minimum};
    padding-left: calc(${sizes.touch.minimum} + ${sizes.gridUnits.divide2});
    color: ${typography.color.dark.base};
    font-family: ${typography.family.body};
    font-size: ${typography.sizes.copy.value};
    line-height: 1;
    font-weight: ${typography.weight.body.regular};
    cursor: pointer;
    box-sizing: border-box;

    strong {
      font-weight: ${typography.weight.body.medium};
    }

    &:before {
      content: "\f0c8"; /* 'square' = 'f0c8' */
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      height: ${sizes.touch.minimum};
      width: ${sizes.touch.minimum};
      background-color: transparent;
      font-family: "Font Awesome 5 Pro";
      font-size: ${typography.sizes.form.base.inputIcon};
      z-index: 2;
      backface-visibility: hidden; /* fixes Chrome translate bug on transform transition */
      transform-origin: center center;
      transform: scale(1);
      transition: transform 150ms ${animationCurves.easeOutQuart};
      box-sizing: border-box;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: ${sizes.touch.minimum};
      width: ${sizes.touch.minimum};
      border-radius: 50%;
      background-color: transparent;
      backface-visibility: hidden; /* fixes Chrome translate bug on transform transition */
      transform-origin: center center;
      transform: scale(0.75);
      box-sizing: border-box;
    }
  }

  input[type="checkbox"]:hover:not([disabled]) ~ label:after,
  input[type="checkbox"]:focus:not([disabled]) ~ label:after {
    color: ${typography.color.dark.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.shades.times1};
    transform: scale(1);
    transition: transform 300ms ${animationCurves.easeOutQuart},
      border-color 300ms ${animationCurves.easeOutQuart},
      box-shadow 300ms ${animationCurves.easeOutQuart};
  }

  input[type="checkbox"]:focus:not([disabled]) ~ label:after {
    box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
    transform: scale(1);
  }

  input[type="checkbox"]:active:not([disabled]) ~ label:after,
  input[type="checkbox"]:hover:not([disabled]):active ~ label:after,
  input[type="checkbox"]:focus:not([disabled]):active ~ label:after {
    color: ${typography.color.dark.emphasis};
    transform: scale(0.5);
    box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
    transition: transform 600ms ${animationCurves.easeOutQuart};
  }

  input[type="checkbox"]:checked ~ label:before {
    content: "\f14a"; /* 'check-square' = 'f14a' */
    color: ${colors.accent1.base};
    font-weight: 600;
    transform: scale(1.25);
    transition: transform 400ms ${animationCurves.easeOutBack};
  }

  input[type="checkbox"]:checked ~ label:after {
    border-radius: 0;
  }

  input[type="checkbox"]:indeterminate ~ label:before {
    content: "\f146"; /* 'minus-square' = 'f146' */
  }

  input[type="checkbox"]:disabled ~ label {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
