import styled from "styled-components";

import { colors } from "../../constants/style-constants";

export const PasswordResetFormWrapper = styled.form``;

export const ErrorSection = styled.div`
  margin-bottom: 20px;
`;

export const Error = styled.div`
  color: ${colors.status.error.base};
  font-size: 1.5em;
`;
