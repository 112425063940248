import React, { FunctionComponent, useState } from "react";

import { IconName } from "@fortawesome/fontawesome-svg-core";
import {
  SuitedAltIcon,
  IconWeight,
  IconSize
} from "suited/components/shared/typography/SuitedAltIcon";
import { Button, A } from "./SuitedAltIconButton.style";

interface ISuitedIconButtonProps {
  /** When the button is over a dark background, it has a light appearance. */
  overDark?: boolean;
  /** size of the icon */
  iconSize?: IconSize;
  /**
   * The name of a version-supported FontAwesome Pro icon
   * (see https://fontawesome.com/icons?d=gallery)
   **/
  iconName: IconName;
  /**
   * Click handler for the majority of use cases. When present, it renders a button in markup.
   */
  onClick?: () => void;
  // @TODO unit tests for href prop
  /**
   * In some rare cases (file downloads), the button should be a link. When present, it renders and anchor in markup.
   */
  href?: string;
  /**
   * A FontAwesome icon weight: "solid" | "regular" | "light"
   * @default "light"
   **/
  iconWeight?: IconWeight;
  /**
   * This string gets applied to the rendered button's `alt` and `aria-label` attributes.
   * @default "icon-button"
   **/
  tooltip?: string;
  disabled?: boolean;
  /**
   * Number of milliseconds between click-event and `onClick` handler fired.
   * This allows the button :active transition to show before navigation, for example.
   * @default 200
   **/
  delay?: number;
  /**
   * Opt-in to event bubbling for special use cases
   */
  bubbleEvents?: boolean;
  /**
   * @TODO needs unit tests
   * Button type can be specified, e.g. for form submit behavior
   */
  type?: "button" | "submit" | "reset" | undefined;
  /**
   * Tabindex can be used to override the default tabindex of 0.
   * @default 0
   */
  tabIndex?: number;
  /**
   * Styled-Component support
   */
  className?: string;
}

/**
 * @description
 * Component for displaying a clickable icon.
 * 
 * This component uses react component state to set styling. This way we have more specific control and don't have to worry
 * about complex css logic.
 * 
 * @component
 * @example
 * <SuitedIconButton
      iconName="cloud-download"
      tooltip="storybook-download"
      onClick={() => download()}
    />
 */
export const SuitedAltIconButton: FunctionComponent<ISuitedIconButtonProps> = (props) => {
  const {
    iconName,
    onClick,
    iconSize,
    iconWeight = "light",
    tabIndex = 0,
    tooltip = "icon-button",
    disabled = false,
    delay = 200,
    type,
    className,
    ...passthroughProps
  } = props;

  const [hovered, setHovered] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (!onClick) return;
    if (disabled) return;
    if (event.key === "Enter" || event.key === " ") {
      if (onClick) onClick();
      setActive(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (!onClick) return;
    if (disabled) return;
    if (event.key === "Enter" || event.key === " ") {
      setActive(true);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!props.bubbleEvents) e.stopPropagation();
    if (disabled) return;
    delay
      ? window.setTimeout(() => {
          onClick && onClick();
        }, delay)
      : onClick && onClick();
  };

  if (!!onClick) {
    return (
      <Button
        className={className}
        {...passthroughProps}
        tabIndex={tabIndex}
        type={type || "button"}
        title={tooltip}
        aria-label={tooltip}
        onClick={handleClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onMouseDown={() => setActive(true)}
        onMouseUp={() => setActive(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyUp={(event) => handleKeyUp(event)}
        onKeyDown={(event) => handleKeyDown(event)}
        hovered={hovered}
        focused={focused}
        active={active}
        disabled={disabled}
        name={tooltip}
        data-testid="component-suited-icon-button"
      >
        <SuitedAltIcon
          name={iconName}
          weight={iconWeight}
          size={iconSize}
        />
      </Button>
    );
  } else {
    return (
      <A
        className={className}
        {...passthroughProps}
        tabIndex={tabIndex}
        title={tooltip}
        aria-label={tooltip}
        href={!disabled ? props.href : "#"}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onMouseDown={() => setActive(true)}
        onMouseUp={() => setActive(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyUp={(event) => handleKeyUp(event)}
        onKeyDown={(event) => handleKeyDown(event)}
        hovered={hovered}
        focused={focused}
        active={active}
        disabled={disabled}
        data-testid="component-suited-icon-button"
      >
        <SuitedAltIcon
          name={iconName}
          weight={iconWeight}
          size={iconSize}
        />
      </A>
    );
  }
};
