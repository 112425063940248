import { useEffect } from "react";

import { Box, Flex, Stack, Wrap } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { SuitedAltCopyCaption } from "suited/components/shared/typography/SuitedAltCopyCaption";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";

import { timeouts } from "../../../constants/interaction-constants";
import { CandidateConfigContext } from "../../CandidateConfig";
import { UserPasswordChangeForm } from "../../CandidateHome/UserSettings/UserPasswordChangeForm";
import { AdditionalContactInfo } from "./AdditionalContactInfo";
import UserEmailDisplay from "./UserEmailChange/UserEmailChangeForm";
import { UserNameChangeForm } from "./UserNameChangeForm";
import { UserProfileVisibilityForm } from "./UserProfileVisibilityForm";
import { UserSecondaryEmailForm } from "./UserSecondaryEmailForm";
import { UserSettingsContent } from "./UserSettingsView.style";

interface Props {
  user: any;
  userSettings: any;
  userSettingsRefetch: () => void;
  userLoading: boolean;
  onLogout(): void;
  onUpdateProfileVisibility: (option: string) => void;
}

const UserSettingsView = (props: Props) => {
  const { user, userSettingsRefetch, userLoading, onLogout } = props;

  useEffect(() => {
    // if the loaded user is undefined, we'll be stuck, so log out for recovery.
    if (!userLoading && typeof user === "undefined") {
      onLogout();
    }
  }, [userLoading, user]); // eslint-disable-line

  const handleClickLogout = () => {
    onLogout();
  };

  return (
    <CandidateConfigContext.Consumer>
      {() => (
        <Flex justify="center" align="center">
          <UserSettingsContent>
            <Stack data-testid="component-user-settings-view">
              <Wrap justify="space-between">
                <Stack space="none">
                  <SuitedCopyHeadline noMargin>User Settings</SuitedCopyHeadline>
                  <SuitedAltCopyCaption noMargin>
                    Manage your account settings below.
                  </SuitedAltCopyCaption>
                </Stack>
                <Box>
                  <SuitedButton
                    purpose="default"
                    delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                    onClick={handleClickLogout}
                    data-testid="log-out"
                  >
                    Sign Out
                  </SuitedButton>
                </Box>
              </Wrap>
              <Box>
                <Stack space="lg">
                  <UserEmailDisplay
                    user={user}
                    userLoading={userLoading}
                    refetch={userSettingsRefetch}
                    data-testid="email-change-form"
                  />
                  <UserSecondaryEmailForm />
                </Stack>
              </Box>
              <Box>
                <Stack>
                  <UserNameChangeForm />
                  <UserPasswordChangeForm />
                  <UserProfileVisibilityForm />
                  <AdditionalContactInfo />
                </Stack>
              </Box>
            </Stack>
          </UserSettingsContent>
        </Flex>
      )}
    </CandidateConfigContext.Consumer>
  );
};

export default UserSettingsView;
