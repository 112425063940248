import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { Box, HStack, Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import SuitedIconButton from "suited/components/shared/buttons/SuitedIconButton";
import { ResponsiveDialogModal } from "suited/components/shared/feedback/ResponsiveDialogModal/ResponsiveDialogModal";
import BasicDialogHeader from "suited/components/shared/layout/Dialogs/BasicDialogHeader";
import { Divider } from "suited/components/shared/layout/Divider";
import { SuitedToolTip } from "suited/components/shared/layout/SuitedToolTip";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { timeouts } from "suited/constants/interaction-constants";

import { GET_USER_SETTINGS } from "./GraphQL/queries";
import { EditableFormState } from "./userSettings.constants";

const AddButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  padding: 0.6rem 1.2rem;
  margin-top: 0.5rem;
  max-width: max-content;

  background-color: transparent;

  color: #595959;

  border: 1px solid #b3b3b3;

  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: #8c8c8c;
  }

  &:focus {
    outline: none;
    border-color: #8c8c8c;
  }

  &:hover span,
  &:hover svg {
    color: #404040;
  }

  span,
  svg {
    display: inline-block;
    transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const EditableContactField = ({
  id,
  formState,
  fieldTitle,
  fieldValue,
  renderEditComponent,
  handleFormStateUpdate,
  isDeleting,
  handleDelete,
  deleteActionText
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const closeConfirmationModal = () => setIsConfirmationModalOpen(false);
  return (
    <Stack space="xxxs">
      <Stack space="xxxs">
        <p style={{ color: "rgba(0, 0, 0, 0.65)", fontSize: "0.9rem" }}>{fieldTitle}</p>
        <Divider />
      </Stack>
      {(function () {
        switch (formState) {
          case EditableFormState.READY:
            return (
              <>
                <HStack space="md">
                  <p style={{ fontSize: "1.15rem" }}>{fieldValue}</p>
                  <Box>
                    <HStack space="none">
                      <Box>
                        <SuitedIconButton
                          iconName="pencil"
                          iconWeight="solid"
                          onClickButton={() => handleFormStateUpdate(EditableFormState.EDITING)}
                          data-test="icon-button"
                          data-tip
                          data-for={`edit-field-${id}`}
                        />
                        <SuitedToolTip
                          id={`edit-field-${id}`}
                          type="dark"
                          effect="solid"
                          place="right"
                        >
                          Edit
                        </SuitedToolTip>
                      </Box>
                      <Box style={{ marginLeft: "-7.5px" }}>
                        <SuitedIconButton
                          iconName="trash"
                          iconWeight="solid"
                          onClickButton={() => setIsConfirmationModalOpen(true)}
                          data-test="icon-button"
                          data-tip
                          data-for={`delete-field-${id}`}
                        />
                        <SuitedToolTip
                          id={`delete-field-${id}`}
                          type="dark"
                          effect="solid"
                          place="right"
                        >
                          Delete
                        </SuitedToolTip>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <ResponsiveDialogModal
                  maxWidth={700}
                  show={isConfirmationModalOpen}
                  onCancel={closeConfirmationModal}
                  closeDuration={timeouts.MODAL_DEFAULT_CLOSE_DURATION}
                  iconButtonRow={<BasicDialogHeader onCancel={closeConfirmationModal} />}
                  shouldCloseOnEsc={false}
                >
                  <Box width="85ch">
                    <Stack space="xl">
                      <Box>
                        <SuitedCopySubheadline noMargin>Delete {fieldTitle}</SuitedCopySubheadline>
                      </Box>
                      <p>{deleteActionText}</p>
                      <Box>
                        <HStack justify="flex-end" space="sm">
                          <Box>
                            <SuitedButton purpose="default" onClick={closeConfirmationModal}>
                              Cancel
                            </SuitedButton>
                          </Box>
                          <Box>
                            <SuitedButton
                              purpose="primary"
                              data-analytics-id="eca-section-finish-before-timer"
                              disabled={isDeleting}
                              onClick={async () => {
                                await handleDelete({
                                  variables: {},
                                  refetchQueries: [
                                    {
                                      query: GET_USER_SETTINGS
                                    }
                                  ]
                                });
                                setIsConfirmationModalOpen(false);
                                handleFormStateUpdate(EditableFormState.MISSING);
                              }}
                            >
                              Delete
                            </SuitedButton>
                          </Box>
                        </HStack>
                      </Box>
                    </Stack>
                  </Box>
                </ResponsiveDialogModal>
              </>
            );
          case EditableFormState.MISSING:
            return (
              <AddButton onClick={() => handleFormStateUpdate(EditableFormState.EDITING)}>
                <FontAwesomeIcon icon={["far", "plus"]} size="lg" color="gray.500" />
                <span>Add {fieldTitle}</span>
              </AddButton>
            );
          case EditableFormState.EDITING:
            return renderEditComponent();

          case EditableFormState.PENDING:
            return (
              <p style={{ maxWidth: "100%", height: "35px" }}>
                <Skeleton height="100%" />
              </p>
            );
        }
      })()}
    </Stack>
  );
};
