import { QuestionSchema, Answers } from "./types";

export function mapQuestions(questions: QuestionSchema[]): QuestionSchema[] {
  let currentIndex = 0;

  // addProperties needs to run first so we add all the properties to the right question types.
  function addProperties(questions: QuestionSchema[]): QuestionSchema[] {
    return questions
      .map((question) => {
        if (question.type === "group") {
          return {
            ...question,
            fields: addProperties(question.fields as QuestionSchema[])
          };
        } else if (question.type === "multiple_choice" || question.type === "dropdown") {
          return {
            ...question,
            properties: {
              ...question.properties,
              choices: question.properties.choices.map((choice) => ({
                label: choice.label,
                value: choice.label
              }))
            }
          };
        } else if (question.type === "statement") {
          return question;
        }
        return question;
      })
      .reduce((questions: QuestionSchema[], question: QuestionSchema) => {
        if (question.type === "group") {
          return questions.concat(question.fields as QuestionSchema[]);
        } else {
          return questions.concat([question]);
        }
      }, []);
  }

  const mappedQuestions = addProperties(questions);
  // then we return all questions that aren't statements or groups with a questionIndex.
  return mappedQuestions.map((question) => {
    if (question.type !== "statement" && question.type !== "group") {
      currentIndex++;
      return { ...question, questionIndex: currentIndex };
    } else {
      return question;
    }
  });
}

export function mapAnswers(
  answers: [{ questionId: string; value: string; multiValue: string[] }]
): Answers {
  if (!answers) return {};
  return answers.reduce((answers, answer) => {
    if (answer.multiValue) {
      answers[answer.questionId] = answer.multiValue;
    } else {
      answers[answer.questionId] = answer.value;
    }
    return answers;
  }, {});
}

export function getPercentComplete(questions: QuestionSchema[], answers: Answers) {
  let numAnswers = 0;
  function getNumRequired(questions: QuestionSchema[]): number {
    return questions.reduce((num: number, question: QuestionSchema) => {
      if (question.type === "group") {
        return num + getNumRequired(question.fields as QuestionSchema[]);
      } else if (question.validations && question.validations.required) {
        if (answers[question.id]) {
          numAnswers = numAnswers + 1;
        }
        return num + 1;
      }
      return num;
    }, 0);
  }
  const numQuestions = getNumRequired(questions);
  return Math.min(100, (numAnswers / numQuestions) * 100);
}
