import styled from "styled-components";

import { sizes, typography } from "suited/constants/style-constants";

interface Props {
  /** display light text on dark backgrounds */
  overDark?: boolean;
  label: string;
  value: string | number;
  width?: string;
  className?: string;
}

const SuitedTileLabelValue = (props: Props) => {
  return (
    <WrapLabelValue width={props.width} className={props.className}>
      <SuitedTileLabel overDark={props.overDark}>{props.label}</SuitedTileLabel>
      <SuitedTileValue overDark={props.overDark}>{props.value}</SuitedTileValue>
    </WrapLabelValue>
  );
};

export default SuitedTileLabelValue;

interface IStyleProps {
  width?: string;
  overDark?: boolean;
}

export const WrapLabelValue = styled.div<IStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  padding-right: ${sizes.gridUnits.times2};
  width: ${(props) => (props.width ? props.width : "auto")};
`;

const SuitedTileLabel = styled.div<IStyleProps>`
  padding: ${sizes.gridUnits.divide4} 0;
  color: ${typography.color.dark.base};
  font-family: ${typography.family.body};
  font-size: 10px;
  font-weight: ${typography.weight.body.light};
  text-transform: uppercase;
  line-height: 1;
  ${(props) => (props.overDark ? `color: ${typography.color.light.base};` : "")}
`;

const SuitedTileValue = styled.div<IStyleProps>`
  color: ${typography.color.dark.base};
  font-family: ${typography.family.body};
  font-size: 14px;
  font-weight: ${typography.weight.body.regular};
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  ${(props) => (props.overDark ? `color: ${typography.color.light.base};` : "")}
`;
