// @ts-nocheck
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Alert, addAlert, removeAlert, setAlerts } from "./reducers";
import Alerts from "./Alerts";

interface AlertsContainerProps {
  messages: Alert[];
  addAlert: (alert: Alert, level?: string) => any;
  removeAlert: (alertId: string) => any;
  setAlerts: (alerts: Alert[]) => any;
}

class AlertsContainer extends React.Component<AlertsContainerProps> {
  handleDismissAll = () => {
    if (!this.props.messages) return;
    this.props.setAlerts([]);
  };

  handleDismiss = (alertId: string) => {
    if (!alertId) return;
    this.props.setAlerts(
      this.props.messages.filter((m) => {
        return m.alertId !== alertId;
      })
    );
  };

  render() {
    return (
      <Alerts
        messages={this.props.messages}
        handleDismissAll={this.handleDismissAll}
        handleDismiss={this.handleDismiss}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.messages
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addAlert, removeAlert, setAlerts }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer);
