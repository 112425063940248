import { TCandidateConfig, TIndustryContext } from "./CandidateConfig.types";

export const defaultCandidateId = "DEFAULT_CANDIDATE";

export const defaultIndustryContext: TIndustryContext = {
  id: "none",
  name: "No industry context set"
};

export const defaultConfig: TCandidateConfig = {
  candidateId: defaultCandidateId,
  industryContext: defaultIndustryContext,
};

export enum EIndustryIds {
  "LAW" = "law",
  "INVESTMENT_BANKING" = "finance_banking_investment",
  "NO_CONTEXT" = "none"
}
