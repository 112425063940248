import styled from "styled-components";
import { Box } from "@suited/components";
import { SuitedAltLink } from "suited/components/shared/buttons/SuitedAltLink";
import { SuitedListItemPrimaryValue } from "suited/components/shared/typography/SuitedListItemPrimaryValue";
import { SuitedCompactButton } from "suited/components/shared/buttons/SuitedCompactButton";
import { sizes, colors, typography } from "suited/constants/style-constants";

export const ListEl = styled.div`
  min-width: 400px;
`;

export const ListItemBox = styled(Box)`
  background-color: ${colors.background.light.primary};
  border: 1px solid ${colors.shades.times2};
`;

export const ListSecondaryEl = styled.div`
  min-width: 400px;
`;

export const ListContainer = styled.ul``;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledSuitedListItemPrimaryValue = styled(SuitedListItemPrimaryValue)``;

export const ListItemTitle = styled.div`
  color: ${typography.color.dark.base};
  padding-left: 0.5rem;
  max-width: 225px;
  @media only screen and (max-width: 550px) {
    ${StyledSuitedListItemPrimaryValue} {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  @media only screen and (min-width: 551px) {
    ${StyledSuitedListItemPrimaryValue} {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const ListItemDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 300px;
  @media only screen and (max-width: 550px) {
    justify-content: space-around;
    padding-bottom: ${sizes.gridUnits.times1};
  }
`;

export const StyledSuitedAltLink = styled(SuitedAltLink)`
  font-size: ${typography.sizes.copy.label};
`;

export const StyledSuitedCompactButton = styled(SuitedCompactButton)`
  height: ${sizes.gridUnits.times4};
  margin-right: ${sizes.gridUnits.times2};
`;
