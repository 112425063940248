import { Box, Stack } from "@suited/components";

export const InvestmentBankingInstructions = () => {
  const underlineStyles = { textDecoration: "underline" };
  const bulletedListStyles = { listStyleType: "disc", paddingLeft: "12.5px", marginLeft: 0 };
  return (
    <Box data-testid="instructions">
      <Stack>
        <p style={underlineStyles}>Enter Your Education Details Below</p>
        <p>
          Undergraduate Degree: This section is mandatory. Please include all undergraduate
          institutions attended, even if your primary focus is on graduate-level education.
        </p>
        <p>
          Add Additional Institutions: After filling in the required fields, you have the option to
          add more schools.
        </p>
        <p>
          School Not Listed?: If you cannot find your school, choose “Other - my school is not
          listed” and enter the name of your university.
        </p>
        <Stack>
          <div>
            <p style={underlineStyles}>For MBA Degrees:</p>
            <p>
              <ul style={bulletedListStyles}>
                <li>Select "Masters" in degree field.</li>
                <li>
                  Choose "Business Administration / Management" as the Primary Major/Focus of Study
                </li>
                <li>Note: We do not record MBA Concentrations or Specializations.</li>
              </ul>
            </p>
          </div>
          <div>
            <p style={underlineStyles}>For Law Degrees:</p>
            <p>
              <ul style={bulletedListStyles}>
                <li>Choose “JD” for the Degree type.</li>
                <li>Use the search function to find and select your university's name.</li>
              </ul>
            </p>
          </div>
        </Stack>
      </Stack>
    </Box>
  );
};
