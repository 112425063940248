import React from "react";
import classnames from "classnames";
import _ from "lodash";

interface AlertEntryProps {
  message: string;
  level?: string;
  alertId: string;
  onDismiss: (alertId: string) => void;
  dismissAfter?: number;
}

class AlertEntry extends React.Component<AlertEntryProps> {
  localHandleDismiss = () => {
    if (this.props.onDismiss) this.props.onDismiss(this.props.alertId);
  };

  render() {
    let dismissAfter;
    if (!this.props.dismissAfter) dismissAfter = 3000;
    if (
      this.props.onDismiss &&
      _.isFunction(this.props.onDismiss) &&
      _.isNumber(dismissAfter) &&
      dismissAfter !== 0
    ) {
      _.delay(this.props.onDismiss, dismissAfter, this.props.alertId);
    }

    let clazz = classnames(
      "alert",
      "alert-dismissible",
      `alert-${this.props.level ? this.props.level : "info"}`,
      "alert-shadow"
    );
    return (
      <div className={clazz} role="alert">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={this.localHandleDismiss}>
          <span aria-hidden="true">&times;</span>
        </button>
        {this.props.message}
      </div>
    );
  }
}

export default AlertEntry;
