import styled from "styled-components";

import {
  typography,
  colors,
  animationCurves,
  sizes,
  responsiveTargets
} from "../../../constants/style-constants";
import SuitedDetailLabel from "../../shared/typography/SuitedDetailLabel";
import { hexToRgba } from "../../../util/cssColorTansforms";

interface RatingContainerProps {
  disabled?: boolean;
}

export const RatingContainer = styled.div<RatingContainerProps>`
  pointer-events: ${ (props) => props.disabled ? "none" : null };
  font-family: ${typography.family.body};
`;

export const RatingList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 auto;
  @media ${responsiveTargets.phone} {
    width: calc(${sizes.containerWidths.nav} + ${sizes.gridUnits.times5});
    flex: 0 0 auto;
    margin: auto;
  }
`;

interface RatingOptionProps {
  checked: boolean;
}

// prettier-ignore
export const RatingOption = styled.li<RatingOptionProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  height: ${sizes.touch.minimum};
  width: ${sizes.touch.minimum};
  font-family: ${typography.family.monospace};
  font-weight: ${props =>
    props.checked ? typography.weight.body.bold : typography.weight.body.regular};
  font-size: ${typography.sizes.copy.value};
  line-height: 1;
  color: ${props => (props.checked ? typography.color.light.base : typography.color.dark.base)};
  background-color: ${props =>
    props.checked ? colors.accent1.base : hexToRgba(colors.color4.base, 0.08)};
  border: 1px solid transparent;
  box-shadow: inset 0px 0px 0px 2px transparent;
  border-radius: 50%;
  cursor: pointer;
  outline: 0;
  transform: ${props => (props.checked ? "scale(1.25)" : "scale(1)")};
  transition: transform 300ms ${animationCurves.easeOutQuart},
    background-color 300ms ${animationCurves.easeOutQuart},
    border-color 300ms ${animationCurves.easeOutQuart},
    box-shadow 300ms ${animationCurves.easeOutQuart};
  &:hover,
  &:focus {
    color: ${props =>
      props.checked ? typography.color.light.emphasis : typography.color.dark.base};
    background-color: ${props =>
      props.checked ? colors.accent1.base : colors.background.light.fourthOrder};
    transform: ${props => (props.checked ? "scale(1.25)" : "scale(1.125)")};
    border: 1px solid ${props => (props.checked ? colors.shades.times1 : colors.accent1.base)};
    box-shadow: inset 0px 0px 0px 2px ${props =>
      props.checked ? colors.shades.times1 : colors.accent1.base};
  }
  &:active,
  &:hover:active,
  &:focus:active {
    color: ${props =>
      props.checked ? typography.color.light.emphasis : typography.color.dark.base};
    background-color: ${props =>
      props.checked ? colors.accent1.shade1 : colors.background.light.fourthOrder};
    transform: scale(1);
    transition: transform 100ms ${animationCurves.easeOutQuart};
  }
`;

export const RatingOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 0 calc(${sizes.touch.minimum} + ${sizes.gridUnits.times1});
  height: calc(${sizes.touch.minimum} + ${sizes.gridUnits.times1});
`;

const RatingLabel = styled(SuitedDetailLabel)`
  margin: 0;
  font-style: italic;
  text-transform: lowercase;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${sizes.gridUnits.divide2};
  padding: 0 ${sizes.gridUnits.times1};
  @media ${responsiveTargets.phone} {
    width: calc(${sizes.containerWidths.nav} + ${sizes.gridUnits.times5});
    flex: 0 0 auto;
    margin: auto;
  }
`;

export const RatingLowLabel = styled(RatingLabel)`
  margin-right: auto;
`;

export const RatingHighLabel = styled(RatingLabel)`
  margin-left: auto;
`;
