import moment, { Moment } from "moment";
import omit from "lodash/omit";
import ISelectOption from "suited/components/shared/types/ISelectOption";
import {
  UNLISTED_SCHOOL_OPTION,
  JURIS_DOCTOR,
  NEW_SCHOOL_ID,
  INDUSTRY_CONTEXTS
} from "./education.constants";
import { SchoolStatus, SchoolEntry, SchoolFormProps } from "./education.types";
import { buildFormDropdownOptions } from "./SchoolForm/SchoolForm.utils";
import { MOMENT_INVALID_DATE_MESSAGE } from "suited/constants/validation.constants";
import { EIndustryIds } from "suited/components/CandidateConfig";

const normalizeDateField = (date: Moment | string) => {
  return date === MOMENT_INVALID_DATE_MESSAGE ? "" : date;
};

export const degreeIsUndergrad = (degree: string) => {
  return degree === "Associates" || degree === "Bachelors";
};

export const degreeIsGrad = (degree: string) => {
  return (
    degree === "Masters" ||
    degree === "Doctorate" ||
    degree === "Professional" ||
    degree === JURIS_DOCTOR
  );
};

export const degreeIsJurisDoctor = (degree: string) => {
  return degree === JURIS_DOCTOR;
};

const isAnyDegreeUndergrad = (degrees: string[]) =>
  degrees.some((degree) => degreeIsUndergrad(degree));

export const isFormValid = {
  [EIndustryIds.INVESTMENT_BANKING]: (degrees: string[]) => isAnyDegreeUndergrad(degrees),
  [EIndustryIds.LAW]: (degrees: string[]) =>
    isAnyDegreeUndergrad(degrees) && degrees.some((degree) => degreeIsJurisDoctor(degree)),
  // NO_CONTEXT is the legacy behavior during the feature transition
  [EIndustryIds.NO_CONTEXT]: (degrees: string[]) => isAnyDegreeUndergrad(degrees)
};

export const canSchoolBeDeleted = ({ id, previouslySavedSchools, industryContextId }) => {
  const currentFormEditing = previouslySavedSchools.find((school: SchoolEntry) => school.id === id);
  const degree = currentFormEditing?.degree;

  if (industryContextId === INDUSTRY_CONTEXTS.LAW) {
    if (degreeIsJurisDoctor(degree)) {
      const jurisDoctorDegreeCount = previouslySavedSchools.reduce(
        (acc: number, school: SchoolEntry) => (degreeIsJurisDoctor(school.degree) ? acc + 1 : acc),
        0
      );

      if (jurisDoctorDegreeCount > 1) {
        return true;
      }

      if (jurisDoctorDegreeCount === 1) {
        return !degreeIsJurisDoctor(degree);
      }
    }
  }

  const undergradDegreeCount = previouslySavedSchools.reduce(
    (acc: number, school: SchoolEntry) => (degreeIsUndergrad(school.degree) ? acc + 1 : acc),
    0
  );

  if (undergradDegreeCount > 1) {
    return true;
  }

  if (undergradDegreeCount === 1) {
    return !degreeIsUndergrad(degree);
  }

  return false;
};

export const doesSchoolDataHaveUndergradDegree = (schoolData: SchoolEntry[]) => {
  return schoolData.some((school) => {
    return degreeIsUndergrad(school.degree);
  });
};

export const doesSchoolDataHaveJurisDoctorDegree = (schoolData: SchoolEntry[]) => {
  return schoolData.some((school) => degreeIsJurisDoctor(school.degree));
};

const sanitizeGPAIn = (value: string | null) => {
  // we store the "N/A" option as " " for the sake of comparing `highestGPASchool` naturally (" " < "1.0")
  if (value === " ") return "N/A";
  // we return null so an empty select shows placeholder text
  if (value === "") return null;

  return value;
};

const getSchoolOption = (
  name: string,
  status: SchoolStatus | string
): ISelectOption | string | null => {
  if (status !== "APPROVED") return UNLISTED_SCHOOL_OPTION.value;
  if (name === "") return null;
  return name;
};

const sanitizeGPAOut = (value: string): string => {
  // while the GUI displays "N/A", we persist " " to the database
  // " ", represents a set-non-value as opposed to
  // "", representing a value-was-not-set
  return value !== "N/A" ? value : " ";
};

export const getDefaultSchoolFormFields = () => {
  return {
    id: `${NEW_SCHOOL_ID}-${new Date().getTime()}`,
    degree: "",
    gpa: "",
    graduationDate: "",
    major: "",
    school: "",
    schoolObj: {
      name: "",
      status: "APPROVED"
    }
  };
};

export const regexCheckNewSchoolID = new RegExp(`^${NEW_SCHOOL_ID}`);

export const convertSchoolAPIDataToFormData = (schoolData: SchoolEntry) => {
  const { id, degree, school, gpa, major, graduationDate, schoolObj } = schoolData;
  const unlistedSchool =
    schoolObj.status === "PENDING" || schoolObj.status === "REJECTED" ? schoolObj.name : "";

  const graduationMonth = moment(graduationDate).format("MMMM");
  const graduationYear = moment(graduationDate).format("YYYY");

  const formFields = buildFormDropdownOptions({
    degree,
    major,
    school: getSchoolOption(school, schoolObj.status),
    gpa: sanitizeGPAIn(gpa),
    graduationMonth: normalizeDateField(graduationMonth),
    graduationYear: normalizeDateField(graduationYear)
  });

  return { ...formFields, id, unlistedSchool, schoolObj };
};

export const createUpdateSchoolsPayloadEntry = (values: SchoolFormProps) => {
  const graduationMonth = values.graduationMonth.value;
  const month = graduationMonth ? moment().month(values.graduationMonth.value).format("M") : "";
  const year = values.graduationYear.value;

  // We want to offset by a week to guarantee that no matter
  // what time zone we are in, that the correct day/month
  // is displayed.
  const ONE_WEEK_IN_DAYS = 7;
  // Date constructor takes a month index, starting at 0,
  // so we'll need to offset
  const MONTH_OFFSET = 1;

  const date = new Date(Number(year), Number(month) - MONTH_OFFSET, ONE_WEEK_IN_DAYS);

  const formattedGraduationDate = month && year ? date?.toISOString() : "";

  return {
    id: values.id,
    degree: values.degree.value,
    gpa: sanitizeGPAOut(values.gpa.value),
    graduationDate: formattedGraduationDate,
    major: values?.major?.value || null,
    school: values.unlistedSchool ? values.unlistedSchool : values.school.value,
    unlistedSchool: values.unlistedSchool,
    schoolObj: {
      name: values?.schoolObj?.name,
      status: values?.schoolObj?.status
    }
  };
};

export const cleanSchoolProps = (school: SchoolEntry) => {
  return omit(school, ["__typename", "schoolObj", "unlistedSchool"]);
};
