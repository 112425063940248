import styled from "styled-components";

import { sizes, colors } from "suited/constants/style-constants";
import { SuitedAltCopy } from "suited/components/shared/typography/SuitedAltCopy";

import SuitedContentArea from "suited/components/SuitedContentArea";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  max-width: 560px;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  padding-bottom: ${sizes.gridUnits.times4};
`;

export const TabContainer = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: ${sizes.gridUnits.times4};
  background-color: ${(props) =>
    props.active ? colors.background.light.primary : colors.background.light.backdrop};
  border: 1px solid ${colors.shades.times2};
  &:hover {
    background-color: ${colors.background.light.secondary};
  }
  &:not(:first-of-type) {
    margin-left: ${sizes.gridUnits.divide4};
  }
  &:focus {
    outline: 1px solid ${colors.accent1.base};
  }
`;

export const StyledSuitedContentArea = styled(SuitedContentArea)`
  flex: 1 0 auto;
`;

export const StyledSuitedCopy = styled(SuitedAltCopy)`
  padding: ${sizes.gridUnits.times1};
`;
