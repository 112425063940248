import styled from "styled-components";
import { SuitedAltCopy } from "suited/components/shared/typography/SuitedAltCopy";
import { sizes, colors } from "suited/constants/style-constants";

export const AccordionEl = styled.div<{ minWidth: number }>`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : "auto")};
`;

export const AccordionToggleButton = styled.button<{ active: boolean }>`
  background-color: ${(props) =>
    props.active ? colors.background.light.primary : colors.background.light.backdrop};
  border: 0;
  margin: ${sizes.gridUnits.times1} 0;
  padding: ${sizes.gridUnits.times2};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${colors.shades.times2};
  &:focus {
    outline: ${colors.accent1.base} auto 1px;
  }
  &:hover {
    background-color: ${colors.background.light.fifthOrder};
  }
`;

const AccordionContentExpanded = `
  height: auto;
  opacity: 1;
  padding: ${sizes.gridUnits.times2};
  `;

export const StyledSuitedCopy = styled(SuitedAltCopy)`
  padding-left: ${sizes.gridUnits.times1};
`;

export const AccordionContent = styled.div<{ collapsed: boolean }>`
  padding: 0 ${sizes.gridUnits.times2};
  opacity: 0;
  transition: all 0.5s;
  height: 0;
  margin: 0;
  margin-top: -${sizes.gridUnits.times1};
  border: 1px solid ${colors.shades.times2};
  border-top: 0;
  ${(props) => (props.collapsed ? null : AccordionContentExpanded)};
`;
