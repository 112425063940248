import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import URI from "urijs";
import slugify from "slugify";

import { getAuthToken, deleteStoredCredentials } from "./util/authUtil";

//
// Set up Apollo http link, middleware, and final composed link
//
//
// middleware/afterware - primary user
//
const userHttpLink = createHttpLink({
  uri: "/api/graphql"
});

export const createInMemoryCache = () => {
  return new InMemoryCache({
    dataIdFromObject: (object: any) => {
      // @WARNING ⚠️ - if you invoke an `object.{property}` that is not part of the bootstrap query, app authentication will stop working, with no explanatory error. -EH
      switch (object.__typename) {
        case "UserAnswer":
          return `suited-assessment-answer-${object.questionId}`;
        case "Question":
          return `suited-assessment-question-${object.id}`;
        case "UserSurveyAnswers":
          return "USER_SURVEY_ANSWERS";
        case "AssessmentOption":
          return `assessment-${slugify(object.name, {
            replacement: "_",
            lower: true
          })}`;
        case "Resume":
          return `resume-${object.id}`;
        case "User":
          return `candidate-${object.id}`;
        default:
          return object.id;
      }
    }
  });
};

const cache = createInMemoryCache();

// middleware/afterware - primary user
//
const headerAuthMiddleware = setContext(() => {
  const token = getAuthToken();
  return {
    headers: { Authorization: `Bearer ${token}` }
  };
});
const networkErrorAfterware = onError(({ networkError }) => {
  // Apollo types networkError as an Error, which doesn't have the statusCode
  // property, so this is just a quick fix to allow us to access it.
  if (networkError && (networkError as any).statusCode === 401) {
    // delete all credentials just to be safe
    deleteStoredCredentials();
    const uri = new URI(window.location.href);
    const originalUrl = `${uri.path()}/${uri.search()}${uri.hash()}`;
    window.location.href = `/login?redirect=${originalUrl}`; // TODO: externalize
  } else if (networkError) {
    console.error(`Error communicating with server: ${networkError}`);
  }
});
export const apolloLink = ApolloLink.from([
  headerAuthMiddleware,
  networkErrorAfterware,
  userHttpLink
]);

export const apolloClient = new ApolloClient({
  link: apolloLink,
  cache
});
