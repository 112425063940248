import moment from "moment";

import ISelectOption from "suited/components/shared/types/ISelectOption";

let years = [] as ISelectOption[];
const currentYear = moment().year();
for (let i = currentYear - 80; i <= currentYear; i++) {
  years.push({
    label: i.toString(),
    value: i.toString()
  });
}
export const OPTIONS_YEARS = years.reverse();