import React from 'react';
import clsx from 'clsx';

import { omit } from '@suited/utils';
import { BaseComponentProps, SpacingOptions } from '../../types';
import { getContentAreaProps } from '../../utils';
import { CONTENT_AREA_PROPERTIES } from '../../constants';
import './Stack.css';

interface Props extends BaseComponentProps {
  space?: SpacingOptions;
  recursive?: boolean;
  direction?: React.CSSProperties['flexDirection'];
}

export const Stack = ({
  children,
  className,
  style,
  recursive,
  space = 'md',
  direction = 'column',
  ...props
}: Props) => {
  const classNames = clsx(
    'ws-l-stack',
    {
      'ws-l-stack-recursive': recursive,
    },
    className
  );
  const rest = omit(props, CONTENT_AREA_PROPERTIES);
  const styles = {
    ...style,
    ...getContentAreaProps(props),
    '--stack-space': `var(--perfect-fifth-${space})`,
    '--stack-direction': direction,
  };

  return (
    <div className={classNames} style={styles} {...rest}>
      {children}
    </div>
  );
};
