export const instructions = [
  {
    headline: "DURATION",
    copy: "It takes about 20 minutes to complete the questions, designed to reveal aspects of your personality.",
    icon: ['fal', 'clock']
  },
  {
    headline: "SINGLE ATTEMPT",
    copy: "Once you submit your answers, you cannot retake the assessment. There is no time limit for each section, allowing you to take breaks as needed.",
    icon: ['fal', 'redo']
  },
  {
    headline: "BE YOURSELF",
    copy: `Respond based on your genuine behavior rather than how you think you should act. There are no correct or incorrect responses.`,
    icon: ['fal', 'user']
  },
  {
    headline: "GO WITH YOUR GUT",
    copy: "Avoid overthinking. For the most accurate results, go with your initial reaction to each statement.",
    icon: ['fal', 'clipboard-check']
  },
];
