import { gql } from "@apollo/client";

// location won't have id since we're not storing these in db
export const GET_USER_LOCATION_PREFERENCES = gql`
  query GetUserLocationPreferences {
    GetUser {
      userProfile {
        workPreferences {
          locations {
            lat
            lng
            label
          }
          workStyle {
            preferredStyle
          }
        }
      }
      userProfileCompletion {
        completedWorkPreferences
      }
    }
  }
`;

export const UPDATE_USER_LOCATION_PREFERENCES = gql`
  mutation UpdateUserLocationPreferences($locations: [Location!], $preferredStyle: String!) {
    UpdateUserLocationPreferences(locations: $locations, preferredStyle: $preferredStyle) {
      id
    }
  }
`;

// Address, partial address, and lat/lng pair
// string input called address
export const GET_GEOGRAPHIC_LOCATION_DATA = gql`
  query GetGeoData($address: String!) {
    GetGeoData(address: $address) {
      label
      lat
      lng
    }
  }
`;
