import { factory, manyOf, primaryKey, nullable } from "@mswjs/data";
import { faker } from "@faker-js/faker";

import { assessmentItemTypes } from "./eca.constants";

export const db = factory({
  section: {
    __typename: () => "SectionSummary",
    id: primaryKey(faker.datatype.uuid),
    status: String
  },
  multiSelectOption: {
    __typename: () => "MultiSelectItemOption",
    id: primaryKey(faker.datatype.uuid),
    label: String,
    isExclusive: Boolean,
    isSelected: Boolean
  },
  multiSelectItem: {
    __typename: () => "MultiSelectItem",
    id: primaryKey(faker.datatype.uuid),
    type: () => assessmentItemTypes.MULTISELECT,
    label: String,
    options: manyOf("multiSelectOption")
  },
  multiSelectItemGroup: {
    __typename: () => "StandardItemGroup",
    id: primaryKey(faker.datatype.uuid),
    itemType: () => assessmentItemTypes.MULTISELECT,
    leadingText: String,
    items: manyOf("multiSelectItem")
  },
  matrixTableItemGroupHeader: {
    __typename: () => "MatrixTableItemHeader",
    label: primaryKey(String),
    value: String
  },
  matrixTableItem: {
    __typename: () => "MatrixTableItem",
    id: primaryKey(faker.datatype.uuid),
    type: () => assessmentItemTypes.MATRIXTABLE,
    label: String,
    value: String
  },
  matrixTableItemGroup: {
    __typename: () => "MatrixTableItemGroup",
    id: primaryKey(faker.datatype.uuid),
    itemType: () => assessmentItemTypes.MATRIXTABLE,
    leadingText: String,
    headers: manyOf("matrixTableItemGroupHeader"),
    items: manyOf("matrixTableItem")
  },
  textInputItem: {
    __typename: () => "TextInputItem",
    id: primaryKey(faker.datatype.uuid),
    type: () => assessmentItemTypes.TEXTINPUT,
    label: String,
    value: String
  },
  textInputItemGroup: {
    __typename: () => "StandardItemGroup",
    id: primaryKey(faker.datatype.uuid),
    itemType: () => assessmentItemTypes.TEXTINPUT,
    leadingText: String,
    items: manyOf("textInputItem")
  },
  multipleChoiceOption: {
    __typename: () => "MultipleChoiceItem",
    id: primaryKey(faker.datatype.uuid),
    label: String,
    isExclusive: Boolean,
    isSelected: Boolean
  },
  multipleChoiceItem: {
    __typename: () => "MultipleChoiceItem",
    id: primaryKey(faker.datatype.uuid),
    type: () => assessmentItemTypes.MULTIPLE_CHOICE,
    label: String,
    options: manyOf("multipleChoiceOption")
  },
  multipleChoiceItemGroup: {
    __typename: () => "MultipleChoiceItemGroup",
    id: primaryKey(faker.datatype.uuid),
    itemType: () => "MULTIPLE_CHOICE",
    leadingText: String,
    items: manyOf("multipleChoiceItem")
  },
  GetAssessment: {
    __typename: () => "AssessmentData",
    id: primaryKey(faker.datatype.uuid),
    title: () => "Essential Competencies Assessment",
    status: String,
    sections: manyOf("section")
  },
  GetNextAssessmentSectionInformation: {
    __typename: () => "SectionInformation",
    id: primaryKey(faker.datatype.uuid),
    status: String,
    completedSections: () => 0,
    totalSections: () => 4,
    timerDuration: nullable(() => 300),
    timerRemaining: nullable(() => 300),
    instructions: () =>
      Array.from({ length: 3 }).map(() => `Lorem ipsum ${faker.lorem.paragraph()}`),
    sampleItemGroups: Array,
    isInformational: Boolean
  },
  GetNextAssessmentSectionQuestions: {
    __typename: () => "SectionQuestions",
    id: primaryKey(faker.datatype.uuid),
    status: String,
    completedSections: () => 0,
    totalSections: () => 4,
    timeRemaining: nullable(() => 300),
    itemGroups: Array
  },
  GetCandidateAssessment: {
    id: primaryKey(faker.datatype.uuid),
    status: String,
    sections: manyOf("section"),
    title: String,
    __typename: () => "AssessmentData"
  },
  GetCandidateAssessments: {
    id: primaryKey(faker.datatype.uuid),
    GetCandidateAssessments: manyOf("GetCandidateAssessment")
  },

  // Used for testing purposes ONLY. We need to keep track
  // of completed sections for "closeAssessmentSection" unit
  // tests.
  completedSections: {
    id: primaryKey(faker.datatype.uuid),
    sectionId: String,
    isCompleted: Boolean
  },
  // Used for testing purposes ONLY. We need to keep track
  // of how many times we call SectionInfo to keep track off
  // 'refetch' query
  sectionInfoCalls: {
    id: primaryKey(faker.datatype.uuid),
    calls: () => 0
  }
});
