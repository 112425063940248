import { IconPrefix } from "@fortawesome/fontawesome-common-types";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { typography } from "suited/constants/style-constants";

/** IconWeight type has the three options that we can use to achieve a different style of icon */
export type IconWeight = "solid" | "regular" | "light";

/** Options for the sizing of an icon */
export type IconSize =
  | "xs"
  | "sm"
  | "lg"
  | "2x"
  | "3x"
  | "4x"
  | "5x"
  | "6x"
  | "7x"
  | "8x"
  | "9x"
  | "10x";

const weightPrefixMap: { [key: string]: IconPrefix } = {
  solid: "fas",
  regular: "far",
  light: "fal"
};

interface IIconProps {
  /** display light text on dark backgrounds */
  overDark?: boolean;
  /** className incase this is needed for styling */
  className?: string;
  /** id for utility if needed */
  id?: string;
  /** accepts MOST icon names from font awesome library: https://fontawesome.com/ */
  name: IconName;
  /** onClick handler if this icon will be used to fire an event */
  onClick?: any;
  /** size of the icon */
  size?: IconSize;
  /** spin in a smooth fashion: https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons */
  spin?: boolean;
  /** rotate with eight steps - looks like a fast flip book: https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons */
  pulse?: boolean;
  /** style that can be passed in to override icon built in css properties */
  style?: any;
  /** standard html title property */
  title?: string;
  /** weight changes the style of an icon */
  weight?: IconWeight;
  /** fixed width for icons that have varying width size: https://fontawesome.com/how-to-use/on-the-web/styling/fixed-width-icons */
  fixedWidth?: boolean;
}

/**
 * ## What
 * `<SuitedIcon>` is a wrapper for Font Awesome Pro icons.
 *
 * ## Where
 * It should be used in the majority of interface iconography use cases. Mostly its use is constrained to inside `<SuitedIconButton>`, which should be relied upon for all clickable icon use cases.
 * For non-clickable icon use cases (such as badges), the sizing and color should be clearly different from `<SuitedIconButton>`.
 *
 * ## Not
 * It should never be used directly for clickable icon use cases. That's what `<SuitedIconButton>` is for.
 *
 * ## Example
 * In Recruiter App, we use this component for non-clickable badges on Candidates List list items.
 * Most other examples will be in the SuitedIconButton format.
 */
export const SuitedAltIcon = ({
  className,
  name,
  onClick,
  pulse,
  size,
  spin,
  style,
  title,
  weight,
  fixedWidth,
  overDark,
  ...passthroughProps
}: IIconProps) => {
  className = className ? `${className} ` : "";
  const prefix = weight ? weightPrefixMap[weight] : weightPrefixMap.solid;
  const color = overDark ? typography.color.light.base : "";
  return (
    <FontAwesomeIcon
      size={size}
      icon={[prefix, name]}
      spin={spin}
      pulse={pulse}
      fixedWidth={fixedWidth}
      color={color}
      className={className}
      onClick={onClick}
      style={style}
      title={title}
      aria-hidden="true"
      data-testid="suited-icon-component"
      {...passthroughProps}
    />
  );
};
