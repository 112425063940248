// @ts-nocheck
import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Select, { SingleValue } from "react-select";

import { Box, Flex, Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import SuitedRadioButton from "suited/components/shared/buttons/SuitedRadioButton";
import SuitedRadioGroup from "suited/components/shared/buttons/SuitedRadioGroup";
import {
  LabelContainer,
  StyledReactSelect
} from "suited/components/shared/inputs/select/common.style";
import { SuitedAltInputLabel } from "suited/components/shared/typography/SuitedAltInputLabel";

import { timeouts } from "../../../constants/interaction-constants";
import { UPDATE_USER_SETTINGS_VISIBILITY } from "./GraphQL/mutations";
import { GET_USER_SETTINGS } from "./GraphQL/queries";
import { USER_PROFILE_VISIBILITY_OPTIONS } from "./UserProfileVisibilityForm.constants";
import { StyledSuitedCopySubheadline } from "./UserSettingsView.style";

export const UserProfileVisibilityForm = () => {
  const { data } = useQuery(GET_USER_SETTINGS);
  const visibility = data?.GetUser?.settings?.visibility;

  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: { isDirty }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      visibility
    }
  });

  const selectedVisibility = watch("visibility");

  const [updateUserProfileVisibility] = useMutation(UPDATE_USER_SETTINGS_VISIBILITY);

  useEffect(() => {
    reset({ visibility });
  }, [reset, visibility]);

  return (
    <form
      onSubmit={handleSubmit((values) => {
        updateUserProfileVisibility({
          variables: { visibility: values.visibility },
          refetchQueries: [{ query: GET_USER_SETTINGS }]
        });
      })}
    >
      <Stack data-test="component-user-profile-visibility-form">
        <StyledSuitedCopySubheadline noMargin>Privacy Settings</StyledSuitedCopySubheadline>
        <Box>
          <Stack stpace="xxs">
            <LabelContainer>
              <SuitedAltInputLabel
                id="visibility"
                htmlFor="visibility"
                data-testid="suited-single-select-label"
              >
                Share my Suited profile and assessment results:
              </SuitedAltInputLabel>
            </LabelContainer>
            <SuitedRadioButton
              name="visibility"
              value="public"
              checked={selectedVisibility === "public"}
              register={register}
              aria-label="All partner firms so I can be seen for any opportunity"
              testId="public-radio-button"
            >
              To all partner firms so I can be seen for any opportunity
            </SuitedRadioButton>
            <SuitedRadioButton
              name="visibility"
              value="invite_only"
              checked={selectedVisibility === "invite_only"}
              register={register}
              aria-label="Only firms that I'm connected with"
              testId="private-radio-button"
            >
              Only to the firms that I'm connected with
            </SuitedRadioButton>
          </Stack>
        </Box>
        <Flex justify="flex-end">
          <Box>
            <SuitedButton
              alignRight
              formButton
              type="submit"
              purpose="primary"
              delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
              data-test="submit-button"
              disabled={!isDirty}
            >
              Save
            </SuitedButton>
          </Box>
        </Flex>
      </Stack>
    </form>
  );
};
