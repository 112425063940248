import styled, { css } from "styled-components";

import { transitions } from "suited/constants/style-transitions";

const TransitionFade = styled.div<{ timeout: number }>`
  ${({ timeout }) => {
    return css`
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
      /* position relative and z-index help prevent some stacking context problems */
      position: relative;
      z-index: 1;
      ${transitions.crossFade(timeout)}
    `;
  }}
`;

export const AnimationA = styled(TransitionFade)``;
export const AnimationB = styled(TransitionFade)``;
