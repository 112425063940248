import styled from "styled-components";

export const QuestionRowSkeleton = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 2fr;
`;

export const QuestionCopy = styled.p`
  font-size: 18px;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;
