import { useMutation } from "@apollo/client";
import owasp from "owasp-password-strength-test";
import { useForm } from "react-hook-form";

import { Box, Flex, Stack } from "@suited/components";
import { isEmptyObject } from "@suited/utils";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { PasswordInput } from "suited/components/shared/inputs/PasswordInput/PasswordInput";
import { SuitedAltInputLabel } from "suited/components/shared/typography/SuitedAltInputLabel";
import { DEFAULT_REQUIRED_ERROR_MESSAGE } from "suited/constants/validation.constants";

import { timeouts } from "../../../constants/interaction-constants";
import { UPDATE_USER_PASSWORD } from "./GraphQL/mutations";
import { StyledSuitedCopySubheadline } from "./UserSettingsView.style";

// NOTE: Need to do this check for jest testing to work
if (owasp && typeof owasp.config === "function") {
  owasp.config({ allowPassphrases: false });
}

export const UserPasswordChangeForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isDirty }
  } = useForm({
    // Ensure that validation runs on blur events
    // in addition to "on change" events
    mode: "all",
    defaultValues: {
      password: "",
      confirmPassword: ""
    }
  });

  const [updatePassword] = useMutation(UPDATE_USER_PASSWORD, { onCompleted: () => reset() });

  const password = getValues("password");
  const confirmPassword = getValues("confirmPassword");
  const formHasErrors = !isEmptyObject(errors);
  const isDisabled = !isDirty || formHasErrors || !password || !confirmPassword;

  return (
    <form
      data-testid="password-change-form"
      onSubmit={handleSubmit((values) => {
        updatePassword({
          variables: {
            password: values.password
          }
        });
      })}
    >
      <Stack>
        <StyledSuitedCopySubheadline noMargin>Change Password</StyledSuitedCopySubheadline>
        <Box>
          <Stack space="none">
            <SuitedAltInputLabel htmlFor="user-settings-new-password">
              New Password*
            </SuitedAltInputLabel>
            <PasswordInput
              id="user-settings-new-password"
              data-testid="user-settings-new-password"
              name="password"
              register={register}
              opts={{
                validate: (value: string) => {
                  if (!value) {
                    return DEFAULT_REQUIRED_ERROR_MESSAGE;
                  }

                  return owasp.test(value).errors.find(Boolean);
                }
              }}
              error={errors?.password?.message}
            />
          </Stack>
        </Box>
        <Box>
          <Stack space="none">
            <SuitedAltInputLabel htmlFor="user-settings-confirm-password">
              Confirm Password*
            </SuitedAltInputLabel>
            <PasswordInput
              id="user-settings-confirm-password"
              data-testid="user-settings-confirm-password"
              name="confirmPassword"
              register={register}
              disabled={!password}
              opts={{
                validate: (confirmPassword: string) => {
                  if (!confirmPassword) {
                    return DEFAULT_REQUIRED_ERROR_MESSAGE;
                  }

                  if (confirmPassword !== password) {
                    return "Passwords must match.";
                  }
                }
              }}
              error={errors?.confirmPassword?.message}
            />
          </Stack>
        </Box>
        <Flex justify="flex-end">
          <Box>
            <SuitedButton
              alignRight
              formButton
              type="submit"
              purpose="primary"
              disabled={isDisabled}
              delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
              data-testid="submit-button"
            >
              Save
            </SuitedButton>
          </Box>
        </Flex>
      </Stack>
    </form>
  );
};
