import styled from "styled-components";

import { responsiveTargets, typography } from "suited/constants/style-constants";

export const StyledContainer = styled.div`
  @media ${responsiveTargets.phone} {
    min-height: calc(
      ${typography.margins.copy.phone} + ${typography.leading.responsive.copy.phone}
    );
  }
  @media ${responsiveTargets.tablet} {
    min-height: calc(
      ${typography.margins.copy.tablet} + ${typography.leading.responsive.copy.tablet} - 2px
    );
  }
  @media ${responsiveTargets.laptop} {
    min-height: calc(
      ${typography.margins.copy.laptop} + ${typography.leading.responsive.copy.laptop} - 2px
    );
  }
  @media ${responsiveTargets.desktop} {
    min-height: calc(
      ${typography.margins.copy.desktop} + ${typography.leading.responsive.copy.desktop} + 18px
    );
  }
  @media ${responsiveTargets.jumbo} {
    min-height: calc(
      ${typography.margins.copy.jumbo} + ${typography.leading.responsive.copy.jumbo} + 22px
    );
  }
`;
