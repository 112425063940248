import { useState, useEffect } from "react";
import moment from "moment";

import { Box, Stack, HStack } from "@suited/components";
import { UnstyledButton } from "suited/components/shared/buttons/UnstyledButton";
import { MultipleChoiceOption, SubmitItemVariables } from "../eca.types";
import {
  MultipleChoiceQuestion,
  MultipleChoiceLetter,
  MultipleChoiceQuestionText
} from "./MultipleChoiceItem.style";
import { MultiSelectLeadingText } from "../MultiSelectItem/MultiSelect.style";
import { charAtIndex, getMultipleChoiceDefaultValues } from "./MultipleChoiceItem.utils";

type Props = {
  itemID: string;
  label: string;
  options: MultipleChoiceOption[];
  onSubmit: (data: SubmitItemVariables) => void;
};

export const MultipleChoiceItem = ({ itemID, label, options = [], onSubmit }: Props) => {
  const [selectedId, setSelectedId] = useState("");
  useEffect(() => {
    const defaultValues = getMultipleChoiceDefaultValues(options);
    const selectedIdFromAPI = Object.keys(defaultValues).find((value) => !!defaultValues[value]);

    if (selectedIdFromAPI) {
      setSelectedId(selectedIdFromAPI);
    }
  }, [options]);
  return (
    <Box>
      <Stack space="xl">
        <MultiSelectLeadingText>{label}</MultiSelectLeadingText>
        <Box>
          <Stack>
            {options.map((option, index) => {
              const isSelected = selectedId === option.id;
              return (
                <UnstyledButton
                  key={option.id}
                  onClick={() => {
                    const timestamp = moment().toISOString();
                    setSelectedId(option.id);
                    onSubmit({ id: itemID, values: [{ id: option.id }], timestamp });
                  }}
                >
                  <MultipleChoiceQuestion $isSelected={isSelected}>
                    <HStack space="xl">
                      <MultipleChoiceLetter>
                        <MultipleChoiceQuestionText>
                          {charAtIndex(index)}
                        </MultipleChoiceQuestionText>
                      </MultipleChoiceLetter>
                      <MultipleChoiceQuestionText>{option.label}</MultipleChoiceQuestionText>
                    </HStack>
                  </MultipleChoiceQuestion>
                </UnstyledButton>
              );
            })}
          </Stack>{" "}
        </Box>
      </Stack>
    </Box>
  );
};
