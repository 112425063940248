import { Box, Center, HStack, Stack } from "@suited/components";

import { StyledFontAwesomeIcon } from "suited/components/shared/icons/StyledFontAwesomeIcon";
import { StyledBackgroundImg } from "suited/components/shared/img/StyledBackgroundImg";
import { ASSESSMENT_COMPLETE_ALT_TEXT } from "suited/components/shared/img/constants";
import SuitedCallout from "suited/components/shared/typography/SuitedCallout";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";

export const AssessmentCompletedPage = () => {
  return (
    <Box overflow="auto">
      <Stack>
        <StyledBackgroundImg role="img" aria-label={ASSESSMENT_COMPLETE_ALT_TEXT} />
        <Center>
          <Box padding="xl" maxWidth="100ch">
            <Stack space="xl">
              <SuitedCopyHeadline noMargin>Cognitive Skills Assessment</SuitedCopyHeadline>
              <SuitedCopySubheadline noMargin>
                You've completed the assessment.
              </SuitedCopySubheadline>
              <HStack align="flex-start">
                <StyledFontAwesomeIcon size="3x" icon={["fal", "clipboard-check"]} />
                <Stack space="xl">
                  <SuitedCallout noMargin>
                    Thank you for completing the Cognitive Skills Assessment, your responses have
                    been recorded and the results will be made available to recruiters soon! In the
                    meantime, make sure your profile is complete by clicking on the button below
                    then completing any remaining pages in the sidebar.
                  </SuitedCallout>
                </Stack>
              </HStack>
            </Stack>
          </Box>
        </Center>
      </Stack>
    </Box>
  );
};
