import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { HStack, Stack } from "@suited/components";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";

const Completed = () => {
  return (
    <Stack space="xl" width="85ch">
      <SuitedCopyHeadline noMargin>Well done.</SuitedCopyHeadline>
      <p>
        Thank you for completing the Suited Behavioral Assessment. The results will provide firms with
        the opportunity to learn more about you.
      </p>
      <p>Don’t forget to complete all of the other sections of your profile, too.</p>
      <HStack space="xs">
        <FontAwesomeIcon size="lg" icon={["far", "info-circle"]} />
        <p>Only once all the checks are marked can you start being recruited.</p>
      </HStack>
    </Stack>
  );
};

export default Completed;
