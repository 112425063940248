interface PortalConfig {
  DEFAULT_PORTAL_MENU_PLACEMENT: "auto" | "bottom" | "top" | undefined;
  DEFAULT_PORTAL_MENU_POSITION: "absolute" | "fixed" | undefined;
  DEFAULT_PORTAL_MENU_TARGET: HTMLElement;
}

export const portalConfig: PortalConfig = {
  DEFAULT_PORTAL_MENU_PLACEMENT: "bottom",
  DEFAULT_PORTAL_MENU_POSITION: "fixed",
  DEFAULT_PORTAL_MENU_TARGET: document.body
};
