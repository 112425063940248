import styled from "styled-components";
import { Box, Center } from "@suited/components";

export const PersonalDataContent = styled(Center)`
  margin-top: 2.5rem;
  max-width: 85ch;
`;

export const ConsentOptionCopy = styled(Box)`
  padding-left: 2.5rem;
`;
