import axios from "axios";
import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setAuthToken } from "suited/components/Login";
import { storeUserId } from "suited/util/authUtil";

class EmailConfirmedContainer extends React.Component<any, any> {
  state = {
    showTrackingPixels: true
  };

  async componentDidMount() {
    const invitationCode = queryString.parse(this.props.location.search).code;
    const email = queryString.parse(this.props.location.search).email;
    const id = queryString.parse(this.props.location.search).id;

    const res = await axios.request({
      method: "POST",
      url: `auth/confirm-email-auth`,
      data: {
        email: decodeURIComponent(email as string),
        confirmationId: id
      }
    });
    if (res.status === 200) {
      const { userId, token } = res.data;
      // store the userId in cookie for initializing ReactGA on app reload
      storeUserId(userId);
      // authenticate
      this.props.setAuthToken(token);
    }
    // we use a timeout of 0 to allow GA to send this pageview before the redirect
    setTimeout(() => {
      if (invitationCode) {
        this.props.history.push(`/staticinvitation?code=${invitationCode}`);
      } else {
        this.props.history.push("/");
      }
    }, 0);
  }

  render() {
    return <React.Fragment />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setAuthToken: (token: string) => {
    dispatch(setAuthToken(token));
  }
});

// @ts-ignore
export default withRouter(connect(null, mapDispatchToProps)(EmailConfirmedContainer));
