import { useReducer } from "react";
import axios from "axios";
import { useQuery, useMutation } from "@apollo/client";

import ResumeView from "./ResumeView";
import { GET_RESUME, GET_USER_RESUME_COMPLETION } from "./GraphQL/queries";
import { DELETE_RESUME } from "./GraphQL/mutations";
import { reducer } from "./reducer";
import { initialState } from "./constants";

const Resume = () => {
  const [resumeState, dispatch] = useReducer(reducer, initialState);

  const {
    data: userData,
    loading: userLoading,
    refetch: refetchResumeCompletionStatus
  } = useQuery(GET_USER_RESUME_COMPLETION);
  const { data: resumeData, loading: resumeLoading, refetch: refetchResume } = useQuery(GET_RESUME);
  const [deleteResume] = useMutation(DELETE_RESUME, {
    refetchQueries: [{ query: GET_USER_RESUME_COMPLETION }, { query: GET_RESUME }]
  });

  const handleFileUpload = (file) => {
    if (file) {
      dispatch({ type: "uploading" });
      let formData = new FormData();
      formData.append("resume", file);
      formData.append("userId", userData.GetUser.id);
      axios
        .post("/api/candidate/v1/resume-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((res) => {
          if (res.status >= 400) {
            dispatch({
              type: "error",
              error: res?.data?.message
            });
          } else {
            dispatch({
              type: "uploadComplete",
              uploadedFile: {
                id: res.data.id,
                filename: res.data.filename,
                filesize: res.data.filesize,
                createdAt: res.data.createdAt,
                updatedAt: res.data.updatedAt
              }
            });
          }
          refetchResume();
          refetchResumeCompletionStatus();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.log("Must attach file in order to upload");
    }
  };

  const handleUploadSuccess = () => {
    dispatch({ type: "updateId" });
  };

  const handleDeleteResume = async () => {
    await deleteResume({
      variables: {
        resumeId: resumeState.uploadedFile.id
          ? resumeState.uploadedFile.id
          : resumeData?.GetResume.id
      }
    });
    await dispatch({ type: "delete" });
  };

  const handleDownloadResume = () => {
    axios(`/api/candidate/v1/resume`, {
      method: "GET",
      responseType: "blob"
    }).then((res) => {
      const file = new File([res.data], res.headers.filename, {
        type: "application/pdf"
      });
      const fileUrl = URL.createObjectURL(file);
      // @hack - iOS Safari does not support a simulated download anchor click
      // so instead, we navigate to the PDF blob in same window
      // https://stackoverflow.com/questions/39375076/how-to-display-pdf-blob-on-ios-sent-from-my-angularjs-app
      const iOS = /^(iPhone|iPad|iPod)/.test(navigator.platform);
      if (iOS) {
        window.location.href = fileUrl;
      } else {
        let link = document.createElement("a");
        link.target = "_blank";
        link.href = fileUrl;
        link.download = file.name;
        link.click();
      }
      // if we revoke the url too soon, iOS Safari blob bombs out - EH
      setTimeout(() => {
        window.URL.revokeObjectURL(fileUrl);
      }, 50);
    });
  };

  const file = resumeState.uploadedFile.id ? resumeState.uploadedFile : resumeData?.GetResume;

  return (
    <ResumeView
      isLoading={userLoading || resumeLoading}
      userId={userData?.GetUser?.id}
      handleFileUpload={(file) => handleFileUpload(file)}
      handleUploadSuccess={() => handleUploadSuccess()}
      handleDeleteResume={() => handleDeleteResume()}
      isUploading={resumeState.isUploading}
      uploaderId={resumeState.uploaderId}
      file={file}
      error={resumeState.error}
      handleDownloadResume={() => handleDownloadResume()}
    />
  );
};

export default Resume;
