import styled from "styled-components";
import { Box } from "@suited/components";
import { typography } from "suited/constants/style-constants";

export const WorkPreferencesContent = styled(Box)`
  margin: 2.5rem 0;
  max-width: 85ch;
`;

export const FormLabel = styled(Box)`
  color: ${typography.color.dark.base};
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.copy.value};
  line-height: ${typography.leading.fixed.singleLine};
  letter-spacing: ${typography.tracking.slight};
  font-weight: ${typography.weight.body.regular};
`;

type LocationPreferenceRowProps = {
  isDraggingOver: boolean;
};

export const LocationPreferenceRow = styled(Box)<LocationPreferenceRowProps>`
  width: 100%;
  position: absolute;
  box-shadow: ${(props) => (props.isDraggingOver ? `0 0 0 2px #d39128` : "0 0 0 1px black")};
`;

export const CloseIconContainer = styled(Box)`
  position: absolute;
  display: flex;
  align-items: center;
  height: 60px;
  top: 0;
  right: -30px;
`;

export const CustomDropdownIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 7px;
`;
