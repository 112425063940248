import styled from "styled-components";
import Icon from "./shared/typography/Icon";
import { typography, sizes, colors, responsiveTargets } from "../constants/style-constants";

const IconTooltipContainer = styled.i`
  cursor: help;

  .tooltip {
    position: relative;
    color: ${typography.color.dark.hint};
    font-size: ${typography.sizes.form.base.hint};
  }

  .tooltip .tooltiptext {
    content: attr(data-tooltip);
    ${typography.styles.accent}; /* */
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    position: absolute;
    bottom: 150%;
    left: 0;
    margin-bottom: ${sizes.gridUnits.times1};
    margin-left: -${sizes.gridUnits.times4};
    padding: ${sizes.gridUnits.times6};
    width: ${sizes.containerWidths.md};
    background-color: ${colors.color1.base};
    color: ${typography.color.light.base};
    text-align: left;
    line-height: ${typography.leading.fixed.multiLine};
    word-break: break-word;
    @media ${responsiveTargets.phone} {
      width: calc(66.66vw - ${sizes.gridUnits.times4});
    }
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: -${sizes.gridUnits.times2};
    left: 0;
    margin-left: 22px;
    width: 0;
    border-top: ${sizes.gridUnits.times2} solid ${colors.color1.base};
    border-right: ${sizes.gridUnits.times2} solid transparent;
    border-left: ${sizes.gridUnits.times2} solid transparent;
    font-size: 0;
    line-height: 0;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
`;

interface Props {
  text?: string;
  style?: {};
  innerStyle?: {};
  component?: JSX.Element;
}

const IconToolTip = ({ text, style = {}, innerStyle = {}, component }: Props) => {
  return (
    <IconTooltipContainer style={style}>
      <div className="tooltip">
        <Icon name="question-circle" />
        <span className="tooltiptext" style={innerStyle}>
          {component ? component : text}
        </span>
      </div>
    </IconTooltipContainer>
  );
};

export default IconToolTip;
