import styled from "styled-components";
import { Box } from "@suited/components";
import { colors } from "suited/constants/colors";

export const MultipleChoiceQuestion = styled(Box)<{ $isSelected?: boolean }>`
  border-radius: 0.25rem;
  padding: 1rem;
  outline: 1px solid transparent;
  &:hover {
    outline: 1px solid ${colors.rust};
    border-radius: 0.25rem;

    p:first-child {
      color: ${colors.white};
      background: ${colors.rust};
      outline: 0;
    }
  }
  ${(props) =>
    props.$isSelected &&
    `

    outline: 1px solid ${colors.rust};
    border-radius: 0.25rem;
    p:first-child {
      color: ${colors.white};
      background: ${colors.rust};
      outline: 0;
    }

  `}
`;

export const MultipleChoiceLetter = styled.p`
  padding: 0.4rem 0.8rem;
  border-radius: 0.25rem;
  outline: 1px solid lightgrey;
  border-radius: 0.25rem;
`;

export const MultipleChoiceQuestionText = styled.p`
  font-size: 1.2rem;
`;
