import { useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";

import { Box, Flex, Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { InputErrorMessage } from "suited/components/shared/inputs/InputErrorMessage/InputErrorMessage";
import { StyledTextInput } from "suited/components/shared/inputs/StyledTextInput/StyledTextInput";
import { timeouts } from "suited/constants/interaction-constants";

import { UPDATE_USER_LINKEDIN_URL } from "../userAdditionalInfo/UserAdditionaInfoOverlay.queries";
import { isValidLinkedInUrl } from "../userAdditionalInfo/UserAdditionalInfoOverlay.utils";
import { GET_USER_SETTINGS } from "./GraphQL/queries";
import { EditableFormState } from "./userSettings.constants";

export const LinkedInUrlForm = ({ setLinkedInUrlFormState, linkedInUrl }) => {
  const [updateUserLinkedInUrl, { loading: submittingLinkedInUrl, error: submitLinkedInUrlError }] =
    useMutation(UPDATE_USER_LINKEDIN_URL);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty }
  } = useForm({
    mode: "all",
    defaultValues: {
      linkedInUrl
    }
  });

  const formHasErrors = Object.keys(errors).length > 0;
  const isLoading = submittingLinkedInUrl;
  const serverErrors = submitLinkedInUrlError;

  const isDisabled = isLoading || !isDirty || formHasErrors || serverErrors;
  const onSubmit = async (values: { linkedInUrl: string }) => {
    await updateUserLinkedInUrl({
      variables: { linkedInUrl: values.linkedInUrl },
      refetchQueries: [{ query: GET_USER_SETTINGS }]
    });
  };

  // NOTE: If we have an existing LinkedIn url, set editable mode.
  // If we haven't set a LinkedIn URL yet, show add URL.
  const formStateToSet = linkedInUrl ? EditableFormState.READY : EditableFormState.MISSING;

  return (
    <Stack space="sm" data-test="linkedin-url-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack space="sm">
          <Box>
            <Stack space="none">
              <Controller
                name="linkedInUrl"
                control={control}
                rules={{
                  validate: (url: string) => {
                    if (!url) {
                      return true;
                    }

                    return isValidLinkedInUrl(url) || "LinkedIn URL is invalid";
                  }
                }}
                render={({ field }) => (
                  <StyledTextInput
                    id="linkedin-url"
                    {...field}
                    error={errors?.linkedInUrl?.message}
                    placeholder="https://www.linkedin.com/in/janedoe/"
                  />
                )}
              />
              <InputErrorMessage message={errors?.linkedInUrl?.message} />
            </Stack>
          </Box>
          <Flex justify="flex-end">
            <Box>
              <SuitedButton
                purpose="default"
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                onClick={() => setLinkedInUrlFormState(formStateToSet)}
                data-testid="log-out"
                style={{ marginRight: "0.75rem" }}
              >
                Cancel
              </SuitedButton>
            </Box>
            <Box>
              <SuitedButton
                alignRight
                formButton
                type="submit"
                purpose="primary"
                disabled={!!isDisabled}
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
              >
                Save
              </SuitedButton>
            </Box>
          </Flex>
        </Stack>
      </form>
    </Stack>
  );
};
