import styled from "styled-components";

import { typography, sizes, colors, animationCurves } from "suited/constants/style-constants";
import SuitedDetailValue from "suited/components/shared/typography/SuitedDetailValue";
import { hexToRgba } from "suited/util/cssColorTansforms";

export const SingleSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

interface ItemProps {
  rows: number;
}

// prettier-ignore
export const Item = styled.div<ItemProps>`
  /* margin-bottom: ${sizes.gridUnits.times1}; */
`;

export const LetterBox = styled.div``;

// prettier-ignore
export const LetterIndexStyle = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${sizes.touch.minimum};
  height: ${sizes.touch.minimum};
  margin: 0 ${sizes.gridUnits.times2};
  color: ${props => (props.checked ? typography.color.light.emphasis : typography.color.dark.base)};
  font-family: ${typography.family.monospace};
  font-weight: ${typography.weight.body.bold};
  background-color: ${props => (props.checked ? colors.accent1.base : colors.shades.times1)};
  border: 1px solid transparent;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 2px transparent;
  transition: color 300ms ${animationCurves.easeOutQuart},
    background-color 300ms ${animationCurves.easeOutQuart},
    border-color 300ms ${animationCurves.easeOutQuart},
    box-shadow 300ms ${animationCurves.easeOutQuart};
`;

export const OptionLabel = styled(SuitedDetailValue)`
  margin-bottom: 0;
`;

interface SingleSelectOptionContainerProps {
  checked?: boolean;
  onClick?: () => void;
  isChild: boolean;
  disabled?: boolean;
}

// prettier-ignore
export const SingleSelectOptionContainer = styled.div<SingleSelectOptionContainerProps>`
  pointer-events: ${ (props) => props.disabled ? "none" : null };
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  position: relative;  
  box-sizing: border-box;
  padding: ${sizes.gridUnits.times1};
  margin-right: ${sizes.gridUnits.times2};
  color: ${props => (props.checked ? typography.color.dark.emphasis : typography.color.dark.base)};
  background-color: ${props => (props.checked ? hexToRgba(colors.color4.base, 0.08) : "transparent")};
  border-radius: 0;
  outline: 0;
  font-size: ${typography.sizes.form.base.input};
  font-family: ${typography.family.body};
  cursor: pointer;
  z-index: ${props => (props.checked ? 2 : 1)};
  transition: background-color 300ms ${animationCurves.easeOutQuart};
  &:hover,
  &:focus {
    background-color: ${hexToRgba(colors.color4.base, 0.04)};
    z-index: 2;
    ${LetterIndexStyle} {
      background-color: ${props => props.checked ? colors.accent1.base : colors.background.light.fourthOrder};
      border: 1px solid ${props => props.checked ? colors.shades.times1 : colors.accent1.base};
      box-shadow: inset 0px 0px 0px 2px ${props => props.checked ? colors.shades.times1 : colors.accent1.base};
    }
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    &:hover,
    &:focus {
      color: ${typography.color.dark.disabled};
    }
  }
  &:last-child {
    margin-right: 0;
  }
  ${OptionLabel} {
    color: ${props =>
      props.checked ? typography.color.dark.emphasis : typography.color.dark.base};
  }
`;
