import React, { useState } from "react";

import { LocationDescriptorObject, Path } from "history";
import { StyledLink, SuitedLinkAEl } from "./SuitedAltLink.style";

interface Props {
  to?: Path | LocationDescriptorObject;
  href?: string;
  children?: React.ReactNode;
  tooltip?: string;
  dataAnalyticsId?: string;
  color?: string;
  backgroundColor?: string;
  className?: string;
  disabled?: boolean;
  // @TODO properly extend <a> element attributes
  target?: "_self" | "_blank" | "_parent" | "top" | string;
  /**
   * Tabindex can be used to override the default tabindex of 0.
   * @default 0
   */
  tabIndex?: number;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const SuitedAltLink = (props: Props) => {
  const {
    to,
    href,
    children,
    disabled = false,
    tabIndex = 0,
    tooltip,
    dataAnalyticsId,
    color,
    backgroundColor,
    target,
    className,
    onClick
  } = props;

  const [hovered, setHovered] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (!onClick) return;
    if (disabled) return;
    if (event.key === "Enter" || event.key === " ") {
      if (onClick) onClick(event as any);
      setActive(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!onClick) return;
    if (disabled) return;
    if (event.key === "Enter" || event.key === " ") {
      setActive(true);
    }
  };

  const handleClick = (event: any) => {
    if (!onClick) return;
    if (disabled) return;
    if (onClick) onClick(event);
  };

  if (!!to) {
    return (
      <StyledLink
        to={!disabled ? to : undefined}
        color={color}
        backgroundColor={backgroundColor}
        title={tooltip}
        name={tooltip}
        aria-label={tooltip}
        data-analytics-id={dataAnalyticsId}
        tabIndex={tabIndex}
        onClick={handleClick}
        target={target}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onMouseDown={() => setActive(true)}
        onMouseUp={() => setActive(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyUp={(event: React.KeyboardEvent) => handleKeyUp(event)}
        onKeyDown={(event: React.KeyboardEvent) => handleKeyDown(event)}
        hovered={hovered}
        focused={focused}
        active={active}
        disabled={disabled}
        className={className}
      >
        {children}
      </StyledLink>
    );
  } else {
    return (
      <SuitedLinkAEl
        href={!disabled ? href : undefined}
        title={tooltip}
        name={tooltip}
        aria-label={tooltip}
        data-analytics-id={dataAnalyticsId}
        color={color}
        backgroundColor={backgroundColor}
        tabIndex={tabIndex}
        onClick={!href ? handleClick : () => {}}
        target={target}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onMouseDown={() => setActive(true)}
        onMouseUp={() => setActive(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyUp={(event: React.KeyboardEvent) => handleKeyUp(event)}
        onKeyDown={(event: React.KeyboardEvent) => handleKeyDown(event)}
        // Transient Props to remove warnings: https://styled-components.com/docs/api#transient-props
        $hovered={hovered}
        $focused={focused}
        $active={active}
        disabled={disabled}
        className={className}
      >
        {children}
      </SuitedLinkAEl>
    );
  }
};
