import { Stack, Box, Center } from "@suited/components";
import { colors } from "suited/constants/style-constants";
import { CardSubheadline } from "./SuitedUploaderCardStates.style";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

interface Props {
  message: string;
  onClickUpload?: () => void;
}

const Empty = ({ message, onClickUpload }: Props) => {
  return (
    <Box
      padding="xxl"
      borderWidth="2px"
      borderRadius="4px"
      minWidth="50ch"
      maxWidth="65ch"
      height="240px"
      bg={colors.tints.mid}
      borderColor={colors.shades.times1}
    >
      <Stack space="lg" data-test="component-suited-uploader-card-empty">
        <CardSubheadline data-test="message">{message}</CardSubheadline>
        {onClickUpload !== undefined ? (
          <Center>
            <SuitedButton purpose="default" onClick={onClickUpload} data-test="upload">
              Upload
            </SuitedButton>
          </Center>
        ) : null}
        <CardSubheadline data-test="pdf-message">
          Your resume must be a PDF smaller than 5MB.
        </CardSubheadline>
      </Stack>
    </Box>
  );
};

export default Empty;
