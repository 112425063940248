import styled from "styled-components";
import { colors, typography } from "suited/constants/style-constants";

export const Container = styled.div`
  position: relative;
  height: 36px;
`;

export const PercentCompleteContainer = styled.div<{ width: number }>`
  height: 36px;
  position: absolute;
  left: 0;
  display: inline-block;
  transition: all 500ms;
  width: 5%;
`;

export const PercentComplete = styled.div`
  height: 100%;
  z-index: 999;
  display: grid;
  place-items: center;
  padding: 0.25rem;
  color: ${typography.color.light.base};
  background: #101820;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledProgressBar = styled.progress`
  width: 100%;
  height: 36px;
  appearance: none;

  &[value]::-webkit-progress-bar {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }

  /* Progress bar fill styling. Required for Webkit-based browsers (Chrome). */
  &[value]::-webkit-progress-value {
    transition: all 500ms;
    background-color: ${colors.background.dark.backdrop};
  }

  /* Progress bar fill styling. Required for Firefox. */
  &[value]::-moz-progress-bar {
    transition: all 500ms;
    background-color: ${colors.background.dark.backdrop};
  }
`;
