import gql from "graphql-tag";

export const UPDATE_USER_PERSONAL_DATA = gql`
  mutation UpdateUserPersonalInformation(
    $gender: String
    $veteran: String
    $lgbtq: String
    $firstGenCollege: String
    $ethnicity: [String]
    $birthYear: Int
    $diversityOptIn: Boolean
  ) {
    UpdateUserPersonalInformation(
      gender: $gender
      veteran: $veteran
      lgbtq: $lgbtq
      firstGenCollege: $firstGenCollege
      ethnicity: $ethnicity
      birthYear: $birthYear
      diversityOptIn: $diversityOptIn
    ) {
      userProfile {
        personalInformation {
          gender
          veteran
          lgbtq
          firstGenCollege
          ethnicity
          birthYear
        }
      }
      settings {
        diversityOptIn {
          optIn
        }
      }
    }
  }
`;
