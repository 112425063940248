import { CSSProperties } from 'react';
import clsx from 'clsx';

import { omit } from '@suited/utils';
import { BaseComponentProps, SpacingOptions } from '../../types';
import { getContentAreaProps } from '../../utils';
import { CONTENT_AREA_PROPERTIES } from '../../constants';
import './Flex.css';

interface Props extends BaseComponentProps {
  space?: SpacingOptions;
  direction?: CSSProperties['flexDirection'];
  align?: CSSProperties['alignItems'];
  alignSelf?: CSSProperties['alignSelf'];
  justify?: CSSProperties['justifyContent'];
  basis?: CSSProperties['flexBasis'];
  grow?: CSSProperties['flexGrow'];
  shrink?: CSSProperties['flexShrink'];
  flex?: CSSProperties['flex'];
  wrap?: boolean;
}

export const Flex = ({
  children,
  className,
  style,
  flex,
  alignSelf,
  direction = 'row',
  align = 'flex-start',
  justify = 'flex-start',
  basis = 'auto',
  grow = 0,
  shrink = 1,
  wrap = false,
  ...props
}: Props) => {
  const classNames = clsx(
    'ws-l-flex',
    { 'ws-l-flex-shorthand': flex },
    className
  );
  const rest = omit(props, CONTENT_AREA_PROPERTIES);
  const styles = {
    ...style,
    ...getContentAreaProps(props),
    '--flex': flex,
    '--flex-direction': direction,
    '--flex-justify': justify,
    '--flex-align': align,
    '--flex-align-self': alignSelf,
    '--flex-basis': basis,
    '--flex-grow': grow,
    '--flex-shrink': shrink,
    '--flex-wrap': wrap ? 'wrap' : 'nowrap',
  };

  return (
    <div className={classNames} style={styles} {...rest}>
      {children}
    </div>
  );
};
