import styled from "styled-components";

import { typography, sizes } from "suited/constants/style-constants";

interface IStyleProps {
  overDark?: boolean;
}

export const SuitedListItemPrimaryValueEl = styled.div<IStyleProps>`
  flex: 0 0 auto;
  padding: 0;
  color: ${typography.color.dark.base};
  font-family: ${typography.family.bodyEmphasis};
  font-size: ${sizes.gridUnits.times2};
  font-weight: ${typography.weight.body.medium};
  ${(props) => (props.overDark ? `color: ${typography.color.light.base};` : "")}
`;
