import { factory, primaryKey } from "@mswjs/data";
import { faker } from "@faker-js/faker";

export const db = factory({
  UpdateUserPersonalInformation: {
    id: primaryKey(faker.datatype.uuid),
    birthYear: Number,
    diversityOptIn: Boolean,
    ethnicity: String,
    firstGenCollege: String,
    gender: String,
    lgbtq: String,
    veteran: String
  }
});
