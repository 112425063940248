import { Dictionary } from '../types';

export const transformValues = (
  obj: Dictionary<any>,
  callback: (key: string, value: any) => void
) => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: callback(key, value),
    }),
    {}
  );
};
