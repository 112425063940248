import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { uuidv4 } from "@suited/utils";

import { setAuthToken } from "../Login";
import { stateKey } from "./reducers";
import UserRegistration from "./UserRegistration.component";
import { IRegistrationState } from "./UserRegistration.types";
import RecaptchaProvider from "../RecaptchaProvider";

const initialRegistrationState: IRegistrationState = {
  industryContext: "",
  invitationCode: "",
  autopilotSessionId: "",
  formKey: uuidv4(),
  utm: {
    source: "",
    campaign: "",
    medium: "",
    content: "",
    brand: "",
    term: ""
  },
  identity: {
    firstname: "",
    lastname: "",
    email: ""
  }
};

class Registration extends React.Component<any, IRegistrationState> {
  constructor(props) {
    super(props);

    const industryContext = new URLSearchParams(this.props.location.search).get("industry") || "";

    const invitationCode = new URLSearchParams(this.props.location.search).get("code");
    /**
     This sessionId is an Autopilot tracking code from the marketing website designed to connect anonymous activity
     there with user registrations and activities on our platform. The sessionId is added to links in
     a script at the footer of wellsuited.com, via the Webflow in-browser editor.
     The sessionId is used by the createAutopilotUser Google Cloud Function to create a new contact 
     in the context of an _autopilot_session_id.
    */
    const autopilotSessionId = new URLSearchParams(this.props.location.search).get("sessionId");

    const utm = {
      source: new URLSearchParams(this.props.location.search).get("utm_source") || "",
      medium: new URLSearchParams(this.props.location.search).get("utm_medium") || "",
      campaign: new URLSearchParams(this.props.location.search).get("utm_campaign") || "",
      content: new URLSearchParams(this.props.location.search).get("utm_content") || "",
      term: new URLSearchParams(this.props.location.search).get("utm_term") || "",
      brand: new URLSearchParams(this.props.location.search).get("utm_brand") || ""
    };

    const identity = {
      firstname: new URLSearchParams(this.props.location.search).get("firstname") || "",
      lastname: new URLSearchParams(this.props.location.search).get("lastname") || "",
      email: new URLSearchParams(this.props.location.search).get("email") || ""
    };

    this.state = {
      ...initialRegistrationState,
      industryContext,
      invitationCode: invitationCode ? invitationCode : "",
      autopilotSessionId: autopilotSessionId ? autopilotSessionId : "",
      utm,
      identity
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.email !== prevProps.email) {
      this.setState({ formKey: uuidv4() });
    }
  }
  render() {
    return (
      <RecaptchaProvider init={this.props.init}>
        <UserRegistration
          formKey={this.state.formKey}
          industryContext={this.state.industryContext}
          autopilotSessionId={this.state.autopilotSessionId}
          history={this.props.history}
          invitationCode={this.state.invitationCode}
          utm={this.state.utm}
          identity={this.state.identity}
          setAuthToken={this.props.setAuthToken}
        />
      </RecaptchaProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  init: ownProps.init || null,
  email: state[stateKey].email,
  companyId: state[stateKey].companyId,
  notConfirmedEmail: state[stateKey].notConfirmedEmail
});

const mapDispatchToProps = (dispatch) => ({
  setAuthToken: (token: string) => {
    dispatch(setAuthToken(token));
  }
});

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(Registration)
);
