export const SAMPLE_QUESTION_TYPES = {
  MATRIX_TABLE_SAME_DIFFERENT: "MATRIX_TABLE_SAME_DIFFERENT",
  MATRIX_TABLE_TRUE_FALSE_UNCERTAIN: "MATRIX_TABLE_TRUE_FALSE_UNCERTAIN",
  MULTI_SELECT: "MULTI_SELECT",
  TEXT_INPUT: "TEXT_INPUT"
};

export const CORRECT_ANSWER_PREFIX = "Correct!";
export const WRONG_ANSWER_PREFIX = "Please try again.";

export const SAMPLE_QUESTIONS_ANSWERS = {
  [SAMPLE_QUESTION_TYPES.MATRIX_TABLE_SAME_DIFFERENT]: [
    {
      answer: "Different",
      feedback:
        'You should have selected DIFFERENT, because the "r" is not capitalized in the first set but is capitalized in the second set.'
    }
  ],

  [SAMPLE_QUESTION_TYPES.MATRIX_TABLE_TRUE_FALSE_UNCERTAIN]: [
    {
      answer: "Uncertain",
      feedback:
        "The first conclusion is uncertain because there is no way to determine from the facts if any tall person is also a swimmer."
    },

    {
      answer: "True",
      feedback: "The second conclusion is true because it follows directly from the facts provided."
    }
  ],

  [SAMPLE_QUESTION_TYPES.MULTI_SELECT]: [
    {
      answer: "AGTr5$",
      feedback:
        "The second and fourth sets of characters exactly match the initial set of characters, while the first and third sets are different."
    }
  ],

  [SAMPLE_QUESTION_TYPES.TEXT_INPUT]: [
    {
      answer: "16",
      feedback:
        "The numbers in this series change by multiplying by 2, and 16 would replace the ____ symbol to make the series complete."
    }
  ]
};

export const CORRECT_ANSWER_FEEDBACK_KEY = "correctAnswerFeedback";
export const WRONG_ANSWER_FEEDBACK_KEY = "wrongAnswerFeeedback";
