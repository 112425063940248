const emailRegEx = new RegExp(
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

function isValidEmail(email: string): string[] | null {
  // Source: http://emailregex.com/
  return emailRegEx.exec(email);
}

export const isValidEmailBoolean = (email: string) => {
  return emailRegEx.test(String(email).toLowerCase().trim());
};

export default isValidEmail;
