import type { Dictionary } from '../types';

export const shallowEqual = (
  source: Dictionary<any>,
  target: Dictionary<any>
) => {
  const sourceKeys = Object.keys(source);
  const targetKeys = Object.keys(target);
  if (sourceKeys.length !== targetKeys.length) {
    return false;
  }
  for (const key of sourceKeys) {
    if (source[key] !== target[key]) {
      return false;
    }
  }
  return true;
};
