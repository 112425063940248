import React from "react";
import { ILabelHTMLAttributes } from "suited/components/shared/types/htmlAttributeTypes";

import { StyledSuitedInputLabel } from "./SuitedAltInputLabel.style";

export interface ISuitedInputLabelProps extends ILabelHTMLAttributes {
  id?: string;
  /** display light text on dark backgrounds */
  overDark?: boolean;
  /** turn off default margins */
  noMargin?: boolean;
  /** styled-components uses className for overrides */
  className?: string;
  children: React.ReactNode;
}

/**
 * ## What
 * `<SuitedInputLabel>` is a label for inputs. It to be vertically stacked above an input, which is our UX standard.
 *
 * ## Where
 * It should be used in forms to label a single input control. Most input components pre-compose this label into their layout.
 *
 * ## Not
 * It should not be used to label values in read-only contexts such as detail views or list items.
 *
 * ## Example
 * In Suited Design System, `<SuitedTextInput>` composes this component with a styled `<input>` in a vertical layout with the whitespace baked in.
 *
 * */
export const SuitedAltInputLabel = (props: ISuitedInputLabelProps) => {
  return (
    <StyledSuitedInputLabel {...props} data-testid="component-suited-input-label">
      {props.children}
    </StyledSuitedInputLabel>
  );
};
