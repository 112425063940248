import { MultipleChoiceOption } from "../eca.types";

export const getMultipleChoiceDefaultValues = (options: MultipleChoiceOption[]) =>
  options.reduce(
    (acc, { id, isSelected }) => ({
      ...acc,
      [id]: isSelected
    }),
    {}
  );

export function charAtIndex(index: number) {
  const CHAR_CODE_CAPITAL_A = 65;
  return String.fromCharCode(CHAR_CODE_CAPITAL_A + index);
}
