import SuitedBasicConfirmFooter from "suited/components/shared/layout/Dialogs/SuitedBasicConfirmFooter";
import SuitedDialogConfirm from "suited/components/shared/layout/Dialogs/SuitedDialogConfirm";

type RecaptchaReloadDialogProps = {
  onCancel: () => void;
  onConfirm: () => void;
  show: boolean;
};

const RecaptchaReloadDialog = ({ onCancel, onConfirm, show }: RecaptchaReloadDialogProps) => {
  return (
    <SuitedDialogConfirm
      show={show}
      title="Turn off Ad Blockers and Reload"
      message={
        "Something went wrong with our human-detection, " +
        "so we cannot submit this registration form. " +
        "You may need to turn off ad-blockers before reloading the registration page."
      }
      onCancel={onCancel}
      shouldFocusAfterRender={true}
      renderFooter={
        <SuitedBasicConfirmFooter
          cancelText="Dismiss"
          confirmText="Did It, Reload"
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      }
    />
  );
};

export default RecaptchaReloadDialog;
