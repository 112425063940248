import styled from "styled-components";
import { Box } from "@suited/components";

export const Container = styled(Box)`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 2;

  @media screen and (max-width: 600px) {
    margin: 0;
  }
`;

export const Number = styled.div`
  color: #333;
  text-shadow: 0 1px #fff;
  background-color: #efefef;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  height: 45px;
  width: 30px;

  &:before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #fff;
    opacity: 0.3;
    filter: alpha(opacity=30);
    transform: translateY(-50%);
    top: 50%;
  }
`;
