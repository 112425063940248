import styled from "styled-components";

import { typography, responsiveTargets } from "suited/constants/style-constants";
import { ISuitedCopyCaptionProps } from "./SuitedAltCopyCaption";

export const SuitedCopyCaptionEl = styled.div<ISuitedCopyCaptionProps>`
  ${typography.styles.body}
  letter-spacing: ${typography.tracking.loose};
  color: ${typography.color.dark.hint};
  font-size: ${typography.sizes.copy.caption.base};
  line-height: ${typography.leading.fixed.multiLine};
  @media ${responsiveTargets.phone} {
    font-size: ${typography.sizes.copy.caption.phone};
    margin-bottom: ${typography.margins.caption.phone};
  }
  @media ${responsiveTargets.tablet} {
    font-size: ${typography.sizes.copy.caption.tablet};
    margin-bottom: ${typography.margins.caption.tablet};
  }
  @media ${responsiveTargets.laptop} {
    font-size: ${typography.sizes.copy.caption.laptop};
    margin-bottom: ${typography.margins.caption.laptop};
  }
  @media ${responsiveTargets.desktop} {
    font-size: ${typography.sizes.copy.caption.desktop};
    margin-bottom: ${typography.margins.caption.desktop};
  }
  @media ${responsiveTargets.jumbo} {
    font-size: ${typography.sizes.copy.caption.jumbo};
    margin-bottom: ${typography.margins.caption.jumbo};
  }
  ${(props) => (props.noMargin ? "margin-bottom: 0 !important;" : "")}
  ${(props) => (props.overDark ? `color: ${typography.color.light.base};` : "")}

  strong,
  em {
    ${typography.styles.emphasis};
    letter-spacing: ${typography.tracking.loose};
  }
`;
