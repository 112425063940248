import Skeleton from "react-loading-skeleton";

import { Box, Stack, Center } from "@suited/components";
import SuitedUploader from "../../shared/inputs/SuitedUploader";
import SuitedCopyHeadline from "../../shared/typography/SuitedCopyHeadline";
import { ResumeContent } from "./ResumeView.style";

interface Props {
  userId: string;
  handleFileUpload: (file) => void;
  handleUploadSuccess: () => void;
  handleDeleteResume: () => void;
  isUploading: boolean;
  uploaderId: string;
  file: any;
  error: any;
  handleDownloadResume: () => void;
  isLoading: boolean;
}

const ResumeView = (props: Props) => {
  return (
    <Box padding="xl" overflow="auto">
      <ResumeContent>
        <Stack space="xl">
          <SuitedCopyHeadline noMargin>Resume</SuitedCopyHeadline>
          <p>
            Please upload your most recent resume here. You can re-upload a new resume at any time.
          </p>
          {props.isLoading ? (
            <Center>
              <Skeleton height={240} width="50ch" />
            </Center>
          ) : (
            <SuitedUploader
              isUploading={props.isUploading}
              acceptMimeTypes={["application/pdf"]}
              maxFilesize={5242880}
              file={props.file}
              serverError={props.error}
              onUpload={props.handleFileUpload}
              onUploadSuccess={props.handleUploadSuccess}
              onClearFile={props.handleDeleteResume}
              onDownload={props.handleDownloadResume}
              minUploadDuration={1000}
            />
          )}
        </Stack>
      </ResumeContent>
    </Box>
  );
};

export default ResumeView;
