import * as React from "react";
import Media from "react-media";
import { responsiveRanges } from "../../../constants/style-constants";

interface Props {
  children?: ((matches: boolean) => React.ReactNode) | React.ReactNode;
  onChange?: (match: boolean) => void;
}

export const Phone = (props: Props) => {
  return (
    <Media query={`${responsiveRanges.phone}`} onChange={props.onChange} data-test="phone">
      {props.children}
    </Media>
  );
};

export const Tablet = (props: Props) => {
  return (
    <Media query={`${responsiveRanges.tablet}`} onChange={props.onChange} data-test="tablet">
      {props.children}
    </Media>
  );
};

export const PhoneAndTablet = (props: Props) => {
  return (
    <Media
      query={`${responsiveRanges.phoneAndTablet}`}
      onChange={props.onChange}
      data-test="phone-and-tablet"
    >
      {props.children}
    </Media>
  );
};

export const Laptop = (props: Props) => {
  return (
    <Media query={`${responsiveRanges.laptop}`} onChange={props.onChange} data-test="laptop">
      {props.children}
    </Media>
  );
};

// a synonym for Laptop
export const TabletLandscape = (props: Props) => {
  return (
    <Media
      query={`${responsiveRanges.tabletLandscape}`}
      onChange={props.onChange}
      data-test="tablet-landscape"
    >
      {props.children}
    </Media>
  );
};

export const Desktop = (props: Props) => {
  return (
    <Media query={`${responsiveRanges.desktop}`} onChange={props.onChange} data-test="desktop">
      {props.children}
    </Media>
  );
};

export const Jumbo = (props: Props) => {
  return (
    <Media query={`${responsiveRanges.jumbo}`} onChange={props.onChange} data-test="jumbo">
      {props.children}
    </Media>
  );
};

export const LaptopAndAbove = (props: Props) => {
  return (
    <Media
      query={`${responsiveRanges.laptopAndAbove}`}
      onChange={props.onChange}
      data-test="laptop-and-above"
    >
      {props.children}
    </Media>
  );
};
