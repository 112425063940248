import { TProfileUpdateAction, TProfileLoadedAction, TProfileAssessmentCompleted } from "./types";

export function profileLoadedAction(): TProfileLoadedAction {
  return {
    type: "PROFILE_LOADED"
  };
}

export function profileUpdateAction(): TProfileUpdateAction {
  return {
    type: "PROFILE_UPDATE"
  };
}

export function profileAssessmentCompletedAction(): TProfileAssessmentCompleted {
  return {
    type: "PROFILE_ASSESSMENT_COMPLETED"
  };
}
