export enum TransitionState {
  NONE,
  UPDATING,
  FROM_LEFT,
  FROM_RIGHT,
  TO_LEFT,
  TO_RIGHT
}

export interface QuestionSchema {
  id: string;
  type: string;
  label: string;
  description?: string;
  properties?: any;
  fields?: QuestionSchema[];
  validations: {
    required: boolean;
  };
  questionIndex?: number;
}

export interface Answer {
  questionId: string;
  value: string | null;
  multiValue: string[] | null;
}

export interface Answers {
  [id: string]: string | string[];
}

export interface Errors {
  [id: string]: string;
}

export interface ISuitedAssessment {
  currentQuestionIndex: number;
  errors: Errors;
  transition: TransitionState;
}

export type TSuitedAssessmentReducerAction =
  | { type: "currentQuestionIndex"; index: number }
  | { type: "transition"; transition: TransitionState }
  | { type: "transitionWithIndexUpdate"; transition: TransitionState; index: number }
  | { type: "error"; errors: Errors; index: number };
