import { Box, Stack } from "@suited/components";

export const LawInstructions = () => {
  const underlineStyles = { textDecoration: "underline" };
  return (
    <Box data-testid="instructions">
      <Stack>
        <p style={underlineStyles}>Enter Your Education Details Below</p>
        <p style={underlineStyles}>Mandatory Fields</p>
        <p>
          Law Degree (JD): Use the Degree dropdown to select “JD.” Locate your university in the list or manually input its name if not found.
        </p>
        <p>
          Undergraduate Degree: This information is essential. Please provide details for all undergraduate institutions you have attended.
        </p>
        <p style={underlineStyles}>Additional Steps</p>
        <p>Add More Schools: After filling out the mandatory fields, you can include additional educational instructions.</p>
        <p>
          School Not Listed?: If you cannot find your school, choose “Other - my school is not listed” and enter the name of your university.
        </p>
      </Stack>
    </Box>
  );
};

