import React, { useReducer, useState } from "react";

import {
  profileAssessmentCompletedAction,
  profileLoadedAction,
  profileUpdateAction
} from "./actions";
import createProfileReducer from "./reducer";
import {
  TProfileCompleteProviderProps,
  TProfileCompleteProviderState,
  TProfileContextValue
} from "./types";
import { checkIfProfileComplete } from "./utils";

const initialState: TProfileCompleteProviderState = {
  complete: false,
  loaded: false
};

const ProfileContext = React.createContext<TProfileContextValue | undefined>(undefined);

/**
 * The Candidate Profile Provider.
 *
 * Provides context for sub-components to extract information about
 * the Candidates profile and dispatch events related to the
 * Candidates profile.
 */
const ProfileCompleteProvider = ({ children, client }: TProfileCompleteProviderProps) => {
  const reducer = createProfileReducer(client);
  const [profileState, profileDispatch] = useReducer(reducer, initialState);
  // This state variable is used to keep track of whether or not the
  // profile was either already completed on profile load
  // or was later completed at some point in time after profile load.
  // It is used to prevent sending the tracking analytics more than once
  // This should eventually be replaced by persistence from the database
  const [alreadyCompleted, setAlreadyCompleted] = useState<boolean>(false);

  /**
   * Dispatches an action to update the Provider's
   * state to indicate the profile has been loaded
   * and to set the initial completion status of the profile.
   */
  function profileDidLoad() {
    setAlreadyCompleted(checkIfProfileComplete(client));
    profileDispatch(profileLoadedAction());
  }

  /**
   * Dispatches an action to update the Provider's state after
   * a profile section is completed. If all of the profile
   * sections have been completed then it will send the
   * profile completion analytics.
   */
  function profileWasUpdated() {
    profileDispatch(profileUpdateAction());

    const profileIsLoaded = profileState.loaded;
    const profileWasNotComplete = !alreadyCompleted;
    const profileWillBeComplete = checkIfProfileComplete(client);

    const profileWasJustCompleted =
      profileWasNotComplete && profileWillBeComplete && profileIsLoaded;

    if (profileWasJustCompleted) {
      setAlreadyCompleted(true);
    }
  }

  /**
   * Dispatches an action to update the Provider's state after
   * the `SuitedAssessment` has been completed.
   */
  function profileAssessmentWasCompleted() {
    profileDispatch(profileAssessmentCompletedAction());
  }

  const value: TProfileContextValue = {
    profileState,
    profileDispatch,
    profileDidLoad,
    profileWasUpdated,
    profileAssessmentWasCompleted
  };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export { ProfileContext };

export default ProfileCompleteProvider;
