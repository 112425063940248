import { css } from "styled-components";

import { IThemeColors, IThemeTypography } from "./theme";
import theme from "./theme-2020";

export const colors: IThemeColors = {
  black: theme.color.black,
  white: theme.color.white,
  color1: theme.color.color1,
  color2: theme.color.color2,
  color3: theme.color.color3,
  color4: theme.color.color4,
  color5: theme.color.color5,
  color6: theme.color.color6,
  color7: theme.color.color7,
  accent1: theme.color.accent1,
  status: theme.color.status,
  background: theme.color.background,
  tints: theme.color.tints,
  shades: theme.color.shades,
  scores: theme.color.scores
};

export const typography: IThemeTypography = {
  color: theme.typography.color,
  family: theme.typography.family,
  weight: theme.typography.weight,
  sizes: theme.typography.sizes,
  margins: theme.typography.margins,
  tracking: theme.typography.tracking,
  leading: theme.typography.leading,
  styles: theme.typography.styles
};

// SIZES
// --------------------------
const baseGridUnit = 8;

const gridUnits = {
  divide4: `${baseGridUnit / 4}px`,
  divide2: `${baseGridUnit / 2}px`,
  times1: `${baseGridUnit}px`,
  times2: `${baseGridUnit * 2}px`,
  times3: `${baseGridUnit * 3}px`,
  times4: `${baseGridUnit * 4}px`,
  times5: `${baseGridUnit * 5}px`,
  times6: `${baseGridUnit * 6}px`,
  times7: `${baseGridUnit * 7}px`,
  times8: `${baseGridUnit * 8}px`,
  times9: `${baseGridUnit * 9}px`,
  times10: `${baseGridUnit * 10}px`,
  times11: `${baseGridUnit * 11}px`,
  times12: `${baseGridUnit * 12}px`,
  times13: `${baseGridUnit * 13}px`,
  times14: `${baseGridUnit * 14}px`,
  times15: `${baseGridUnit * 15}px`,
  times16: `${baseGridUnit * 16}px`,
  times18: `${baseGridUnit * 18}px`,
  times20: `${baseGridUnit * 20}px`,
  zero: "0px"
};

const touchTargets = {
  base: "48px",
  minimum: "40px",
  padded: "56px"
};

export const containerWidths = {
  xxs: "calc(100vw - 32px)", // containers not filling phone
  xs: "100vw", // filling phone
  sm: "320px", // "phone-sized" such as sidebars or alert/confirmation dialogs or notifications
  md: "480px", // non-trivial dialogs
  lg: "640px", // wizards and complex dialogs, body copy in medium layouts
  xl: "960px", // body copy or lists in large layouts
  nav: "240px" // side navigation panel (this may change in the future, up to 296px max)
};

const cardSizes = {
  uploader: {
    containerHeight: "240px",
    height: "200px",
    minHeight: "160px",
    width: "calc(100% - 40px)",
    minWidth: "calc(320px - 40px)",
    maxWidth: "480px"
  }
};

const modalWidths = {
  // @TODO - finalize the various semantic modalWidths
  sm: 320, // ???
  md: 480, // alert/confirm dialogs
  lg: 560, // non-trivial window dialogs
  xl: 640 // wizards and complex window dialogs
};

export const sizes = {
  gridUnits: gridUnits,
  containerWidths: containerWidths,
  touch: touchTargets,
  cards: cardSizes,
  modalWidths: modalWidths
};

// BOX SHADOWS
// --------------------------
export const boxShadows = {
  depth1: "rgba(0, 0, 0, 0.16) 0px 3px 8px -1px",
  depth1Dark: "rgba(0, 0, 0, 0.40) 0px 3px 8px -1px",
  insetRow1:
    "inset rgba(0, 0, 0, .64) 0px 2px 8px -2px, inset rgba(0, 0, 0, .64) 0px -2px 8px -2px",
  card: "rgba(0, 0, 0, 0.24) 8px 8px 16px -4px",
  cardVignette: "inset rgba(0,0,0,0.048) 0 0 40px 0px"
};

export const borders = {
  card: css`
    border-top: 1px solid ${colors.tints.most};
    border-right: 1px solid ${colors.shades.times1};
    border-bottom: 1px solid ${colors.shades.times2};
    border-left: 1px solid ${colors.tints.mid};
    outline: 1px solid ${colors.shades.divide2};
  `
};

export const gradients = {
  card: {
    shade: `linear-gradient(to bottom,
      rgba(0,0,0,0.008) 0px,
      transparent ${sizes.cards.uploader.height}
    )`,
    tint: `linear-gradient(to bottom,
      rgba(255,255,255,0.008) 0px,
      transparent ${sizes.cards.uploader.height}
    )`
  }
};

const textureShade1 = "rgba(0,0,0,0.00125)";
const textureShade2 = "rgba(0,0,0,0.00250)";
const textureShade3 = "rgba(0,0,0,0.00375)";

export const textures = {
  card: {
    backgrounds: `
      linear-gradient(
        45deg, 
        ${textureShade1} 12%, 
        transparent 0, 
        transparent 88%, 
        ${textureShade1} 0
      ),
      linear-gradient(
        135deg,
        transparent 37%,
        ${textureShade3} 0,
        ${textureShade3} 63%,
        transparent 0
      ), 
      linear-gradient(
        45deg,
        transparent 37%,
        ${textureShade1} 0,
        ${textureShade1} 63%,
        transparent 0
      ),
      linear-gradient(
        0deg,
        ${textureShade2} 33%,
        transparent 100%
      )
    `,
    backgroundSizes: `
      9px 9px,
      9px 9px,
      9px 9px,
      9px 9px
    `
  }
};

// RESPONSIVE LAYOUT
// --------------------------
const responsiveMinSizes = {
  phone: "0px",
  tablet: "600px",
  laptop: "900px", // a.k.a tabletLandscape
  tabletLandscape: "900px",
  desktop: "1280px",
  jumbo: "1800px"
};

export const responsiveMaxSizes = {
  phone: "599px",
  tablet: "899px",
  laptop: "1279px", // a.k.a tabletLandscape
  tabletLandscape: "1279px",
  desktop: "1799px",
  jumbo: ""
};

export const responsiveTargets = {
  phone: `(max-width: ${responsiveMaxSizes.phone})`,
  tablet: `(min-width: ${responsiveMinSizes.tablet})`,
  laptop: `(min-width: ${responsiveMinSizes.laptop})`,
  tabletLandscape: `(min-width: ${responsiveMinSizes.tabletLandscape})`,
  desktop: `(min-width: ${responsiveMinSizes.desktop})`,
  jumbo: `(min-width: ${responsiveMinSizes.jumbo})`
};

export const responsiveRanges = {
  phone: `(max-width: ${responsiveMaxSizes.phone})`,
  tablet: `(min-width: ${responsiveMinSizes.tablet}) and (max-width: ${responsiveMaxSizes.tablet})`,
  tabletAndAbove: `(min-width: ${responsiveMinSizes.tablet})`,
  phoneAndTablet: `(min-width: ${responsiveMinSizes.phone}) and (max-width: ${responsiveMaxSizes.tablet})`,
  laptop: `(min-width: ${responsiveMinSizes.laptop}) and (max-width: ${responsiveMaxSizes.laptop})`,
  tabletLandscape: `(min-width: ${responsiveMinSizes.tabletLandscape}) and (max-width: ${responsiveMaxSizes.tabletLandscape})`,
  desktop: `(min-width: ${responsiveMinSizes.desktop}) and (max-width: ${responsiveMaxSizes.desktop})`,
  jumbo: `(min-width: ${responsiveMinSizes.jumbo})`,
  laptopAndAbove: `(min-width: ${responsiveMinSizes.laptop})`
};

interface IThemeTypographyResponsiveTextStyles {
  heading: string;
  subheading: string;
  body: string;
  label: string;
  hint: string;
}
export const responsiveTextStyles: IThemeTypographyResponsiveTextStyles = {
  heading: ``,
  subheading: ``,
  body: `
    @media ${responsiveTargets.phone} {
      font-size: ${typography.sizes.copy.body.phone};
      line-height: ${typography.leading.responsive.copy.phone};
    }
    @media ${responsiveTargets.tablet} {
      font-size: ${typography.sizes.copy.body.tablet};
      line-height: ${typography.leading.responsive.copy.tablet};
    }
    @media ${responsiveTargets.laptop} {
      font-size: ${typography.sizes.copy.body.laptop};
      line-height: ${typography.leading.responsive.copy.laptop};
    }
    @media ${responsiveTargets.desktop} {
      font-size: ${typography.sizes.copy.body.desktop};
      line-height: ${typography.leading.responsive.copy.desktop};
    }
    @media ${responsiveTargets.jumbo} {
      font-size: ${typography.sizes.copy.body.jumbo};
      line-height: ${typography.leading.responsive.copy.jumbo};
    }
  `,
  label: ``,
  hint: ``
};

export const animationCurves = {
  easeOutBack: "cubic-bezier(0.175, 0.885, 0.275, 1.075)",
  easeOutQuart: "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
  easeInBack: "cubic-bezier(0.545, -0.075, 0.735, 0.045)",
  easeInQuart: "cubic-bezier(0.895, 0.030, 0.685, 0.220)"
};
