import gql from "graphql-tag";

export const SET_ANSWER_SINGLE = gql`
  mutation SetAnswerSingle($id: ID!, $value: String!) {
    SetUserSurveyAnswer(questionId: $id, value: $value) {
      __typename
      questionId
      value
    }
  }
`;

export const SET_ANSWER_MULTI = gql`
  mutation SetAnswerMulti($id: ID!, $value: [String]!) {
    SetUserSurveyAnswerMultiValue(questionId: $id, value: $value) {
      __typename
      questionId
      multiValue
    }
  }
`;

export const SET_COMPLETE_SURVEY = gql`
  mutation SetComplete {
    SetUserSurveyComplete {
      status
      invalidAnswers {
        questionId
        reason
      }
      completeStatus
    }
  }
`;
