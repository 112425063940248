import styled, { keyframes } from "styled-components";
import Icon from "./shared/typography/Icon";

const RotateKeyframes = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const LoadingContainer = styled.div`
  background: #ffffff;
  bottom: 0;
  font-size: 40px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  i {
    animation: ${RotateKeyframes} 1s linear infinite;
    height: 40px;
    left: calc(50% - 20px);
    margin: auto;
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
  }
`;

export default function Loading(props) {
  return (
    <LoadingContainer {...props}>
      <Icon name="spinner" />
    </LoadingContainer>
  );
}
