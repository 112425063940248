import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";
import {
  getNumberOfCompletedSections,
  getTotalNumberOfSections,
  setNumberOfCompletedSections,
  setTotalNumberOfSections
} from "../lateral.slice";
import { getECAPercentComplete } from "../../eca/eca.utils";
import { DEFAULT_FILL } from "./LateralProgressBar.constants";
import { FixedFooter } from "./LateralProgressBar.style";
import { ProgressBar } from "suited/components/shared/feedback/ProgressBar/ProgressBar";
import { useGetCandidateAssessmentById, useGetCandidateAssessments, useGetNextAssessmentSectionInformation } from "../../eca/eca.hooks";
import { AssessmentIds } from "../lateral.constants";

export const LateralProgressBar = () => {
  const dispatch = useAppDispatch();
  const previousNumberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);
  const { assessments } = useGetCandidateAssessments();
  const assessmentID =
    assessments.find((assessment) => assessment.id === AssessmentIds.LATERAL_LAW)?.id || "";
  const { sections } = useGetCandidateAssessmentById(assessmentID);

  const { id } = useGetNextAssessmentSectionInformation({ fetchPolicy: "network-only" }, assessmentID);

  useEffect(() => {
    // Used for cases when the page is refreshed and we need to "rehydrate"
    // the ECA progress bar's state
    const numberOfCompletedSections = sections?.findIndex((section) => section.id === id) || 0;

    // Prevent "jump" in progress bar when race condition occurs. Prevents number of completed from jumping for example, from 2 back to 1, then back to 2 again.
    if (numberOfCompletedSections > previousNumberOfCompletedSections) {
      dispatch(setNumberOfCompletedSections(numberOfCompletedSections));
    }

    if (sections?.length) {
      dispatch(setTotalNumberOfSections(sections?.length));
    }
  }, [dispatch, sections, id, previousNumberOfCompletedSections]);

  const numberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);
  const totalNumberOfSections = useAppSelector(getTotalNumberOfSections);

  const ecaProgressPercentage = getECAPercentComplete(
    numberOfCompletedSections,
    totalNumberOfSections
  );

  const progress = ecaProgressPercentage === 0 ? DEFAULT_FILL : ecaProgressPercentage;
  const displayText = ecaProgressPercentage === 0 ? 0 : progress;

  return (
    <FixedFooter>
      <ProgressBar progress={progress} displayText={displayText} />
    </FixedFooter>
  );
};
