import React, { useState } from "react";
import { AnimationA, AnimationB } from "./SuitedABTransitionManager.style";
import { CSSTransition } from "react-transition-group";

interface ISuitedABTransitionManagerProps {
  timeout: number;
  showA: boolean;
  renderA: React.ReactChild | null;
  renderB: React.ReactChild | null;
  className?: string;
  appear?: boolean;
  unmount?: boolean;
}

const SuitedABTransitionManager: React.FunctionComponent<ISuitedABTransitionManagerProps> = (
  props
) => {
  const { showA, appear = true, unmount = true } = props;
  // track which state is currently mounted so they never overlap in layout.
  // we use the "transform-relayout-transform" principle of hardware-optimized DOM animation, a.k.a "slide-pop-slide".
  const [aIn, setAIn] = useState<boolean>(showA);
  const [bIn, setBIn] = useState<boolean>(!showA);

  return (
    <React.Fragment>
      <CSSTransition
        appear={appear}
        mountOnEnter={true}
        unmountOnExit={unmount}
        in={showA && !bIn}
        timeout={props.timeout}
        onEnter={() => setAIn(true)}
        onExited={() => setAIn(false)}
        className={props.className}>
        <AnimationA timeout={props.timeout}>{props.renderA}</AnimationA>
      </CSSTransition>
      <CSSTransition
        appear={appear}
        mountOnEnter={true}
        unmountOnExit={unmount}
        in={!showA && !aIn}
        timeout={props.timeout}
        onEnter={() => setBIn(true)}
        onExited={() => setBIn(false)}
        className={props.className}>
        <AnimationB timeout={props.timeout}>{props.renderB}</AnimationB>
      </CSSTransition>
    </React.Fragment>
  );
};

export default SuitedABTransitionManager;
