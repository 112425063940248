// @ts-nocheck
import React from "react";
import Select from "react-select";

import { InputProps } from "./index";
import { FormDropdownContainer } from "../../Forms";

interface DropdownOption {
  value: string;
  label: string;
}

interface DropdownProps extends InputProps {
  value: string;
  options: DropdownOption[];
  onChange(value: string): void;
  disabled?: boolean;
}

class Dropdown extends React.Component<DropdownProps> {
  select: HTMLElement | null = null;

  componentDidUpdate(prevProps) {
    if (!prevProps.active && this.props.active) {
      if (this.select) this.select.focus();
    }
  }

  onRef = (el) => {
    this.select = el;
  };

  _onChange = (option) => {
    if (option) {
      this.props.onChange(option.value);
    } else {
      this.props.onChange("");
    }
  };

  render() {
    const { name, value, options, disabled = false } = this.props;
    return (
      <FormDropdownContainer completed={true} className="row box col-xs-12">
        <Select
          ref={this.onRef}
          name={name}
          value={value}
          options={options}
          onChange={this._onChange}
          disabled={disabled}
          className="col-xs-12"
        />
      </FormDropdownContainer>
    );
  }
}

export default Dropdown;
