import styled from "styled-components";

import { sizes, typography } from "../../constants/style-constants";
import ButtonRowCentered from "../shared/layout/ButtonRowCentered";

export const PasswordResetFormWrapper = styled.form``;

export const PasswordResetButtonRowCentered = styled(ButtonRowCentered)`
  margin-top: ${sizes.gridUnits.times3};
`;

export const InstructionsCopy = styled.p`
  color: ${typography.color.light.base};
  margin-bottom: 1.75rem;
`;
