import { useMutation, useQuery } from "@apollo/client";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { Box, Flex, Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { InputErrorMessage } from "suited/components/shared/inputs/InputErrorMessage/InputErrorMessage";
import { timeouts } from "suited/constants/interaction-constants";

import { UPDATE_USER_PHONE_NUMBER } from "../userAdditionalInfo/UserAdditionaInfoOverlay.queries";
import { PhoneInputGlobalStyles } from "../userAdditionalInfo/UserAdditionalInfoOverlay.styles";
import { GET_USER_SETTINGS } from "./GraphQL/queries";
import { EditableFormState } from "./userSettings.constants";
import { getLocaleFromIso2Code } from "./userSettings.utils";

export const PhoneNumberForm = ({ setPhoneNumberFormState, formattedPhoneNumber }) => {
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const phoneInputRef = useRef(null);

  const { data } = useQuery(GET_USER_SETTINGS);
  const [
    updateUserPhoneNumber,
    { loading: submittingUserPhoneNumber, error: submitUserPhoneNumberError }
  ] = useMutation(UPDATE_USER_PHONE_NUMBER);

  const user = data?.GetUser;

  const {
    handleSubmit,
    setValue,
    formState: { errors, isDirty }
  } = useForm({
    mode: "all",
    defaultValues: {
      phoneNumber: formattedPhoneNumber
    }
  });

  const formHasErrors = Object.keys(errors).length > 0;
  const isLoading = submittingUserPhoneNumber;
  const serverErrors = submitUserPhoneNumberError;

  const isDisabled =
    isLoading || !isDirty || formHasErrors || serverErrors || (phoneNumber && !isPhoneNumberValid);
  const onSubmit = async (values: { phoneNumber: string }) => {
    // @ts-ignore
    const iso2Code = phoneInputRef?.current?.getInstance()?.selectedCountryData?.iso2;

    const locale = getLocaleFromIso2Code(iso2Code);

    await updateUserPhoneNumber({
      variables: { phoneNumber: values.phoneNumber, locale },
      refetchQueries: [{ query: GET_USER_SETTINGS }]
    });
  };

  // NOTE: If we have an existing phone number, set editable mode.
  // If we haven't set a phone number yet, show add phone number view.
  const formStateToSet = user?.phoneNumber ? EditableFormState.READY : EditableFormState.MISSING;

  return (
    <Stack space="sm" data-test="phone-number-change-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack space="sm">
          <Box>
            <Stack space="none">
              <PhoneInputGlobalStyles />
              <IntlTelInput
                ref={phoneInputRef}
                initialValue={formattedPhoneNumber}
                inputProps={{
                  className: "intl-tel-input-custom-styles"
                }}
                onChangeNumber={(value) => {
                  setPhoneNumber(value);
                  setValue("phoneNumber", value, { shouldDirty: true });
                }}
                onChangeValidity={(isValid) => {
                  setIsPhoneNumberValid(isValid);
                }}
                initOptions={{
                  initialCountry: "us"
                }}
              />
              <InputErrorMessage
                message={
                  phoneNumber && !isPhoneNumberValid ? "Please enter a valid phone number" : ""
                }
              />
            </Stack>
          </Box>
          <Flex justify="flex-end">
            <Box>
              <SuitedButton
                purpose="default"
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                onClick={() => setPhoneNumberFormState(formStateToSet)}
                style={{ marginRight: "1rem" }}
              >
                Cancel
              </SuitedButton>
            </Box>
            <Box>
              <SuitedButton
                alignRight
                formButton
                purpose="primary"
                type="submit"
                disabled={!!isDisabled}
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
              >
                Save
              </SuitedButton>
            </Box>
          </Flex>
        </Stack>
      </form>
    </Stack>
  );
};
