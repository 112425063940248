import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

import { Box, Stack } from "@suited/components";

import { SuitedAltCopy } from "suited/components/shared/typography/SuitedAltCopy";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import SuitedListItemLabelValuePair from "suited/components/shared/typography/SuitedTileLabelValue";
import { GET_PENDING_INVITES } from "suited/components/shared/user/Header/GraphQL/queries";

import { AssociationHelperText } from "../../Associations/Association.style";
import { UPDATE_CANDIDATE_INVITATION } from "../../GraphQL/mutations";
import {
  GET_CANDIDATE_INVITATIONS,
  GET_COMPANY_CANDIDATE_ASSOCIATIONS
} from "../../GraphQL/queries";
import {
  ListContainer,
  ListEl,
  ListHeader,
  StyledSuitedAltLink,
  StyledSuitedCompactButton,
  StyledSuitedListItemPrimaryValue
} from "../../shared/InviteStates.style";
import { ListItem } from "../../shared/ListItem";
import { TCompanyInvite, TCompanyInviteMutation } from "../../types";
import dateFormat from "../../utils/dateFormat";

interface Props {
  pendingInvites: TCompanyInvite[];
  loading: boolean;
}

export const Pending = (props: Props) => {
  const { pendingInvites, loading } = props;

  return (
    <ListEl>
      <Stack>
        <ListHeader>
          <Stack space="xxxs">
            <SuitedCopySubheadline noMargin>
              {loading ? <Skeleton width="150px" /> : <span>Pending</span>}
            </SuitedCopySubheadline>
            <AssociationHelperText>
              These are invitations you've received but haven't acted on. Connect to share results
              or ignore to hide. Ignored invites can be connected later.
            </AssociationHelperText>
          </Stack>
        </ListHeader>
        <Box style={{ marginTop: "2rem" }}>
          {pendingInvites?.length > 0 ? (
            <PendingList pendingList={pendingInvites} />
          ) : (
            <SuitedAltCopy>
              {loading ? (
                <Skeleton width="560px" height="56px" />
              ) : (
                <span>You have no active invitations.</span>
              )}
            </SuitedAltCopy>
          )}
        </Box>
      </Stack>
    </ListEl>
  );
};

interface IPendingListProps {
  pendingList: TCompanyInvite[];
}

const PendingList = (props: IPendingListProps) => {
  const { pendingList } = props;
  const [loadingItems, setLoadingItems] = useState<string[]>([]);

  // Ignoring a pending invite only needs to refetch the invitations view.
  const [updatePendingItemIgnored, { loading: loadingIgnoreInvitation }] = useMutation(
    UPDATE_CANDIDATE_INVITATION,
    {
      refetchQueries: () => [
        { query: GET_CANDIDATE_INVITATIONS },
        { query: GET_PENDING_INVITES, variables: { status: "PENDING" } }
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        const completedData: TCompanyInviteMutation = data.UpdateCandidateInvitation;
        const filteredItems = loadingItems.filter((item) => {
          return item !== completedData._id;
        });
        setLoadingItems(filteredItems);
      }
    }
  );
  // Accepting an invite needs to refetch both the invitations and associations view.
  const [updatePendingItemAccept, { loading: loadingAcceptInvitation }] = useMutation(
    UPDATE_CANDIDATE_INVITATION,
    {
      refetchQueries: () => [
        { query: GET_CANDIDATE_INVITATIONS },
        { query: GET_COMPANY_CANDIDATE_ASSOCIATIONS },
        { query: GET_PENDING_INVITES, variables: { status: "PENDING" } }
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        const completedData: TCompanyInviteMutation = data.UpdateCandidateInvitation;
        const filteredItems = loadingItems.filter((item) => {
          return item !== completedData._id;
        });
        setLoadingItems(filteredItems);
      }
    }
  );

  const handleConnectClick = (id: string) => {
    setLoadingItems([...loadingItems, id]);
    updatePendingItemAccept({ variables: { id, status: "ACCEPTED" } });
  };

  const handleIgnoreClick = (id: string) => {
    setLoadingItems([...loadingItems, id]);
    updatePendingItemIgnored({ variables: { id, status: "IGNORED" } });
  };

  return (
    <ListContainer>
      {pendingList.map((item: TCompanyInvite) => {
        const dateReceived = new Date(item.createdAt);
        const formattedDate = dateFormat(dateReceived);
        return (
          <ListItem
            key={item._id}
            title={
              <StyledSuitedListItemPrimaryValue style={{ textOverflow: "ellipsis" }}>
                {item.companyName}
              </StyledSuitedListItemPrimaryValue>
            }
            detail={
              <React.Fragment>
                <SuitedListItemLabelValuePair label={"Received On"} value={formattedDate} />
                <StyledSuitedCompactButton
                  onClick={() => handleConnectClick(item._id)}
                  disabled={loadingItems.includes(item._id)}
                >
                  {loadingAcceptInvitation ? "Connecting..." : "Connect"}
                </StyledSuitedCompactButton>
                <StyledSuitedAltLink
                  onClick={() => handleIgnoreClick(item._id)}
                  disabled={loadingItems.includes(item._id)}
                >
                  {loadingIgnoreInvitation ? "ignoring..." : "ignore"}
                </StyledSuitedAltLink>
              </React.Fragment>
            }
          />
        );
      })}
    </ListContainer>
  );
};
