import * as React from "react";
import styled from "styled-components";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface ConfirmProps {
  disabled?: boolean;
  onClick(): void;
}

export default class Confirm extends React.Component<ConfirmProps> {
  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onClick();
    }
  };

  render() {
    return (
      <ConfirmContainer>
        <SuitedButton
          autofocus={true}
          onClick={this.props.onClick}
          disabled={this.props.disabled}
          purpose="primary"
        >
          Okay
        </SuitedButton>
      </ConfirmContainer>
    );
  }
}
