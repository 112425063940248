import styled from "styled-components";
import { typography, colors } from "../../constants/style-constants";

const ErrorMessage = styled.p`
  background-color: ${colors.status.error.base};
  border: 1px solid ${colors.status.error.base};
  border-radius: 4px;
  color: ${typography.color.light.base};
  width: 100%;
  padding: 5px;
  font-size: 14px;
  margin: 10px 0px;
  &:before {
    content: "\f071";
    font-family: "Font Awesome 5 Pro";
    color: ${typography.color.light.base};
    padding: 0px 10px 0px 5px;
  }
`;

export default ErrorMessage;
