import { IResume } from "./types";

export const initialState: IResume = {
  isUploading: false,
  uploaderId: "",
  uploadedFile: {
    id: "",
    filename: "",
    filesize: 0,
    createdAt: "",
    updatedAt: ""
  },
  error: ""
};
