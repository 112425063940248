import gql from "graphql-tag";

export const UPDATE_CANDIDATE_INVITATION = gql`
  mutation UpdateCandidateInvitation($id: String!, $status: String!) {
    UpdateCandidateInvitation(id: $id, status: $status) {
      _id
      status
    }
  }
`;

export const UPDATE_COMPANY_CANDIDATE_ASSOCIATION = gql`
  mutation UpdateCompanyCandidateAssociation($id: String!, $status: String!) {
    UpdateCompanyCandidateAssociation(id: $id, status: $status) {
      _id
      status
    }
  }
`;
