// @ts-nocheck
import { useState, useEffect, useMemo, useCallback } from "react";
import Select from "react-select";
import Async from "react-select/async";
import { useLazyQuery } from "@apollo/client";
import { useForm, Controller, useWatch } from "react-hook-form";
import _debounce from "lodash/debounce";
import get from "lodash/get";
import useDeepCompareEffect from "use-deep-compare-effect";

import { Stack, Box, Flex, HStack } from "@suited/components";
import type ISelectOption from "suited/components/shared/types/ISelectOption";
import {
  OPTIONS_DEGREE_LAW_CONTEXT,
  OPTIONS_DEGREE,
  OPTIONS_MAJOR,
  OPTIONS_GPA,
  OPTIONS_MONTHS,
  OPTIONS_YEARS,
  UNLISTED_SCHOOL_OPTION,
  UNDERGRAD_TOOLTIP,
  UNLISTED_SCHOOL_VALUE,
  GRADUATE_TOOLTIP
} from "../education.constants";
import {
  degreeIsGrad,
  degreeIsJurisDoctor,
  canSchoolBeDeleted,
  regexCheckNewSchoolID
} from "../education.utils";
import { DEFAULT_REQUIRED_ERROR_MESSAGE } from "suited/constants/validation.constants";
import { FormFieldError, SchoolEntry } from "../education.types";
import { QUERY_SCHOOL_NAME_LIST } from "../education.queries";
import { timeouts, debounce } from "suited/constants/interaction-constants";
import { useIndustryContext } from "suited/components/CandidateConfig/hooks/useIndustryContext";
import { EIndustryIds } from "suited/components/CandidateConfig";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { SuitedAltInputLabel } from "suited/components/shared/typography/SuitedAltInputLabel";
import StyledReactSelect from "suited/components/shared/inputs/select/common.style";
import IconTooltip from "suited/components/IconTooltip";
import { StyledTextInput } from "suited/components/shared/inputs/StyledTextInput/StyledTextInput";
import { InputErrorMessage } from "suited/components/shared/inputs/InputErrorMessage/InputErrorMessage";
import {
  CustomLoadingMessageComponent,
  CustomNoOptionsMessageComponent
} from "suited/components/GeneralSurvey/GeneralDropdown.style";

export const SchoolForm = ({
  id,
  schoolData,
  previouslySavedSchools,
  handleUpdateSchool,
  handleCancelAddSchool,
  handleDeleteSchool,
  isLastForm,
  isLoadingUpdateSchools,
  isLoadingUserEducation
}) => {
  const [industryContext] = useIndustryContext();
  const [isUnlistedSchoolInputVisible, setIsUnlistedSchoolInputVisible] = useState(false);

  const isSchoolOnServer = previouslySavedSchools.find((school: SchoolEntry) => school.id === id);
  const canDeleteSchool = canSchoolBeDeleted({
    id,
    previouslySavedSchools,
    industryContextId: industryContext.id
  });

  const {
    control,
    register,
    setValue,
    formState: { errors, isValid, isDirty }
  } = useForm({
    mode: "all",
    defaultValues: schoolData
  });

  const { school } = schoolData;
  const degree = schoolData?.degree?.value;
  const watchedValues = useWatch({ control });

  useDeepCompareEffect(() => {
    handleUpdateSchool({ id, isDirty, isValid, values: watchedValues });
  }, [watchedValues, isDirty, isValid, id]);

  useEffect(() => {
    if (school.value === UNLISTED_SCHOOL_VALUE) {
      setIsUnlistedSchoolInputVisible(true);
    } else {
      setIsUnlistedSchoolInputVisible(false);
      setValue("unlistedSchool", "");
    }
  }, [school, setValue, setIsUnlistedSchoolInputVisible]);

  // Need to redeclare custom form error type since react-hook-form
  // mistakenly throws Typescript error for dropdown values.
  const formErrors = errors as Record<string, FormFieldError>;

  const getDegreeOptions = () => {
    return industryContext.id === EIndustryIds.LAW ? OPTIONS_DEGREE_LAW_CONTEXT : OPTIONS_DEGREE;
  };

  const [fetchSchoolNamesList] = useLazyQuery(QUERY_SCHOOL_NAME_LIST);

  const debouncedFetchSchools = useMemo(
    () =>
      _debounce((school, callback) => {
        fetchSchoolNamesList({ variables: { school } }).then((data) => {
          const decoratedOptions: ISelectOption[] = [
            ...get(data, "data.GetSchoolOptions", []),
            {
              label: <span style={{ marginLeft: "0.65rem" }}>Can't find what you need?</span>,
              options: [UNLISTED_SCHOOL_OPTION]
            }
          ];

          callback(decoratedOptions);
        });
      }, debounce.typeAhead),
    [fetchSchoolNamesList]
  );

  const getSchools = useCallback(
    (school: string, callback: () => any) => {
      debouncedFetchSchools(school, callback);
    },
    [debouncedFetchSchools]
  );

  const isDeleteButtonDisabled = isLoadingUpdateSchools || isLoadingUserEducation;
  const buttonContainerStyles = isLastForm ? { margin: "1rem 0" } : { margin: "1rem 0 0 0" };

  return (
    <div data-testid={`school-form-${id}`} data-degree={degree}>
      <Stack space="lg">
        <Box>
          <Stack space="xxs">
            <SuitedAltInputLabel
              noMargin
              id="degree"
              htmlFor="degree"
              data-testid="suited-single-select-label"
            >
              Degree
            </SuitedAltInputLabel>
            <Controller
              name="degree"
              control={control}
              rules={{ required: DEFAULT_REQUIRED_ERROR_MESSAGE }}
              render={({ field: { ref, ...props } }) => {
                return (
                  <Select
                    aria-labelledby="degree"
                    inputRef={ref}
                    placeholder="Select a degree"
                    styles={StyledReactSelect}
                    options={getDegreeOptions()}
                    isClearable={false}
                    {...props}
                  />
                );
              }}
            />
            <InputErrorMessage message={formErrors?.degree?.message} />
          </Stack>
        </Box>
        <Box>
          <Stack space="xxs">
            <SuitedAltInputLabel
              noMargin
              id="school"
              htmlFor="school"
              data-testid="suited-single-select-label"
            >
              School
            </SuitedAltInputLabel>
            <Controller
              name="school"
              control={control}
              rules={{ required: DEFAULT_REQUIRED_ERROR_MESSAGE }}
              render={({ field: { ref, ...props } }) => {
                return (
                  <Async
                    innerRef={ref}
                    aria-labelledby="school"
                    styles={StyledReactSelect}
                    isClearable={false}
                    loadOptions={getSchools}
                    noOptionsMessage={CustomNoOptionsMessageComponent}
                    components={{
                      LoadingMessage: CustomLoadingMessageComponent
                    }}
                    placeholder={
                      degreeIsJurisDoctor(degree)
                        ? "Begin typing to select a law school."
                        : "Begin typing to select a school."
                    }
                    {...props}
                  />
                );
              }}
            />
            <InputErrorMessage message={formErrors?.school?.message} />
          </Stack>
        </Box>
        {isUnlistedSchoolInputVisible && (
          <Box>
            <Stack space="xxs">
              <SuitedAltInputLabel noMargin htmlFor="unlisted-school">
                Name of Unlisted School
              </SuitedAltInputLabel>
              <StyledTextInput
                id="unlisted-school"
                {...register("unlistedSchool", { required: DEFAULT_REQUIRED_ERROR_MESSAGE })}
                placeholder="Type in the name of your unlisted school"
                error={!!formErrors?.unlistedSchool?.message}
              />
              <InputErrorMessage message={formErrors?.unlistedSchool?.message} />
            </Stack>
          </Box>
        )}

        <Box>
          <Stack space="xxs">
            <HStack space="xs" align="center">
              <SuitedAltInputLabel
                noMargin
                id="gpa"
                htmlFor="gpa"
                data-testid="suited-single-select-label"
              >
                {degreeIsGrad(degree) ? "Graduate Degree GPA" : "Cumulative GPA"}
              </SuitedAltInputLabel>
              <IconTooltip text={degreeIsGrad(degree) ? GRADUATE_TOOLTIP : UNDERGRAD_TOOLTIP} />
            </HStack>
            <Controller
              name="gpa"
              control={control}
              rules={{ required: DEFAULT_REQUIRED_ERROR_MESSAGE }}
              render={({ field: { ref, ...props } }) => {
                return (
                  <Select
                    inputRef={ref}
                    aria-labelledby="gpa"
                    placeholder="Select a GPA"
                    styles={StyledReactSelect}
                    options={OPTIONS_GPA}
                    isClearable={false}
                    {...props}
                  />
                );
              }}
            />
            <InputErrorMessage message={formErrors?.gpa?.message} />
          </Stack>
        </Box>
        {!degreeIsJurisDoctor(degree) && (
          <Box>
            <Stack space="xxs">
              <HStack space="xs">
                <SuitedAltInputLabel
                  noMargin
                  id="major"
                  htmlFor="major"
                  data-testid="suited-single-select-label"
                >
                  Primary Major / Focus of Study
                </SuitedAltInputLabel>
                <IconTooltip text="If you majored in two subjects, please provide the one you feel is most relevant to your profile." />
              </HStack>
              <Controller
                name="major"
                control={control}
                rules={{ required: DEFAULT_REQUIRED_ERROR_MESSAGE }}
                render={({ field: { ref, ...props } }) => {
                  return (
                    <Select
                      innerRef={ref}
                      aria-labelledby="major"
                      placeholder="Select a major"
                      styles={StyledReactSelect}
                      options={OPTIONS_MAJOR}
                      isClearable={false}
                      {...props}
                    />
                  );
                }}
              />
              <InputErrorMessage message={formErrors?.major?.message} />
            </Stack>
          </Box>
        )}
        <HStack>
          <Box width="100%" minHeight="85px">
            <Stack space="xxs">
              <HStack space="xs">
                <SuitedAltInputLabel
                  noMargin
                  id="graduationMonth"
                  htmlFor="graduationMonth"
                  data-testid="suited-single-select-label"
                >
                  Graduation Month
                </SuitedAltInputLabel>

                <IconTooltip text="Month of Actual or Anticipated Graduation" />
              </HStack>
              <Controller
                name="graduationMonth"
                control={control}
                rules={{ required: DEFAULT_REQUIRED_ERROR_MESSAGE }}
                render={({ field: { ref, ...props } }) => {
                  return (
                    <Select
                      innerRef={ref}
                      aria-labelledby="graduationMonth"
                      placeholder="Month"
                      styles={StyledReactSelect}
                      options={OPTIONS_MONTHS}
                      isClearable={false}
                      {...props}
                    />
                  );
                }}
              />
              <InputErrorMessage message={formErrors?.graduationMonth?.message} />
            </Stack>
          </Box>
          <Box width="100%" minHeight="85px">
            <Stack space="xxs">
              <HStack space="xs">
                <SuitedAltInputLabel
                  noMargin
                  id="graduationYear"
                  htmlFor="graduationYear"
                  data-testid="suited-single-select-label"
                >
                  Graduation Year
                </SuitedAltInputLabel>
                <IconTooltip text="Year of Actual or Anticipated Graduation" />
              </HStack>
              <Controller
                name="graduationYear"
                control={control}
                rules={{ required: DEFAULT_REQUIRED_ERROR_MESSAGE }}
                render={({ field: { ref, ...props } }) => {
                  return (
                    <Select
                      innerRef={ref}
                      aria-labelledby="graduationYear"
                      placeholder="Year"
                      styles={StyledReactSelect}
                      options={OPTIONS_YEARS}
                      isClearable={false}
                      {...props}
                    />
                  );
                }}
              />
              <InputErrorMessage message={formErrors?.graduationYear?.message} />
            </Stack>
          </Box>
        </HStack>
        {isSchoolOnServer && canDeleteSchool && (
          <Flex justify="flex-end" style={buttonContainerStyles}>
            <SuitedButton
              data-degree={degree}
              disabled={isDeleteButtonDisabled}
              purpose="default"
              onClick={() => handleDeleteSchool(id)}
              delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
            >
              Delete
            </SuitedButton>
          </Flex>
        )}
        {regexCheckNewSchoolID.test(id) && (
          <Flex justify="flex-end" style={buttonContainerStyles}>
            <SuitedButton
              purpose="default"
              onClick={() => handleCancelAddSchool(id)}
              delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
            >
              Cancel
            </SuitedButton>
          </Flex>
        )}
      </Stack>
    </div>
  );
};
