import styled from "styled-components";

import { typography, sizes, colors, animationCurves } from "../../constants/style-constants";

interface SingleSelectContainerProps {
  completed: boolean;
}

export const SingleSelectContainer = styled.div<SingleSelectContainerProps>`
  border: 1px solid ${(props) => (props.completed ? "none" : colors.status.error.base)};
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
`;

interface SingleSelectOptionContainerProps {
  checked?: boolean;
  disabled?: boolean;
  onClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
}

export const SingleSelectOptionContainer = styled.div<SingleSelectOptionContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 1 auto;
  position: relative;
  box-sizing: border-box;
  padding: calc(${sizes.gridUnits.times1} - 1px) calc(${typography.margins.input} - 1px);
  margin-right: ${sizes.gridUnits.times2};
  color: ${(props) =>
    props.checked ? typography.color.light.emphasis : typography.color.dark.base};
  background: ${(props) => (props.checked ? colors.accent1.base : colors.background.light.primary)};
  border-radius: 0;
  border: 1px solid ${colors.shades.times1};
  box-shadow: ${(props) =>
    props.checked ? `inset 0px 0px 0px 2px ${colors.shades.times2}` : "none"};
  outline: 0;
  font-size: ${typography.sizes.form.base.input};
  font-family: ${typography.family.body};
  cursor: pointer;
  z-index: ${(props) => (props.checked ? 2 : 1)};
  transform: ${(props) => (props.checked ? "scale(1.025)" : "scale(1)")};
  transition: transform 300ms ${animationCurves.easeOutQuart},
    background-color 300ms ${animationCurves.easeOutQuart},
    border-color 300ms ${animationCurves.easeOutQuart},
    box-shadow 300ms ${animationCurves.easeOutQuart};
  &:hover,
  &:focus {
    transform: ${(props) => (props.checked ? "scale(1.1025)" : "scale(1.025)")};
    border: ${(props) =>
      props.checked ? `1px solid ${colors.shades.times3}` : `1px solid ${colors.shades.times2}`};
    box-shadow: ${(props) =>
      props.checked
        ? `inset 0px 0px 0px 2px ${colors.shades.times3}`
        : `inset 0px 0px 0px 2px ${colors.shades.times2}`};
    z-index: 2;
  }
  &:focus {
    outline: none;
  }
  &[disabled] {
    cursor: not-allowed;
    color: ${(props) =>
      props.checked ? typography.color.light.disabled : typography.color.dark.disabled};
    font-weight: 300;
    border: 2px dashed ${(props) => (props.checked ? colors.tints.times2 : colors.shades.times1)};
    background-color: ${(props) => (props.checked ? colors.accent1.tint1 : colors.tints.times2)};
    box-shadow: none;
    transform: scale(1);
    &:hover,
    &:focus {
      background-color: ${(props) => (props.checked ? colors.accent1.tint1 : colors.tints.times2)};
      transform: scale(1);
      border: 2px dashed ${(props) => (props.checked ? colors.tints.times2 : colors.shades.times1)};
      box-shadow: none;
    }
    label {
      cursor: not-allowed;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const Input = styled.input`
  display: none;
`;
