import styled from "styled-components";

import { sizes, colors } from "suited/constants/style-constants";

export const HeaderEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  height: ${sizes.gridUnits.times6};
`;

export const FillerBar = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: ${colors.background.dark.backdrop};
`;
