import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'react-hook-form';

import './FeatureFlagNav.css';
import { Box, HStack, Wrap } from '../..';
import { Dictionary } from '../../types';

interface Props {
  features: Dictionary<boolean>;
  className?: React.HTMLAttributes<HTMLDivElement>;
  onSubmit: (data: Dictionary<boolean>) => void;
}

export const FeatureFlagNav = ({ className, features, onSubmit }: Props) => {
  const classNames = clsx('ws-c-feature-flag-nav', className);

  const { register, handleSubmit, setValue } = useForm<Dictionary<boolean>>({
    defaultValues: features,
  });

  return (
    <Box padding="md" className={classNames}>
      <Wrap>
        <FontAwesomeIcon size="lg" icon={faFlag} />
        {Object.keys(features).map((label) => {
          return (
            <HStack
              className="ws-c-feature-flag-nav__feature-flag"
              space="xxs"
              key={label}
            >
              <input
                id={label}
                type="checkbox"
                {...register(label, { value: features[label] })}
                onChange={(event) => {
                  setValue(label, event.target.checked);

                  handleSubmit(onSubmit)();
                }}
              />
              <label htmlFor={label}>{label}</label>
            </HStack>
          );
        })}
      </Wrap>
    </Box>
  );
};
