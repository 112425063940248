import { HttpResponse, graphql } from "msw";

import { uuidv4 } from "@suited/utils";

import { JURIS_DOCTOR, UNLISTED_SCHOOL_VALUE } from "./education.constants";
import { db } from "./education.models";
import { SchoolEntry } from "./education.types";

export const getMockUser = () => {
  return db.GetUser.getAll()[0];
};

export const getPreviouslySavedSchoolData = () => {
  return db.School.getAll()[0];
};

export const educationMockHandlers = [
  graphql.query("GetUser", () => {
    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  }),

  graphql.query("GetSchoolNameList", () => {
    return HttpResponse.json({
      data: {
        GetSchoolOptions: [
          {
            label: "Arizona State University",
            value: "Arizona State University",
            __typename: "SchoolOptions"
          }
        ]
      }
    });
  }),

  graphql.mutation("UpdateUserSchoolsArray", ({ variables }) => {
    const { schools } = variables;

    const schoolsInDB = db.School.getAll();
    const schoolIDsInDB = schoolsInDB.map((school) => school.id);
    const schoolIDsInUpdate = schools.map((school) => school.id);

    // Delete school flow
    const deleteSchools = schoolsInDB.filter((school) => !schoolIDsInUpdate.includes(school.id));
    deleteSchools.forEach((school) => {
      db.School.delete({
        where: {
          id: {
            equals: school?.id
          }
        }
      });
    });

    // Add school flow
    const addSchools = schools.filter((school) => !schoolIDsInDB.includes(school.id));
    addSchools.forEach((newSchool: SchoolEntry) => {
      db.School.create({
        ...newSchool,
        major: newSchool.degree === JURIS_DOCTOR ? null : newSchool.major,
        schoolObj: db.SchoolObj.create({
          name: newSchool.school || "",
          status: newSchool.school === "test school" ? "PENDING" : "APPROVED"
        }),
        id: uuidv4()
      });
    });

    // Update school flow
    const updateSchools = schools.filter((school) => schoolIDsInDB.includes(school.id));
    updateSchools.forEach((school) => {
      db.School.update({
        where: {
          id: {
            equals: school.id
          }
        },
        // @ts-ignore
        data: {
          ...school,
          //          id: uuidv4(),
          major: school.degree === JURIS_DOCTOR ? null : school.major,
          schoolObj(prevData) {
            prevData.name = school.school;
            prevData.status = school.school === "test school" ? "PENDING" : "APPROVED";

            return prevData;
          }
        }
      });
    });

    const schoolsToUpdate = db.School.getAll();

    const { userProfile } = getMockUser();
    db.UserProfileData.update({
      where: {
        id: {
          equals: userProfile?.id
        }
      },
      data: {
        schools(prevData) {
          prevData = schoolsToUpdate;
          return prevData;
        }
      }
    });

    return HttpResponse.json({
      data: {
        UpdateUserSchoolsArray: db.School.getAll()
      }
    });
  })
];

export const EDUCATION_MOCK_HANDLER_EXCEPTIONS = {
  SCHOOL_NOT_LISTED: graphql.query("GetUser", () => {
    const user = getMockUser();
    const schoolId = user?.userProfile?.schools[0]?.id;

    db.School.update({
      where: {
        id: {
          equals: schoolId
        }
      },
      data: {
        school(prevData) {
          prevData = UNLISTED_SCHOOL_VALUE;

          return prevData;
        },
        schoolObj(prevData) {
          prevData.name = "Test School";
          prevData.status = "PENDING";

          return prevData;
        }
      }
    });

    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  }),

  LAW_DEGREE_ONLY: graphql.query("GetUser", () => {
    const user = getMockUser();
    const schoolId = user?.userProfile?.schools[0]?.id;

    db.School.update({
      where: {
        id: {
          equals: schoolId
        }
      },
      data: {
        degree(prevData) {
          prevData = "Juris Doctor (JD)";

          return prevData;
        }
      }
    });

    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  }),

  UNDERGRAD_AND_GRAD_DEGREE: graphql.query("GetUser", () => {
    db.School.create({
      degree: "Juris Doctor (JD)",
      gpa: "4.0",
      school: "University of Pennsylvania",
      schoolObj: db.SchoolObj.create({ name: "University of Pennsylvania" })
    });

    const { userProfile } = getMockUser();
    db.UserProfileData.update({
      where: {
        id: {
          equals: userProfile?.id
        }
      },
      data: {
        schools(prevData) {
          prevData = db.School.getAll();
          return prevData;
        }
      }
    });

    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  }),

  NO_DEGREES: graphql.query("GetUser", () => {
    const schools = db.School.getAll();

    schools.forEach((school) => {
      db.School.delete({
        where: {
          id: {
            equals: school.id
          }
        }
      });
    });

    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  }),

  MORE_THAN_ONE_UNDERGRAD_DEGREE_SAVED: graphql.query("GetUser", () => {
    db.School.create({
      degree: "Associates",
      gpa: "4.0",
      school: "University of Pennsylvania",
      schoolObj: db.SchoolObj.create({ name: "University of Pennsylvania" })
    });

    const schoolsToUpdate = db.School.getAll();

    const { userProfile } = getMockUser();
    db.UserProfileData.update({
      where: {
        id: {
          equals: userProfile?.id
        }
      },
      data: {
        schools(prevData) {
          prevData = schoolsToUpdate;
          return prevData;
        }
      }
    });

    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  }),

  MULTIPLE_UNDERGRAD_AND_ONE_JD: graphql.query("GetUser", () => {
    const schools = db.School.getAll();

    schools.forEach((school) => {
      db.School.delete({
        where: {
          id: {
            equals: school?.id
          }
        }
      });
    });

    db.School.create({
      degree: "Bachelors",
      gpa: "3.5",
      school: "Rutgers",
      major: "Chemical Engineering",
      graduationDate: "2022-09-13T23:11:50-07:00",
      schoolObj: db.SchoolObj.create({ name: "Rutgers" })
    });

    db.School.create({
      degree: "Bachelors",
      gpa: "3.5",
      school: "University of Pennsylvania",
      major: "Finance",
      graduationDate: "2022-09-13T23:11:50-07:00",
      schoolObj: db.SchoolObj.create({ name: "Rutgers" })
    });

    db.School.create({
      degree: "Juris Doctor (JD)",
      gpa: "4.0",
      school: "University of Pennsylvania",
      schoolObj: db.SchoolObj.create({ name: "University of Pennsylvania" })
    });

    const { userProfile } = getMockUser();
    db.UserProfileData.update({
      where: {
        id: {
          equals: userProfile?.id
        }
      },
      data: {
        schools(prevData) {
          prevData = db.School.getAll();
          return prevData;
        }
      }
    });

    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  }),

  ONE_UNDERGRAD_AND_JD: graphql.query("GetUser", () => {
    db.School.create({
      degree: "Juris Doctor (JD)",
      gpa: "4.0",
      school: "University of Pennsylvania",
      schoolObj: db.SchoolObj.create({ name: "University of Pennsylvania" })
    });

    const { userProfile } = getMockUser();
    db.UserProfileData.update({
      where: {
        id: {
          equals: userProfile?.id
        }
      },
      data: {
        schools(prevData) {
          prevData = db.School.getAll();
          return prevData;
        }
      }
    });

    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  }),

  MULTIPLE_JD_DEGREES: graphql.query("GetUser", () => {
    const schools = db.School.getAll();

    schools.forEach((school) => {
      db.School.delete({
        where: {
          id: {
            equals: school?.id
          }
        }
      });
    });

    db.School.create({
      degree: "Bachelors",
      gpa: "3.5",
      school: "Rutgers",
      major: "Chemical Engineering",
      graduationDate: "2022-09-13T23:11:50-07:00",
      schoolObj: db.SchoolObj.create({ name: "Rutgers" })
    });

    db.School.create({
      degree: "Juris Doctor (JD)",
      gpa: "4.0",
      school: "University of Pennsylvania",
      schoolObj: db.SchoolObj.create({ name: "University of Pennsylvania" })
    });

    db.School.create({
      degree: "Juris Doctor (JD)",
      gpa: "4.0",
      school: "Arizona State University",
      schoolObj: db.SchoolObj.create({ name: "Arizona State University" })
    });

    const { userProfile } = getMockUser();
    db.UserProfileData.update({
      where: {
        id: {
          equals: userProfile?.id
        }
      },
      data: {
        schools(prevData) {
          prevData = db.School.getAll();
          return prevData;
        }
      }
    });

    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  }),

  UNDERGRAD_GRAD_AND_JD: graphql.query("GetUser", () => {
    const schools = db.School.getAll();

    schools.forEach((school) => {
      db.School.delete({
        where: {
          id: {
            equals: school?.id
          }
        }
      });
    });

    db.School.create({
      degree: "Bachelors",
      gpa: "3.5",
      school: "Rutgers",
      major: "Chemical Engineering",
      graduationDate: "2022-09-13T23:11:50-07:00",
      schoolObj: db.SchoolObj.create({ name: "Rutgers" })
    });

    db.School.create({
      degree: "Juris Doctor (JD)",
      gpa: "4.0",
      school: "University of Pennsylvania",
      schoolObj: db.SchoolObj.create({ name: "University of Pennsylvania" })
    });

    db.School.create({
      degree: "Masters",
      gpa: "4.0",
      school: "Arizona State University",
      schoolObj: db.SchoolObj.create({ name: "Arizona State University" })
    });

    const { userProfile } = getMockUser();
    db.UserProfileData.update({
      where: {
        id: {
          equals: userProfile?.id
        }
      },
      data: {
        schools(prevData) {
          prevData = db.School.getAll();
          return prevData;
        }
      }
    });

    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  })
};
