import React, { useState, useEffect, useRef } from "react";
import _get from "lodash/get";
import Skeleton from "react-loading-skeleton";

import { Box, Center, Flex, Stack } from "@suited/components";
import { useAppDispatch } from "suited/util/hooks/reduxHooks";
import { showEmailChangeModal } from "../userSettings.slice";
import { Email } from "./UserEmailChangeForm.style";
import { UserEmailChangeModal } from "../UserEmailChangeModal/UserEmailChangeModal";
import SuitedFormInstructions from "../../../shared/typography/SuitedFormInstructions";
import SuitedDialogConfirm from "../../../shared/layout/Dialogs/SuitedDialogConfirm";
import SuitedBasicConfirmFooter from "../../../shared/layout/Dialogs/SuitedBasicConfirmFooter";
import { useMutation } from "@apollo/client";
import { CANCEL_CHANGE_EMAIL } from "../GraphQL/mutations";
import { GET_USER_SETTINGS } from "../GraphQL/queries";
import { pollingIntervals, timeouts } from "../../../../constants/interaction-constants";
import SuitedCopyCaption from "../../../shared/typography/SuitedCopyCaption";
import SuitedLink from "../../../shared/buttons/SuitedLink";
import { UserEmailChangeDisplay } from "./UserEmailChangeDisplay";

interface Props {
  user: any;
  refetch: () => void;
  userLoading: boolean;
}

enum Status {
  SENT = "SENT",
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED"
}

const UserEmailDisplay = (props: Props) => {
  const { user, refetch, userLoading } = props;
  const dispatch = useAppDispatch();

  const intervalRef = useRef(0);
  const pollingDuration = useRef(0);

  const [cancelChangeEmail] = useMutation(CANCEL_CHANGE_EMAIL, {
    refetchQueries: () => [{ query: GET_USER_SETTINGS }]
  });

  const [showCancelEmailChangeDialog, setShowCancelEmailChangeDialog] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(
    _get(user, "settings.changeEmail.emailStatus", null)
  );

  const isChangeWindowOpen = _get(user, "settings.changeEmail.isChangeWindowOpen", null);

  useEffect(() => {
    setStatus(_get(user, "settings.changeEmail.emailStatus", null));
  }, [user]);

  // @TODO - get this to pass linter rules for hooks dependencies
  useEffect(() => {
    clearInterval(intervalRef.current);
    if (status === Status.SENT && isChangeWindowOpen) {
      const interval = window.setInterval(() => {
        pollingDuration.current = pollingDuration.current + pollingIntervals.EMAIL_CHANGE_STATUS;
        intervalRef.current = interval;
        refetch();

        if (pollingDuration.current >= timeouts.EMAIL_CHANGE_POLLING_MAX_DURATION) {
          clearInterval(intervalRef.current);
        }
      }, pollingIntervals.EMAIL_CHANGE_STATUS);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [status]); // eslint-disable-line

  const handleClickChangeEmail = () => dispatch(showEmailChangeModal(true));
  const handleClickCancelEmailChange = () => setShowCancelEmailChangeDialog(true);

  const handleConfirmCancelEmailChange = () => {
    cancelChangeEmail();
    setShowCancelEmailChangeDialog(false);
  };

  return (
    <Stack data-test="component-user-email-form">
      <React.Fragment>
        {(() => {
          if (status === Status.SENT && isChangeWindowOpen)
            return (
              <Stack space="none">
                <Flex justify="flex-start">
                  <Center>
                    <UserEmailChangeDisplay user={user} />
                  </Center>
                </Flex>

                <Stack>
                  <SuitedCopyCaption noMargin>
                    Your email change is in progress.
                    <br />
                  </SuitedCopyCaption>
                  <SuitedCopyCaption noMargin>
                    Next step: Click the change request confirmation link at the email{" "}
                    {_get(user, "settings.changeEmail.newEmail", "target email").toLowerCase()}.
                  </SuitedCopyCaption>
                </Stack>
                <Box>
                  <SuitedLink onClick={handleClickCancelEmailChange}>
                    cancel email change
                  </SuitedLink>
                </Box>
              </Stack>
            );
          if (status === Status.SENT && !isChangeWindowOpen)
            return (
              <Stack space="none">
                <Flex justify="flex-start">
                  <Center>
                    <UserEmailChangeDisplay user={user} />
                  </Center>
                </Flex>
                <Stack>
                  <SuitedCopyCaption noMargin>
                    Your email change in progress has timed out.
                  </SuitedCopyCaption>
                  <Box>
                    <SuitedLink onClick={() => cancelChangeEmail()}>click to dismiss</SuitedLink>
                  </Box>
                </Stack>
              </Stack>
            );
          return (
            <Stack space="none">
              <Email noMargin>{userLoading ? <Skeleton /> : <span>{user?.email}</span>}</Email>
              <Box>
                {userLoading ? (
                  <SuitedLink style={{ backgroundImage: "none" }} onClick={handleClickChangeEmail}>
                    <Skeleton width="10ch" />
                  </SuitedLink>
                ) : (
                  <SuitedLink onClick={handleClickChangeEmail}>change email</SuitedLink>
                )}
              </Box>
            </Stack>
          );
        })()}
        <UserEmailChangeModal />
        <SuitedDialogConfirm
          show={showCancelEmailChangeDialog}
          title="Cancel Email Change"
          message={
            <SuitedFormInstructions>
              Are you sure you want to cancel the email change process? This will disable any
              pending links in emails you received.
            </SuitedFormInstructions>
          }
          onCancel={() => setShowCancelEmailChangeDialog(false)}
          renderFooter={
            <SuitedBasicConfirmFooter
              cancelText="No"
              confirmText="Yes"
              onCancel={() => setShowCancelEmailChangeDialog(false)}
              onConfirm={handleConfirmCancelEmailChange}
            />
          }
        />
      </React.Fragment>
    </Stack>
  );
};

export default UserEmailDisplay;
