import { ApolloError, useApolloClient, useQuery } from "@apollo/client";
import { useState } from "react";
import { match } from "react-router-dom";

import ProfileCompleteProvider from "suited/components/CandidateHome/ProfileCompleteProvider";
import { deleteStoredCredentials } from "suited/util/authUtil";

import { ApolloClientInstance } from "../../types";
import GetUserQueryError from "../Alerts/GetUserQueryError";
import CandidateConfig, { TCandidateConfig, defaultConfig } from "../CandidateConfig";
import {
  defaultCandidateId,
  defaultIndustryContext
} from "../CandidateConfig/CandidateConfig.constants";
import CandidateHome from "../CandidateHome";
import { GET_USER_BOOTSTRAP } from "../CandidateHome/GraphQL/Queries";
import { TUserQueryData } from "../CandidateHome/GraphQL/types";

interface Props {
  match: match;
}

const AppBootstrap = (props: Props) => {
  const { match } = props;
  const [config, setConfig] = useState<TCandidateConfig>(defaultConfig);
  const client = useApolloClient();

  // Initialize the application w/ feature flags, showing app skeleton until response is loaded.
  const { error, data } = useQuery<TUserQueryData>(GET_USER_BOOTSTRAP, {
    onError: (error: ApolloError) => {
      if (error?.networkError) {
        deleteStoredCredentials();
      }
    },
    onCompleted: (data) => {
      setConfig({
        ...defaultConfig,
        candidateId: data?.GetUser.id || defaultCandidateId,
        industryContext: data?.GetUser.industryContext?.id
          ? data.GetUser.industryContext
          : defaultIndustryContext,
        hasSecondaryContactInfo: data?.GetUser?.hasSecondaryContactInfo
      });
    }
  });

  if (error?.networkError) {
    return <GetUserQueryError />;
  }

  return (
    <CandidateConfig config={config}>
      <ProfileCompleteProvider client={client as ApolloClientInstance}>
        <CandidateHome match={match} data={data} />
      </ProfileCompleteProvider>
    </CandidateConfig>
  );
};

export default AppBootstrap;
