import styled from "styled-components";

import { typography, sizes, animationCurves } from "suited/constants/style-constants";
import {
  TStyledSuitedInputStatusMessageProps,
  TStyledSuitedInputStatusMessageTextProps
} from "./SuitedInputStatusMessage.types";
import { getSuitedInputStatusMessageColor } from "./SuitedInputStatusMessage.utils";
import { SuitedAltIcon } from "suited/components/shared/typography/SuitedAltIcon";

export const StyledSuitedInputStatusMessage = styled.div<TStyledSuitedInputStatusMessageProps>`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  align-self: stretch;
  margin: 0 ${typography.margins.input};
  padding: 0;
  min-height: ${sizes.gridUnits.times3};
  overflow: hidden;
  list-style: none;
  color: ${(props) => getSuitedInputStatusMessageColor(props)};
`;

StyledSuitedInputStatusMessage.displayName = "StyledSuitedInputStatusMessage";

export const StyledSuitedInputStatusMessageText = styled.span`
  ${(props: TStyledSuitedInputStatusMessageTextProps) => `
    padding-top: ${sizes.gridUnits.times1};
    padding-right: ${sizes.gridUnits.times1};
    padding-bottom: ${sizes.gridUnits.divide2};
    padding-left: 0;
    color: inherit;
    font-family: ${typography.family.body};
    font-size: calc(${typography.sizes.form.base.hint} + 1px);
    font-weight: ${typography.weight.body.light || ""};
    font-style: italic;
    line-height: 1;
    letter-spacing: ${typography.tracking.slight};
    white-space: ${props.noEllipsis ? "normal" : "nowrap"};
    overflow: ${props.noEllipsis ? "visible" : "hidden"};
    text-overflow: ${props.noEllipsis ? "initial" : "ellipsis"};
    width: 100%;
    transform-origin: top center;
    &.enter {
      opacity: 0.01;
      transform: translateY(100%);
    }
    &.enter-active {
      opacity: 1;
      transform: translateY(0%);
      transition: opacity 200ms ${animationCurves.easeInQuart}, 
        transform 300ms ${animationCurves.easeOutQuart};
    }
    &.exit {
      opacity: 1;
      transform: translateY(-0%);
    }
    &.exit-active {
      opacity: 0.01;
      transform: translateY(-100%);
      transition: opacity 200ms 100ms ${animationCurves.easeOutQuart}, 
        transform 300ms ${animationCurves.easeInQuart};
    }
  `}
`;

StyledSuitedInputStatusMessageText.displayName = "StyledSuitedInputStatusMessageText";

export const StyledSuitedInputStatusMessageIcon = styled(SuitedAltIcon)`
  margin-right: ${sizes.gridUnits.divide2};
`;

StyledSuitedInputStatusMessageIcon.displayName = "StyledSuitedInputStatusMessageIcon";
