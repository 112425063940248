import { Box } from "@suited/components";

import { colors } from "suited/constants/style-constants";
import { CardSubheadline } from "./SuitedUploaderCardStates.style";

interface Props {
  message: string;
}

const Dragging = (props: Props) => {
  const { message, ...passthroughProps } = props;
  return (
    <Box
      padding="xxs"
      borderWidth="2px"
      borderRadius="4px"
      minWidth="50ch"
      maxWidth="65ch"
      height="240px"
      data-test="component-suited-uploader-card-dragging"
      borderColor={colors.shades.times1}
      bg={colors.tints.mid}
      {...passthroughProps}
    >
      <CardSubheadline data-test="message">{props.message}</CardSubheadline>
    </Box>
  );
};

export default Dragging;
