import { ISuitedAssessment, TSuitedAssessmentReducerAction } from "./types";

export const reducer = (state: ISuitedAssessment, action: TSuitedAssessmentReducerAction) => {
  switch (action.type) {
    case "currentQuestionIndex":
      return { ...state, currentQuestionIndex: action.index };
    case "transition":
      return { ...state, transition: action.transition };
    case "transitionWithIndexUpdate":
      return { ...state, transition: action.transition, currentQuestionIndex: action.index };
    case "error":
      return { ...state, errors: action.errors, currentQuestionIndex: action.index };
  }
};
