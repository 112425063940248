import styled from "styled-components";
import { SuitedButtonEl } from "suited/components/shared/buttons/SuitedButton";

import { WrapLabelValue } from "../../typography/SuitedTileLabelValue";
import SuitedDialogWindow from "./SuitedDialogWindow";
import { StyledPaper } from "./SuitedDialogWindow.style";
import { sizes, responsiveTargets } from "../../../../constants/style-constants";

export const CompactSuitedDialogWindow = styled(SuitedDialogWindow)`
  ${StyledPaper} {
    min-height: auto;
    @media ${responsiveTargets.phone} {
      max-height: calc(100vh - ${sizes.gridUnits.times2});
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 100%;
  z-index: 1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  z-index: 1;
  ${SuitedButtonEl} {
    margin: 0;
  }
`;

export const ButtonColumn = styled(Column)`
  flex-direction: column;
  ${SuitedButtonEl} {
    margin-bottom: ${sizes.gridUnits.times1};
  }
`;

export const End = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 auto;
  ${WrapLabelValue}:last-child {
    padding-right: 0;
  }
`;
