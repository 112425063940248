import styled from "styled-components";

import { sizes } from "suited/constants/style-constants";
import SuitedSplashPage from "suited/components/shared/layout/SuitedSplashPage";
import hero from "suited/img/splash/splash-02-unsupported.jpg";
import heroPlaceholder from "suited/img/splash/splash-02-unsupported-placeholder.jpg";
import logoSrc from "suited/img/logo.svg";
import SuitedSplashPaper from "suited/components/shared/layout/SuitedSplashPaper";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  padding: 0px;
  margin: 0px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: ${sizes.gridUnits.times4};
`;

const Logo = styled.img`
  width: 170px;
  height: 55px;
`;

/** Render this when the current browser is not supported. Implements SuitedSplashPage for layout. Content and image are hard-coded.
 * Props docgen is not yet supported.
 */
const UnsupportedBrowser = () => {
  return (
    <StyledSuitedSplashPage
      image={hero}
      placeholderImage={heroPlaceholder}
      imageAlt="Suited does not work in your browser"
      showLogo={false}
    >
      <SuitedSplashPaper>
        <Content>
          <LogoContainer>
            <Logo src={logoSrc} />
          </LogoContainer>
          <h2>So sorry.</h2>
          <h3>The Suited app does not work in your browser.</h3>
          <p>
            We recommend you visit us using a modern web browser. Suited works best in Chrome and
            FireFox.
          </p>
        </Content>
      </SuitedSplashPaper>
    </StyledSuitedSplashPage>
  );
};

const StyledSuitedSplashPage = styled(SuitedSplashPage)`
  align-self: stretch;
`;

export default UnsupportedBrowser;
