import styled from "styled-components";
import { SuitedValidatedTextInput } from "suited/components/shared/inputs/SuitedValidatedTextInput";

import { typography, sizes } from "../../constants/style-constants";
import SuitedInputErrorMessage from "../shared/typography/SuitedInputErrorMessage";

export const LoginFormWrapper = styled.form``;

export const ErrorMessage = styled(SuitedInputErrorMessage)`
  margin: ${sizes.gridUnits.times1} 0;
  line-height: ${typography.leading.fixed.multiLine};
  white-space: normal;
`;

export const RegisterLink = styled.div`
  text-align: center;
  margin-top: ${sizes.gridUnits.times4};
  margin-bottom: ${sizes.gridUnits.times2};
  font-size: ${typography.sizes.form.compact.link};
`;

export const ForgotPasswordLink = styled.div`
  text-align: center;
  margin-top: ${sizes.gridUnits.times2};
  margin-bottom: ${sizes.gridUnits.times4};
  font-size: ${typography.sizes.form.compact.link};
`;

export const RequestNewLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: ${sizes.gridUnits.times2};
  margin-bottom: ${sizes.gridUnits.times4};
`;

export const StyledSuitedValidatedTextInput = styled(SuitedValidatedTextInput)`
  margin-bottom: ${sizes.gridUnits.times2};
`;
