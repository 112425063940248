export const routePaths = {
  HOME: "/home",
  ASSESSMENTS_BASE_URL: "/home/assessments",
  ECA_ASSESSMENT: "/home/assessments/eca",
  LATERAL_ASSESSMENT: "/home/assessments/lateral",
  LATERAL_ASSESSMENT_CONTENT: "/home/assessments/lateral/content",
  ASSESSMENT_CONTENT_BASE_URL: "/home/assessments/eca/content",
  USER_SETTINGS: "/home/user-settings",
  EDUCATION: "/home/education",
  DEMOGRAPHIC_INFO: "/home/demographic",
  WORK_PREFERENCES: "/home/work-preferences",
  REGISTER: "/register",
  FAQ: "faq"
};
