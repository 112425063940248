import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

import { End, Column } from "./common.style";

interface Props {
  confirmText: string;
  onConfirm: () => void;
}

const BasicAlertFooter = (props: Props) => {
  return (
    <Column>
      <End>
        <SuitedButton purpose="cancel" autofocus={true} onClick={props.onConfirm}>
          {props.confirmText}
        </SuitedButton>
      </End>
    </Column>
  );
};

export default BasicAlertFooter;
