import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "configureStore";
import type { FeatureFlagConfigOptions } from "./FeatureFlag.types";
import { featureFlagDefaultConfig } from "./FeatureFlags.constants";

export const FEATURE_FLAG_STATE_KEY = "featureFlags";

const featureFlagSlice = createSlice({
  name: FEATURE_FLAG_STATE_KEY,
  initialState: {
    isFeatureFlagNavVisible: false,
    featureFlagConfig: featureFlagDefaultConfig
  },
  reducers: {
    showFeatureFlagNav: (state) => {
      state.isFeatureFlagNavVisible = true;
    },
    hideFeatureFlagNav: (state) => {
      state.isFeatureFlagNavVisible = false;
    },
    setFeatureFlags: (state, action) => {
      state.featureFlagConfig = action.payload;
    }
  }
});

// Actions
export const { setFeatureFlags, showFeatureFlagNav, hideFeatureFlagNav } = featureFlagSlice.actions;

// Selectors
export const getFeatureFlagConfig = (state: RootState) => state.featureFlags.featureFlagConfig;

export const getIsFeatureFlagEnabled =
  (featureFlag: FeatureFlagConfigOptions) => (state: RootState) =>
    state.featureFlags.featureFlagConfig[featureFlag];

export const getIsFeatureFlagNavVisible = (state: RootState) =>
  state.featureFlags.isFeatureFlagNavVisible;

// Reducer
export const featureFlagReducer = featureFlagSlice.reducer;
