import { assessmentItemTypes } from "../eca.constants";
import { SAMPLE_QUESTION_TYPES } from "./SampleItemGroupsList.constants";

export const getSampleQuestionType = (sampleItemGroup: any) => {
  const itemType = sampleItemGroup?.itemType;
  switch (itemType) {
    case assessmentItemTypes.MATRIXTABLE:
      // Check if header length is 3 (true, false, certain)
      // If it's not true, false, and uncertain question type,
      // it will be a same/different question type.
      return sampleItemGroup?.headers?.length === 3
        ? SAMPLE_QUESTION_TYPES.MATRIX_TABLE_TRUE_FALSE_UNCERTAIN
        : SAMPLE_QUESTION_TYPES.MATRIX_TABLE_SAME_DIFFERENT;
    case assessmentItemTypes.MULTISELECT:
      return SAMPLE_QUESTION_TYPES.MULTI_SELECT;
    case assessmentItemTypes.TEXTINPUT:
      return SAMPLE_QUESTION_TYPES.TEXT_INPUT;
  }
};

