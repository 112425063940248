import { match, Redirect, Route, Switch } from "react-router-dom";
import { EducationPage } from "suited/components/CandidateHome/Education/EducationPage/EducationPage";
import Home from "suited/components/CandidateHome/Home";
import PersonalData from "suited/components/CandidateHome/PersonalData/PersonalData.component";
import Resume from "suited/components/CandidateHome/Resume";
import UserSettings from "suited/components/CandidateHome/UserSettings";
import ConnectionManager from "suited/components/CandidateHome/ConnectionManager";
import SuitedAssessmentLanding from "suited/components/SuitedAssessment/SuitedAssessmentLanding";
import { routePaths } from "suited/constants/routePaths";
import { ECALandingPage } from "../CandidateHome/eca/ECALandingPage/ECALandingPage";
import { ECAContent } from "../CandidateHome/eca/ECAContent/ECAContent";
import { WorkPreferences } from "../CandidateHome/workPreferences/WorkPreferences/WorkPreferences";
import { LateralLandingPage } from "../CandidateHome/lateral/LateralLandingPage/LateralLandingPage";
import { LateralContent } from "../CandidateHome/lateral/LateralContent/LateralContent";
import { Faq } from "../CandidateHome/faq/Faq";

interface IHomeRoutesProps {
  match: match;
  data: any;
}

const HomeRoutes = (props: IHomeRoutesProps) => {
  const { match, data } = props;

  const isSuitedAssessmentCompleted =
    data?.GetUserSurveyAnswers?.status === "complete" ? true : false;

  const homeUrl = `${match.url}`;
  const userSettingsUrl = `${match.url}/user-settings`;
  const connectionManager = `${match.url}/connection-manager`;
  const educationUrl = `${match.url}/education`;
  const demographicUrl = `${match.url}/demographic`;
  const suitedAssessmentUrl = `${match.url}/suited-assessment/personality`;
  const resumeUrl = `${match.url}/resume`;
  const assessmentUrl = `${match.url}/assessment/:assessmentName`;
  const faqUrl = `${match.url}/${routePaths.FAQ}`;

  return (
    <Switch>
      <Route exact path={homeUrl} render={() => <Home />} />
      <Route
        path={suitedAssessmentUrl}
        exact
        render={() => (
          <SuitedAssessmentLanding completedApplicationSurvey={isSuitedAssessmentCompleted} />
        )}
      />
      <Route exact path={userSettingsUrl} render={() => <UserSettings />} />
      <Route path={educationUrl} exact render={() => <EducationPage />} />
      <Route path={routePaths.WORK_PREFERENCES} exact component={WorkPreferences} />
      <Route path={demographicUrl} exact render={() => <PersonalData />} />
      <Route path={assessmentUrl} exact render={() => <Redirect to={homeUrl} />} />
      <Route path={resumeUrl} exact render={() => <Resume />} />
      <Route path={faqUrl} exact render={() => <Faq />} />
      <Route exact path={connectionManager} render={() => <ConnectionManager />} />
      <Route exact path={routePaths.ECA_ASSESSMENT} component={ECALandingPage} />
      <Route exact path={routePaths.LATERAL_ASSESSMENT} component={LateralLandingPage} />
      <Route exact path={routePaths.ASSESSMENT_CONTENT_BASE_URL} component={ECAContent} />
      <Route exact path={routePaths.LATERAL_ASSESSMENT_CONTENT} component={LateralContent} />
      <Route path="*" render={() => <Redirect to="/home" />} />
    </Switch>
  );
};

export default HomeRoutes;
