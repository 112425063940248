import styled from "styled-components";

import { typography, responsiveTargets, sizes } from "suited/constants/style-constants";
import { ISuitedCopyProps } from "./SuitedAltCopy";

export const SuitedCopyEl = styled.div<ISuitedCopyProps>`
  ${typography.styles.body}
  letter-spacing: ${typography.tracking.loose};
  font-size: ${typography.sizes.copy.body.base};
  line-height: ${typography.leading.responsive.copy.base};
  @media ${responsiveTargets.phone} {
    font-size: ${typography.sizes.copy.body.phone};
    margin-bottom: ${typography.margins.copy.phone};
    line-height: ${typography.leading.responsive.copy.phone};
    max-width: 100%;
  }
  @media ${responsiveTargets.tablet} {
    font-size: ${typography.sizes.copy.body.tablet};
    margin-bottom: ${typography.margins.copy.tablet};
    line-height: ${typography.leading.responsive.copy.tablet};
    max-width: calc(${sizes.containerWidths.md} + ${sizes.gridUnits.times10});
  }
  @media ${responsiveTargets.laptop} {
    font-size: ${typography.sizes.copy.body.laptop};
    margin-bottom: ${typography.margins.copy.laptop};
    line-height: ${typography.leading.responsive.copy.laptop};
    max-width: calc(${sizes.containerWidths.md} + ${sizes.gridUnits.times10});
  }
  @media ${responsiveTargets.desktop} {
    font-size: ${typography.sizes.copy.body.desktop};
    margin-bottom: ${typography.margins.copy.desktop};
    line-height: ${typography.leading.responsive.copy.desktop};
    max-width: ${sizes.containerWidths.lg};
  }
  @media ${responsiveTargets.jumbo} {
    font-size: ${typography.sizes.copy.body.jumbo};
    margin-bottom: ${typography.margins.copy.jumbo};
    line-height: ${typography.leading.responsive.copy.jumbo};
    max-width: calc(${sizes.containerWidths.lg} + ${sizes.gridUnits.times10});
  }
  ${(props) => (props.noMargin ? "margin-bottom: 0 !important;" : "")}
  ${(props) => (props.overDark ? `color: ${typography.color.light.base};` : "")}

  strong,
  em {
    ${typography.styles.emphasis};
    letter-spacing: ${typography.tracking.loose};
  }

  ul {
    margin-top: ${sizes.gridUnits.times1};
    margin-right: ${sizes.gridUnits.times2};
    margin-bottom: ${sizes.gridUnits.times1};
    margin-left: ${sizes.gridUnits.times4};
    li {
      list-style-type: disc;
      &:not(:last-child) {
        margin-bottom: ${sizes.gridUnits.times1};
      }
    }
  }
`;
