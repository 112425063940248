import styled, { css } from "styled-components";

import { typography, colors, animationCurves, sizes } from "suited/constants/style-constants";
import IElementInteractionState from "suited/components/shared/types/IElementInteractionState";

const buttonBaseStyles = css`
  flex: 0 0 auto;
  width: ${sizes.gridUnits.times5};
  height: ${sizes.gridUnits.times5};
  margin-left: ${sizes.gridUnits.times1};
  padding: 0;
  border: 1px solid transparent;
  border-radius: 20px;
  outline: none;
  color: ${typography.color.dark.hint};
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  font-size: ${typography.sizes.form.base.buttonIcon};
  transform: scale(1);
  transition: transform 300ms ${animationCurves.easeOutQuart},
    border-color 300ms ${animationCurves.easeOutQuart},
    box-shadow 300ms ${animationCurves.easeOutQuart};
`;

const buttonHoverStyles = css`
  color: ${typography.color.dark.base};
  background-color: ${colors.tints.times3};
  transform: scale(1.1);
  border: 1px solid ${colors.shades.times1};
  box-shadow: inset 0px 0px 0px 2px ${colors.shades.times1};
`;

const buttonFocusStyles = css`
  ${buttonHoverStyles}
  border: 1px solid ${colors.accent1.base};
  box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
`;

const buttonActiveStyles = css`
  color: ${typography.color.dark.emphasis};
  background-color: ${colors.tints.mid};
  transform: scale(1);
  border: 1px solid ${colors.accent1.base};
  box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
  transition: transform 100ms ${animationCurves.easeOutQuart};
`;

const buttonDisabledStyles = css`
  opacity: 0.5 !important;
  cursor: not-allowed;
`;

export const Button = styled.button<IElementInteractionState>`
  ${buttonBaseStyles};
  ${({ disabled }) =>
    disabled &&
    css`
      ${buttonDisabledStyles}
    `}
  ${({ hovered, disabled }) =>
    hovered &&
    !disabled &&
    css`
      ${buttonHoverStyles}
    `}
  ${({ focused, disabled }) =>
    focused &&
    !disabled &&
    css`
      ${buttonFocusStyles}
    `}
  ${({ active, disabled }) =>
    active &&
    !disabled &&
    css`
      ${buttonActiveStyles}
    `}
`;

export const A = styled.a<IElementInteractionState>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${buttonBaseStyles};
  ${({ disabled }) =>
    disabled &&
    css`
      ${buttonDisabledStyles}
    `}
  ${({ hovered, disabled }) =>
    hovered &&
    !disabled &&
    css`
      ${buttonHoverStyles}
    `}
  ${({ focused, disabled }) =>
    focused &&
    !disabled &&
    css`
      ${buttonFocusStyles}
    `}
  ${({ active, disabled }) =>
    active &&
    !disabled &&
    css`
      ${buttonActiveStyles}
    `}
`;
