import { useQuery } from "@apollo/client";

import { assessmentStatusTypes, sectionStatusTypes } from "../eca.constants";
import { ECAInstructionsPage } from "../ECAInstructionsPage/ECAInstructionsPage";
import { AssessmentCompletedPage } from "../AssessmentCompletedPage/AssessmentCompletedPage";
import { ECAInProgressPage } from "../ECAInProgressPage/ECAInProgressPage";
import { ECALandingPageSkeleton } from "./ECALandingPage.skeleton";
import {
  useGetCandidateAssessmentById,
  useGetCandidateAssessments,
  useHydrateStatusBar
} from "../eca.hooks";
import { AssessmentIds } from "../eca.constants";
import { GET_USER_BOOTSTRAP } from "../../GraphQL/Queries";
import { TUserQueryData } from "../../GraphQL/types";

export const ECALandingPage = () => {
  useHydrateStatusBar();

  const { data: getUserBootstrapData } = useQuery<TUserQueryData>(GET_USER_BOOTSTRAP);

  const { assessments, isGetCandidateAssessmentsLoading } = useGetCandidateAssessments({
    fetchPolicy: "network-only"
  });
  const assessmentID =
    assessments.find((assessment) => assessment.id === AssessmentIds.ECA_ASSESSMENT)?.id || "";
  const { assessment, isGetAssessmentLoading } = useGetCandidateAssessmentById(assessmentID, {
    fetchPolicy: "network-only"
  });

  const isAssessmentComplete = assessment?.status === assessmentStatusTypes.COMPLETE;
  const isAssesmentPartiallyCompleted = assessment?.sections?.some(
    (section) => section.status === sectionStatusTypes.COMPLETE
  );
  const accommodationMultiplier =
    getUserBootstrapData?.GetUser?.accommodations?.assessmentTimerMultiplier;

  if (isGetAssessmentLoading || isGetCandidateAssessmentsLoading) {
    return <ECALandingPageSkeleton />;
  }

  if (!isAssessmentComplete && isAssesmentPartiallyCompleted) {
    return <ECAInProgressPage accommodationMultiplier={accommodationMultiplier} />;
  }

  return isAssessmentComplete ? (
    <AssessmentCompletedPage />
  ) : (
    <ECAInstructionsPage accommodationMultiplier={accommodationMultiplier} />
  );
};
