import styled from "styled-components";
import { typography, colors, sizes, animationCurves } from "suited/constants/style-constants";

export const StyledReactSelect = {
  container: (provided: any) => ({
    ...provided,
    backgroundColor: "inherit",
    pointerEvents: "auto"
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    cursor: state.isDisabled ? "not-allowed" : "default",
    height: "auto",
    minHeight: `${sizes.gridUnits.times5}`,
    marginBottom: 0,
    backgroundColor: state.isDisabled
      ? `${colors.shades.divide2}`
      : state.selectProps.touched
      ? `${colors.tints.times4}`
      : `${colors.tints.most}`,
    borderRadius: 0,
    borderStyle: state.isDisabled ? "none" : "solid",
    borderWidth: state.isDisabled ? 2 : 1,
    borderColor: state.selectProps.touched
      ? `${colors.shades.times2}`
      : state.isFocused
      ? `${colors.accent1.base}`
      : `${colors.shades.times1}`,
    boxShadow: state.isFocused ? `inset 0px 0px 0px 2px ${colors.accent1.base}` : "none",
    color: state.isDisabled ? `${typography.color.dark.base}` : `${typography.color.dark.disabled}`,
    fontSize: `${typography.sizes.form.base.input}`,
    fontFamily: `${typography.family.body}`,
    fontWeight: state.isDisabled ? "300" : `${typography.weight.body.medium}`,
    letterSpacing: `${typography.tracking.loose}`,
    flexWrap: "nowrap",
    zIndex: 1,
    boxSizing: "border-box",
    transition: `transform 300ms ${animationCurves.easeOutQuart},
      background-color 300ms ${animationCurves.easeOutQuart},
      border-color 300ms ${animationCurves.easeOutQuart},
      box-shadow 300ms ${animationCurves.easeOutQuart}`,
    "&:hover, &:focus-within": {
      ...provided["&:hover"],
      backgroundColor: state.isDisabled
        ? `${colors.shades.divide2}`
        : state.isFocused
        ? `${colors.background.light.primary}`
        : `${colors.tints.full}`,
      borderColor: state.isFocused ? `${colors.accent1.base}` : `${colors.shades.times2}`,
      boxShadow: state.isDisabled
        ? "none"
        : state.isFocused
        ? `inset 0px 0px 0px 2px ${colors.accent1.base}`
        : `inset 0px 0px 0px 2px ${colors.shades.times2}`
    }
  }),
  placeholder: (provided: any) => ({
    ...provided,
    marginLeft: 0,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.base.input}`,
    color: `${typography.color.dark.disabled}`,
    fontWeight: `${typography.weight.body.light}`,
    letterSpacing: `${typography.tracking.loose}`,
    whiteSpace: "nowrap"
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: 0,
    zIndex: 2,
    boxShadow: "none",
    borderRadius: 0,
    borderStyle: "inset",
    border: `solid 1px ${colors.shades.times2}`,
    fontSize: `${typography.sizes.form.base.input}`,
    backgroundColor: `${colors.background.light.primary}`
  }),
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: "inherit"
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    overflowWrap: "anywhere",
    fontSize: `${typography.sizes.form.base.input}`,
    fontFamily: `${typography.family.body}`,
    fontWeight: state.isDisabled ? "300" : `${typography.weight.body.medium}`,
    letterSpacing: `${typography.tracking.loose}`,
    color: state.isSelected
      ? `${typography.color.light.emphasis}`
      : `${typography.color.dark.base}`,
    backgroundColor: state.isDisabled
      ? "rgba(0, 0, 0, 0.1)"
      : state.isSelected
      ? `${colors.accent1.base}`
      : state.isFocused
      ? `${colors.shades.divide2}`
      : "inherit",
    "&:active": {
      color: `${typography.color.light.base}`,
      backgroundColor: `${colors.accent1.base}`
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: `${typography.color.dark.base}`,
    fontSize: `${typography.sizes.form.base.input}`
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: `${colors.tints.times2}`,
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: `${colors.shades.times1}`,
    padding: 0,
    marginLeft: 0,
    marginRight: `calc(${typography.margins.input} / 2)`,
    fontFamily: `${typography.family.subheading}`,
    color: `${typography.color.dark.base}`,
    fontSize: `${typography.sizes.copy.label}`,
    "div:nth-of-type(2n)": {
      visibility: state.isDisabled ? "hidden" : "visible"
    }
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    whiteSpace: "normal",
    fontSize: `${typography.sizes.copy.label}`,
    fontWeight: `${typography.weight.accent.medium}`,
    color: `${typography.color.dark.base}`
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    marginLeft: 2,
    borderRadius: 0,
    color: `${typography.color.dark.hint}}`,
    "&:hover": {
      color: `${typography.color.dark.base}`,
      backgroundColor: `${colors.shades.divide2}`
    },
    "&:active": {
      color: `${typography.color.dark.emphasis}`,
      backgroundColor: `${colors.shades.times1}`
    }
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    alignItems: "flex-start",
    visibility: state.isDisabled ? "hidden" : "visible"
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: `${typography.margins.input}`
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.copy.label}`,
    color: `${typography.color.dark.hint}}`,
    // not sure why, but I had to override fontFamily in the input tag underneath the input container. -EH
    input: {
      fontFamily: `${typography.family.body}`,
      fontSize: `${typography.sizes.copy.label}`,
      color: `${typography.color.dark.hint}}`
    }
  }),
  loadingMessage: (provided: any) => ({
    ...provided,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.instructions}`,
    fontStyle: "italic",
    fontWeight: `${typography.weight.body.light}`,
    color: `${typography.color.dark.hint}}`
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.instructions}`,
    fontStyle: "italic",
    fontWeight: `${typography.weight.body.light}`,
    color: `${typography.color.dark.hint}}`
  }),
  group: (provided: any) => ({
    ...provided,
    borderTop: `1px solid ${colors.shades.divide2}`,
    paddingTop: `${sizes.gridUnits.times1}`
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    paddingTop: `${sizes.gridUnits.times1}`,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.base.label}`,
    fontWeight: `${typography.weight.body.light}`,
    color: `${typography.color.dark.hint}}`
  })
};

export const StyledOverDarkReactSelect = {
  container: (provided: any) => ({
    ...provided,
    backgroundColor: "inherit",
    pointerEvents: "auto"
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    cursor: state.isDisabled ? "not-allowed" : "default",
    height: "auto",
    minHeight: `${sizes.gridUnits.times5}`,
    marginBottom: 0,
    backgroundColor: state.isDisabled
      ? `${colors.tints.times1}`
      : state.selectProps.touched
      ? `${colors.shades.times1}`
      : `${colors.shades.divide2}`,
    borderRadius: 0,
    borderStyle: state.isDisabled ? "none" : "solid",
    borderWidth: state.isDisabled ? 2 : 1,
    borderColor: state.selectProps.touched
      ? `${colors.tints.times2}`
      : state.isFocused
      ? `${colors.accent1.base}`
      : `${colors.tints.times1}`,
    boxShadow: state.isFocused ? `inset 0px 0px 0px 2px ${colors.accent1.base}` : "none",
    color: state.isDisabled
      ? `${typography.color.light.base}`
      : `${typography.color.light.disabled}`,
    fontSize: `${typography.sizes.form.base.input}`,
    fontFamily: `${typography.family.bodyEmphasis}`,
    fontWeight: state.isDisabled ? "300" : `${typography.weight.body.medium}`,
    letterSpacing: `${typography.tracking.loose}`,
    flexWrap: "nowrap",
    zIndex: 1,
    boxSizing: "border-box",
    transition: `transform 300ms ${animationCurves.easeOutQuart},
      background-color 300ms ${animationCurves.easeOutQuart},
      border-color 300ms ${animationCurves.easeOutQuart},
      box-shadow 300ms ${animationCurves.easeOutQuart}`,
    "&:hover, &:focus-within": {
      ...provided["&:hover"],
      backgroundColor: state.isDisabled
        ? `${colors.tints.times1}`
        : state.isFocused
        ? `${colors.tints.divide2}`
        : `${colors.shades.times1}`,
      borderColor: state.isFocused ? `${colors.accent1.base}` : `${colors.tints.times3}`,
      boxShadow: state.isDisabled
        ? "none"
        : state.isFocused
        ? `inset 0px 0px 0px 2px ${colors.accent1.base}`
        : `inset 0px 0px 0px 2px ${colors.tints.times3}`
    }
  }),
  placeholder: (provided: any) => ({
    ...provided,
    marginLeft: 0,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.base.input}`,
    color: `${typography.color.light.disabled}`,
    fontWeight: `${typography.weight.body.light}`,
    letterSpacing: `${typography.tracking.loose}`,
    whiteSpace: "nowrap"
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: 0,
    zIndex: 2,
    boxShadow: "none",
    borderRadius: 0,
    borderStyle: "inset",
    border: `solid 1px ${colors.tints.times4}`,
    fontSize: `${typography.sizes.form.base.input}`,
    backgroundColor: "inherit"
  }),
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: "inherit"
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    overflowWrap: "anywhere",
    fontFamily: `${typography.family.bodyEmphasis}`,
    fontWeight: state.isDisabled ? "300" : `${typography.weight.body.medium}`,
    letterSpacing: `${typography.tracking.loose}`,
    color: `${typography.color.light.base}`,
    backgroundColor: state.isSelected
      ? `${colors.tints.times2}`
      : state.isFocused
      ? `${colors.tints.times1}`
      : "inherit",
    "&:active": {
      color: `${typography.color.light.base}`,
      backgroundColor: `${colors.tints.times4}`
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: `${typography.color.light.base}`,
    fontSize: `${typography.sizes.form.base.input}`
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: `${colors.tints.times1}`,
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: `${colors.shades.times1}`,
    padding: 0,
    marginLeft: 0,
    marginRight: `calc(${typography.margins.input} / 2)`,
    fontFamily: `${typography.family.subheading}`,
    color: `${typography.color.dark.base}`,
    fontSize: `${typography.sizes.copy.label}`,
    "div:nth-of-type(2n)": {
      visibility: state.isDisabled ? "hidden" : "visible"
    }
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    whiteSpace: "normal",
    fontSize: `${typography.sizes.copy.label}`,
    fontWeight: `${typography.weight.accent.medium}`,
    color: `${typography.color.light.emphasis}`
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    marginLeft: 2,
    borderRadius: 0,
    color: `${typography.color.light.hint}}`,
    "&:hover": {
      color: `${typography.color.light.base}`,
      backgroundColor: `${colors.tints.divide2}`
    },
    "&:active": {
      color: `${typography.color.light.emphasis}`,
      backgroundColor: `${colors.tints.times1}`
    }
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    alignItems: "flex-start",
    visibility: state.isDisabled ? "hidden" : "visible"
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? `${colors.tints.most}` : `${colors.tints.times3}`
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? `${colors.tints.most}` : `${colors.tints.times3}`
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: `${typography.margins.input}`
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.copy.label}`,
    color: `${typography.color.light.hint}}`,
    // not sure why, but I had to override fontFamily in the input tag underneath the input container. -EH
    input: {
      fontFamily: `${typography.family.body}`,
      fontSize: `${typography.sizes.copy.label}`,
      color: `${typography.color.light.hint}}`
    }
  }),
  loadingMessage: (provided: any) => ({
    ...provided,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.instructions}`,
    fontStyle: "italic",
    fontWeight: `${typography.weight.body.light}`,
    color: `${typography.color.dark.hint}}`
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.instructions}`,
    fontStyle: "italic",
    fontWeight: `${typography.weight.body.light}`,
    color: `${typography.color.light.base}`
  }),
  group: (provided: any) => ({
    ...provided,
    borderTop: `1px solid ${colors.shades.divide2}`,
    paddingTop: `${sizes.gridUnits.times1}`
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    paddingTop: `${sizes.gridUnits.times1}`,
    fontFamily: `${typography.family.body}`,
    fontSize: `${typography.sizes.form.base.label}`,
    fontWeight: `${typography.weight.body.light}`,
    color: `${typography.color.dark.hint}}`
  })
};

export const InputContainer = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;
  align-self: stretch;
  background-color: inherit;
  ${(props) => (props.noMargin ? null : `margin-bottom: ${sizes.gridUnits.times3};`)}
`;

export const LabelContainer = styled.div<{ overDark?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;
  color: ${(props) => (props.overDark ? typography.color.light.base : typography.color.dark.base)};
`;

export default StyledReactSelect;
