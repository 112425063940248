import { CSSTransition } from "react-transition-group";

import { Box, Flex, Stack } from "@suited/components";
import { colors } from "suited/constants/style-constants";
import {
  CardSubheadline,
  IconTransitionGroup,
  TransitionIcon
} from "./SuitedUploaderCardStates.style";
import SuitedLinearProgress from "../../../loading/SuitedLinearProgress";

interface Props {
  message: string;
  progress: number;
  done: boolean;
  error: boolean;
  onDone: () => void;
  onError: () => void;
}

const TRANSITION_DURATION = 400;

const Progress = (props: Props) => {
  const { message, progress, done, error, onDone, onError, ...passthroughProps } = props;

  const handleDone = () => {
    props.onDone();
  };

  const handleError = () => {
    props.onError();
  };

  return (
    <Box
      padding="xxl"
      borderWidth="2px"
      borderRadius="4px"
      width="50ch"
      height="240px"
      bg={colors.tints.mid}
      borderColor={colors.shades.times1}
      {...passthroughProps}
    >
      <Stack space="lg" data-test="component-suited-uploader-card-progress" width="100%">
        <CardSubheadline>{props.message}</CardSubheadline>
        <Flex align="center" justify="stretch">
          <IconTransitionGroup>
            <CSSTransition in={!done && !error} unmountOnExit={true} timeout={TRANSITION_DURATION}>
              <TransitionIcon
                name="cloud-upload"
                weight="light"
                size="3x"
                timeout={TRANSITION_DURATION}
              />
            </CSSTransition>
            <CSSTransition in={done && !error} unmountOnExit={true} timeout={TRANSITION_DURATION}>
              <TransitionIcon name="check" weight="solid" size="3x" timeout={TRANSITION_DURATION} />
            </CSSTransition>
            <CSSTransition in={error} unmountOnExit={true} timeout={TRANSITION_DURATION}>
              <TransitionIcon
                name="exclamation"
                weight="solid"
                size="3x"
                timeout={TRANSITION_DURATION}
              />
            </CSSTransition>
          </IconTransitionGroup>
          <SuitedLinearProgress
            value={progress}
            complete={done}
            onDidTransitionToComplete={handleDone}
            error={error}
            onDidTransitionToError={handleError}
          />
        </Flex>
      </Stack>
    </Box>
  );
};

export default Progress;
