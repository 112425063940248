import { useMutation } from "@apollo/client";

import {
  AssessmentID,
  GetNextAssessmentSectionQuestionsQuery,
  SubmitItemResponse,
  SubmitItemVariables
} from "../eca.types";
import { GET_NEXT_ASSESSMENT_SECTION_QUESTIONS, SUBMIT_ITEM_RESPONSE } from "../eca.queries";
import { getUpdatedGetNextAssessmentSectionQuestionsCacheData } from "./SectionQuestionsItemGroups.utils";
import { useGetCandidateAssessments, useGetNextAssessmentSectionQuestions } from "../eca.hooks";
import { ItemGroupsList } from "../ItemGroupsList/ItemGroupsList";

export const SectionQuestionsItemGroups = () => {
  const { assessmentID } = useGetCandidateAssessments();
  const { itemGroups } = useGetNextAssessmentSectionQuestions();

  const [sendItemResponseToServer] = useMutation<SubmitItemResponse, SubmitItemVariables>(
    SUBMIT_ITEM_RESPONSE,
    {
      fetchPolicy: "no-cache",
      update(cache, _, { variables: formValues }) {
        const queryCache = {
          query: GET_NEXT_ASSESSMENT_SECTION_QUESTIONS,
          variables: { assessmentID }
        };
        const questionCache = cache.readQuery<GetNextAssessmentSectionQuestionsQuery, AssessmentID>(
          queryCache
        );

        cache.writeQuery({
          ...queryCache,
          data: getUpdatedGetNextAssessmentSectionQuestionsCacheData(
            questionCache as GetNextAssessmentSectionQuestionsQuery,
            formValues as SubmitItemVariables
          )
        });
      }
    }
  );
  const submitItemResponse = (formValues: SubmitItemVariables) => {
    sendItemResponseToServer({ variables: formValues });
  };

  return <ItemGroupsList itemGroups={itemGroups} onSubmit={submitItemResponse} />;
};
