import styled from "styled-components";

import { typography, sizes, colors } from "suited/constants/style-constants";

export const Input = styled.input`
  height: ${sizes.gridUnits.times5};
  padding: ${typography.margins.input};
  border: 1px solid ${colors.shades.times1};
  color: ${typography.color.dark.base};
  font-size: ${typography.sizes.form.base.input};
  font-family: ${typography.family.body};
  font-weight: ${typography.weight.body.medium};
  letter-spacing: ${typography.tracking.loose};
  letter-spacing: 0.115em;
  &::placeholder {
    font-family: ${typography.family.body};
    font-weight: ${typography.weight.body.light};
    letter-spacing: ${typography.tracking.loose};
  }
`;
