import { useQuery, useMutation } from "@apollo/client";

import { Box, Center } from "@suited/components";
import PersonalDataView, { IPersonalData } from "./PersonalDataView.component";
import { GET_USER_PERSONAL_DATA } from "./GraphQL/queries";
import { UPDATE_USER_PERSONAL_DATA } from "./GraphQL/mutations";

const PersonalData = () => {
  const { data, loading } = useQuery(GET_USER_PERSONAL_DATA);
  const [updatePersonalData] = useMutation(UPDATE_USER_PERSONAL_DATA, {
    refetchQueries: [{ query: GET_USER_PERSONAL_DATA }],
    awaitRefetchQueries: true
  });
  const handleSavePersonalData = (data) => {
    updatePersonalData({
      variables: data
    });
  };

  const personalData: IPersonalData = data?.GetUser?.userProfile?.personalData;
  const diversityOptIn = data?.GetUser?.settings?.diversityOptIn?.optIn;

  return (
    <Box padding="xl" overflow="auto">
      <Center>
        <PersonalDataView
          personalData={personalData}
          diversityOptIn={diversityOptIn}
          onSave={handleSavePersonalData}
          loading={loading}
        />
      </Center>
    </Box>
  );
};

export default PersonalData;
