import React, { useState } from "react";

import {
  IconButtonWrapper,
  StyledSuitedIconButton,
  StyledSuitedValidatedTextInput,
  SuitedShowPasswordInputEl
} from "./SuitedShowPasswordInput.style";

export interface ISuitedShowPasswordInputProps {
  /** optional state that turns the input green */
  isStrongPassword?: boolean;
  /** input name for the form, also populates `id` */
  name: string;
  /** parent's change handler. this should update `value` */
  onInputChange: (value: any, targetName: string) => void;
  /** optional initial value */
  value?: string;
  /** optional label to appear above the input */
  label?: string;
  /** if true, shows asterisk in label */
  required?: boolean;
  /** optional managed validity state turns the input red and shows error message (after touch) */
  invalid?: boolean;
  /** the inline error message to display */
  error?: string;
  /** optional that if true, will set this input to get focus on page render. */
  autoFocus?: boolean;
  /** optional HTML attribute to hint browser autocomplete feature */
  autoComplete?: "current-password" | "new-password";
  /** if present, delays `onChange` by x milliseconds */
  debounceTime?: number;
  /** optionally handle focus events */
  onInputFocus?: (value: any, targetName: string) => void;
  /** optionally handle blur events */
  onInputBlur?: (value: any, targetName: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  /** for Styled Component compatibility */
  className?: string;
  /** When the text is over a dark background, it has a light appearance. */
  overDark?: boolean;
}

/**
 * ## What
 * `<SuitedShowPasswordInput>` is a component that extends `<SuitedValidatedTextInput>` for "password" inputs. It supplies a button to toggle the input between "password" and "text" types, so a user can peek at their password during entry.
 *
 * ## When
 * This component is used on a form or wherever a password input appears in the app.
 *
 * ## Not
 * This should not be used anywhere else that isn't a password field.
 *
 * ## Example
 * In Candidate App, it's used on the registration, login, and user settings.
 * In Recruiter App, it's used on the login form.
 */
export const SuitedShowPasswordInput: React.FunctionComponent<ISuitedShowPasswordInputProps> = (
  props
) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    onInputChange,
    onInputFocus,
    onInputBlur,
    className,
    isStrongPassword,
    ...passthroughProps
  } = props;

  const handleClickToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleFocus = (value: any, targetName: string) => {
    if (props.onInputFocus) props.onInputFocus(value, targetName);
  };

  const handleBlur = (value: any, targetName: string) => {
    if (props.onInputBlur) props.onInputBlur(value, targetName);
  };

  const handleChange = (value: any, targetName: string) => {
    props.onInputChange(value, targetName);
  };

  return (
    <SuitedShowPasswordInputEl
      className={props.className}
      data-testid="suited-show-password-input-root-el"
    >
      <StyledSuitedValidatedTextInput
        {...passthroughProps}
        value={props.value}
        type={showPassword ? "text" : "password"}
        onInputChange={handleChange}
        onInputFocus={handleFocus}
        onInputBlur={handleBlur}
        isStrongPassword={isStrongPassword}
      />
      <IconButtonWrapper invalid={props.invalid}>
        <StyledSuitedIconButton
          tooltip={showPassword ? "Hide password" : "Show password"}
          type="button"
          iconName={showPassword ? "eye-slash" : "eye"}
          onClick={handleClickToggle}
          overDark={props.overDark}
        />
      </IconButtonWrapper>
    </SuitedShowPasswordInputEl>
  );
};
