import gql from "graphql-tag";

export const GET_CANDIDATE_INVITATIONS = gql`
  query GetCandidateInvitations {
    GetCandidateInvitations {
      _id
      status
      companyName
      createdAt
      updatedAt
    }
  }
`;

export const GET_COMPANY_CANDIDATE_ASSOCIATIONS = gql`
  query GetCompanyCandidateAssociations {
    GetCompanyCandidateAssociations {
      _id
      status
      companyName
      createdAt
      updatedAt
    }
  }
`;
