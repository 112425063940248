import { sizes } from "suited/constants/style-constants";
import BasicAlertFooter from "./BasicAlertFooter";
import BasicDialogHeader from "./BasicDialogHeader";
import { SuitedDialogAlertWindow } from "./SuitedDialogAlert.style";

interface Props {
  show: boolean;
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText?: string;
  closeDuration?: number;
  shouldFocusAfterRender?: boolean;
  /** String indicating how the content container should be announced to screenreaders */
  contentLabel?: string;
}

const SuitedDialogAlert = (props: Props) => {
  return (
    <SuitedDialogAlertWindow
      show={props.show}
      onCancel={props.onCancel}
      contentLabel={props.contentLabel || props.title}
      closeDuration={props.closeDuration}
      iconButtonRow={<BasicDialogHeader onCancel={props.onCancel} />}
      renderFooter={
        <BasicAlertFooter
          confirmText={props.confirmText || "Got it."}
          onConfirm={props.onConfirm}
        />
      }
      maxWidth={sizes.modalWidths.md}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
    >
      <p>{props.message}</p>
    </SuitedDialogAlertWindow>
  );
};

export default SuitedDialogAlert;
