import * as React from "react";
import styled from "styled-components";

import { typography, responsiveTargets } from "../../../constants/style-constants";

interface Props {
  /** display light text on dark backgrounds */
  overDark?: boolean;
  noMargin?: boolean;
  className?: string;
  children: React.ReactNode;
}

const SuitedCopyHeadline = (props: Props) => {
  return (
    <SuitedCopyHeadlineEl {...props} data-test="component-suited-copy-subheadline">
      {props.children}
    </SuitedCopyHeadlineEl>
  );
};

export const SuitedCopyHeadlineEl = styled.h2<Props>`
  ${typography.styles.heading} /*  */
  font-size: ${typography.sizes.copy.heading.base};
  @media ${responsiveTargets.phone} {
    font-size: ${typography.sizes.copy.heading.phone};
    margin-bottom: ${typography.margins.heading.phone};
  }
  @media ${responsiveTargets.tablet} {
    font-size: ${typography.sizes.copy.heading.tablet};
    margin-bottom: ${typography.margins.heading.tablet};
  }
  @media ${responsiveTargets.laptop} {
    font-size: ${typography.sizes.copy.heading.laptop};
    margin-bottom: ${typography.margins.heading.laptop};
  }
  @media ${responsiveTargets.desktop} {
    font-size: ${typography.sizes.copy.heading.desktop};
    margin-bottom: ${typography.margins.heading.desktop};
  }
  @media ${responsiveTargets.jumbo} {
    font-size: ${typography.sizes.copy.heading.jumbo};
    margin-bottom: ${typography.margins.heading.jumbo};
  }
  ${(props) => (props.noMargin ? "margin-bottom: 0 !important;" : "")} /*  */
  ${(props) => (props.overDark ? `color: ${typography.color.light.base};` : "")}
`;

export default SuitedCopyHeadline;
