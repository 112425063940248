import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

import { Stack } from "@suited/components";
import queryString from "query-string";
import ButtonRowCentered from "../shared/layout/ButtonRowCentered";
import SuitedLink from "../shared/buttons/SuitedLink";
import {
  StyledButtonRowCentered,
  ThankYouRow,
  Error,
  ConfirmationText
} from "./RegistrationThankYou.style";
import AuthenticationTemplate from "../AuthenticationTemplate/AuthenticationTemplate";

const RegistrationThankYou = () => {
  const [navigateToLogin, setNavigateToLogin] = useState<boolean>(false);
  const [newConfirmationEmailRequested, setNewConfirmationEmailRequested] =
    useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { search } = useLocation();

  const { requestId, email, code: invitationCode } = queryString.parse(search);

  const onClickResendConfirmationEmail = () => {
    const body = invitationCode ? { email, invitationCode, requestId } : { email, requestId };
    axios
      .post("/request-new-confirmation-email", body, {
        headers: { "Content-Type": "application/json; charset=utf-8" }
      })
      .then(() => {
        setNewConfirmationEmailRequested(true);
      })
      // @TODO: This is another opportunity to notify the user of a failure case. See also Login/index -- TWS & EHOCH
      .catch((err) => {
        if (err.response.data.message === "EMAIL_ALREADY_CONFIRMED") {
          setError("You have already confirmed your email address.");
          console.error(err);
        }
      });
  };

  const handleClickSignIn = () => {
    setNavigateToLogin(true);
  };

  if (navigateToLogin) {
    return <Redirect push to={"/login"} />;
  }
  return (
    <AuthenticationTemplate
      headline="Thank you for signing up for Suited."
      ctaText="Sign In"
      ctaOnClick={handleClickSignIn}
      children={
        <Stack>
          <ConfirmationText>
            You should receive a confirmation email in your inbox shortly. To access the
            application, please verify your email address through the link provided. If you don't
            receive the email, click the button below to request a new one.
          </ConfirmationText>
          {newConfirmationEmailRequested ? (
            <ConfirmationText>You should get another confirmation email soon.</ConfirmationText>
          ) : (
            <React.Fragment>
              <ButtonRowCentered>
                <SuitedButton
                  purpose="primary"
                  onClick={onClickResendConfirmationEmail}
                  title="Resend Confirmation Email"
                >
                  Resend Confirmation Email
                </SuitedButton>
              </ButtonRowCentered>
              <StyledButtonRowCentered>
                <SuitedLink to={`/login`} tooltip="Log in">
                  Sign in
                </SuitedLink>
              </StyledButtonRowCentered>
            </React.Fragment>
          )}
          {error ? (
            <ThankYouRow>
              <Error>{error}</Error>
              <SuitedLink to="/login" tooltip="Log in">
                Please sign in.
              </SuitedLink>
            </ThankYouRow>
          ) : (
            <div />
          )}
        </Stack>
      }
    />
  );
};

export default RegistrationThankYou;
