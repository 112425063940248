import styled from "styled-components";

import {
  typography,
  colors,
  responsiveMaxSizes,
  sizes,
  responsiveTargets
} from "../../constants/style-constants";
import { ImageContainer } from "../shared/layout/SuitedFullBleedImage.style";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 auto;
  min-height: 0;
  font-family: ${typography.family.body};
  background-color: ${colors.color6.tint1};
`;

export const HomeMainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: stretch;
  flex: 1 1 auto;
  min-height: 0%; /* Fix FireFox bug */
`;

interface HomeTabContainerProps {
  sidePanelOpen: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex: 1 1 auto;
`;

export const HomeTabContainer = styled.div<HomeTabContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: stretch;
  flex: 1 1 auto;
  overflow: hidden;

  @media (max-width: ${responsiveMaxSizes.tablet}) {
    position: relative;
    margin-left: 0px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 auto;
  max-width: 100vw;
`;

export const PaddingBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  overflow: auto;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  ${ImageContainer} {
    margin: 0;
    @media ${responsiveTargets.phone} {
      flex: 0 0 40vh;
      min-height: 240px;
      margin-bottom: -${sizes.gridUnits.times4};
    }
    @media ${responsiveTargets.tablet} {
      flex: 0 0 35vh;
      min-height: 272px;
      margin-bottom: -${sizes.gridUnits.times3};
    }
    @media ${responsiveTargets.laptop} {
      margin-bottom: -${sizes.gridUnits.times2};
    }
    @media ${responsiveTargets.desktop} {
      flex: 0 0 33vh;
      min-height: 304px;
      margin-bottom: -${sizes.gridUnits.times3};
    }
    @media ${responsiveTargets.jumbo} {
      margin-bottom: -${sizes.gridUnits.times5};
    }
  }
`;
