import styled, { css } from "styled-components";

import { sizes, colors, animationCurves, typography } from "suited/constants/style-constants";
import { transitions } from "suited/constants/style-transitions";

interface DeterminateStyleProps {
  value: number;
  changeDuration: number;
}

interface IndeterminateStyleProps {
  loopDuration: number;
}

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 auto;
  min-width: 0;
  width: 100%;
  height: ${sizes.gridUnits.times4};
  position: relative;
`;

const Meter = styled.div<{ loopDuration?: number }>`
  ${({ loopDuration }) => {
    return css`
      flex: 0 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      margin: ${sizes.gridUnits.times1} 0;
      height: ${sizes.gridUnits.times2};
      padding: 2px;
      box-shadow: inset 0 0 0 2px ${colors.shades.mid};
      background: linear-gradient(0deg, transparent, ${colors.shades.times1});
      background-size: 400% 400%;
      animation: pulse ${loopDuration ? loopDuration * 0.75 : 0}ms ease infinite;
      @keyframes pulse {
        0% {
          background-position: 51% 0%;
        }
        50% {
          background-position: 50% 100%;
        }
        100% {
          background-position: 51% 0%;
        }
      }
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
    `;
  }}
`;

const TransitionMeter = styled(Meter)<{ transitionDuration: number }>`
  ${(props) =>
    props.transitionDuration ? transitions.crossScaleBump(props.transitionDuration) : ""}
`;

export const Determinate = styled(TransitionMeter)<DeterminateStyleProps>`
  &:after {
    content: "";
    width: auto;
    height: ${sizes.gridUnits.times2};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: ${colors.shades.mid};
    transform: translateX(${(props) => -100 + props.value}%);
    transition: transform ${(props) => props.changeDuration}ms ${animationCurves.easeOutQuart};
  }
`;

export const Indeterminate = styled(TransitionMeter)<IndeterminateStyleProps>`
  &:before {
    content: "";
    width: auto;
    height: ${sizes.gridUnits.times2};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* palindromic gradient mask inspired by the Fibonacci sequence */
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent 1.92%,
      ${colors.shades.times1} 1.92%,
      ${colors.shades.times1} 3.85%,
      transparent 3.85%,
      transparent 7.69%,
      ${colors.shades.times1} 7.69%,
      ${colors.shades.times1} 13.46%,
      transparent 13.46%,
      transparent 23.08%,
      ${colors.shades.times1} 23.08%,
      ${colors.shades.times1} 38.46%,
      transparent 38.46%,
      transparent 63.46%,
      ${colors.shades.times1} 63.46%,
      ${colors.shades.times1} 78.85%,
      transparent 78.85%,
      transparent 88.46%,
      ${colors.shades.times1} 88.46%,
      ${colors.shades.times1} 94.23%,
      transparent 94.23%,
      transparent 98.08%,
      ${colors.shades.times1} 98.08%,
      ${colors.shades.times1} 100%
    );
    background-position: 50% 0%;
    background-size: 450% 100%;
    background-repeat: repeat;
    animation-duration: ${(props) => props.loopDuration * 2}ms;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: fibonacci;
    animation-timing-function: linear;
  }
  @keyframes fibonacci {
    0% {
      background-position-x: 50%;
    }
    99.9999% {
      background-position-x: -180%;
    }
    100% {
      background-position-x: 50%;
    }
  }
  &:after {
    content: "";
    width: auto;
    height: ${sizes.gridUnits.times2};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* palindromic gradient mask inspired by the Pell sequence */
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent 1.47%,
      ${colors.shades.times1} 1.47%,
      ${colors.shades.times1} 4.41%,
      transparent 4.41%,
      transparent 11.76%,
      ${colors.shades.times1} 11.76%,
      ${colors.shades.times1} 29.41%,
      transparent 29.41%,
      transparent 72.06%,
      ${colors.shades.times1} 72.06%,
      ${colors.shades.times1} 89.71%,
      transparent 89.71%,
      transparent 97.06%,
      ${colors.shades.times1} 97.06%,
      ${colors.shades.times1} 100%
    );
    background-position: 50% 0%;
    background-size: 250% 100%;
    background-repeat: repeat;
    animation-duration: ${(props) => props.loopDuration * 1.5}ms;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: pell;
    animation-timing-function: linear;
  }
  @keyframes pell {
    0% {
      background-position-x: 50%;
    }
    99.9999% {
      background-position-x: -218%;
    }
    100% {
      background-position-x: 50%;
    }
  }
`;

const Done = styled(Meter)<{ transitionDuration: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: ${sizes.gridUnits.times4};
  margin: 0;
  padding: ${sizes.gridUnits.times1};
  color: ${typography.color.dark.base};
  box-shadow: inset 0 0 0 2px ${colors.shades.mid};
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.copy.value};
  font-weight: ${typography.weight.body.medium};
  line-height: normal;
  animation: none;
  transform: scale(1);
  ${(props) =>
    props.transitionDuration ? transitions.crossScaleBump(props.transitionDuration) : ""}
`;

export const Complete = styled(Done)`
  color: ${typography.color.light.emphasis};
  background: ${colors.shades.mid};
`;

export const Error = styled(Done)`
  color: ${typography.color.light.emphasis};
  background: ${colors.status.error.base};
`;
