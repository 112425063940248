import React from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { Flex } from "@suited/components";
import type { Dictionary } from "suited/components/shared/types/utility";
import { Grid, StyledRadioButton, MatrixTableUnsearchableText } from "./MatrixTableItem.style";
import type { MatrixTableItemHeader, SubmitItemVariables } from "../eca.types";

interface Props {
  id: string;
  label: string;
  value: string;
  headers: MatrixTableItemHeader[];
  onSubmit: (data: SubmitItemVariables) => void;
}

export const MatrixTableItem = ({ id, label: itemLabel, value, headers, onSubmit }: Props) => {
  const { register, handleSubmit, setValue } = useForm<Dictionary<string>>({
    defaultValues: { [id]: value }
  });
  return (
    <Grid>
      <label id={itemLabel}>
        <Flex align="center">
          <p id={itemLabel}>
            <MatrixTableUnsearchableText data-content={itemLabel} />
          </p>
        </Flex>
      </label>
      {headers.map(({ value, label: headerLabel }: MatrixTableItemHeader) => {
        const accesibleLabel = `${itemLabel} ${headerLabel}`;
        return (
          <Flex key={value} justify="center" align="center">
            <StyledRadioButton
              type="radio"
              value={value}
              data-testid={accesibleLabel}
              aria-labelledby={accesibleLabel}
              {...register(id, {
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  setValue(id, event.target.value);
                  handleSubmit((formValues) => {
                    const valueToSubmit = formValues[id];
                    const values = valueToSubmit ? [{ value: valueToSubmit }] : [];
                    const timestamp = moment().toISOString();
                    onSubmit({ id, values, timestamp });
                  })();
                }
              })}
            />
          </Flex>
        );
      })}
    </Grid>
  );
};
