import { css } from "styled-components";

interface ComposableTransitionObject {
  attributes: {
    enter: string;
    enterActive: string;
    exit: string;
    exitActive: string;
  };
  transitions: {
    enterActive: string;
    exitActive: string;
  };
}

export type ComposableTransition = (duration: number) => ComposableTransitionObject;

export const composeTransitions = (duration: number, ...transitions: ComposableTransition[]) => {
  const result = css`
    &.enter {
      ${transitions
        .map((transition) => {
          return transition(duration).attributes.enter;
        })
        .join("\n      ")};
    }
    &.enter-active {
      ${transitions
        .map((transition) => {
          return transition(duration).attributes.enterActive;
        })
        .join("\n      ")} transition: ${transitions
    .map((transition) => {
      return transition(duration).transitions.enterActive;
    })
    .join(",\n        ")};
    }
    &.exit {
      ${transitions
        .map((transition) => {
          return transition(duration).attributes.exit;
        })
        .join("\n      ")};
    }
    &.exit-active {
      ${transitions
        .map((transition) => {
          return transition(duration).attributes.exitActive;
        })
        .join("\n      ")} transition: ${transitions
    .map((transition) => {
      return transition(duration).transitions.exitActive;
    })
    .join(",\n        ")};
    }
  `;
  return result;
};
