import styled, { css } from "styled-components";
import { SuitedFormLabelEl } from "suited/components/shared/typography/SuitedFormLabel";
import SuitedInputErrorMessage from "suited/components/shared/typography/SuitedInputErrorMessage";
import { colors, sizes, typography } from "suited/constants/style-constants";
import { SuitedSingleSelect } from "suited/components/shared/inputs/select/SuitedSingleSelect";

export const SuitedValidatedSingleSelectEl = styled.div`
  background-color: ${colors.background.dark.backdrop};
  position: relative;
`;

const getValidationBorderStyle = (hasError: boolean) => {
  return hasError
    ? css`
        .react_select__control {
          border: 1px solid ${colors.status.error.base};
        }
        .react_select__control:hover,
        .react_select__control--is-focused {
          border: 1px solid ${colors.status.error.base};
          box-shadow: inset 0px 0px 0px 2px ${colors.status.error.base};
        }
        .react_select__control--is-focused {
          border: 1px solid ${colors.status.error.base};
          box-shadow: inset 0px 0px 0px 2px ${colors.status.error.base};
          outline: none;
        }
      `
    : css`
        .react_select__control {
          border: 1px solid ${colors.shades.times1};
        }
        .react_select__control:hover,
        .react_select__control--is-focused {
          border: 1px solid ${colors.shades.times2};
          box-shadow: inset 0px 0px 0px 2px ${colors.shades.times2};
        }
        .react_select__control--is-focused {
          border: 1px solid ${colors.accent1.base};
          box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
          outline: none;
        }
      `;
};

export const StyledSuitedSingleSelect = styled(SuitedSingleSelect)<{
  hasError: boolean;
}>`
  ${({ hasError }) => {
    return css`
      margin-bottom: ${sizes.gridUnits.times4};
      ${SuitedFormLabelEl} {
        color: ${typography.color.light.base};
      }
      ${hasError ? getValidationBorderStyle(true) : getValidationBorderStyle(false)}/*
      .react_select__control, .react_select__control--is-focused {
        ${hasError ? getValidationBorderStyle(true) : getValidationBorderStyle(false)}
      }
      */
    `;
  }}
`;

export const StyledSuitedInputErrorMessage = styled(SuitedInputErrorMessage)`
  position: absolute;
  bottom: -${sizes.gridUnits.times4};
`;
