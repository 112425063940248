import React, { useState } from "react";
import { ISuitedSingleSelectProps } from "suited/components/shared/inputs/select/SuitedSingleSelect";
import ISelectOption from "suited/components/shared/types/ISelectOption";

import {
  StyledSuitedInputErrorMessage,
  StyledSuitedSingleSelect,
  SuitedValidatedSingleSelectEl
} from "./SuitedValidatedSingleSelect.style";

interface ISuitedValidatedSingleSelectProps extends ISuitedSingleSelectProps {
  overDark?: boolean;
  error?: string;
  invalid?: boolean;
  title?: string;
  required?: boolean;
  "data-testid"?: string;
}

const SuitedValidatedSingleSelect: React.FunctionComponent<ISuitedValidatedSingleSelectProps> = (
  props
) => {
  const [touched, setTouched] = useState<boolean>(props.invalid || false);

  const handleChange = (value: ISelectOption): void => {
    props.onChange(value);
    setTouched(false);
  };

  const getDefaultErrorMessage = () => {
    if (props.error) return props.error;
    return props.required
      ? "This field is required. Please enter a value."
      : "This field is not valid. Please try again.";
  };

  // when `invalid` is not managed, a required input with value "" is derived to be invalid
  const deriveIsInvalid = (value: ISelectOption | null) => {
    if (props.invalid) {
      return props.invalid;
    } else if (props.required && props.invalid) {
      return true;
    } else if (props.required && value === null) {
      return true;
    } else {
      return false;
    }
  };

  const deriveErrorMessage = () => {
    if (props.error) {
      return props.error;
    } else {
      return getDefaultErrorMessage();
    }
  };

  return (
    <SuitedValidatedSingleSelectEl className={props.className} data-testid={props["data-testid"]}>
      <StyledSuitedSingleSelect
        overDark={props.overDark}
        name={props.name}
        label={props.label}
        value={props.value}
        options={props.options}
        onChange={handleChange}
        hasError={touched && deriveIsInvalid(props.value)}
      />
      <StyledSuitedInputErrorMessage
        error={deriveErrorMessage()}
        show={touched && deriveIsInvalid(props.value)}
        validationFor={props.name}
        data-test="error"
      />
    </SuitedValidatedSingleSelectEl>
  );
};

export default SuitedValidatedSingleSelect;
