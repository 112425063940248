import { TransitionState, QuestionSchema } from "./types";
import QuestionHeader from "./QuestionHeader";
import ErrorMessage from "./ErrorMessage";
import MultiSelect from "./Inputs/MultiSelect";
import SingleSelect from "./Inputs/SingleSelect";
import Dropdown from "./Inputs/Dropdown";
import Rating from "./Inputs/Rating";
import Text from "./Inputs/Text";
import Number from "./Inputs/Number";
import Confirm from "./Inputs/Confirm";
import PointAllocation from "./Inputs/PointAllocation";
import { QuestionContainer, QuestionInputContainer } from "./Question.style";

interface QuestionProps {
  schema: QuestionSchema;
  answer: string | string[];
  error: string;
  transition: TransitionState;
  setAnswer(value: any): void;
  advanceToNext(): void;
  disabled: boolean;
}

export default function Question({
  schema,
  answer,
  error,
  transition,
  setAnswer,
  advanceToNext,
  disabled
}: QuestionProps) {
  const setAnswerAndAdvance = (value: any) => {
    setAnswer(value);
    advanceToNext();
  };
  return (
    <QuestionContainer type={schema.type} transition={transition}>
      <QuestionHeader hidden={false} schema={schema} />
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      <QuestionInputContainer>
        {(() => {
          if (schema.type === "multiple_choice") {
            if (schema.properties.allow_multiple_selection) {
              return (
                <MultiSelect
                  name={schema.id}
                  active={true}
                  values={(answer as string[]) || []}
                  options={schema.properties.choices}
                  onChange={setAnswer}
                  onSubmit={advanceToNext}
                  rows={schema.properties && schema.properties.vertical_alignment ? 1 : 2}
                  disabled={disabled}
                />
              );
            } else {
              return (
                <SingleSelect
                  name={schema.id}
                  active={true}
                  value={(answer as string) || ""}
                  options={schema.properties.choices}
                  onChange={setAnswerAndAdvance}
                  rows={schema.properties && schema.properties.vertical_alignment ? 1 : 2}
                  disabled={disabled}
                />
              );
            }
          } else if (schema.type === "dropdown") {
            return (
              <Dropdown
                name={schema.id}
                active={true}
                value={answer as string}
                options={schema.properties.choices}
                onChange={setAnswerAndAdvance}
                disabled={disabled}
              />
            );
          } else if (schema.type === "rating") {
            return (
              <Rating
                name={schema.id}
                active={true}
                value={answer as string}
                lowLabel={
                  schema.properties && schema.properties.labels ? schema.properties.labels.left : ""
                }
                highLabel={
                  schema.properties && schema.properties.labels
                    ? schema.properties.labels.right
                    : ""
                }
                onChange={setAnswerAndAdvance}
                disabled={disabled}
              />
            );
          } else if (schema.type === "short_text") {
            return (
              <Text
                name={schema.id}
                active={true}
                value={answer as string}
                onChange={setAnswer}
                onSubmit={advanceToNext}
                disabled={disabled}
              />
            );
          } else if (schema.type === "number") {
            return (
              <Number
                name={schema.id}
                active={true}
                value={answer as string}
                min={schema.properties && schema.properties.min ? schema.properties.min : null}
                max={schema.properties && schema.properties.max ? schema.properties.max : null}
                onChange={setAnswer}
                onSubmit={advanceToNext}
                disabled={disabled}
              />
            );
          } else if (schema.type === "point_allocation") {
            return (
              <PointAllocation
                name={schema.id}
                value={(answer as string[]) || []}
                questions={schema.properties.questions}
                totalPoints={schema.properties.total_points}
                active={true}
                onChange={setAnswer}
                onSubmit={advanceToNext}
                disabled={disabled}
              />
            );
          } else if (schema.type === "statement") {
            return <Confirm onClick={advanceToNext} />;
          } else {
            return null;
          }
        })()}
      </QuestionInputContainer>
    </QuestionContainer>
  );
}
