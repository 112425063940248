import { HttpResponse, delay, graphql } from "msw";

import { AccountTypes } from "suited/constants/user.constants";

import { INDUSTRY_CONTEXTS } from "../Education/education.constants";
import { AssessmentIds } from "../lateral/lateral.constants";
import {
  assessmentStatusTypes,
  mockItemGroupIDs,
  mockSectionIDs,
  sectionStatusTypes
} from "./eca.constants";
import { AssessmentIds as ECAAssessmentIds } from "./eca.constants";
import { db } from "./eca.models";

export const getMockMatrixTableItemGroup = () => {
  return db.matrixTableItemGroup.findFirst({
    where: {
      id: {
        equals: mockItemGroupIDs.MATRIX_TABLE
      }
    }
  });
};

export const getPreFilledMockMatrixTableItemGroup = () => {
  return db.matrixTableItemGroup.update({
    where: {
      id: {
        equals: mockItemGroupIDs.MATRIX_TABLE
      }
    },
    data: {
      items(prevItems) {
        // @mswjs/data only updates when you mutate an array,
        // hence the use of forEach over map
        prevItems.forEach((item) => {
          item.value = "Uncertain";
        });
        return prevItems;
      }
    }
  });
};

export const getMockMultiSelectItemGroup = () => {
  return db.multiSelectItemGroup.findFirst({
    where: {
      id: {
        equals: mockItemGroupIDs.MULTI_SELECT
      }
    }
  });
};

export const getPreFilledNonExclusiveMockMultiSelectItemGroup = () => {
  return db.multiSelectItemGroup.update({
    where: {
      id: {
        equals: mockItemGroupIDs.MULTI_SELECT
      }
    },
    data: {
      items(prevItems) {
        // @mswjs/data only updates when you mutate an array,
        // hence the use of forEach over map
        prevItems.forEach((item) => {
          item.options.forEach((option) => {
            if (!option.isExclusive) {
              option.isSelected = true;
            }
          });
        });
        return prevItems;
      }
    }
  });
};

export const getPreFilledExclusiveMockMultiSelectItemGroup = () => {
  return db.multiSelectItemGroup.update({
    where: {
      id: {
        equals: mockItemGroupIDs.MULTI_SELECT
      }
    },
    data: {
      items(prevItems) {
        // @mswjs/data only updates when you mutate an array,
        // hence the use of forEach over map
        prevItems.forEach((item) => {
          item.options.forEach((option) => {
            if (option.isExclusive) {
              option.isSelected = true;
            }
          });
        });
        return prevItems;
      }
    }
  });
};

export const getMockTextItem = () => {
  return db.textInputItemGroup.findFirst({
    where: {
      id: {
        equals: mockItemGroupIDs.TEXT_INPUT
      }
    }
  });
};

export const getMockPreFilledTextItem = () => {
  return db.textInputItemGroup.update({
    where: {
      id: {
        equals: mockItemGroupIDs.TEXT_INPUT
      }
    },
    data: {
      items(prevItems) {
        // // @mswjs/data only updates when you mutate an array,
        // // hence the use of forEach over map
        prevItems.forEach((item) => {
          item.value = "16";
        });
        return prevItems;
      }
    }
  });
};

const getNextSectionFactory =
  (queryName: string, assessmentID = "") =>
  () => {
    const updatedAssessment = db.GetAssessment.findFirst({
      where: {
        id: {
          equals: assessmentID
        }
      }
    });

    const nextCompletedSectionIndex = updatedAssessment?.sections.findIndex((section) => {
      return section.status === assessmentStatusTypes.COMPLETE;
    });

    const sections = db[queryName].getAll();

    // Is first section
    if (nextCompletedSectionIndex === -1) {
      return sections[0];
    }

    // Remaining sections
    const getIsSectionComplete = ({ status }) => status !== sectionStatusTypes.COMPLETE;
    const nextSection = sections.find(getIsSectionComplete);

    return nextSection;
  };

export const ecaMockHandlers = [
  graphql.query("GetCandidateAssessments", () => {
    return HttpResponse.json({
      data: {
        GetCandidateAssessments: db.GetCandidateAssessment.getAll()
      }
    });
  }),

  graphql.query("GetAssessment", ({ variables }) => {
    const assessment = db.GetAssessment.findFirst({
      where: { id: { equals: variables.assessmentID } }
    });

    return HttpResponse.json({
      data: {
        GetAssessment: assessment
      }
    });
  }),

  graphql.query("GetNextAssessmentSectionInformation", () => {
    const getNextAssessmentSectionInformation = getNextSectionFactory(
      "GetNextAssessmentSectionInformation",
      ECAAssessmentIds.ECA_ASSESSMENT
    );
    return HttpResponse.json({
      data: {
        GetNextAssessmentSectionInformation: getNextAssessmentSectionInformation()
      }
    });
  }),

  graphql.query("GetNextAssessmentSectionQuestions", ({ variables }) => {
    const { assessmentID } = variables;

    if (assessmentID === AssessmentIds.LATERAL_LAW) {
      const updatedAssessment = db.GetAssessment.findFirst({
        where: {
          id: {
            equals: assessmentID
          }
        }
      });

      const nextCompletedSectionIndex = updatedAssessment?.sections.findIndex((section) => {
        return section.status === assessmentStatusTypes.COMPLETE;
      });

      const sections = db.GetNextAssessmentSectionQuestions.getAll();

      // Is first section
      if (nextCompletedSectionIndex === -1) {
        const nextSection = sections.find(({ id }) => id === mockSectionIDs.MULTIPLE_CHOICE);

        return HttpResponse.json({
          data: {
            GetNextAssessmentSectionQuestions: nextSection
          }
        });
      }

      // Remaining sections
      const getIsSectionComplete = ({ status }) => status !== sectionStatusTypes.COMPLETE;
      const nextSection = sections.find(getIsSectionComplete);

      return HttpResponse.json({
        data: {
          GetNextAssessmentSectionQuestions: nextSection
        }
      });
    }

    const getNextAssessmentSectionQuestions = getNextSectionFactory(
      "GetNextAssessmentSectionQuestions",
      ECAAssessmentIds.ECA_ASSESSMENT
    );

    return HttpResponse.json({
      data: {
        GetNextAssessmentSectionQuestions: getNextAssessmentSectionQuestions()
      }
    });
  }),

  graphql.mutation("SubmitItemResponse", () => {
    return HttpResponse.json({
      data: {
        SubmitItemResponse: {
          status: "COMPLETE",
          timeRemaining: 470
        }
      }
    });
  }),

  graphql.mutation("AssessmentSectionClose", ({ request, variables }) => {
    const page = request.referrer;
    const isLateralAssessment = page.includes("lateral");
    const assessmentID = isLateralAssessment
      ? AssessmentIds.LATERAL_LAW
      : ECAAssessmentIds.ECA_ASSESSMENT;
    const { id } = variables;
    // Mark section as completed for unit testing purposes
    db.completedSections.create({ sectionId: id, isCompleted: true });

    db.GetAssessment.update({
      where: {
        id: {
          equals: assessmentID
        }
      },
      data: {
        sections(prevSections) {
          prevSections.forEach((section) => {
            if (section.id === id) {
              section.status = sectionStatusTypes.COMPLETE;
            }
          });
          return prevSections;
        }
      }
    });

    db.GetNextAssessmentSectionInformation.update({
      where: {
        id: {
          equals: id
        }
      },
      data: {
        status: sectionStatusTypes.COMPLETE
      }
    });

    db.GetNextAssessmentSectionQuestions.update({
      where: {
        id: {
          equals: id
        }
      },
      data: {
        status: sectionStatusTypes.COMPLETE
      }
    });

    const allSectionsCompleted = db.section
      .findMany({
        where: {
          id: {
            equals: assessmentID
          }
        }
      })
      .every((section) => section.status === sectionStatusTypes.COMPLETE);

    if (allSectionsCompleted) {
      db.GetAssessment.update({
        where: {
          id: {
            equals: assessmentID
          }
        },
        data: {
          status: assessmentStatusTypes.COMPLETE
        }
      });

      db.GetCandidateAssessment.update({
        where: {
          id: {
            equals: assessmentID
          }
        },
        data: {
          status: assessmentStatusTypes.COMPLETE
        }
      });
    }

    return HttpResponse.json({
      data: {
        AssessmentSectionClose: "COMPLETE"
      }
    });
  }),

  graphql.mutation("CreateDefaultAssessments", async () => {
    const assessment = db.GetAssessment.findFirst({
      where: { id: { equals: ECAAssessmentIds.ECA_ASSESSMENT } }
    });

    await delay(2000);

    return HttpResponse.json({
      data: {
        ...assessment
      }
    });
  })
];

export const ecaMockHandlerExceptions = {
  NO_CANDIDATE_ASSESSMENTS_AVAILABLE: graphql.query("GetCandidateAssessments", () => {
    return HttpResponse.json({
      data: {
        GetCandidateAssessments: []
      }
    });
  }),
  CANDIDATE_ECA_ASSESSMENTS_COMPLETE: graphql.query("GetCandidateAssessments", () => {
    const assessment = db.GetAssessment.update({
      where: {
        id: {
          equals: ECAAssessmentIds.ECA_ASSESSMENT
        }
      },
      data: {
        status: assessmentStatusTypes.COMPLETE
      }
    });
    return HttpResponse.json({
      data: {
        GetCandidateAssessments: [assessment]
      }
    });
  }),
  // @ts-ignore
  CANDIDATE_ECA_ASSESSMENTS_ERROR: graphql.query("GetCandidateAssessments", () => {
    return new Response(
      JSON.stringify({
        errors: [
          {
            message: "Error loading candidate assessments",
            locations: [
              {
                line: 8,
                column: 12
              }
            ]
          }
        ]
      }),
      {
        status: 400,
        headers: { "Content-Type": "application/json" }
      }
    );
  }),
  CANDIDATE_ECA_ASSESSMENT_COMPLETE: graphql.query("GetAssessment", ({ variables }) => {
    const { assessmentID } = variables;
    return HttpResponse.json({
      data: {
        GetAssessment: db.GetAssessment.update({
          where: {
            id: {
              equals: assessmentID
            }
          },
          data: {
            status: assessmentStatusTypes.COMPLETE
          }
        })
      }
    });
  }),
  GET_NEXT_ASSESSMENT_SECTION_INFO_TIMER_PARTIAL_MINUTE: graphql.query(
    "GetNextAssessmentSectionInformation",
    () => {
      return HttpResponse.json({
        data: {
          GetNextAssessmentSectionInformation: db.GetNextAssessmentSectionInformation.update({
            where: {
              id: {
                equals: mockSectionIDs.MATRIX_TABLE
              }
            },
            data: {
              timerDuration: 270
            }
          })
        }
      });
    }
  ),
  GET_NEXT_ASSESSMENT_SECTION_INFO_UNTIMED: graphql.query(
    "GetNextAssessmentSectionInformation",
    () => {
      return HttpResponse.json({
        data: {
          GetNextAssessmentSectionInformation: db.GetNextAssessmentSectionInformation.update({
            where: {
              id: {
                equals: mockSectionIDs.MATRIX_TABLE
              }
            },
            data: {
              timerDuration: null
            }
          })
        }
      });
    }
  ),
  GET_NEXT_ASSESSMENT_SECTION_INFORMATION_WITH_COMPLETED_SECTIONS: graphql.query(
    "GetNextAssessmentSectionInformation",
    () => {
      return HttpResponse.json({
        data: {
          GetNextAssessmentSectionInformation: db.GetNextAssessmentSectionInformation.update({
            where: {
              id: {
                equals: mockSectionIDs.MATRIX_TABLE
              }
            },
            data: {
              completedSections: 1
            }
          })
        }
      });
    }
  ),
  GET_NEXT_ASSESSMENT_SECTION_INFORMATION_IS_INFORMATIONAL_ONLY: graphql.query(
    "GetNextAssessmentSectionInformation",
    () => {
      const sectionInfoCalls = db.sectionInfoCalls.getAll().find(Boolean);
      const id = sectionInfoCalls?.id;
      const callsToServer = sectionInfoCalls?.calls || 0;

      if (sectionInfoCalls) {
        db.sectionInfoCalls.update({
          where: {
            id: {
              equals: id
            }
          },
          data: {
            calls: callsToServer + 1
          }
        });
      } else {
        db.sectionInfoCalls.create({ calls: callsToServer + 1 });
      }

      return HttpResponse.json({
        data: {
          GetNextAssessmentSectionInformation: db.GetNextAssessmentSectionInformation.update({
            where: {
              id: {
                equals: mockSectionIDs.MATRIX_TABLE
              }
            },
            data: {
              isInformational: true
            }
          })
        }
      });
    }
  ),
  GET_NEXT_ASSESSMENT_SECTION_INFORMATION_ECA_ALREADY_COMPLETED: graphql.query(
    "GetNextAssessmentSectionInformation",
    () => {
      return HttpResponse.json({
        data: {
          GetNextAssessmentSectionInformation: {
            id: null,
            instructions: null,
            isInformational: null,
            sampleItemGroups: null,
            status: null,
            timerDuration: null,
            timerRemaining: null,
            completedSections: 4,
            totalSections: 4,
            __typename: "SectionInformation"
          }
        }
      });
    }
  ),
  GET_NEXT_ASSESSMENT_SECTION_QUESTIONS_UNTIMED: graphql.query(
    "GetNextAssessmentSectionQuestions",
    () => {
      return HttpResponse.json({
        data: {
          GetNextAssessmentSectionQuestions: db.GetNextAssessmentSectionQuestions.update({
            where: {
              id: {
                equals: mockSectionIDs.MATRIX_TABLE
              }
            },
            data: {
              timeRemaining: null
            }
          })
        }
      });
    }
  ),
  GET_ASSESSMENT_WITH_COMPLETED_SECTIONS: graphql.query("GetAssessment", () => {
    return HttpResponse.json({
      data: {
        GetAssessment: db.GetAssessment.update({
          where: {
            id: {
              equals: ECAAssessmentIds.ECA_ASSESSMENT
            }
          },
          data: {
            sections(prevSections) {
              prevSections[0].status = sectionStatusTypes.COMPLETE;
              return prevSections;
            }
          }
        })
      }
    });
  }),
  GET_NEXT_ASSESSMENT_SECTION_QUESTIONS_MULTI_SELECT: graphql.query(
    "GetNextAssessmentSectionQuestions",
    () => {
      return HttpResponse.json({
        data: {
          GetNextAssessmentSectionQuestions: db.GetNextAssessmentSectionQuestions.findFirst({
            where: {
              id: {
                equals: mockSectionIDs.MULTI_SELECT
              }
            }
          })
        }
      });
    }
  ),
  GET_NEXT_ASSESSMENT_SECTION_QUESTIONS_TEXT_INPUT: graphql.query(
    "GetNextAssessmentSectionQuestions",
    () => {
      return HttpResponse.json({
        data: {
          GetNextAssessmentSectionQuestions: db.GetNextAssessmentSectionQuestions.findFirst({
            where: {
              id: {
                equals: mockSectionIDs.TEXT_INPUT
              }
            }
          })
        }
      });
    }
  ),
  GET_NEXT_ASSESSMENT_SECTION_QUESTIONS_WITH_SINGLE_ITEM_GROUP: graphql.query(
    "GetNextAssessmentSectionQuestions",
    () => {
      return HttpResponse.json({
        data: {
          GetNextAssessmentSectionQuestions: db.GetNextAssessmentSectionQuestions.update({
            where: {
              id: {
                equals: mockSectionIDs.MATRIX_TABLE
              }
            },
            data: {
              itemGroups(prevItemGroups) {
                // Reduce to 1 item group for easier testing, since  we have to
                // fill out all form fields in order to enable "Next" button
                prevItemGroups.splice(1, prevItemGroups.length - 1);
                return prevItemGroups;
              }
            }
          })
        }
      });
    }
  ),
  GET_ASSESSMENT_FINAL_SECTION: graphql.query("GetAssessment", () => {
    return HttpResponse.json({
      data: {
        GetAssessment: db.GetAssessment.update({
          where: {
            id: {
              equals: ECAAssessmentIds.ECA_ASSESSMENT
            }
          },
          data: {
            sections(prevSections) {
              // Mark all sections except last one as complete
              prevSections.forEach((section, i) => {
                if (i < 3) {
                  section.status = sectionStatusTypes.COMPLETE;
                }
              });
              return prevSections;
            }
          }
        })
      }
    });
  }),

  PARTIALLY_COMPLETED_ASSESSMENT: graphql.query("GetAssessment", () => {
    return HttpResponse.json({
      data: {
        GetAssessment: {
          __typename: "AssessmentData",
          id: "c3feb71f-db6e-411a-8d6a-350d8c3b825c",
          title: "Essential Competencies Assessment",
          status: "READY",
          sections: [
            {
              __typename: "SectionSummary",
              id: "7340660f-50e1-4e74-accb-505ac10cff09",
              status: "COMPLETE"
            },
            {
              __typename: "SectionSummary",
              id: "9b87ea09-56f5-4d6f-8a30-0894b95fe2f1",
              status: "READY"
            },
            {
              __typename: "SectionSummary",
              id: "f81d52db-229a-4414-bdee-75b9092d12df",
              status: "READY"
            }
          ]
        }
      }
    });
  }),
  // @ts-ignore
  ECA_PROVISION_ERROR: graphql.mutation("CreateDefaultAssessments", () => {
    return new Response(
      JSON.stringify({
        errors: [
          {
            message: "Error provisioning ECA",
            locations: [
              {
                line: 8,
                column: 12
              }
            ]
          }
        ]
      }),
      {
        status: 400,
        headers: { "Content-Type": "application/json" }
      }
    );
  }),

  ECA_REASONABLE_ACCOMMODATION: graphql.query("GetUserBootstrap", () => {
    return HttpResponse.json({
      data: {
        GetUserSurveyAnswers: null,
        GetUser: {
          id: "fd334c1f-f8d8-4347-85e8-cb410744c94c",
          firstName: "Test",
          lastName: "User",
          email: "testuser+test-school@wellsuited.com",
          accountType: AccountTypes.CAMPUS,
          userProfileCompletion: {
            completedSchools: true,
            completedPersonalInformation: false,
            completedResume: false,
            __typename: "UserProfileCompletionData"
          },
          settings: {
            visibility: "public",
            __typename: "UserSettings"
          },
          industryContext: {
            id: INDUSTRY_CONTEXTS.FINANCE,
            name: INDUSTRY_CONTEXTS.FINANCE,
            __typename: "IndustryContext"
          },
          accommodations: {
            assessmentTimerMultiplier: 1.5
          },
          __typename: "User"
        },
        GetAssessments: []
      }
    });
  })
};
