import React from "react";
import { CSSTransition } from "react-transition-group";

import {
  StyledSuitedInputStatusMessage,
  StyledSuitedInputStatusMessageIcon,
  StyledSuitedInputStatusMessageText
} from "./SuitedInputStatusMessage.style";

export interface ISuitedInputStatusMessageProps {
  /** The `id` of the message text. The `id` can be used to associate this element to other elements for aria accessibility. */
  id?: string;
  /** The status message. Just one unformatted string. This will ellipsis w/ title-attribute hover if too long to fit container. */
  message: string;
  /** Animate the error message to show and hide */
  show: boolean;
  /** When the text is over a dark background, it has a light appearance. */
  overDark?: boolean;
  /** Opt-into line-wrapping (changes layout) */
  noEllipsis?: boolean;
  /** for Styled Component compatibility */
  className?: string;
  /** The type of status message to render, determines the color of the message. */
  statusType: "error" | "success";
  /** Validation error */
  error: boolean;
}

// @TODO - consider revising API to render either `message` OR children. (or make a breaking change to only use children?). Accept any React node, not just a string?
export const SuitedInputStatusMessage = (props: ISuitedInputStatusMessageProps) => {
  const { message, show, noEllipsis, id, ...passthroughProps } = props;
  const iconId = `${id}-icon`;
  const iconName = props.statusType === "error" ? "times" : "check";
  const iconAriaLabel = props.statusType === "error" ? "invalid input" : "valid input";
  return (
    <StyledSuitedInputStatusMessage
      {...passthroughProps}
      aria-live="polite"
      data-testid="component-suited-input-status-message"
    >
      <CSSTransition in={show} timeout={300} unmountOnExit={true}>
        <StyledSuitedInputStatusMessageText
          id={id}
          title={message}
          noEllipsis={noEllipsis}
          aria-describedby={iconId}
        >
          <StyledSuitedInputStatusMessageIcon
            aria-label={iconAriaLabel}
            id={iconId}
            name={iconName}
          />
          <span>{message}</span>
        </StyledSuitedInputStatusMessageText>
      </CSSTransition>
    </StyledSuitedInputStatusMessage>
  );
};

/**
 * ## What
 * `<SuitedInputErrorMessage>` is a single-input validation failure message to appear directly below an input.
 *
 * ## Where
 * It should always appear directly underneath the input it is validating. It can also be used for whole-form validation messaging.
 * It is most commonly intended to display in a static reserved space under an input, where only one message will be visible at a time if there are multiple messages. This rule can be broken sparingly, if absolutely necessary for the use case.
 *
 * ## Not
 * This should not be used to communicate the status of network transactions. It's only for validation state.
 *
 * ## Example
 * In Candidate App, Registration's Password input uses this component to indicate when the password does not meet password strength requirements.
 *
 * This component renders and animates contextual error messages designed to be placed directly below an input.
 * It renders in the brand error coloring. Works on dark backgrounds too, with the `overDark` prop.
 * By default, it truncates text that would overflow with ellipsis. Browser-tooltips will display the full message text.
 * For use cases that permit vertical layout changes, the truncation can be turned off with `noEllipsis`.
 * At present, layout will pop at the beginning of an un-truncated error message `show` transition.
 */
export const SuitedInputErrorMessage: React.FunctionComponent<
  Omit<ISuitedInputStatusMessageProps, "statusType">
> = (props) => {
  return (
    <SuitedInputStatusMessage
      {...props}
      statusType="error"
      data-testid="component-suited-input-error-message"
    />
  );
};

/**
 * ## What
 * `<SuitedInputSuccessMessage>` is a single-input validation confirmation message to appear directly below an input.
 *
 * ## Where
 * It should always appear directly underneath the input it is validating. It can also be used for whole-form validation messaging.
 * It is most commonly intended to display in a static reserved space under an input, where only one message will be visible at a time if there are multiple messages. This rule can be broken sparingly, if absolutely necessary for the use case.
 *
 * ## Not
 * This should not be used to communicate the status of network transactions. It's only for validation state.
 *
 * ## Example
 * In Candidate App, Registration's Password input OUGHT TO (currently does not?) use this component to indicate when the password passes password strength requirements.
 *
 * This component renders and animates contextual success messages designed to be placed directly below an input.
 * It renders in the brand success coloring. Works on dark backgrounds too, with the `overDark` prop.
 * By default, it truncates text that would overflow with ellipsis. Browser-tooltips will display the full message text.
 * For use cases that permit vertical layout changes, the truncation can be turned off with `noEllipsis`.
 * At present, layout will pop at the beginning of an un-truncated error message `show` transition.
 */
export const SuitedInputSuccessMessage: React.FunctionComponent<
  Omit<ISuitedInputStatusMessageProps, "statusType">
> = (props) => {
  return (
    <SuitedInputStatusMessage
      {...props}
      statusType="success"
      data-testid="component-suited-input-success-message"
    />
  );
};

