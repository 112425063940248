import { useHistory, useRouteMatch } from "react-router-dom";

import { Box, Center, Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { ProgressBar } from "suited/components/shared/feedback/ProgressBar/ProgressBar";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { useAppSelector } from "suited/util/hooks/reduxHooks";

import { DEFAULT_FILL } from "../ECAProgressBar/ECAProgressBar.constants";
import { useGetNextAssessmentSectionInformation } from "../eca.hooks";
import { getNumberOfCompletedSections } from "../eca.slice";
import { getECAPercentComplete } from "../eca.utils";
import { ECAInProgressContent } from "./ECAInProgressPage.style";

export const ECAInProgressPage = ({ accommodationMultiplier = 1.0 }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const numberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);

  const startAssessment = () => {
    const sectionContentRoute = `${match.url}/content`;
    history.push(sectionContentRoute);
  };

  const sectionInformationResponse = useGetNextAssessmentSectionInformation();
  const { totalSections } = sectionInformationResponse;

  const percentCompleted =
    totalSections == null ? 0 : getECAPercentComplete(numberOfCompletedSections, totalSections);
  const progress = percentCompleted === 0 ? DEFAULT_FILL : percentCompleted;
  const displayText = percentCompleted === 0 ? 0 : progress;
  const hasReasonableAccommodation = accommodationMultiplier !== 1;

  return (
    <Box padding="xl" overflow="auto">
      <Center>
        <ECAInProgressContent>
          <Stack space="xl">
            <SuitedCopyHeadline noMargin>Assessment In Progress</SuitedCopyHeadline>
            {hasReasonableAccommodation ? (
              <SuitedCopySubheadline noMargin noMarginTop important>
                {accommodationMultiplier}× Reasonable Accommodation
              </SuitedCopySubheadline>
            ) : null}
            <p>
              Your Cognitive Skills Assessment is still in progress. To start where you left off,
              please click the "Continue Assessment" button below.
            </p>
            <p>
              You will resume the assessment at the section after the last one you finished. If you
              exited mid-section, you will return to that section unless the timer has expired.
            </p>
            <Box>
              <ProgressBar progress={progress} displayText={displayText} />
            </Box>
            <Center>
              <SuitedButton
                purpose="primary"
                data-analytics-id="eca-assessment-continue"
                onClick={startAssessment}
              >
                Continue Assessment
              </SuitedButton>
            </Center>
          </Stack>
        </ECAInProgressContent>
      </Center>
    </Box>
  );
};
