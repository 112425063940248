import React from "react";

import { Box, Flex, Stack } from "@suited/components";
import SuitedIconButton from "suited/components/shared/buttons/SuitedIconButton";
import { colors } from "suited/constants/style-constants";

interface Props {
  onClear: () => void;
  onDownload: () => void;
  layout: (props: any) =>  JSX.Element | Element;
  data: any;
}

const Complete = (props: Props) => {
  const { onClear, onDownload, ...passthroughProps } = props;
  const handleClear = () => {
    onClear();
  };

  const handleDownload = () => {
    onDownload();
  };

  return (
    <Box
      padding="xxs"
      borderWidth="2px"
      borderRadius="4px"
      minWidth="50ch"
      maxWidth="65ch"
      height="240px"
      borderColor={colors.shades.times1}
      bg={colors.tints.mid}
      data-test="component-suited-uploader-card-complete"
      {...passthroughProps}
    >
      <Stack space="none" width="100%">
        <Flex justify="flex-end">
          <SuitedIconButton
            iconName="trash"
            iconWeight="solid"
            tooltip="Remove uploaded file"
            onClickButton={handleClear}
            data-test="icon-button"
            data-analytics-id="delete-resume"
            style={{ position: "absolute" }}
          />
        </Flex>
        <Box padding="xl">
          {props.layout({
            data: props.data,
            onDownload: handleDownload
          }) as React.ReactNode}
        </Box>
      </Stack>
    </Box>
  );
};

export default Complete;
