import gql from "graphql-tag";

export const GET_USER_SETTINGS = gql`
  query GetUserSettings {
    GetUser {
      id
      firstName
      lastName
      email
      settings {
        visibility
        changeEmail {
          newEmail
          emailStatus
          initiatedAt
          isChangeWindowOpen
        }
      }
      secondaryEmail
      linkedInUrl
      phoneNumber
      phoneNumberLocale
    }
  }
`;
