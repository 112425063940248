import * as React from "react";
import { SplashPaper } from "./SuitedSplashPaper.style";

type Props = {
  className?: string;
  children?: React.ReactNode;
};

/**
 * Paper is a responsive layout wrapper that appears as a piece of paper in the workspace.
 * It applies padding and typography appropriate to the current responsive media query target.
 */
const SuitedSplashPaper = ({ className, children }: Props) => {
  return (
    <SplashPaper className={className} data-test="component-splash-paper">
      {children}
    </SplashPaper>
  );
};

export default SuitedSplashPaper;
