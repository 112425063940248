import { FunctionComponent, useState } from "react";
import SuitedDialogMountWrapper from "suited/components/shared/layout/Dialogs/SuitedDialogMount";
import SuitedDialogAlert from "suited/components/shared/layout/Dialogs/SuitedDialogAlert";
import { Redirect } from "react-router-dom";

/**
 * Displays a Suited Alert Dialog letting the user know
 * their session has expired, but triggers on GetUser error.
 */
const GetUserQueryError: FunctionComponent = () => {
  const [showAlert, setShowAlert] = useState<boolean>(true);

  if (!showAlert) {
    return <Redirect to="/login" push />;
  }

  return (
    <SuitedDialogMountWrapper show={showAlert}>
      <SuitedDialogAlert
        show={showAlert}
        title="User Session Expired"
        message={
          "The previously logged in user session has expired. Clicking Ok below will redirect you to the login page."
        }
        onCancel={() => setShowAlert(false)}
        onConfirm={() => setShowAlert(false)}
      />
    </SuitedDialogMountWrapper>
  );
};

export default GetUserQueryError;
