import styled from "styled-components";

import { typography, sizes, colors, animationCurves } from "suited/constants/style-constants";
import { timeouts } from "suited/constants/interaction-constants";

export const Errors = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  margin: 0 ${typography.margins.input};
  padding: 0;
  min-height: ${sizes.gridUnits.times3};
  overflow: hidden;
  list-style: none;
  color: ${colors.status.error.shade1};
`;

export const ErrorText = styled.li`
  padding-top: ${sizes.gridUnits.times1};
  padding-right: ${sizes.gridUnits.times1};
  padding-bottom: ${sizes.gridUnits.divide2};
  padding-left: 0;
  color: inherit;
  font-family: ${typography.family.body};
  font-size: calc(${typography.sizes.form.base.hint} + 1px);
  font-weight: ${typography.weight.body.light};
  font-style: italic;
  line-height: 1;
  letter-spacing: ${typography.tracking.slight};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  transform-origin: top center;
  &.enter {
    opacity: 0.01;
    transform: translateY(100%);
  }
  &.enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity ${timeouts.OPACITY_FADE_DURATION}ms ${animationCurves.easeInQuart},
      transform ${timeouts.TRANSITION_MANAGER_DURATION}ms ${animationCurves.easeOutQuart};
  }
  &.exit {
    opacity: 1;
    transform: translateY(-0%);
  }
  &.exit-active {
    opacity: 0.01;
    transform: translateY(-100%);
    transition: opacity ${timeouts.OPACITY_FADE_DURATION}ms ${timeouts.TRANSITION_DELAY}ms
        ${animationCurves.easeOutQuart},
      transform ${timeouts.TRANSITION_MANAGER_DURATION}ms ${animationCurves.easeInQuart};
  }
`;
