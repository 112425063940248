import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

import { Stack } from "@suited/components";
import { requestUserPasswordReset } from "./reducers";
import isValidEmail from "../../util/isValidEmail";
import { SuitedValidatedTextInput } from "suited/components/shared/inputs/SuitedValidatedTextInput";
import {
  PasswordResetFormWrapper,
  PasswordResetButtonRowCentered,
  InstructionsCopy
} from "./UserRequestPasswordReset.style";
import { debounce, timeouts } from "../../constants/interaction-constants";
import AuthenticationTemplate from "../AuthenticationTemplate/AuthenticationTemplate";

interface Props {
  requestUserPasswordReset(email: string): void;
}
interface State {
  email: string;
  resetRequested: boolean;
  invalidEmail: boolean;
  navigateToCandidateLogin: boolean;
}

class UserPasswordReset extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      resetRequested: false,
      invalidEmail: false,
      navigateToCandidateLogin: false
    };
  }

  submit = (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (this.state.email && isValidEmail(this.state.email)) {
      this.props.requestUserPasswordReset(encodeURIComponent(this.state.email));
      this.setState({
        resetRequested: true,
        invalidEmail: false
      });
    } else {
      this.setState({
        invalidEmail: true
      });
    }
  };

  handleChangeEmail = (value: any) => {
    this.setState({
      email: value,
      invalidEmail: isValidEmail(value) ? false : true
    });
  };

  handleClickSignIn = () => {
    this.setState({ navigateToCandidateLogin: true });
  };

  render() {
    if (this.state.navigateToCandidateLogin) {
      return <Redirect push to="/login" />;
    }
    return (
      <AuthenticationTemplate
        headline="Forgot Your Password?"
        ctaText="Sign In"
        ctaOnClick={this.handleClickSignIn}
      >
        <PasswordResetFormWrapper onSubmit={(e) => this.submit(e)}>
          {this.state.resetRequested ? (
            <Stack>
              <InstructionsCopy className="end">
                An email has been sent with instructions for resetting your password. If you don't
                receive an email in a few minutes, please check your spam folder.
              </InstructionsCopy>
              <PasswordResetButtonRowCentered>
                <SuitedButton
                  purpose="primary"
                  overDark={true}
                  onClick={this.handleClickSignIn}
                  delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                >
                  Sign In
                </SuitedButton>
              </PasswordResetButtonRowCentered>
            </Stack>
          ) : (
            <Stack>
              <InstructionsCopy>
                Please submit your email and we'll send you a link to reset your password.
              </InstructionsCopy>
              <SuitedValidatedTextInput
                name="email"
                type="email"
                onInputChange={this.handleChangeEmail}
                value={this.state.email}
                label="Email"
                autoComplete="email"
                required={true}
                invalid={!this.state.email || this.state.invalidEmail}
                error={this.state.invalidEmail ? "Please enter a valid email." : undefined}
                debounceTime={debounce.validation}
                overDark={true}
              />
              <PasswordResetButtonRowCentered>
                <SuitedButton
                  purpose="primary"
                  overDark={true}
                  type="submit"
                  onClick={this.submit}
                  disabled={!this.state.email || this.state.invalidEmail}
                  delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                >
                  Submit
                </SuitedButton>
              </PasswordResetButtonRowCentered>
            </Stack>
          )}
        </PasswordResetFormWrapper>
      </AuthenticationTemplate>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  requestUserPasswordReset: (email: string) => {
    dispatch(requestUserPasswordReset(email));
  }
});

// @ts-ignore
export default connect(null, mapDispatchToProps)(UserPasswordReset);
