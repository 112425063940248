import { useEffect } from "react";

import { useAppSelector, useAppDispatch } from "suited/util/hooks/reduxHooks";
import { assessmentViews } from "../eca.constants";
import { getCurrentAssessmentView, setCurrentAssessmentView } from "../eca.slice";
import { SectionInformationPage } from "../SectionInformationPage/SectionInformationPage";
import { SectionQuestionsPage } from "../SectionQuestionsPage/SectionQuestionsPage";
import { FocusMode } from "suited/components/shared/FocusMode/FocusMode";
import { EndOfAssessmentPage } from "../EndOfAssessmentPage/EndOfAssessmentPage";
import { ECAProgressBar } from "../ECAProgressBar/ECAProgressBar";

// We need to do dynamically render views via Redux rather than react-router
// so that focus mode doesn't re-render between routes and cause unwanted animations
export const ECAContent = () => {
  const currentAssessmentView = useAppSelector(getCurrentAssessmentView);
  const dispatch = useAppDispatch();

  const assessmentViewComponents = {
    [assessmentViews.SECTION_INFO]: <SectionInformationPage />,
    [assessmentViews.SECTION_QUESTIONS]: <SectionQuestionsPage />,
    [assessmentViews.END_OF_ASSESSMENT]: <EndOfAssessmentPage />
  };
  const currentViewComponent = assessmentViewComponents[currentAssessmentView];

  // Reset to the section information page when we the user closes out
  // of the modal. This covers cases where the user hits the back/forward
  // in the browser.
  useEffect(() => {
    return () => {
      dispatch(setCurrentAssessmentView(assessmentViews.SECTION_INFO));
    };
  }, [dispatch]);

  return (
    <FocusMode>
      {currentViewComponent}
      <ECAProgressBar />
    </FocusMode>
  );
};
