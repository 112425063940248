import React from "react";
import styled from "styled-components";

import { typography, sizes } from "../../../constants/style-constants";

interface Props {
  title?: string;
  children: React.ReactNode;
}

export const Value = styled.div`
  color: ${typography.color.dark.base};
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.copy.value};
  font-weight: ${typography.weight.body.regular};
  line-height: normal;
  margin-bottom: ${sizes.gridUnits.times1};
`;

const SuitedDetailValue = (props: Props) => {
  return (
    <Value title={props.title} {...props} data-test="component-suited-detail-value">
      {props.children}
    </Value>
  );
};

export default SuitedDetailValue;
