import "intl-tel-input/styles";
import styled, { createGlobalStyle, css } from "styled-components";

import { colors as redesignColors } from "suited/constants/colors";
import { animationCurves, colors, sizes, typography } from "suited/constants/style-constants";

const getErrorBorderStyle = () => {
  return css`
    &:not(:disabled) {
      border: 1px solid ${colors.status.error.shade1};
    }
    &:not(:disabled):hover,
    &:not(:disabled):focus {
      border: 1px solid ${colors.status.error.base};
      box-shadow: inset 0px 0px 0px 2px ${colors.status.error.shade1};
    }
    &:not(:disabled):focus {
      border: 1px solid ${colors.status.error.base};
      box-shadow: inset 0px 0px 0px 2px ${colors.status.error.shade1};
      outline: none;
    }
  `;
};

const intTelInputCustomStyles = css<{ touched?: boolean; error?: boolean }>`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: ${sizes.gridUnits.times5};
  box-sizing: border-box;
  padding: ${typography.margins.input};
  background-color: ${(props) => (props.touched ? colors.tints.times4 : colors.shades.divide4)};
  border: 1px solid ${colors.shades.times1};
  box-shadow: none;
  color: ${typography.color.dark.base};
  font-size: ${typography.sizes.form.base.input};
  font-family: ${typography.family.body};
  font-weight: ${typography.weight.body.medium};
  letter-spacing: ${typography.tracking.loose};
  z-index: 1;
  transition: background-color 300ms ${animationCurves.easeOutQuart},
    border-color 300ms ${animationCurves.easeOutQuart},
    box-shadow 300ms ${animationCurves.easeOutQuart};

  &::placeholder {
    color: ${typography.color.dark.disabled};
    font-family: ${typography.family.body};
    font-weight: ${typography.weight.body.light};
    letter-spacing: ${typography.tracking.loose};
  }

  &:hover,
  &:focus {
    background-color: ${colors.tints.times4};
    border-color: ${colors.shades.times2};
    box-shadow: inset 0px 0px 0px 2px ${colors.shades.times2};
  }

  &:focus {
    background-color: ${colors.background.light.primary};
    border-color: ${colors.accent1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.shades.divide2};
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 2px transparent;
    text-overflow: ellipsis;

    &::placeholder {
      color: transparent;
    }

    &:hover,
    &:focus {
      background-color: ${colors.shades.divide2};
      border-color: transparent;
      box-shadow: inset 0px 0px 0px 2px transparent;
    }
  }

  border-color: ${(props) => (props.touched ? colors.shades.times2 : colors.shades.times1)};
  ${(props) => (props.error ? getErrorBorderStyle() : null)}
`;

export const PhoneInputGlobalStyles = createGlobalStyle`
  .intl-tel-input-custom-styles {
    ${intTelInputCustomStyles}
    width: 100%;
  }
  .iti__search-input {
    // Styles for the search input field
    padding: 10px;
    font-size: 14px;
  }
`;

export const ServerError = styled.p`
  color: ${redesignColors.englishRed};
  margin-top: 1rem;
`;
