import * as React from "react";

import { InputProps } from "./index";
import {
  LetterIndexStyle,
  SingleSelectContainer,
  Item,
  SingleSelectOptionContainer,
  LetterBox,
  OptionLabel,
} from "./SingleSelect.style";

interface SingleSelectOption {
  value: string;
  label: string;
}

interface SingleSelectProps extends InputProps {
  value: string;
  options: SingleSelectOption[];
  onChange(value): void;
  rows?: number;
  disabled?: boolean;
}

const LetterIndex = ({ index, checked = false }) => {
  return <LetterIndexStyle checked={checked}>{String.fromCharCode(65 + index)}</LetterIndexStyle>;
};

export default class SingleSelect extends React.Component<SingleSelectProps> {
  state = {
    timeoutId: 0,
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
  }

  onKeyDown = (e) => {
    if (this.props.active) {
      const optionIndex = e.keyCode - 65;
      if (optionIndex >= 0 && optionIndex < this.props.options.length) {
        const option = this.props.options[optionIndex];
        this.props.onChange(option.value);
      }
    }
  };

  render() {
    const { name, value, options, onChange, rows = 2, disabled } = this.props;
    return (
      <div>
        <SingleSelectContainer>
          {options.map((option, i) => {
            const checked = value === option.value;
            const onClick = () => onChange(option.value);
            const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
              if (e.keyCode === 32) {
                e.preventDefault();
                onChange(option.value);
              }
            };
            return (
              // @TODO - cleanup `rows`. I think it is unneeded. -EH
              <Item key={`${name}-${i}`} rows={rows}>
                <SingleSelectOptionContainer
                  isChild={true}
                  onClick={onClick}
                  onKeyDown={onKeyDown}
                  checked={checked}
                  tabIndex={0}
                  disabled={disabled}>
                  <LetterBox>
                    <LetterIndex index={i} checked={checked} />
                  </LetterBox>
                  <OptionLabel>{option.label}</OptionLabel>
                </SingleSelectOptionContainer>
              </Item>
            );
          })}
        </SingleSelectContainer>
      </div>
    );
  }
}
