import styled, { css } from "styled-components";

import { typography, colors, animationCurves, sizes } from "../../../constants/style-constants";

const buttonStyles = css`
  flex: 0 0 auto;
  width: ${sizes.gridUnits.times5};
  height: ${sizes.gridUnits.times5};
  margin-left: ${sizes.gridUnits.times1};
  padding: 0;
  border: 1px solid transparent;
  border-radius: 20px;
  outline: none;
  color: ${typography.color.dark.hint};
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  font-size: ${typography.sizes.form.base.buttonIcon};
  transform: scale(1);
  transition: transform 300ms ${animationCurves.easeOutQuart},
    border-color 300ms ${animationCurves.easeOutQuart},
    box-shadow 300ms ${animationCurves.easeOutQuart};
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    color: ${typography.color.dark.base};
    background-color: ${colors.tints.times3};
    transform: scale(1.1);
    border: 1px solid ${colors.shades.times1};
    box-shadow: inset 0px 0px 0px 2px ${colors.shades.times1};
  }
  &:focus:not([disabled]) {
    border: 1px solid ${colors.accent1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
  }
  &:active:not([disabled]),
  &:hover:not([disabled]):active,
  &:focus:not([disabled]):active {
    color: ${typography.color.dark.emphasis};
    background-color: ${colors.tints.mid};
    transform: scale(1);
    border: 1px solid ${colors.accent1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
    transition: transform 100ms ${animationCurves.easeOutQuart};
  }
  &:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed;
  }
`;

export const Button = styled.button`
  ${buttonStyles};
`;

export const A =
  styled.a <
  { disabled: boolean | undefined } >
  `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${buttonStyles}
`;
