import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { FeatureFlagNav } from "@suited/components";
import { shallowEqual } from "@suited/utils";
import { useAppSelector, useAppDispatch } from "suited/util/hooks/reduxHooks";
import {
  showFeatureFlagNav,
  hideFeatureFlagNav,
  setFeatureFlags,
  getFeatureFlagConfig,
  getIsFeatureFlagNavVisible
} from "./FeatureFlags.slice";
import {
  FEATURE_FLAG_URL_PARAM_NAME,
  FEATURE_FLAG_KEY,
  FEATURE_FLAG_VISIBILITY_KEY
} from "./FeatureFlags.constants";

export const FeatureFlags = () => {
  const features = useAppSelector(getFeatureFlagConfig);
  const isFeatureFlagNavVisible = useAppSelector(getIsFeatureFlagNavVisible);

  const dispatch = useAppDispatch();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const featureFlagUrlParamSetting = params.get(FEATURE_FLAG_URL_PARAM_NAME);

  useEffect(() => {
    // If no feature flag param is added to the url bar, don't trigger an action to show or
    // hide the feature flag nav, as we want don't want to reset our settings as we navigate between pages.
    if (featureFlagUrlParamSetting == null) {
      return;
    }

    // If we add ?ff=false to the url bar, then hide the feature flag nav.
    if (featureFlagUrlParamSetting === "false") {
      dispatch(hideFeatureFlagNav());
      dispatch(setFeatureFlags(features));
      localStorage.removeItem(FEATURE_FLAG_VISIBILITY_KEY);
      localStorage.removeItem(FEATURE_FLAG_KEY);
      return;
    }

    // If we add ?ff=true to the navbar or the feature flag nav setting has already been set
    // prior to refreshing the page (so we don't lose our settings on refresh), then show the feature flag nav.
    if (featureFlagUrlParamSetting === "true" || isFeatureFlagNavVisible) {
      dispatch(showFeatureFlagNav());
      localStorage.setItem(FEATURE_FLAG_VISIBILITY_KEY, "true");

      const localStorageFeatureFlagConfig = localStorage.getItem(FEATURE_FLAG_KEY);
      const parsedLocalStorageFeatureFlagConfig =
        (localStorageFeatureFlagConfig && JSON.parse(localStorageFeatureFlagConfig)) || {};
      const isFeatureFlagConfigSavedInLocalStorage = localStorage.getItem(FEATURE_FLAG_KEY);
      const haveFeatureFlagsChanged = !shallowEqual(features, parsedLocalStorageFeatureFlagConfig);

      if (haveFeatureFlagsChanged) {
        const preSavedFeatureFlagConfig = Object.entries(
          parsedLocalStorageFeatureFlagConfig
        ).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        const updatedFeatureFlagConfig = isFeatureFlagConfigSavedInLocalStorage
          ? preSavedFeatureFlagConfig
          : features;

        dispatch(setFeatureFlags(updatedFeatureFlagConfig));
        localStorage.setItem(FEATURE_FLAG_KEY, JSON.stringify(updatedFeatureFlagConfig));
      }
    }
  }, [dispatch, isFeatureFlagNavVisible, featureFlagUrlParamSetting, features]);

  return isFeatureFlagNavVisible ? (
    <FeatureFlagNav
      features={features}
      onSubmit={(values) => {
        dispatch(setFeatureFlags(values));
        localStorage.setItem(FEATURE_FLAG_KEY, JSON.stringify(values));
      }}
    />
  ) : null;
};
