import { TRecaptchaProviderState, RecaptchaProviderAction } from "./types";

function reducer(
  state: TRecaptchaProviderState,
  action: RecaptchaProviderAction
): TRecaptchaProviderState {
  switch (action.type) {
    case "SCRIPT_LOAD_START":
      return {
        ...state,
        loading: true
      };
    case "SCRIPT_LOAD":
      return {
        ...state,
        error: window.grecaptcha ? false : true,
        loading: false,
        loaded: window.grecaptcha ? true : false,
        grecaptcha: window.grecaptcha ? window.grecaptcha : null
      };
    case "SCRIPT_ERROR":
      return {
        ...state,
        error: true,
        loading: false
      };
    case "SCRIPT_TIMEOUT":
      return {
        ...state,
        error: true,
        loading: false
      };
    case "SCRIPT_RELOAD":
      return {
        ...state,
        error: false,
        grecaptcha: null,
        loaded: false,
        loading: false
      };
    case "WIDGET_ERROR":
      return {
        ...state,
        error: true,
        loaded: true,
        loading: false
      };
    default:
      throw new Error(`Unhandled action type for Recaptcha Context Reducer.`);
  }
}

export default reducer;
