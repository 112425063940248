import React from "react";
import styled from "styled-components";

import { InputProps } from "./index";
import Confirm from "./Confirm";
import ErrorMessage from "../ErrorMessage";
import { typography, colors } from "suited/constants/style-constants";

// isNaN can take strings, but the type definition for it shipped with TypeScript doesn't allow this,
// so we're aliasing it here for this use case.
const isNotNumberString = (isNaN as any) as (value: string) => boolean;

const NumberInput = styled.input`
  color: ${colors.accent1.base};
  border: 1px solid ${colors.background.light.backdrop};
  display: inline-block;
  background: ${colors.background.light.tertiary};
  border-radius: 4px;
  outline: 0;
  text-indent: auto;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 44px;
  font-size: 14px;
  padding: 0px 0px 0px 10px;
  &:hover {
    color: ${colors.accent1.base};
    transition: all 0.3s ease-in-out;
  }
  &:focus,
  &:active {
    color: ${colors.accent1.base};
    text-indent: 0;
    background: white;
  }
  &:focus + label,
  &:active + label {
    transform: translateY(-100%);
    font-size: 14px;
    top: 20px;
    left: -15px;
    background-color: transparent;
    color: ${typography.color.dark.hint};
    :before {
      border-radius: 5px;
    }
  }
  + label {
    color: ${typography.color.light.base};
    display: inline-block;
    position: absolute;
    transform: translateX(0);
    top: 25px;
    left: 5px;
    padding: 10px 15px;
    text-shadow: 0 1px 0 rgba(19, 74, 70, 0.4);
    transition: all 0.3s ease-in-out;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow: hidden;
    font-size: 14px;
    background-color: ${typography.color.dark.hint};
  }
  &:before {
    top: 6px;
    left: 5px;
    right: 5px;
    bottom: 6px;
    background: #377d6a;
  }
  &:after {
    top: 0;
    bottom: 0;
    background: #377d6a;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }
`;

interface NumberProps extends InputProps {
  value: string;
  min?: number;
  max?: number;
  onChange(value: string): void;
  onSubmit(): void;
  disabled?: boolean;
}

interface NumberState {
  value: string;
  error: string;
}

export default class Number extends React.Component<NumberProps, NumberState> {
  input: HTMLInputElement | null = null;

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      error: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.active && this.props.active) {
      if (this.input) this.input.focus();
    } else if (prevProps.active && !this.props.active) {
      if (this.input) this.input.blur();
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
      error: this.getError(e.target.value),
    });
  };

  onRef = (el) => {
    this.input = el;
  };

  getError(value): string {
    const { min, max } = this.props;
    if (isNotNumberString(value ? value : this.state.value)) {
      return `Please enter a number`;
    }

    const number = parseFloat(value ? value : this.state.value);
    if (typeof min === "number" && number < min) {
      return `Please enter a number greater than or equal to ${min}.`;
    }
    if (typeof max === "number" && number > max) {
      return `Please enter a number less than or equal to ${max}.`;
    }
    return "";
  }

  handleSubmit = () => {
    this.props.onChange(this.state.value);
    this.props.onSubmit();
  };

  render() {
    const { active, name, disabled } = this.props;
    const value = this.state.value;
    const error = this.state.error;

    return (
      <div className="row box col-xs-12">
        <NumberInput
          ref={this.onRef}
          type="text"
          name={name}
          value={value || ""}
          onChange={this.onChange}
          disabled={disabled}
        />
        {value && error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {active && value && !error && value.trim() !== "" ? (
          <Confirm onClick={this.handleSubmit.bind(this)} />
        ) : null}
      </div>
    );
  }
}
