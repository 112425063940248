// @ts-nocheck
import * as React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { FormDropdownContainer, FormInputs, ContainerBox, SecondaryText } from "../Forms";
import {
  SelectWrapperMulti,
  selectStyles,
  SelectWrapper,
  OverflowFormFieldLabel
} from "./GeneralDropdown.style";
import { IDropdownOption, IDropdownOptionGroup } from "./GeneralSurveyConstants";

type ReactSelectInputChangeAction =
  | "set-value"
  | "input-change"
  | "input-blur"
  | "menu-close";

interface DropdownOption {
  label: string;
  value: string;
}
interface DropdownProps {
  value: DropdownOption | DropdownOption[] | null;
  name: string;
  label: string;
  secondaryText?: string;
  placeholder: string;
  options?: (IDropdownOption | IDropdownOptionGroup)[];
  onChange(value: string, name: string): void;
  onSchoolInputChange?(value: string): void;
  completed: boolean;
  multi: boolean;
  clearable?: boolean;
  async?: boolean;
  loadOptions?(inputValue: string, callback: any): void;
  menuIsOpen?: boolean;
  defaultOptions?: boolean;
  onInputChange?: (inputValue: string, options: { action: ReactSelectInputChangeAction }) => void;
  disabled?: boolean;
  maxMenuHeight?: number;
  children?: React.ReactNode;
}

interface DropdownState {
  completed: boolean;
  focused: boolean;
}

export default class GeneralDropdown extends React.Component<DropdownProps, DropdownState> {
  static defaultProps = {
    placeholder: "Select..."
  };
  constructor(props) {
    super(props);
    this.state = {
      completed: this.props.completed,
      focused: false
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.completed === undefined) {
      return {
        completed:
          nextProps.value || (Array.isArray(nextProps.value) && nextProps.value.length > 0)
            ? true
            : false
      };
    } else {
      return {
        completed: nextProps.completed
      };
    }
  }

  onFocus = () => {
    this.setState({
      completed: true,
      focused: true
    });
  };

  onBlur = () => {
    let props = this.props;
    this.setState({
      completed:
        props.value || (Array.isArray(props.value) && (props.value as DropdownOption[]).length > 0)
          ? true
          : false,
      focused: false
    });
  };

  _onChange = (option) => {
    if (Array.isArray(option) && option.length === 0) {
      this.props.onChange("", this.props.name);
      return;
    }
    if (this.props.multi) {
      this.props.onChange(option, this.props.name);
    } else {
      this.props.onChange(option ? option.value : "", this.props.name);
    }
  };

  render() {
    const { name, value, options, label, multi, clearable = false, async } = this.props;
    let select;
    if (multi) {
      select =
        value && Array.isArray(value) && value.length > 0 ? (
          <SelectWrapperMulti hasValue={true} data-disabled={this.props.disabled}>
            <Select
              styles={selectStyles}
              name={name}
              arrowRenderer={null}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              placeholder={this.state.focused ? "" : this.props.placeholder}
              value={value}
              isMulti={true}
              isClearable={clearable}
              options={options}
              closeMenuOnSelect={false}
              onChange={this._onChange}
              isDisabled={this.props.disabled}
            />
          </SelectWrapperMulti>
        ) : (
          <SelectWrapperMulti hasValue={false} data-disabled={this.props.disabled}>
            <Select
              styles={selectStyles}
              name={name}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              placeholder={this.state.focused ? "" : this.props.placeholder}
              value={value}
              isMulti={true}
              isClearable={clearable}
              options={options}
              closeMenuOnSelect={false}
              onChange={this._onChange}
              isDisabled={this.props.disabled}
            />
          </SelectWrapperMulti>
        );
    } else if (async) {
      select = value ? (
        <SelectWrapper data-disabled={this.props.disabled}>
          <AsyncSelect
            styles={selectStyles}
            name={name}
            arrowRenderer={null}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            placeholder={this.state.focused ? "" : this.props.placeholder}
            value={value}
            isClearable={clearable}
            onChange={this._onChange}
            loadOptions={this.props.loadOptions}
            noOptionsMessage={() =>
              value ? "Begin typing to see more options." : "No options. Please type a new search."
            }
            onInputChange={this.props.onInputChange}
            isDisabled={this.props.disabled}
          />
        </SelectWrapper>
      ) : (
        <SelectWrapper data-disabled={this.props.disabled}>
          <AsyncSelect
            styles={selectStyles}
            name={name}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            placeholder={this.state.focused ? "" : this.props.placeholder}
            value={value}
            isClearable={clearable}
            onChange={this._onChange}
            loadOptions={this.props.loadOptions}
            noOptionsMessage={() =>
              value ? "Begin typing to see more options." : "No options. Please type a new search."
            }
            onInputChange={this.props.onInputChange}
            isDisabled={this.props.disabled}
          />
        </SelectWrapper>
      );
    } else {
      select = value ? (
        <SelectWrapper data-disabled={this.props.disabled}>
          <Select
            styles={selectStyles}
            maxMenuHeight={this.props.maxMenuHeight}
            name={name}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            arrowRenderer={null}
            placeholder={this.state.focused ? "" : this.props.placeholder}
            value={value}
            isClearable={clearable}
            options={options}
            onChange={this._onChange}
            isDisabled={this.props.disabled}
          />
        </SelectWrapper>
      ) : (
        <SelectWrapper data-disabled={this.props.disabled}>
          <Select
            styles={selectStyles}
            maxMenuHeight={this.props.maxMenuHeight}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            name={name}
            placeholder={this.state.focused ? "" : this.props.placeholder}
            value={value}
            isClearable={clearable}
            options={options}
            onChange={this._onChange}
            isDisabled={this.props.disabled}
          />
        </SelectWrapper>
      );
    }

    return (
      <ContainerBox data-testid={name}>
        <FormInputs>
          <FormDropdownContainer completed={this.state.completed}>
            <OverflowFormFieldLabel>
              {label}
              {this.props.children}
            </OverflowFormFieldLabel>
            {this.props.secondaryText ? (
              <SecondaryText> {this.props.secondaryText}</SecondaryText>
            ) : null}
            {select}
          </FormDropdownContainer>
        </FormInputs>
      </ContainerBox>
    );
  }
}
