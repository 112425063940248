import { assessmentItemTypes } from "../eca.constants";
import type {
  GetNextAssessmentSectionQuestionsQuery,
  SubmitItemVariables,
  ItemProps,
  ItemResponsePayload,
  MultiSelectItemGroupProps,
  MultiSelectResponse,
  MultiSelectItemProps,
  MatrixTableItemGroupProps,
  TextInputItemGroupProps,
  MultipleChoiceItemGroupProps,
  MultipleChoiceItemProps,
  MultipleChoiceResponse
} from "../eca.types";

// Used to sync form state for multi-select items
const updateMultiSelectFormCachedValues = (
  itemGroup: MultiSelectItemGroupProps,
  formValues: SubmitItemVariables
) => {
  const items = itemGroup.items.map((item: ItemProps) => {
    if (item.id === formValues.id) {
      const options = (item as MultiSelectItemProps).options.map((option) => {
        return {
          ...option,
          isSelected: (formValues.values as MultiSelectResponse[]).some(
            (value) => value.id === option.id
          )
        };
      });
      return { ...item, options };
    }

    return item;
  });

  return { ...itemGroup, items };
};

const updateMultipleChoiceFormCachedValues = (
  itemGroup: MultipleChoiceItemGroupProps,
  formValues: SubmitItemVariables
) => {
  const items = itemGroup.items.map((item: ItemProps) => {
    if (item.id === formValues.id) {
      const options = (item as MultipleChoiceItemProps).options.map((option) => {
        return {
          ...option,
          isSelected: (formValues.values as MultipleChoiceResponse[]).some(
            (value) => value.id === option.id
          )
        };
      });
      return { ...item, options };
    }

    return item;
  });

  return { ...itemGroup, items };
};

// Used to sync form state for matrix table and text input items
const updateItemResponsePayloadCachedValues = (
  itemGroup: MatrixTableItemGroupProps | TextInputItemGroupProps,
  formValues: SubmitItemVariables
) => {
  const items = itemGroup.items.map((item: ItemProps) => {
    if (item.id === formValues.id) {
      return {
        ...item,
        value: (formValues.values as ItemResponsePayload[]).find(Boolean)?.value
      };
    }

    return item;
  });

  return { ...itemGroup, items };
};

export const getUpdatedGetNextAssessmentSectionQuestionsCacheData = (
  cache: GetNextAssessmentSectionQuestionsQuery,
  formValues: SubmitItemVariables
) => {
  const updatedItemGroups = cache?.GetNextAssessmentSectionQuestions.itemGroups.map((itemGroup) => {
    if (itemGroup.itemType === assessmentItemTypes.MULTISELECT) {
      return updateMultiSelectFormCachedValues(itemGroup as MultiSelectItemGroupProps, formValues);
    }

    if (itemGroup.itemType === assessmentItemTypes.MULTIPLE_CHOICE) {
      return updateMultipleChoiceFormCachedValues(
        itemGroup as MultiSelectItemGroupProps,
        formValues
      );
    }

    return updateItemResponsePayloadCachedValues(
      itemGroup as MatrixTableItemGroupProps | TextInputItemGroupProps,
      formValues
    );
  });

  return {
    GetNextAssessmentSectionQuestions: {
      ...cache?.GetNextAssessmentSectionQuestions,
      itemGroups: updatedItemGroups
    }
  };
};
