import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { Box, Stack } from "@suited/components";

import {
  DELETE_USER_LINKEDIN_URL,
  DELETE_USER_PHONE_NUMBER
} from "../userAdditionalInfo/UserAdditionaInfoOverlay.queries";
import { EditableContactField } from "./EditableContactField";
import { GET_USER_SETTINGS } from "./GraphQL/queries";
import { LinkedInUrlForm } from "./LinkedInUrlForm";
import { PhoneNumberForm } from "./PhoneNumberForm";
import { StyledSuitedCopySubheadline } from "./UserSettingsView.style";
import { EditableFormState } from "./userSettings.constants";
import { getFormattedPhoneNumber } from "./userSettings.utils";

export const AdditionalContactInfo = () => {
  const { data, loading } = useQuery(GET_USER_SETTINGS);
  const linkedInUrl = data?.GetUser?.linkedInUrl;
  const phoneNumber = data?.GetUser?.phoneNumber;
  const phoneNumberLocale = data?.GetUser?.phoneNumberLocale;
  const [linkedInUrlFormState, setLinkedInUrlFormState] = useState(EditableFormState.PENDING);
  const [phoneNumberFormState, setPhoneNumberFormState] = useState(EditableFormState.PENDING);

  const formattedPhoneNumber =
    phoneNumber && phoneNumberLocale ? getFormattedPhoneNumber(phoneNumber, phoneNumberLocale) : "";

  useEffect(() => {
    if (linkedInUrl) {
      setLinkedInUrlFormState(EditableFormState.READY);
    }

    if (!loading && !linkedInUrl) {
      setLinkedInUrlFormState(EditableFormState.MISSING);
    }
  }, [linkedInUrl, loading]);

  useEffect(() => {
    if (phoneNumber) {
      setPhoneNumberFormState(EditableFormState.READY);
    }

    if (!loading && !phoneNumber) {
      setPhoneNumberFormState(EditableFormState.MISSING);
    }
  }, [phoneNumber, loading]);

  const [deleteUserLinkedInUrl, { loading: isDeleteUserLinkedInUrlLoading }] =
    useMutation(DELETE_USER_LINKEDIN_URL);

  const [deleteUserPhoneNumber, { loading: isDeleteUserPhoneNumberLoading }] =
    useMutation(DELETE_USER_PHONE_NUMBER);

  return (
    <Stack space="sm">
      <StyledSuitedCopySubheadline noMargin>Contact Info</StyledSuitedCopySubheadline>
      <Stack>
        <Box>
          <EditableContactField
            id="linkedin-url"
            formState={linkedInUrlFormState}
            fieldTitle="LinkedIn URL"
            fieldValue={linkedInUrl}
            handleFormStateUpdate={setLinkedInUrlFormState}
            handleDelete={deleteUserLinkedInUrl}
            isDeleting={isDeleteUserLinkedInUrlLoading}
            deleteActionText="Are you sure you want to delete your LinkedIn URL?"
            renderEditComponent={() => (
              <LinkedInUrlForm setLinkedInUrlFormState={setLinkedInUrlFormState} linkedInUrl={linkedInUrl} />
            )}
          />
        </Box>
        <Box>
          <EditableContactField
            id="phone-number"
            formState={phoneNumberFormState}
            fieldTitle="Phone Number"
            fieldValue={formattedPhoneNumber}
            handleFormStateUpdate={setPhoneNumberFormState}
            handleDelete={deleteUserPhoneNumber}
            isDeleting={isDeleteUserPhoneNumberLoading}
            deleteActionText="Are you sure you want to delete your phone number?"
            renderEditComponent={() => (
              <PhoneNumberForm
                setPhoneNumberFormState={setPhoneNumberFormState}
                formattedPhoneNumber={formattedPhoneNumber}
              />
            )}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
