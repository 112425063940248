import { gql } from "@apollo/client";

export const GET_USER_EDUCATION = gql`
  query GetUser {
    GetUser {
      id
      userProfile {
        schools {
          id
          school
          schoolObj {
            name
            status
          }
          degree
          major
          graduationDate
          gpa
        }
      }
      userProfileCompletion {
        completedSchools
        completedPersonalInformation
        completedResume
        completedWorkPreferences
      }
    }
  }
`;

export const UPDATE_SCHOOLS = gql`
  mutation UpdateUserSchoolsArray($schools: [SchoolsInput]!) {
    UpdateUserSchoolsArray(schools: $schools) {
      school
      degree
      major
      graduationDate
      gpa
      id
    }
  }
`;

export const QUERY_SCHOOL_NAME_LIST = gql`
  query GetSchoolNameList($school: String) {
    GetSchoolOptions(school: $school, limit: 25) {
      label
      value
    }
  }
`;
