import { Row, End } from "./common.style";
import SuitedIconButton from "../../buttons/SuitedIconButton";

interface Props {
  onCancel: () => void;
}

const BasicDialogHeader = (props: Props) => {
  return (
    <Row>
      <End>
        <SuitedIconButton iconName="times" tooltip="Cancel" onClickButton={props.onCancel} />
      </End>
    </Row>
  );
};

export default BasicDialogHeader;
