import { gql } from "@apollo/client";

export const UPDATE_USER_LINKEDIN_URL = gql`
  mutation UpdateUserLinkedInUrl($linkedInUrl: String) {
    UpdateUserLinkedInUrl(linkedInUrl: $linkedInUrl) {
      id
    }
  }
`;

export const UPDATE_USER_PHONE_NUMBER = gql`
  mutation UpdateUserPhoneNumber($phoneNumber: String, $locale: String) {
    UpdateUserPhoneNumber(phoneNumber: $phoneNumber, locale: $locale) {
      id
    }
  }
`;

export const DELETE_USER_LINKEDIN_URL = gql`
  mutation DeleteUserLinkedInUrl {
    DeleteUserLinkedInUrl {
      id
    }
  }
`;

export const DELETE_USER_PHONE_NUMBER = gql`
  mutation DeleteUserPhoneNumber {
    DeleteUserPhoneNumber {
      id
    }
  }
`;
