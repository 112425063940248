import { HttpResponse, graphql } from "msw";

export const accountTypeSettingsMockHandlers = [
  graphql.mutation("SetAccountType", () => {
    return HttpResponse.json({
      data: {
        SetAccountType: {
          status: "COMPLETE"
        }
      }
    });
  })
];
