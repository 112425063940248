import React from "react";
import { CSSTransition } from "react-transition-group";

import Icon from "../typography/Icon";
import { Disclosure, Label, Toggle, ContentWrapper, Content } from "./ProgressiveDisclosure.style";
import { timeouts } from "suited/constants/interaction-constants";

interface Props {
  isDisclosed: boolean;
  label?: string;
  count?: number;
  onToggle?: () => void;
  className?: string;
  children: React.ReactNode;
}

const ProgressiveDisclosure = (props: Props) => {
  const handleClick = () => {
    if (props.onToggle) props.onToggle();
  };

  const handleKeydown = (e: React.KeyboardEvent<Element>) => {
    // only handle events that orginate from this component's root element that can receive focus
    const eventTarget: Element = e.target as Element;
    if (eventTarget.getAttribute("data-event-filter") === "progressive-disclosure") {
      if (e.key === " " || e.key === "Enter") {
        e.preventDefault();
        if (props.onToggle) props.onToggle();
      }
    }
  };

  return (
    <Disclosure
      disclosed={props.isDisclosed}
      className={props.className}
      tabIndex={0}
      onKeyDown={handleKeydown}
      data-event-filter="progressive-disclosure"
      data-test="component-progressive-disclosure"
      data-testid="component-progressive-disclosure"
    >
      {props.label ? (
        <Label onClick={handleClick} data-count={props.count} data-test="label">
          <Toggle>
            <Icon
              name={props.isDisclosed ? "caret-down" : "caret-right"}
              weight="solid"
              data-test="caret"
            />
          </Toggle>
          <div data-test="label-text">{props.label}</div>
        </Label>
      ) : null}
      <ContentWrapper data-test="content-wrapper">
        <CSSTransition
          unmountOnExit
          in={props.isDisclosed}
          timeout={timeouts.TRANSITION_MANAGER_DURATION}
        >
          <Content timeout={timeouts.TRANSITION_MANAGER_DURATION} data-test="content">
            {props.children}
          </Content>
        </CSSTransition>
      </ContentWrapper>
    </Disclosure>
  );
};

export default ProgressiveDisclosure;
