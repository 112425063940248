import Skeleton from "react-loading-skeleton";

import { HeaderEl, FillerBar } from "./Header.style";
import NavButtons from "./NavButtons";
import { Badge } from "suited/components/shared/feedback/Badge/Badge";
import { useQuery } from "@apollo/client";
import { TUserQueryData } from "suited/components/CandidateHome/GraphQL/types";
import { GET_USER_BOOTSTRAP } from "suited/components/CandidateHome";
import { AccountTypes } from "suited/constants/user.constants";

export const Header = () => {
  const { loading, data } = useQuery<TUserQueryData>(GET_USER_BOOTSTRAP);
  const accountType = data?.GetUser?.accountType;
  return (
    <HeaderEl>
      <FillerBar>
        <Badge style={{ marginRight: "15px" }}>
          {loading ? (
            <Skeleton />
          ) : (
            <p>{accountType === AccountTypes.LATERAL ? "Lateral" : "Campus"}</p>
          )}
        </Badge>
      </FillerBar>
      <NavButtons />
    </HeaderEl>
  );
};

export default Header;
