import React from "react";

import { LocationDescriptorObject, Path } from "history";
import { StyledLink, SuitedLinkAEl } from "./SuitedLink.style";

interface Props {
  to?: Path | LocationDescriptorObject;
  href?: string;
  children?: React.ReactNode;
  dataAnalyticsId?: string;
  tooltip?: string;
  color?: string;
  backgroundColor?: string;
  allowDefault?: boolean;
  // props below implictly pass through to DOM element, if present
  target?: "_self" | "_blank" | "_parent" | "top" | string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  style?: React.CSSProperties;
}

const SuitedLink = (props: Props) => {
  const {
    to,
    href,
    children,
    tooltip,
    dataAnalyticsId,
    color,
    backgroundColor,
    onClick,
    allowDefault,
    ...passthroughProps
  } = props;

  const handleClick = (event: any) => {
    if (!props.onClick) return;
    if (!props.allowDefault) event.preventDefault();
    if (props.onClick) props.onClick(event);
  };

  if (!!to) {
    return (
      <StyledLink
        to={to}
        color={color}
        backgroundColor={backgroundColor}
        title={tooltip}
        aria-label={tooltip}
        data-analytics-id={dataAnalyticsId}
        tabIndex={0}
        onClick={handleClick}
        {...passthroughProps}
      >
        {children}
      </StyledLink>
    );
  } else {
    return (
      <SuitedLinkAEl
        href={href || "#"}
        title={tooltip}
        aria-label={tooltip}
        data-analytics-id={dataAnalyticsId}
        color={color}
        backgroundColor={backgroundColor}
        tabIndex={0}
        onClick={handleClick}
        {...passthroughProps}
      >
        {children}
      </SuitedLinkAEl>
    );
  }
};

export default SuitedLink;
