import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Center, Stack, HStack, Box } from "@suited/components";
import { delay } from "@suited/utils";
import { TUserQueryData } from "suited/components/CandidateHome/GraphQL/types";
import { SET_ACCOUNT_TYPE } from "../accountTypeSettings.queries";
import { GET_USER_BOOTSTRAP } from "suited/components/CandidateHome";
import { FocusMode } from "suited/components/shared/FocusMode/FocusMode";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { AccountTypeSelection } from "./AccountTypeSettingsModal.style";
import { ANIMATION_BUFFER_DELAY, DEFAULT_DELAY } from "./AccountTypeSettingsModal.constants";
import { AccountTypes } from "suited/constants/user.constants";
import {
  ProgressButton,
  StateValues
} from "suited/components/shared/buttons/ProgressButton/ProgressButton";

export const AccountTypeSettingsModal = () => {
  const {
    data: userBootstrapData,
    loading,
    refetch
  } = useQuery<TUserQueryData>(GET_USER_BOOTSTRAP, {
    fetchPolicy: "cache-and-network"
  });
  const userHasAccountTypeSet = !!userBootstrapData?.GetUser?.accountType;

  const [setAccountType] = useMutation(SET_ACCOUNT_TYPE);

  const [campusButtonState, setCampusButtonState] = useState<StateValues>("");
  const [lateralButtonState, setLateralButtonState] = useState<StateValues>("");

  return (
    <FocusMode show={!loading && !userHasAccountTypeSet}>
      <AccountTypeSelection>
        <Center style={{ height: "100%" }}>
          <Stack>
            <SuitedCopySubheadline>Please choose an account type:</SuitedCopySubheadline>
            <Center>
              <HStack space="xl">
                <Box>
                  <ProgressButton
                    durationSuccess={DEFAULT_DELAY}
                    state={campusButtonState}
                    data-analytics-id="campus-account-selection"
                    onClick={async () => {
                      setCampusButtonState("loading");

                      await delay(DEFAULT_DELAY);

                      await setAccountType({
                        variables: { accountType: AccountTypes.CAMPUS }
                      });

                      setCampusButtonState("success");

                      await delay(ANIMATION_BUFFER_DELAY);
                      refetch();
                    }}
                  >
                    Campus
                  </ProgressButton>
                </Box>
                <Box>
                  <ProgressButton
                    state={lateralButtonState}
                    durationSuccess={DEFAULT_DELAY}
                    data-analytics-id="lateral-account-selection"
                    onClick={async () => {
                      setLateralButtonState("loading");

                      await delay(DEFAULT_DELAY);

                      await setAccountType({
                        variables: { accountType: AccountTypes.LATERAL }
                      });

                      setLateralButtonState("success");

                      await delay(ANIMATION_BUFFER_DELAY);
                      refetch();
                    }}
                  >
                    Lateral
                  </ProgressButton>
                </Box>
              </HStack>
            </Center>
          </Stack>
        </Center>
      </AccountTypeSelection>
    </FocusMode>
  );
};
