import { QuestionSchema } from "./types";
import {
  QuestionHeaderContainer,
  QuestionLabel,
  QuestionDescription
} from "./QuestionHeader.style";

interface QuestionHeaderProps {
  schema: QuestionSchema;
  hidden?: boolean;
}

export default function QuestionHeader({ schema, hidden = false }: QuestionHeaderProps) {
  return (
    <QuestionHeaderContainer hidden={hidden}>
      <QuestionLabel
        data-number={
          schema?.questionIndex ? schema.questionIndex.toString().padStart(2, "0") : null
        }
      >
        {schema.label}
      </QuestionLabel>
      {schema.description && schema.type !== "statement" ? (
        <QuestionDescription>{schema.description}</QuestionDescription>
      ) : null}
    </QuestionHeaderContainer>
  );
}
