import {
  ITheme,
  IThemeOpacityColors,
  IBackgroundColors,
  IThemeColor,
  IThemeTypographyCopySizes,
  IThemeTypographyFormSizes,
  IThemeTypographyNavSizes,
  IThemeTypographyFamilies,
  IThemeTypographyDialogSizes,
  IThemeTypographyColors,
  IThemeTypographyLeading
} from "./theme";

// some colors are semantically addressed later, so we declare them at the top
const background: IBackgroundColors = {
  light: {
    primary: "#ffffff",
    secondary: "#F7FCFC",
    tertiary: "#F2F7F7",
    fourthOrder: "#EDF4F5",
    fifthOrder: "#E6EEF0",
    sixthOrder: "#DFE9EB",
    backdrop: "#D7E1E3"
  },
  dark: {
    primary: "#101820",
    secondary: "#111C21",
    tertiary: "#122024",
    fourthOrder: "#132426",
    fifthOrder: "#142829",
    sixthOrder: "#152E2D",
    backdrop: "#17332F"
  },
  scrim: "rgba(23, 51, 47, 0.96)" // dark backdrop at 96%
};

const tints: IThemeOpacityColors = {
  divide4: "rgba(255, 255, 255, 0.02)",
  divide2: "rgba(255, 255, 255, 0.04)",
  times1: "rgba(255, 255, 255, 0.08)",
  times2: "rgba(255, 255, 255, 0.16)",
  times3: "rgba(255, 255, 255, 0.24)",
  times4: "rgba(255, 255, 255, 0.32)",
  times5: "rgba(255, 255, 255, 0.40)",
  times6: "rgba(255, 255, 255, 0.48)",
  mid: "rgba(255, 255, 255, 0.60)",
  most: "rgba(255, 255, 255, 0.87)",
  full: "rgba(255, 255, 255, 1.0)"
};

const shades: IThemeOpacityColors = {
  divide4: "rgba(0, 0, 0, 0.02)",
  divide2: "rgba(0, 0, 0, 0.04)",
  times1: "rgba(0, 0, 0, 0.08)",
  times2: "rgba(0, 0, 0, 0.16)",
  times3: "rgba(0, 0, 0, 0.24)",
  times4: "rgba(0, 0, 0, 0.32)",
  times5: "rgba(0, 0, 0, 0.40)",
  times6: "rgba(0, 0, 0, 0.48)",
  mid: "rgba(0, 0, 0, 0.60)",
  most: "rgba(0, 0, 0, 0.87)",
  full: "rgba(0, 0, 0, 1.0)"
};

const color1: IThemeColor = {
  base: "#A14F37",
  tint1: "#BD7F6E",
  tint2: "#D9B6AB",
  shade1: "#873F2A",
  shade2: "#6E311F"
};

const color2: IThemeColor = {
  base: "#B88A63",
  tint1: "#CDAA8B",
  tint2: "#E0C9B6",
  shade1: "#9E7552",
  shade2: "#855F40"
};

const color4: IThemeColor = {
  base: "#17332F",
  tint1: "#3C5C57",
  tint2: "#6A8F8B",
  shade1: "#0E2623",
  shade2: "#051A17"
};

const accent1: IThemeColor = {
  base: "#D39128",
  tint1: "#E0B268",
  tint2: "#EDD0A1",
  shade1: "#A97016",
  shade2: "#8B5500"
};

const fontFamily: IThemeTypographyFamilies = {
  heading: "'HK Gothic', sans-serif",
  subheading: "neue-haas-grotesk-display, sans-serif",
  body: "neue-haas-grotesk-text, sans-serif",
  bodyEmphasis: "neue-haas-grotesk-display, sans-serif",
  accent: "input-mono, monospace",
  monospace: "input-mono, monospace",
  icon: '"Font Awesome 5 Pro", sans-serif'
};

const fontWeight = {
  heading: {
    medium: "500",
    bold: "600",
    ultrabold: "700",
    black: "800"
  },
  subheading: {
    regular: "400",
    medium: "500"
  },
  body: {
    regular: "400",
    medium: "500"
  },
  accent: {
    light: "300",
    regular: "400",
    medium: "500"
  }
};

const tracking = {
  regular: "0em",
  tight: "-.05em",
  slight: ".075em",
  loose: ".150em",
  loosest: ".20em"
};

const leading: IThemeTypographyLeading = {
  fixed: {
    body: "1.5",
    multiLine: "1.35",
    singleLine: "1"
  },
  responsive: {
    copy: {
      base: "25px",
      phone: "20px",
      tablet: "22px",
      laptop: "22px",
      desktop: "25px",
      jumbo: "27px"
    }
  }
};

const darkType: IThemeTypographyColors = {
  base: "rgba(0, 0, 0, 0.87)",
  emphasis: "rgba(0, 0, 0, 1.0)",
  hint: "rgba(0, 0, 0, 0.60)",
  disabled: "rgba(0, 0, 0, 0.38)",
  cancel: "rgba(0, 0, 0, 0.48)"
};

const lightType: IThemeTypographyColors = {
  base: "rgba(255, 255, 255, 0.87)",
  emphasis: "rgba(255, 255, 255, 1.0)",
  hint: "rgba(255, 255, 255, 0.60)",
  disabled: "rgba(255, 255, 255, 0.38)",
  cancel: "rgba(255, 255, 255, 0.48)"
};

const fontSizeCopy: IThemeTypographyCopySizes = {
  body: {
    base: "15px",
    phone: "12px",
    tablet: "13px",
    laptop: "13px",
    desktop: "15px",
    jumbo: "16px"
  },
  title: "29px",
  heading: {
    base: "29px",
    phone: "24px",
    tablet: "26px",
    laptop: "29px",
    desktop: "32px",
    jumbo: "32px"
  },
  subheading: {
    base: "22px",
    phone: "18px",
    tablet: "20px",
    laptop: "22px",
    desktop: "24px",
    jumbo: "24px"
  },
  caption: {
    base: "14px",
    phone: "12px",
    tablet: "14px",
    laptop: "14px",
    desktop: "16px",
    jumbo: "16px"
  },
  label: "14px",
  value: "18px",
  inlinelabel: "",
  inlinevalue: "",
  link: "14px",
  legal: "10px"
};

const fontSizeForm: IThemeTypographyFormSizes = {
  sectionLabel: "16px",
  instructions: "16px",
  base: {
    label: "16px",
    value: "16px",
    input: "16px",
    hint: "12px",
    button: "12px",
    buttonIcon: "18px",
    inputIcon: "24px",
    link: ""
  },
  compact: {
    label: "12px",
    value: "16px",
    input: "18px",
    hint: "",
    button: "12px",
    buttonIcon: "16px",
    inputIcon: "24px",
    link: "12px"
  },
  ultracompact: {
    label: "",
    value: "",
    input: "16px",
    hint: "",
    button: "12px",
    buttonIcon: "14px",
    inputIcon: "24px",
    link: "14px"
  },
  large: {
    label: "",
    value: "",
    input: "",
    hint: "",
    button: "14px",
    buttonIcon: "24px",
    inputIcon: "",
    link: ""
  },
  extralarge: {
    label: "",
    value: "",
    input: "",
    hint: "",
    button: "",
    buttonIcon: "32px",
    inputIcon: "",
    link: ""
  }
};

const fontSizeDialog: IThemeTypographyDialogSizes = {
  heading: "25px",
  content: "19px",
  buttonIcon: "28px"
};

const fontSizeNav: IThemeTypographyNavSizes = {
  heading: "19px",
  item: "12px",
  itemIcon: "16px",
  subItem: "",
  count: "14px",
  selectedItem: "15px",
  selectedCount: "16px",
  footerItem: "13px",
  footerSubItem: "12px",
  footerIcon: "12px"
};

const theme: ITheme = {
  color: {
    black: "#101820",
    white: "#ffffff",
    color1: color1,
    color2: color2,
    color3: {
      base: "#DFD7C8",
      tint1: "#EDE8DD",
      tint2: "#F7F4ED",
      shade1: "#C4B79F",
      shade2: "#AB9D82"
    },
    color4: color4,
    color5: {
      base: "#C8E2D7",
      tint1: "#D5EDE4",
      tint2: "#E6F5EF",
      shade1: "#B2D1C4",
      shade2: "#97B8AA"
    },
    color6: {
      base: "#D8E1E2",
      tint1: "#E4ECED",
      tint2: "#F2F7F7",
      shade1: "#CEDADB",
      shade2: "#C0CFD1"
    },
    color7: {
      base: "#2FE8A8",
      tint1: "#7AF0C8",
      tint2: "#CDFAEA",
      shade1: "#29CA92",
      shade2: "#1E946C"
    },
    accent1: accent1,
    status: {
      error: color1,
      info: color2,
      success: {
        ...color4,
        base: color4.tint1
      },
      warning: accent1
    },
    background: background,
    tints: tints,
    shades: shades,
    scores: {
      quartiles: {
        skeleton: {
          first: `${shades.times1}`,
          second: `${shades.times1}`,
          third: `${shades.times1}`,
          fourth: `${shades.times1}`
        },
        grey: {
          first: `${background.light.tertiary}`,
          second: `${background.light.fourthOrder}`,
          third: `${background.light.sixthOrder}`,
          fourth: `${background.light.backdrop}`
        },
        active: {
          first: `${accent1.tint2}`,
          second: `${accent1.tint1}`,
          third: `${accent1.base}`,
          fourth: `${accent1.shade1}`
        }
      }
    }
  },
  // 2020: text should never occupy more than 50 percent of a document or page
  typography: {
    color: {
      dark: darkType,
      light: lightType,
      // To be optically correct, text color usually has to be darker than adjacent icon color…
      status: {
        error: color1.shade1,
        info: color2.shade1,
        success: color4.shade1,
        warning: accent1.shade1
      }
    },
    family: fontFamily,
    // 2020: Skip at least one weight when pairing body text and subheadings with headings
    weight: fontWeight,
    sizes: {
      copy: fontSizeCopy,
      form: fontSizeForm,
      dialog: fontSizeDialog,
      nav: fontSizeNav
    },
    margins: {
      copy: {
        base: "24px",
        phone: "20px",
        tablet: "24px",
        laptop: "24px",
        desktop: "32px",
        jumbo: "32px"
      },
      heading: {
        base: "40px",
        phone: "32px",
        tablet: "40px",
        laptop: "40px",
        desktop: "48px",
        jumbo: "48px"
      },
      subheading: {
        base: "40px",
        phone: "32px",
        tablet: "40px",
        laptop: "40px",
        desktop: "48px",
        jumbo: "48px"
      },
      caption: {
        base: "32px",
        phone: "24px",
        tablet: "32px",
        laptop: "32px",
        desktop: "40px",
        jumbo: "40px"
      },
      input: "calc(16px - 1px)"
    },
    // tracking = CSS `letter-spacing`
    tracking: tracking,
    // leading = CSS `line-height`
    leading: leading,
    styles: {
      // 2020: headings should always be at least double the size of subheaders and body text
      heading: `
        color: ${darkType.base};
        font-family: ${fontFamily.heading};
        font-weight: ${fontWeight.heading.ultrabold};
        letter-spacing: ${tracking.slight};
        line-height: ${leading.fixed.multiLine};
        text-transform: uppercase;
      `,
      // 2020: The subheader may appear above or below the main heading depending on the application, with the space in-between occupying 50 or 200 percent of the size of the header (in other words, half or double the size of the header type size).
      subheading: `
        color: ${darkType.base};
        font-family: ${fontFamily.heading};
        font-weight: ${fontWeight.heading.ultrabold};
        letter-spacing: ${tracking.slight};
        line-height: ${leading.fixed.multiLine};
        text-transform: uppercase;
      `,
      // 2020: Body text should always follow the header or subheader by a space no less than 100 percent the size of the header type. For extra drama, extend the space to 200 percent.
      body: `
        color: ${darkType.base};
        font-family: ${fontFamily.body};
        font-weight: ${fontWeight.body.regular};
        letter-spacing: ${tracking.slight};
        line-height: ${leading.fixed.body};
        text-transform: none;
      `,
      emphasis: `
        color: ${darkType.emphasis};
        font-family: ${fontFamily.bodyEmphasis};
        font-weight: ${fontWeight.body.medium};
        letter-spacing: ${tracking.slight};
        line-height: ${leading.fixed.body};
        text-transform: none;
      `,
      emphasisItalics: `
        color: ${darkType.emphasis};
        font-family: ${fontFamily.bodyEmphasis};
        font-weight: ${fontWeight.body.medium};
        font-style: italic;
        letter-spacing: ${tracking.slight};
        line-height: ${leading.fixed.body};
        text-transform: none;
      `,
      accent: `
        color: ${darkType.base};
        font-family: ${fontFamily.accent};
        font-weight: ${fontWeight.accent.regular};
        letter-spacing: ${tracking.regular};
        line-height: ${leading.fixed.singleLine};
        text-transform: none;
      `,
      label: `
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        color: ${darkType.base};
        font-family: ${fontFamily.body};
        font-size: 16px;
        font-weight: ${fontWeight.body.regular};
        letter-spacing: ${tracking.slight};
        line-height: ${leading.fixed.singleLine};
        text-transform: none;
      `,
      hint: `
        padding-top: 0;
        padding-bottom: 4px;
        color: ${darkType.base};
        font-family: ${fontFamily.body};
        font-size: 12px;
        font-weight: 300;
        letter-spacing: ${tracking.slight};
        line-height: ${leading.fixed.singleLine};
        text-transform: none;
      `,
      nav: `
        font-family: ${fontFamily.heading};
        font-size: ${fontSizeNav.item};
        font-weight: ${fontWeight.heading.ultrabold};
        text-transform: uppercase;
        letter-spacing: ${tracking.slight};
      `,
      // 2020: To mirror the Suited logo, a double highlight of two lines should be added for emphasis on some of the main headings (HK Gothic type only). To upkeep the minimalism of the Suited brand and avoid overwhelming the eye with too many elements, the double highlight should not be used next to the lined Suited logo, nor should it be used more than once in a single heading or section.
      doubleHighlight: `

      `
    }
  },
  logo: {
    url: "",
    iconUrl: "",
    colorDark: "",
    colorLight: ""
  }
};

export default theme;
