export const instructions = [
  {
    headline: "TIMING",
    copy: "The following questions take approximately 10 minutes to complete and will help us learn more about your personality.",
    icon: ["fal", "clock"]
  },
  {
    headline: "NO RETAKES // NO REDOS",
    copy: "Once you click Submit, you will not be able to retake the assessment. However, this section is not timed, so you are free to pause before submitting.",
    icon: ["fal", "redo"]
  },
  {
    headline: "BE HONEST",
    copy: "Your answers to select questions may be verified later in the process and any false statements may jeopardize your application to all of Suited's partner firms.",
    icon: ["fal", "heart"]
  },
  {
    headline: "BE YOURSELF",
    copy: `Choose answers based on your actual behavior and not based on how you think you should behave. There are no "right" or "wrong" answers!`,
    icon: ["fal", "user"]
  },
  {
    headline: "GO WITH YOUR GUT",
    copy: "Try not to over-analyze the statements. We will obtain more accurate results if you answer naturally rather than thinking of those extreme scenarios.",
    icon: ["fal", "clipboard-check"]
  },
  {
    headline: "RELAX",
    copy: "Try to relax and answer the questions simply and intuitively, and to maintain the same frame of mind as you respond to each of the questions.",
    icon: ["fal", "leaf"]
  }
];
