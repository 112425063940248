import styled from "styled-components";
import { sizes, responsiveTargets } from "suited/constants/style-constants";
import { CompactSuitedDialogWindow } from "./common.style";
import {
  Content,
  fadePadding,
  footerTopPadding,
  phonePadding,
  tabletPadding,
  desktopPadding
} from "./SuitedDialogWindow.style";

const footerHeight = sizes.gridUnits.times6;

export const SuitedDialogAlertWindow = styled(CompactSuitedDialogWindow)`
  ${Content} {
    @media ${responsiveTargets.phone} {
      padding-bottom: calc(
        ${fadePadding} + ${footerTopPadding} + ${footerHeight} + ${phonePadding}
      );
    }
    @media ${responsiveTargets.tablet} {
      padding-bottom: calc(
        ${fadePadding} + ${footerTopPadding} + ${footerHeight} + ${tabletPadding}
      );
    }
    @media ${responsiveTargets.desktop} {
      padding-bottom: calc(
        ${fadePadding} + ${footerTopPadding} + ${footerHeight} + ${desktopPadding}
      );
    }
  }
`;
