import { ComposableTransition } from "../util/composeTransitions";
import { animationCurves } from "./style-constants";

export const swap: ComposableTransition = (duration: number) => {
  return {
    attributes: {
      enter: `opacity: 0.01;`,
      enterActive: `opacity: 1;`,
      exit: `opacity: 1;`,
      exitActive: `opacity: 0.01;`
    },
    transitions: {
      enterActive: `opacity 0ms linear ${duration / 2}ms`,
      exitActive: `opacity 0ms linear ${duration / 2}ms`
    }
  };
};

export const scaleUp: ComposableTransition = (duration: number) => {
  return {
    attributes: {
      enter: `transform: scale(1);`,
      enterActive: `transform: scale(1.125);`,
      exit: `transform: scale(1.125);`,
      exitActive: `transform: scale(0.875);`
    },
    transitions: {
      enterActive: `transform ${duration / 2}ms ${animationCurves.easeOutQuart} ${duration / 2}ms`,
      exitActive: `transform ${duration / 2}ms ${animationCurves.easeInQuart} 0ms`
    }
  };
};

export const swoopIn: ComposableTransition = (duration: number) => {
  return {
    attributes: {
      enter: `transform: scale(0.25) translateY(75%);`,
      enterActive: `transform: scale(1) translateY(0);`,
      exit: `transform: scale(1) translateY(0);`,
      exitActive: `transform: scale(1) translateY(0);`
    },
    transitions: {
      enterActive: `transform ${duration / 2}ms ${animationCurves.easeOutQuart} ${duration / 2}ms`,
      exitActive: `transform ${duration / 2}ms ${animationCurves.easeInQuart} 0ms`
    }
  };
};
