import { ApolloClientInstance } from "../../../types";
import { TProfileCompleteProviderState, TProfileCompleteProviderAction } from "./types";
import { checkIfProfileComplete } from "./utils";

/**
 * Creates the profile provider reducer and makes
 * the Apollo Client instance available.
 * @param client The Apollo Client Instance
 */
function createProfileReducer(client: ApolloClientInstance) {
  return (state: TProfileCompleteProviderState, action: TProfileCompleteProviderAction) => {
    switch (action.type) {
      case "PROFILE_LOADED":
        if (!state.loaded) {
          return {
            complete: checkIfProfileComplete(client),
            loaded: true
          };
        }
        return { ...state };
      case "PROFILE_UPDATE":
        return {
          ...state,
          complete: checkIfProfileComplete(client)
        };
      case "PROFILE_ASSESSMENT_COMPLETED":
        return {
          ...state,
          complete: checkIfProfileComplete(client, true)
        };
      default:
        throw new Error(`Unhandled action type for Profile Context Reducer.`);
    }
  };
}

export default createProfileReducer;
