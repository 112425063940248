import * as React from "react";
import { StyledFileDrop } from "./SuitedUploaderDropzone.style";

interface Props {
  onDragDrop: (files: FileList | null, event: React.DragEvent<any>) => void;
  onDragOver: (event: React.DragEvent<any>) => void;
  onDragLeave: (event: React.DragEvent<any>) => void;
  onFrameDrop: (event: DragEvent) => void;
  onFrameDragEnter: (event: DragEvent) => void;
  onFrameDragLeave: (event: DragEvent) => void;
  className?: string;
}
export interface SuitedUploaderDropzoneProps extends Props {}

const SuitedUploaderDropzone = (props: Props) => {
  const handleDrop = (files: FileList | null, event: React.DragEvent<any>) => {
    // drag dropped on the target
    props.onDragDrop(files, event);
  };

  const handleDragOver = (event: React.DragEvent<any>) => {
    // drag over the target
    props.onDragOver(event);
  };

  const handleDragLeave = (event: React.DragEvent<any>) => {
    // drag out of the target
    props.onDragLeave(event);
  };

  const handleFrameDrop = (event: DragEvent) => {
    // drop outside the target
    props.onFrameDrop(event);
  };

  const handleFrameDragEnter = (event: DragEvent) => {
    // begin drag in the frame (window.document)
    props.onFrameDragEnter(event);
  };

  const handleFrameDragLeave = (event: DragEvent) => {
    // drag leaves the frame (window.document)
    props.onFrameDragLeave(event);
  };

  return (
    <>
      {/* @ts-ignore */}
      <StyledFileDrop
        {...props}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onFrameDrop={handleFrameDrop}
        onFrameDragEnter={handleFrameDragEnter}
        onFrameDragLeave={handleFrameDragLeave}
      />
    </>
  );
};

export default SuitedUploaderDropzone;
