export const optionsGender = [
  { label: "Female", value: "Female" },
  { label: "Male", value: "Male" },
  { label: "Non-binary", value: "Non-binary" },
  { label: "Transgender", value: "Transgender" },
  { label: "Prefer not to disclose", value: "Prefer not to disclose" }
];

export const optionsEthnicity = [
  { label: "Asian", value: "Asian" },
  { label: "Black or African American", value: "Black or African American" },
  { label: "Hispanic or Latino", value: "Hispanic or Latino" },

  {
    label: "Middle Eastern or North African",
    value: "Middle Eastern or North African"
  },
  {
    label: "Native American or Alaska Native",
    value: "Native American or Alaska Native"
  },
  {
    label: "Pacific Islander or Native Hawaiian",
    value: "Pacific Islander or Native Hawaiian"
  },
  {
    label: "South Asian (including Indian and Pakistani)",
    value: "South Asian (including Indian and Pakistani)"
  },
  { label: "White", value: "White" },
  { label: "Prefer not to disclose", value: "Prefer not to disclose" }
];

export const optionsYesNo = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "Prefer not to disclose", value: "Prefer not to disclose" }
];

export interface IDropdownOption {
  label: string;
  value: string;
}

export interface IDropdownOptionGroup {}
