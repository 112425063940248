import * as React from "react";
import AlertEntry from "./AlertEntry";
import { Alert } from "./reducers";

interface AlertsProps {
  handleDismiss: (alertId: string) => void;
  handleDismissAll: () => void;
  messages: Alert[];
}

class Alerts extends React.Component<AlertsProps> {
  render() {
    if (!this.props.messages || this.props.messages.length === 0) {
      return null;
    } else {
      return (
        <div style={{ width: "100%" }}>
          <div className="alert-container">
            {/*
              <button role="button" className="btn btn-default btn-xs" onClick={ this.props.handleDismissAll }>
                <Icon name="check" /> Dismiss All
              </button>
 */}
            {this.props.messages.map(e => {
              return (
                <div key={e.alertId}>
                  <AlertEntry
                    message={e.message}
                    level={e.level}
                    alertId={e.alertId}
                    onDismiss={this.props.handleDismiss}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
}

export default Alerts;
