import { uniqueId as _uniqueId } from "lodash";

export const stateKey = "alerts";
const ACTION_ALERT_NEW_ALERT = "ACTION_ALERT_NEW_ALERT";
const ACTION_ALERT_REMOVE_ALERT = "ACTION_ALERT_REMOVE_ALERT";
const ACTION_ALERT_SET_ALERTS = "ACTION_ALERT_SET_ALERTS";

export interface Alert {
  alertId: string;
  message: string;
  level: string;
}

interface AlertsState {
  messages: Alert[];
}

const initialState: AlertsState = {
  messages: []
};

// message object shape: { message: string, level: string }
function reducer(state = initialState, action): AlertsState {
  switch (action.type) {
    case ACTION_ALERT_NEW_ALERT:
      return {
        messages: [
          ...state.messages,
          { message: action.message, level: action.level, alertId: action.alertId }
        ]
      };
    case ACTION_ALERT_REMOVE_ALERT:
      return { messages: state.messages.filter(e => e.alertId !== action.alertId) };
    case ACTION_ALERT_SET_ALERTS:
      return { messages: action.messages };
    default:
      return state;
  }
}

export function addAlert(message: Alert, level = "info") {
  return {
    type: ACTION_ALERT_NEW_ALERT,
    message: message,
    level,
    alertId: _uniqueId("alert_")
  };
}

export function removeAlert(alertId: string) {
  return {
    type: ACTION_ALERT_REMOVE_ALERT,
    alertId
  };
}

export function setAlerts(alerts: Alert[]) {
  return {
    type: ACTION_ALERT_SET_ALERTS,
    messages: alerts
  };
}

export default reducer;
