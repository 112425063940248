import { useState } from "react";

import { Flex, Stack } from "@suited/components";
import { StyledTextInput } from "suited/components/shared/inputs/StyledTextInput/StyledTextInput";
import { InputErrorMessage } from "suited/components/shared/inputs/InputErrorMessage/InputErrorMessage";
import { EyeIconButton } from "./PasswordInput.style";

export const PasswordInput = ({ name, error, register, opts = {}, ...props }) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const inputType = isPasswordHidden ? "password" : "text";
  const tooltip = isPasswordHidden ? "Show password" : "Hide password";
  const iconName = isPasswordHidden ? "eye-slash" : "eye";
  return (
    <Stack space="none">
      <Flex align="center" style={{ position: "relative" }}>
        <StyledTextInput
          {...(register && register(name, opts))}
          type={inputType}
          data-test="password-field"
          style={{ width: "100%" }}
          error={error}
          {...props}
        />
        <EyeIconButton
          tooltip={tooltip}
          type="button"
          iconName={iconName}
          onClick={() => setIsPasswordHidden(!isPasswordHidden)}
        />
      </Flex>
      <InputErrorMessage message={error} />
    </Stack>
  );
};
