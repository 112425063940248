import { timeouts } from "../../../../constants/interaction-constants";
import { SuitedDialogWindowEl } from "./AssessmentDialog.style";

interface Props {
  show: boolean;
  onCancel: () => void;
  renderAssessment?: any;
  doNotCloseOnEsc?: boolean;
  zIndex?: number;
}

const AssessmentDialog = (props: Props) => {
  const RenderAssessment = props.renderAssessment;
  return (
    <SuitedDialogWindowEl
      show={props.show}
      onCancel={props.onCancel}
      closeDuration={timeouts.MODAL_DEFAULT_CLOSE_DURATION}
      shouldCloseOnEsc={props.doNotCloseOnEsc ? false : true}
      zIndex={props.zIndex}
    >
      {<RenderAssessment onClose={props.onCancel} />}
    </SuitedDialogWindowEl>
  );
};

export default AssessmentDialog;
