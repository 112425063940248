import { useLazyQuery, useMutation } from "@apollo/client";
import owasp from "owasp-password-strength-test";
import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

import { Box, HStack, Stack } from "@suited/components";
import { isEmptyObject } from "@suited/utils";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { PasswordInput } from "suited/components/shared/inputs/PasswordInput/PasswordInput";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { DEFAULT_REQUIRED_ERROR_MESSAGE } from "suited/constants/validation.constants";

import { VERIFY_USER_PASSWORD } from "../GraphQL/mutations";
import { GET_USER_SETTINGS } from "../GraphQL/queries";

type Props = {
  onClose: () => void;
  setEmailChangeView: (args: string) => void;
  nextStep: string;
};

export const ConfirmPasswordForm = (props: Props) => {
  const { onClose, setEmailChangeView } = props;
  const [getUserSettings] = useLazyQuery(GET_USER_SETTINGS, { fetchPolicy: "network-only" });
  const [verifyUserPassword] = useMutation(VERIFY_USER_PASSWORD);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors, isDirty }
  } = useForm<{ currentPassword: string; root?: any }>({
    mode: "all",
    defaultValues: {
      currentPassword: ""
    }
  });

  const watchedValues = useWatch({ control });

  useEffect(() => {
    clearErrors("currentPassword");
    clearErrors("root.serverError");
  }, [watchedValues, clearErrors]);

  const handlePasswordSubmit = async (values: { currentPassword: string }) => {
    try {
      const response = await verifyUserPassword({
        variables: { password: values.currentPassword }
      });
      const data = response.data.VerifyUserPassword;

      if (!data.verified) {
        return setError("root.serverError", {
          message: "Password doesn't match our records."
        });
      }

      await getUserSettings();
      setEmailChangeView(props.nextStep);
    } catch (error) {
      if (error instanceof Error) {
        setError("root.serverError", {
          message: error?.message
        });
      }
    }
  };

  const formHasErrors = !isEmptyObject(errors);
  const isDisabled = !isDirty || formHasErrors;

  const closeAndReset = () => {
    onClose();
    reset();
  };

  return (
    <form onSubmit={handleSubmit(handlePasswordSubmit)}>
      <Stack space="lg" width="85ch">
        <Box>
          <SuitedCopySubheadline noMargin>1. Confirm Password</SuitedCopySubheadline>
        </Box>
        <Box>
          <Stack>
            <p>Before we begin, for your security, please enter your current password.</p>
            <div>
              <PasswordInput
                name="currentPassword"
                register={register}
                opts={{
                  validate: (value: string) => {
                    if (!value) {
                      return DEFAULT_REQUIRED_ERROR_MESSAGE;
                    }

                    const hasErrors = owasp.test(value).errors.find(Boolean);
                    if (hasErrors) {
                      return owasp.test(value).errors.find(Boolean);
                    }
                  }
                }}
                error={
                  errors?.currentPassword?.message || (errors?.root as any)?.serverError?.message
                }
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  // Need to prevent modal from closing when form is submitted
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit(handlePasswordSubmit)();
                  }
                }}
              />
            </div>
          </Stack>
        </Box>
        <Box>
          <HStack justify="flex-end" space="sm">
            <Box>
              <SuitedButton type="submit" purpose="default" onClick={closeAndReset}>
                Cancel
              </SuitedButton>
            </Box>
            <Box>
              <SuitedButton formButton purpose="primary" disabled={isDisabled}>
                Confirm
              </SuitedButton>
            </Box>
          </HStack>
        </Box>
      </Stack>
    </form>
  );
};
