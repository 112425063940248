import styled from "styled-components";

import {
  typography,
  colors,
  sizes,
  animationCurves,
  responsiveTargets
} from "../../constants/style-constants";

interface MultiSelectContainerProps {
  completed: boolean;
}

export const MultiSelectContainer = styled.div<MultiSelectContainerProps>`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: ${sizes.gridUnits.times3};
  border: 1px solid ${(props) => (props.completed ? "none" : colors.status.error.base)};
`;

interface ItemProps {
  rows: number;
}

export const Item = styled.div<ItemProps>`
  flex: 0 1 50%;
  padding-bottom: ${sizes.gridUnits.times1};
  &:nth-child(2n + 1) {
    padding-right: ${sizes.gridUnits.times1};
  }
  &:nth-child(2n) {
    padding-left: ${sizes.gridUnits.times1};
  }

  @media ${responsiveTargets.phone} {
    width: 50%;
  }
`;

interface MultiSelectOptionContainerProps {
  checked?: boolean;
  disabled?: boolean;
  onClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
}

export const MultiSelectOptionContainer = styled.div<MultiSelectOptionContainerProps>`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: calc(${sizes.gridUnits.times1} - 1px) calc(${typography.margins.input} - 1px);
  color: ${(props) =>
    props.checked ? typography.color.light.emphasis : typography.color.dark.base};
  background: ${(props) => (props.checked ? colors.accent1.base : colors.background.light.primary)};
  border-radius: 0;
  border: 1px solid ${colors.shades.times1};
  box-shadow: ${(props) =>
    props.checked ? `inset 0px 0px 0px 2px ${colors.shades.times2}` : "none"};
  outline: 0;
  font-size: ${typography.sizes.form.base.input};
  font-family: ${typography.family.body};
  cursor: pointer;
  z-index: 1;
  transform: ${(props) => (props.checked ? "scale(1.025)" : "scale(1)")};
  transition: transform 300ms ${animationCurves.easeOutQuart},
    background-color 300ms ${animationCurves.easeOutQuart},
    border-color 300ms ${animationCurves.easeOutQuart},
    box-shadow 300ms ${animationCurves.easeOutQuart};
  &:hover,
  &:focus {
    transform: scale(1.025);
    border: ${(props) =>
      props.checked ? `1px solid ${colors.shades.times3}` : `1px solid ${colors.shades.times2}`};
    box-shadow: ${(props) =>
      props.checked
        ? `inset 0px 0px 0px 2px ${colors.shades.times3}`
        : `inset 0px 0px 0px 2px ${colors.shades.times2}`};
  }
  &:focus {
    outline: none;
  }
  &[disabled] {
    cursor: not-allowed;
    color: ${(props) =>
      props.checked ? typography.color.light.disabled : typography.color.dark.disabled};
    font-weight: 300;
    border: 2px dashed ${(props) => (props.checked ? colors.tints.times2 : colors.shades.times1)};
    background-color: ${(props) => (props.checked ? colors.accent1.tint1 : colors.tints.times2)};
    box-shadow: none;
    transform: scale(1);
    &:hover,
    &:focus {
      background-color: ${(props) => (props.checked ? colors.accent1.tint1 : colors.tints.times2)};
      transform: scale(1);
      border: 2px dashed ${(props) => (props.checked ? colors.tints.times2 : colors.shades.times1)};
      box-shadow: none;
    }
  }
`;

export const OptionLabel = styled.div`
  display: flex;
  justify-content: flex-start;
`;
