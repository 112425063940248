import { useMutation, useQuery } from "@apollo/client";

import { Box, Stack, Center } from "@suited/components";
import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";
import { useGetCandidateAssessments } from "../../eca/eca.hooks";
import {
  ASSESSMENT_SECTION_CLOSE,
  GET_ASSESSMENT,
  GET_NEXT_ASSESSMENT_SECTION_QUESTIONS
} from "../../eca/eca.queries";
import { setCurrentAssessmentView } from "../lateral.slice";
import {
  AssessmentID,
  GetAssessmentQuery,
  GetNextAssessmentSectionQuestionsQuery
} from "../../eca/eca.types";
import { getIsEveryQuestionAnsweredInSection } from "./LateralSubmitButton.utils";
import { sectionStatusTypes } from "../../eca/eca.constants";
import { AssessmentIds, AssessmentViews } from "../lateral.constants";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { TimeRemainingMessage } from "../../eca/NextButton/NextButton.style";
import { getNumberOfCompletedSections, setNumberOfCompletedSections } from "../lateral.slice";
import { getIsFinalSectionOfAssessment } from "../../eca/eca.utils";

export const LateralSubmitButton = () => {
  const numberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);
  const dispatch = useAppDispatch();
  const { assessments } = useGetCandidateAssessments({
    fetchPolicy: "network-only"
  });

  const assessmentID =
    assessments.find((assessment) => assessment.id === AssessmentIds.LATERAL_LAW)?.id || "";

  const { loading, data } = useQuery<GetNextAssessmentSectionQuestionsQuery, AssessmentID>(
    GET_NEXT_ASSESSMENT_SECTION_QUESTIONS,
    {
      fetchPolicy: "network-only",
      skip: !assessmentID,
      variables: { assessmentID }
    }
  );

  const { id, itemGroups, totalSections } = data?.GetNextAssessmentSectionQuestions || {};
  const isFinalSectionOfAssessment = getIsFinalSectionOfAssessment(
    numberOfCompletedSections,
    totalSections
  );
  console.log({ numberOfCompletedSections, totalSections, isFinalSectionOfAssessment })

  const [closeAssessmentSection] = useMutation(ASSESSMENT_SECTION_CLOSE, {
    onCompleted: () => {
      if (isFinalSectionOfAssessment) {
        dispatch(setCurrentAssessmentView(AssessmentViews.END_OF_ASSESSMENT));
      } else {
        dispatch(setCurrentAssessmentView(AssessmentViews.SECTION_INFO));
      }
      dispatch(setNumberOfCompletedSections(numberOfCompletedSections + 1));
    },

    update(cache) {
      const queryCache = {
        query: GET_ASSESSMENT,
        variables: { assessmentID }
      };

      const assessmentCache = cache.readQuery<GetAssessmentQuery, AssessmentID>(queryCache);
      const notCompleteIndex = assessmentCache?.GetAssessment?.sections?.findIndex((section) => {
        return section.status !== sectionStatusTypes.COMPLETE;
      });
      const updatedSections = assessmentCache?.GetAssessment?.sections?.map((section, index) => {
        if (index === notCompleteIndex) {
          return { ...section, status: sectionStatusTypes.COMPLETE };
        }

        return section;
      });

      cache.writeQuery({
        ...queryCache,
        data: {
          GetAssessment: {
            ...assessmentCache?.GetAssessment,
            sections: updatedSections
          }
        }
      });
    },
    fetchPolicy: "no-cache",
    variables: {
      id,
      closeType: "MANUAL"
    }
  });

  const isEveryQuestionAnsweredInSection = getIsEveryQuestionAnsweredInSection(itemGroups);

  const handleNextButtonClick = () => {
    closeAssessmentSection();
  };

  return (
    <Stack space="xl">
      {!isEveryQuestionAnsweredInSection ? (
        <TimeRemainingMessage>
          You still have time! Please attempt to answer every question before moving on.
        </TimeRemainingMessage>
      ) : null}
      <Center>
        <Box>
          <SuitedButton
            purpose="primary"
            disabled={loading || !isEveryQuestionAnsweredInSection}
            onClick={handleNextButtonClick}
          >
            {isFinalSectionOfAssessment ? "Submit" : "Next"}
          </SuitedButton>
        </Box>
      </Center>
    </Stack>
  );
};
