import { HttpResponse, graphql } from "msw";

import { getMockUser } from "../Education/education.mocks";
import { db } from "../Education/education.models";
import { db as personalDataViewDB } from "./personalDataView.model";

export const personalDataMockHandlers = [
  graphql.mutation("UpdateUserPersonalInformation", ({ variables }) => {
    if (!personalDataViewDB.UpdateUserPersonalInformation?.getAll().length) {
      personalDataViewDB.UpdateUserPersonalInformation.create(variables);
    }
    const settings = getMockUser()?.settings;

    return HttpResponse.json({
      data: {
        UpdateUserPersonalInformation: {
          userProfile: {
            personalInformation: {
              ...variables,
              __typename: "PersonalInformation"
            },
            __typename: "UserProfileData"
          },
          settings: {
            ...settings,
            __typename: "UserSettings"
          },
          __typename: "User"
        }
      }
    });
  })
];

export const PERSONAL_DATA_VIEW_MOCK_HANDLER_EXCEPTIONS = {
  OPT_OUT_OF_DEMOGRAPHIC_INFO: graphql.query("GetUser", () => {
    const user = getMockUser();
    const userId = user.id;

    db.GetUser.update({
      where: {
        id: {
          equals: userId
        }
      },
      data: {
        settings(prevData) {
          if (prevData?.diversityOptIn?.optIn) {
            prevData.diversityOptIn.optIn = false;
          }

          return prevData;
        }
      }
    });

    return HttpResponse.json({
      data: {
        GetUser: getMockUser()
      }
    });
  })
};
