import styled from "styled-components";

import SuitedFormLabel from "../typography/SuitedFormLabel";
import { typography, animationCurves, sizes, colors } from "../../../constants/style-constants";

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
  flex: 1 1 auto;
  min-width: 0;
`;

export const SuitedTextInputLabelEl = styled(SuitedFormLabel)`
  align-self: stretch;
  & > span {
    color: ${typography.color.dark.base};
    font-size: ${typography.sizes.form.compact.label};
    top: -4px;
    position: relative;
  }
`;

export const StyledInput = styled.input`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: ${sizes.gridUnits.times5};
  box-sizing: border-box;
  padding: ${typography.margins.input};
  margin-bottom: ${sizes.gridUnits.times3};
  background-color: ${colors.background.light.primary};
  border: 1px solid ${colors.shades.times1};
  box-shadow: none;
  color: ${typography.color.dark.base};
  font-size: ${typography.sizes.form.base.input};
  font-family: ${typography.family.body};
  z-index: 1;
  transition: transform 300ms ${animationCurves.easeOutQuart},
    background-color 300ms ${animationCurves.easeOutQuart},
    border-color 300ms ${animationCurves.easeOutQuart},
    box-shadow 300ms ${animationCurves.easeOutQuart};
  &::placeholder {
    color: ${typography.color.dark.hint};
    font-weight: ${typography.weight.body.light};
    font-style: italic;
  }
  &:hover,
  &:focus {
    transform: scale(1.025);
    border: 1px solid ${colors.shades.times2};
    box-shadow: inset 0px 0px 0px 2px ${colors.shades.times2};
  }
  &:focus {
    border: 1px solid ${colors.accent1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-overflow: ellipsis;
    &:hover,
    &:focus {
      background-color: transparent;
      transform: scale(1);
      border: none;
      box-shadow: none;
    }
  }
`;
