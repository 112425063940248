import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useState } from "react";

import { Box, Center, Grid, HStack, Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import SuitedLink from "suited/components/shared/buttons/SuitedLink";
import { StyledFontAwesomeIcon } from "suited/components/shared/icons/StyledFontAwesomeIcon";
import SuitedCallout from "suited/components/shared/typography/SuitedCallout";
import SuitedCalloutHeadline from "suited/components/shared/typography/SuitedCalloutHeadline";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { LegalCopy } from "suited/components/shared/typography/SuitedLegalCopy";

import { ReasonableAccommodationsModal } from "../ReasonableAccommodationsModal/ReasonableAccommodationsModal";
import { ECA_INSTRUCTIONS } from "../eca.constants";
import { InstructionContent } from "./ECAInstructionsPage.style";

export const ECAInstructionsPage = ({ accommodationMultiplier = 1.0, baseTimeMinutes = 15 }) => {
  const [isReasonableAccommodationsModalOpen, setIsReasonableAccomodationsModalOpen] =
    useState(false);
  const hasReasonableAccommodation = accommodationMultiplier !== 1;
  // Calculate how many minutes candidates should set aside for the assessment and ensure it's rounded to the tenths place
  const timeMinutesString = String(baseTimeMinutes * accommodationMultiplier);
  const timeMinutes = parseFloat(timeMinutesString).toFixed(
    timeMinutesString.includes(".") ? 1 : 0
  );

  return (
    <Box padding="xl" overflow="auto">
      <Center>
        <InstructionContent>
          <Stack space="xl">
            <SuitedCopyHeadline noMargin>Cognitive Skills Assessment</SuitedCopyHeadline>
            {hasReasonableAccommodation ? (
              <SuitedCopySubheadline noMargin noMarginTop important>
                {accommodationMultiplier}× Reasonable Accommodation
              </SuitedCopySubheadline>
            ) : null}
            <p>
              This assessment evaluates skills critical to the position you're applying for.
              Completing all questions is not required, but try to answer as many as you can.
            </p>
            <SuitedCopySubheadline noMargin>INSTRUCTIONS</SuitedCopySubheadline>
            <Grid space="xl">
              {ECA_INSTRUCTIONS.map(({ headline, icon, copy }) => {
                return (
                  <HStack align="flex-start" key={headline}>
                    <StyledFontAwesomeIcon size="3x" icon={icon as IconProp} />
                    <Stack>
                      <SuitedCalloutHeadline noMargin>{headline}</SuitedCalloutHeadline>
                      <SuitedCallout noMargin>
                        {copy.replace("%TIME_MINUTES%", timeMinutes)}
                      </SuitedCallout>
                    </Stack>
                  </HStack>
                );
              })}
            </Grid>
            <Box>
              <Stack space="xl">
                <p>
                  By proceeding, you agree to abide by{" "}
                  <SuitedLink
                    target="_blank"
                    href="https://www.wellsuited.com/essential-competencies-assessment-policy"
                  >
                    this Cognitive Skills Assessment Policy.
                  </SuitedLink>{" "}
                  Your activity may be recorded, and any potential cheating behavior will be flagged
                  and reviewed. Attempts to cheat will result in removal from Suited and may impact
                  your candidacy with our partner firms.
                </p>
                <p>
                  <strong>
                    You cannot pause or restart once begun. However, breaks are allowed during
                    untimed sections. Ready to start? Click below.
                  </strong>
                </p>
                {!hasReasonableAccommodation ? (
                  <p>
                    If you require a reasonable accommodation to complete this assessment, please
                    refrain from starting and contact{" "}
                    <SuitedLink href="mailto:support@wellsuited.com?subject=Reasonable Accommodation Issue for ECA">
                      Suited Support
                    </SuitedLink>
                    .
                  </p>
                ) : null}
                {hasReasonableAccommodation ? (
                  <p>
                    You have been granted a reasonable accommodation of {accommodationMultiplier}×
                    time. If this is not correct, please email us at{" "}
                    <SuitedLink href="mailto:support@wellsuited.com?subject=Reasonable Accommodation Request for ECA">
                      Suited Support
                    </SuitedLink>
                    .
                  </p>
                ) : null}
                <LegalCopy>
                  This assessment is intellectual property created and administered by Suited. By
                  proceeding to take this assessment,{" "}
                  <strong>
                    you agree to not disclose, redistribute, or reproduce the contents
                  </strong>
                  , in whole or in part, without the prior express written consent of Suited.
                </LegalCopy>
                <Center>
                  <SuitedButton
                    purpose="primary"
                    onClick={() => setIsReasonableAccomodationsModalOpen(true)}
                  >
                    Begin
                  </SuitedButton>
                </Center>
              </Stack>
            </Box>
          </Stack>
        </InstructionContent>
      </Center>
      <ReasonableAccommodationsModal
        isOpen={isReasonableAccommodationsModalOpen}
        onCancel={() => setIsReasonableAccomodationsModalOpen(false)}
        accommodationMultiplier={accommodationMultiplier}
      />
    </Box>
  );
};
