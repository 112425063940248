import { CSSProperties } from 'react';
import clsx from 'clsx';

import { omit } from '@suited/utils';
import { BaseComponentProps, SpacingOptions } from '../../types';
import { getContentAreaProps } from '../../utils';
import { CONTENT_AREA_PROPERTIES } from '../../constants';
import './HStack.css';

interface Props extends BaseComponentProps {
  space?: SpacingOptions;
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
}

export const HStack = ({
  children,
  className,
  style,
  space = 'md',
  justify = 'flex-start',
  align = 'center',
  ...props
}: Props) => {
  const classNames = clsx('ws-l-hstack', className);
  const rest = omit(props, CONTENT_AREA_PROPERTIES);
  const styles = {
    ...style,
    ...getContentAreaProps(props),
    '--hstack-space': `var(--perfect-fifth-${space})`,
    '--hstack-justify': justify,
    '--hstack-align': align,
  };

  return (
    <div className={classNames} style={styles} {...rest}>
      {children}
    </div>
  );
};
