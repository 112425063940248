import gql from "graphql-tag";

export const USER_PROFILE_COMPLETION = gql`
  query GetUserProfileCompletion {
    GetUserSurveyAnswers {
      status
    }
    GetUser {
      userProfileCompletion {
        completedSchools
        completedPersonalInformation
        completedResume
        completedWorkPreferences
      }
    }
    GetCandidateAssessments {
      status
    }
  }
`;
