// @ts-nocheck
import * as React from "react";
import styled from "styled-components";

import { typography, responsiveTargets, colors } from "../../../constants/style-constants";

interface Props {
  /** display light text on dark backgrounds */
  overDark?: boolean;
  noMargin?: boolean;
  noMarginTop?: boolean;
  className?: string;
  important?: boolean;
  children: React.ReactNode;
  as?: React.ReactNode;
  style?: React.CSSProperties;
}

const SuitedCopySubheadline = (props: Props) => {
  return (
    <SuitedCopySubheadlineEl {...props} data-test="component-suited-copy-subheadline">
      {props.children}
    </SuitedCopySubheadlineEl>
  );
};

export const SuitedCopySubheadlineEl = styled.h3<Props>`
  ${typography.styles.subheading} /* */
  font-size: ${typography.sizes.copy.subheading.base};
  @media ${responsiveTargets.phone} {
    font-size: ${typography.sizes.copy.subheading.phone};
    margin-bottom: ${typography.margins.subheading.phone};
  }
  @media ${responsiveTargets.tablet} {
    font-size: ${typography.sizes.copy.subheading.tablet};
    margin-bottom: ${typography.margins.subheading.tablet};
  }
  @media ${responsiveTargets.laptop} {
    font-size: ${typography.sizes.copy.subheading.laptop};
    margin-bottom: ${typography.margins.subheading.laptop};
  }
  @media ${responsiveTargets.desktop} {
    font-size: ${typography.sizes.copy.subheading.desktop};
    margin-bottom: ${typography.margins.subheading.desktop};
  }
  @media ${responsiveTargets.jumbo} {
    font-size: ${typography.sizes.copy.subheading.jumbo};
    margin-bottom: ${typography.margins.subheading.jumbo};
  }
  ${(props) => (props.noMargin ? "margin-bottom: 0 !important;" : "")} /*  */
  ${(props) => (props.noMarginTop ? "margin-top: 0 !important;" : "")}
  ${(props) => (props.overDark ? `color: ${typography.color.light.base};` : "")}
  ${(props) => (props.important ? `color: ${colors.color1.base};` : "")}
`;

export default SuitedCopySubheadline;
