export const colors = {
  white: "#ffffff",
  white200: "#fcfcfc",
  grey100: "#dce1e2",
  grey200: "#dfd7c8",
  grey300: "#c7c5c5",
  blue: "#212aa5",
  gold100: "#ddd5c7",
  gold200: "#ebe6dd",
  black100: "#11181f",
  lightGrey100: "#f8f7f7",
  lightGrey200: "#f3f3f3",
  // #a14437
  rust: "#a14f37",
  pine: "#18332f",
  cloud: "#d9e1e2",
  sunflower: "#d39237",
  myrtleGreen: "#3b7d73",
  englishRed: "#9e4245"
};
