import gql from "graphql-tag";

export const GET_RESUME = gql`
  {
    GetResume {
      id
      filename
      filesize
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_RESUME_COMPLETION = gql`
  {
    GetUser {
      id
      userProfileCompletion {
        completedSchools
        completedResume
        completedPersonalInformation
        completedWorkPreferences
      }
    }
  }
`;
