import styled from "styled-components";
import { FormResponse } from "suited/components/Forms";
import { colors, sizes } from "suited/constants/style-constants";

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: -${sizes.gridUnits.times2};
  padding: ${sizes.gridUnits.times1} 0;
  transform: scaleY(0);
  transition: transform 250ms ease-out;
  transform-origin: bottom;
  &:not(:empty) {
    transform: scaleY(1);
  }
  h5 {
    margin-bottom: ${sizes.gridUnits.times1};
  }
`;

export const ValidationWarning = styled(FormResponse)`
  color: ${colors.status.error.shade1};
`;
