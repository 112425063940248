import React, { useState } from "react";
import { useLazyQuery, useApolloClient } from "@apollo/client";

import SuitedDialogMountWrapper from "suited/components/shared/layout/Dialogs/SuitedDialogMount";
import SuitedDialogConfirm from "suited/components/shared/layout/Dialogs/SuitedDialogConfirm";
import SuitedBasicConfirmFooter from "suited/components/shared/layout/Dialogs/SuitedBasicConfirmFooter";
import SuitedABTransitionManager from "suited/components/shared/animation/SuitedABTransitionManager";
import { timeouts } from "suited/constants/interaction-constants";
import { GET_SURVEY_DEFINITION, GET_USER_SURVEY_ANSWERS } from "./GraphQL/queries";
import { StyledContainer } from "./RetryDialog.style";
import logErrorToSlack from "suited/util/logErrorToSlack";
import Loading from "../Loading";

interface Props {
  onCompleted: () => void;
  onClose: () => void;
}

const RetryDialog: React.FunctionComponent<Props> = (props) => {
  const [showAlert, setShowAlert] = useState<boolean>(true);
  const [showMessage, setShowMessage] = useState<boolean>(true);

  const client = useApolloClient();

  const [assessmentRefetch] = useLazyQuery(GET_SURVEY_DEFINITION, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setShowMessage(true);
      const infoMessage =
        "GET_SURVEY_DEFINITION GraphQL Error in SuitedAssessmentComponent Retry Dialog";
      logErrorToSlack(error.message, infoMessage, client);
    },
    onCompleted: () => {
      props.onCompleted();
      setShowAlert(false);
    }
  });

  const [answerRefetch] = useLazyQuery(GET_USER_SURVEY_ANSWERS, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setShowMessage(true);
      const infoMessage =
        "GET_USER_SURVEY_ANSWERS GraphQL Error in SuitedAssessmentComponent Retry Dialog";
      logErrorToSlack(error.message, infoMessage, client);
    },
    onCompleted: () => {
      props.onCompleted();
      setShowAlert(false);
    }
  });

  const refetchData = () => {
    assessmentRefetch();
    answerRefetch();
  };

  return (
    <SuitedDialogMountWrapper show={showAlert}>
      <SuitedDialogConfirm
        contentLabel="Oops! Something Went Wrong."
        show={showAlert}
        title="Oops! Something Went Wrong."
        message={
          <SuitedABTransitionManager
            showA={showMessage}
            renderA={
              "It looks like there was an issue retrieving the assessment. Please click the button below to retry."
            }
            renderB={
              <StyledContainer>
                <Loading />
              </StyledContainer>
            }
            timeout={timeouts.TRANSITION_MANAGER_DURATION}
          />
        }
        onCancel={() => {
          props.onClose();
          setShowAlert(false);
        }}
        renderFooter={
          <SuitedBasicConfirmFooter
            cancelText="cancel"
            confirmText="Retry"
            onCancel={() => {
              props.onClose();
              setShowAlert(false);
            }}
            onConfirm={() => {
              setShowMessage(false);
              refetchData();
            }}
          />
        }
      />
    </SuitedDialogMountWrapper>
  );
};

export default RetryDialog;
