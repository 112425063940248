import styled from "styled-components";

import {
  Empty,
  Dragging,
  DraggingOver,
  Progress,
  Complete,
  Error
} from "./SuitedUploaderCardStates";
import { transitions } from "suited/constants/style-transitions";
import { sizes, colors } from "suited/constants/style-constants";
import { composeTransitions } from "suited/util/composeTransitions";
import { swap, scaleUp, swoopIn } from "suited/constants/style-composable-transitions";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 1 1 auto;
  min-width: 0;
  height: ${sizes.cards.uploader.containerHeight};
  position: relative;
`;

const transitionPosition = `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: auto;
`;

export const TransitionEmpty = styled(Empty)<{ timeout: number }>`
  ${(props) => composeTransitions(props.timeout, swap)}
  ${(props) => transitions.childWhiteFade(props.timeout)}
`;

export const TransitionDragging = styled(Dragging)<{ timeout: number }>`
  ${transitionPosition}
  border-color: ${colors.accent1.base};
  ${(props) => composeTransitions(props.timeout, swap)}
  ${(props) => transitions.childWhiteFade(props.timeout)}
`;

export const TransitionDraggingOver = styled(DraggingOver)<{ timeout: number }>`
  ${transitionPosition}
  transform: scale(1.125);
  border-color: ${colors.accent1.base};
  ${(props) => composeTransitions(props.timeout, swap, scaleUp)}
  ${(props) => transitions.childWhiteFade(props.timeout)}
`;

export const TransitionProgress = styled(Progress)<{ timeout: number }>`
  ${transitionPosition}
  ${(props) => composeTransitions(props.timeout, swap, swoopIn)}
  ${(props) => transitions.childWhiteFade(props.timeout)}
`;

export const TransitionComplete = styled(Complete)<{ timeout: number }>`
  ${transitionPosition}
  ${(props) => composeTransitions(props.timeout, swap)}
  ${(props) => transitions.childWhiteFade(props.timeout)}
`;

export const TransitionError = styled(Error)<{ timeout: number }>`
  ${transitionPosition}
  ${(props) => composeTransitions(props.timeout, swap)}
  ${(props) => transitions.childWhiteFade(props.timeout)}
`;
