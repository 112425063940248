import { SECONDS_IN_MINUTE } from "./Timer.constants";

export const getTimerData = (duration: number) => {
  const minutes = Math.floor(duration / SECONDS_IN_MINUTE);
  const seconds = duration % SECONDS_IN_MINUTE;

  return { minutes, seconds };
};

export const getFormattedTimerDuration = (duration: number) => {
  const { minutes, seconds } = getTimerData(duration);

  // If amount of time allotted is not a whole minute, then display partial
  // minute. For example, if the section is two and a half minutes, we would
  // display 2.5.
  const isDecimalPlaceDisplayed = seconds > 0 && seconds < SECONDS_IN_MINUTE;
  if (isDecimalPlaceDisplayed) {
    const MULTIPLIER = 10;
    const formattedDecimalPlace = Math.round((seconds / SECONDS_IN_MINUTE) * MULTIPLIER);
    return `${minutes}.${formattedDecimalPlace}`;
  }

  return minutes.toString();
};
