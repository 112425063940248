// @ts-nocheck
import { ApolloProvider } from "@apollo/client";
import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import AlertContainer from "suited/components/Alerts/AlertsContainer";
import { setAuthToken } from "suited/components/Login";
import UserRoutes from "suited/components/routes/AuthRoutes";
import UnsupportedBrowser from "suited/components/shared/UnsupportedBrowser";

import "../node_modules/@fortawesome/fontawesome-pro-webfonts/css/fa-light.css";
import "../node_modules/@fortawesome/fontawesome-pro-webfonts/css/fa-regular.css";
// @TODO migrate to Font Awesome 5 SVG with JS
//  this will require additional work:
//      - `yarn add @fortawesome/fontawesome-pro`
//      - `yarn remove @fortawesome/fontawesome-pro-webfonts`
//      - remove the active lines below to stop using FA5 CSS
//      - uncomment the lines below to activate FA5 SVG with JS
//      - refactor to use <FontAwesomeIcon> component w/ props API instead of classnames
//      - correct any problems with sizing and padding of SVGs
//  after that, we will import individual icons into each component which uses it
//    import "../node_modules/@fortawesome/fontawesome-pro"
//    import { fa-icon-name } from "../node_modules/@fortawesome/fontawesome-pro/js/solid/faIconName"
import "../node_modules/@fortawesome/fontawesome-pro-webfonts/css/fa-solid.css";
import "../node_modules/@fortawesome/fontawesome-pro-webfonts/css/fontawesome.css";
import { apolloClient } from "./apolloClient";
import ErrorBoundary from "./components/ErrorBoundary";
import { FeatureFlags } from "./components/FeatureFlags/FeatureFlags";
import { store } from "./configureStore";
import "./fontAwesome";
import theme from "./theme";
import { getAuthToken } from "./util/authUtil";

// bootstrap the authentication tokens if they're there
const token = getAuthToken();
if (token) {
  store.dispatch(setAuthToken(token));
}

const App = () => {
  return (
    <ErrorBoundary client={apolloClient}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <SkeletonTheme baseColor="#d9e1e2" highlightColor="#e0e7e7">
            {/* @ts-ignore */}
            <Router basename="/">
              {/* check to see if the browser set the unsupported flag */}
              {window["unsupportedBrowser"] ? (
                <UnsupportedBrowser />
              ) : (
                <React.Fragment>
                  <AlertContainer />
                  <ApolloProvider client={apolloClient}>
                    <UserRoutes />
                    <FeatureFlags />
                  </ApolloProvider>
                </React.Fragment>
              )}
            </Router>
          </SkeletonTheme>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
