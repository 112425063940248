import { filesize } from "filesize";

import SuitedDetailLabel from "../../shared/typography/SuitedDetailLabel";
import SuitedDetailValue from "../../shared/typography/SuitedDetailValue";
import { StyledSuitedDetailPrimaryValue, FileLink } from "./ResumeUploadCompleteCard.style";
import { Box, Flex, Stack } from "@suited/components";
import SuitedIconButton from "suited/components/shared/buttons/SuitedIconButton";

interface Props {
  data: Data;
  onDownload: () => void;
}

interface Data {
  filename: string;
  filesize: number;
  uploadedOn: string;
}

const dateStringOptions: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit"
};

const ResumeUploadCompleteCard = (props: Props) => {
  const { filename, filesize: fileSize, uploadedOn } = props.data;
  const handleDownload = () => {
    props.onDownload();
  };

  return (
    <Box data-test="component-resume-upload-complete-card">
      <Stack space="xs">
        <Flex align="baseline">
          <StyledSuitedDetailPrimaryValue>Uploaded Resume</StyledSuitedDetailPrimaryValue>
          <Box>
            <SuitedIconButton
              iconName="file-pdf"
              onClickButton={handleDownload}
              tooltip="Download"
            />
          </Box>
        </Flex>
        <Box>
          <SuitedDetailLabel>Filename</SuitedDetailLabel>
          <SuitedDetailValue>
            <FileLink onClick={handleDownload} title="Download">
              {filename}
            </FileLink>
          </SuitedDetailValue>
        </Box>
        <Flex justify="space-between">
          <Box>
            <SuitedDetailLabel>Size</SuitedDetailLabel>
            <SuitedDetailValue>{filesize(fileSize) as string}</SuitedDetailValue>
          </Box>
          <Box>
            <SuitedDetailLabel>Uploaded On</SuitedDetailLabel>
            <SuitedDetailValue>
              {new Date(uploadedOn).toLocaleDateString("en-US", dateStringOptions)}
            </SuitedDetailValue>
          </Box>
        </Flex>
      </Stack>
    </Box>
  );
};

export default ResumeUploadCompleteCard;
