import styled from "styled-components";

import SuitedTextInput, { ISuitedTextInputProps } from "./SuitedTextInput";
import { StyledInput } from "./SuitedTextInput.style";
import { typography } from "../../../constants/style-constants";

interface Props extends ISuitedTextInputProps {}

// @ts-ignore
const StyledSuitedTextInput = styled(SuitedTextInput)`
  ${StyledInput} {
    margin-bottom: 0;
    font-size: ${typography.sizes.form.compact.input};
  }
`;

const SuitedCompactTextInput = (props: Props) => {
  return <StyledSuitedTextInput {...props} data-test="component-suited-compact-text-input" />;
};

export default SuitedCompactTextInput;
