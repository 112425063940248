import React, { useReducer, useRef, useEffect } from "react";

import { ClickAnim, SuitedButtonEl } from "./SuitedButton.style";
import { TButtonPurpose, TButtonType } from "./SuitedButton.types";
import {
  reducer,
  initialState,
  getViewportOffset,
  getSanitizedEvent,
  calculateCenterOffset,
  buttonPurposeTypes,
  getClickAnimIsDark
} from "./SuitedButton.utils";

export interface ISuitedButtonProps {
  /** Only simple strings are allowed as children */
  children: string;
  // @TODO - figure out how to deal with pointer events types
  onClick?(e: React.MouseEvent): void;
  /**
   * Set color by purpose. "default" (outline) | "primary" (solid accent color) | "secondary" (solid white/black) | "cancel" (transparent, no border)
   * @default "default"
   */
  purpose?: TButtonPurpose;
  /** When the button is over a dark background, it has a light appearance. */
  overDark?: boolean;
  /** Optionally optically align-left with negative margin */
  alignLeft?: boolean;
  /** Optionally optically align-right with negative margin */
  alignRight?: boolean;
  disabled?: boolean;
  /**
   * Number of milliseconds between click-event and `onClick` handler fired.
   * This allows the button :active transition to show before navigation, for example.
   * @default 0
   **/
  delay?: number;
  /** Styled-Component compatible */
  className?: string;
  type?: TButtonType;
  autofocus?: boolean;
  title?: string;
  formButton?: boolean;
  style?: React.CSSProperties;
}

/**
 * The `<SuitedButton>` component is an opinionated button with interaction animation and purpose-driven coloring. It should only be used for text- buttons. Icon-only buttons should use a different component.
 */
export const SuitedButton: React.FunctionComponent<ISuitedButtonProps> = (props) => {
  const {
    purpose = "default",
    overDark = false,
    onClick = () => {},
    delay,
    ...passthroughProps
  } = props;

  const elRef = useRef<HTMLButtonElement>(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  // to avoid "state update on unmounted component" warning, we track if mounted
  // https://daviseford.com/blog/2019/07/11/react-hooks-check-if-mounted.html
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  // Click animation side-effects
  useEffect(() => {
    const animateClick = () => {
      // use nested requestAnimationFrame: "single rAF call would schedule the layout update for the current frame while the second rAF call inside the first one schedules the layout update for the next frame. Otherwise both of the changes would happen on the same frame."
      // https://gist.github.com/getify/3002042#gistcomment-2351663
      // https://medium.com/@owencm/one-weird-trick-to-performant-touch-response-animations-with-react-9fe4a0838116
      return window.requestAnimationFrame(() => {
        return window.requestAnimationFrame(() => {
          // only dispatch within the nested rAF if component is still mounted
          if (componentIsMounted.current) {
            dispatch({ type: "RUN_ANIMATE" });
          }
        });
      });
    };
    // only fire one time
    if (state.isHandlingClick) {
      const raf = animateClick();
      return function cleanup() {
        window.cancelAnimationFrame(raf);
      };
    }
    return;
  }, [state.isHandlingClick]);

  // delayed onClick side-effects
  useEffect(() => {
    if (state.isDelayingOnClick && delay) {
      const timeout = window.setTimeout(() => {
        if (state.event) onClick(state.event);
        if (componentIsMounted.current) {
          dispatch({ type: "COMPLETED_ONCLICK" });
        }
      }, delay);
      return function cleanup() {
        window.clearTimeout(timeout);
      };
    }
    return;
  }, [state.isDelayingOnClick, state.event, onClick, delay]);

  const handleClick = (event: React.MouseEvent) => {
    // always prevent default to avoid form submission
    event.persist();
    if (!props.formButton) {
      event.preventDefault();
    }
    const viewportOffset = getViewportOffset(elRef);
    const sanitizedEvent = getSanitizedEvent(event, viewportOffset);
    const { offsetX, offsetY } = calculateCenterOffset({
      pointerEvent: sanitizedEvent,
      viewportOffset
    });
    dispatch({ type: "INIT_ANIMATE", value: { offsetX, offsetY }, event: sanitizedEvent });
    if (!delay) {
      if (event) onClick(event);
    } else {
      dispatch({ type: "DELAY_ONCLICK" });
    }
  };

  const handleTransitionEnd = (event: React.TransitionEvent) => {
    event.persist();
    // two property transitions end, but we only want to dispatch once
    if (event.propertyName === "opacity") {
      dispatch({ type: "RESET_ANIMATE" });
    }
  };

  return (
    <SuitedButtonEl
      className={props.className}
      {...passthroughProps}
      purpose={props.purpose || "default"}
      overDark={props.overDark}
      alignLeft={props.alignLeft}
      alignRight={props.alignRight}
      type={props.type || (props.purpose ? buttonPurposeTypes[props.purpose] : "button")}
      disabled={props.disabled}
      onClick={handleClick}
      ref={elRef}
      autoFocus={props.autofocus}
      title={props.title || props.children}
      data-testid="component-suited-button"
    >
      <span data-testid="button-children">{props.children}</span>
      <ClickAnim
        isDark={getClickAnimIsDark(purpose, overDark)}
        className={`${state.isAnimating ? "active" : ""}`}
        style={{
          transform: `translate(${state.centerOffsetX}px, ${state.centerOffsetY}px) scale(${state.scale})`,
          transformOrigin: "center"
        }}
        onTransitionEnd={handleTransitionEnd}
        data-testid="click-animation"
      />
    </SuitedButtonEl>
  );
};
