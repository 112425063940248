import styled from "styled-components";

export const StyledInput = styled.input`
  cursor: pointer;
  transform: scale(1.2);
`;

export const Label = styled.label`
  cursor: pointer;
`;

export const MultiSelectLeadingText = styled.p`
  font-size: 20px;
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`;
export const MultiSelectUnsearchableText = styled.span`
  &[data-content]:before {
    content: attr(data-content);
  }
  font-size: 20px;
`;
