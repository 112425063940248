import ProgressiveImage from "react-progressive-image";

import {
  PlaceholderImage,
  FullImage,
  ImageContainer,
  ErrorAlt
} from "./SuitedFullBleedImage.style";

interface Props {
  className?: string;
  src: string;
  placeholder: string;
  alt: string;
  delay?: number;
  // @TODO - implement srcSet and sizes support
  // srcSetData?: SourceSetData;
  // @TODO - implement PlaceholderImage's onError API
  // onError?: (error: any) => void;
}

export const renderProgressiveImage = (src, loading, props) => {
  return loading ? (
    <PlaceholderImage
      className={props.className}
      src={props.placeholder}
      alt={`${props.alt}-placeholder`}
    />
  ) : (
    <FullImage className={props.className} src={src} alt={props.alt} />
  );
};

const SuitedFullBleedImage = (props: Props) => {
  const { delay = 0 } = props;
  return (
    <ImageContainer className={props.className} data-test="component-full-bleed-image">
      {/* @ts-ignore */}
      <ProgressiveImage src={props.src} placeholder="" delay={delay} data-test="progressive-image">
        {(src, loading) => {
          return renderProgressiveImage(src, loading, props);
        }}
      </ProgressiveImage>
      <ErrorAlt aria-hidden="true" data-test="error">
        Could not load image:
        <br /> <strong data-test="error-strong">{props.alt}</strong>
      </ErrorAlt>
    </ImageContainer>
  );
};

export default SuitedFullBleedImage;
