import { useEffect, useState } from "react";

import { TIndustryContext } from "suited/components/CandidateConfig/CandidateConfig.types";
import SuitedValidatedSingleSelect from "suited/components/shared/inputs/select/validatedSingle";
import ISelectOption from "suited/components/shared/types/ISelectOption";
import { useInitialMountEffect } from "suited/util/hooks";

interface IIndustrySelectProps {
  industryContextParam?: string;
  validIndustryContexts: TIndustryContext[];
  onChange: (value: string) => void;
  error?: string;
}

const IndustrySelect = (props: IIndustrySelectProps) => {
  const [value, setValue] = useState<ISelectOption | null>(null);
  const [industryContextOptions, setIndustryContextOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useInitialMountEffect(async () => {
    setIndustryContextOptions(
      props.validIndustryContexts.map((option) => ({
        label: option.name,
        value: option.id
      }))
    );
  });

  //  NOTE: MT 7/11/24: We want to extract the industry context from the URL when users are redirected
  //  to the login page from the applicant pool or invitation link so we can prefill the industry context
  //  dropdown.
  const industryContext = industryContextOptions.find(
    (industryContext) => industryContext.value === props.industryContextParam
  );
  useEffect(() => {
    if (industryContext) {
      handleChange(industryContext);
    }
  }, [industryContext]);

  const handleChange = (value: ISelectOption) => {
    setValue(value);
    props.onChange(value.value);
  };

  return (
    <SuitedValidatedSingleSelect
      name="industry-context"
      label="I am interested in*"
      required={true}
      placeholder="Please select an industry…"
      error={props.error}
      invalid={props.error ? true : false}
      options={industryContextOptions}
      value={value as ISelectOption}
      onChange={handleChange}
      overDark={true}
      data-testid="industry-context"
    />
  );
};

export default IndustrySelect;
