import gql from "graphql-tag";

export const GET_USER_PERSONAL_DATA = gql`
  query GetUser {
    GetUser {
      id
      userProfile {
        personalData: personalInformation {
          gender
          veteran
          lgbtq
          firstGenCollege
          ethnicity
          birthYear
        }
      }
      settings {
        diversityOptIn {
          optIn
        }
      }
      userProfileCompletion {
        completedPersonalInformation
        completedPersonalInformation
        completedResume
        completedWorkPreferences
      }
    }
  }
`;
