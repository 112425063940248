import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import { PhoneAndTablet } from "../responsive/Responsive";
import NavButtons from "../user/Header/NavButtons";
import { Header, StyledSuitedIconButton, Title } from "./SuitedNavigationHeader.style";

interface Props {
  /**
   * Handler for clicking the menu icon
   */
  onMenuClick: () => void;
  /**
   * Children display as the header title
   */
  children?: React.ReactChild;
  /**
   * An optional route for a back button. Otherwise shows the menu button.
   */
  to?: string;
  className?: string;
}

const SuitedNavigationHeader: React.FunctionComponent<Props> = (props) => {
  const [backRedirect, setBackRedirect] = useState<boolean>(false);

  const handleClickBack = () => {
    setBackRedirect(true);
  };

  const { to, onMenuClick } = props;
  return (
    <PhoneAndTablet>
      <Header className={props.className} data-test="component-navigation-header">
        {to && backRedirect ? <Redirect push to={to} data-test="redirect" /> : null}
        {to ? (
          <StyledSuitedIconButton
            iconName="arrow-left"
            onClickButton={handleClickBack}
            iconWeight="solid"
            data-test="icon-button"
            tooltip="Navigate Back"
          />
        ) : (
          <StyledSuitedIconButton
            iconName="bars"
            onClickButton={onMenuClick}
            iconWeight="solid"
            data-test="icon-button"
            tooltip="Open Navigation Menu"
            delay={0}
          />
        )}
        <Title data-test="title">{props.children}</Title>
        <NavButtons />
      </Header>
    </PhoneAndTablet>
  );
};

export default SuitedNavigationHeader;
