import * as React from "react";
import styled from "styled-components";

import { typography, responsiveTargets, sizes } from "../../../constants/style-constants";

interface Props {
  noMargin?: boolean;
  className?: string;
  children: React.ReactNode;
}

const SuitedCallout = (props: Props) => {
  return (
    <SuitedCalloutEl {...props} data-test="component-suited-callout">
      {props.children}
    </SuitedCalloutEl>
  );
};

const SuitedCalloutEl = styled.p<Props>`
  ${typography.styles.body} /*  */
  font-size: ${typography.sizes.copy.caption.base};
  line-height: ${typography.leading.fixed.multiLine};
  @media ${responsiveTargets.phone} {
    font-size: ${typography.sizes.copy.caption.phone};
    margin-bottom: ${typography.margins.caption.phone};
  }
  @media ${responsiveTargets.tablet} {
    font-size: ${typography.sizes.copy.caption.tablet};
    margin-bottom: ${typography.margins.caption.tablet};
  }
  @media ${responsiveTargets.laptop} {
    font-size: ${typography.sizes.copy.caption.laptop};
    margin-bottom: ${typography.margins.caption.laptop};
  }
  @media ${responsiveTargets.desktop} {
    font-size: ${typography.sizes.copy.caption.desktop};
    margin-bottom: ${typography.margins.caption.desktop};
  }
  @media ${responsiveTargets.jumbo} {
    font-size: ${typography.sizes.copy.caption.jumbo};
    margin-bottom: ${typography.margins.caption.jumbo};
  }
  ${(props) => (props.noMargin ? "margin-bottom: 0 !important;" : "")} /*  */

  strong,
  em {
    ${typography.styles.emphasis};
  }

  ul {
    margin-top: ${sizes.gridUnits.times1};
    margin-right: ${sizes.gridUnits.times2};
    margin-bottom: ${sizes.gridUnits.times1};
    margin-left: ${sizes.gridUnits.times4};
    li {
      list-style-type: disc;
      &:not(:last-child) {
        margin-bottom: ${sizes.gridUnits.times1};
      }
    }
  }
`;

export default SuitedCallout;
