import { CSSTransition } from "react-transition-group";

import { timeouts } from "suited/constants/interaction-constants";
import { Errors, ErrorText } from "./SuitedInputErrorMessage.style";

interface Props {
  error: string;
  show: boolean;
  validationFor?: string;
  className?: string;
}

const SuitedInputErrorMessage = (props: Props) => {
  const { error, show, ...passthroughProps } = props;
  return (
    <Errors {...passthroughProps} data-test="component-suited-input-error-message">
      <CSSTransition unmountOnExit in={show} timeout={timeouts.TRANSITION_MANAGER_DURATION}>
        <ErrorText
          title={error}
          data-test="error"
          aria-label={props.validationFor ? `${props.validationFor}-error` : undefined}
        >
          {error}
        </ErrorText>
      </CSSTransition>
    </Errors>
  );
};

export default SuitedInputErrorMessage;
