import { useState } from "react";

import { Stack } from "@suited/components";

import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";

import { PaddingBox } from "../CandidateHome.style";
import Associations from "./Associations";
import { AssociationHelperText } from "./Associations/Association.style";
import Invitations from "./Invitations";
import {
  StyledSuitedContentArea,
  StyledSuitedCopy,
  TabContainer,
  TabsContainer
} from "./shared/Main.style";

const CompanyInvites = () => {
  const [invitationsPage, setInvitationsPage] = useState<boolean>(true);

  return (
    <PaddingBox id="nav-scroll-top">
      <StyledSuitedContentArea>
        {invitationsPage ? (
          <Stack space="xxxs">
            <SuitedCopyHeadline noMargin>Invitations</SuitedCopyHeadline>
            <AssociationHelperText>
              Firms invite you to take assessments and share results. Assessments are taken once and
              apply to all future positions.
            </AssociationHelperText>
          </Stack>
        ) : (
          <Stack space="xxxs">
            <SuitedCopyHeadline noMargin>Connections</SuitedCopyHeadline>
            <AssociationHelperText>
                Connected means that you've agreed to share your results with these firms and are open to opportunities there. 
            </AssociationHelperText>
          </Stack>
        )}
        <TabsContainer>
          <TabContainer
            tabIndex={0}
            onClick={() => setInvitationsPage(true)}
            active={invitationsPage}
          >
            <StyledSuitedCopy noMargin={true}>Invitations</StyledSuitedCopy>
          </TabContainer>
          <TabContainer
            tabIndex={0}
            onClick={() => setInvitationsPage(false)}
            active={!invitationsPage}
          >
            <StyledSuitedCopy noMargin={true}>Connections</StyledSuitedCopy>
          </TabContainer>
        </TabsContainer>
        {invitationsPage ? <Invitations /> : <Associations />}
      </StyledSuitedContentArea>
    </PaddingBox>
  );
};

export default CompanyInvites;
