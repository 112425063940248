import styled from "styled-components";
import { Center } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

export const StyledSuitedButton = styled(SuitedButton)`
  flex: 0 1 auto;
`;

export const EducationContent = styled(Center)`
  margin-top: 2.5rem;
  max-width: 85ch;
  margin-left: auto;
  margin-right: auto;
`;

