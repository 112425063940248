import axios from "axios";

const ACTION_PASSWORD_RESET_DETAILS_RECEIVED = "ACTION_PASSWORD_RESET_DETAILS_RECEIVED";
const ACTION_HANDLE_PASSWORD_RESET_ERROR = "ACTION_HANDLE_PASSWORD_RESET_ERROR";
const ACTION_HANDLE_PASSWORD_RESET_SUCCESS = "ACTION_HANDLE_PASSWORD_RESET_SUCCESS";
const ACTION_HANDLE_CLEAR_ERRORS = "ACTION_HANDLE_CLEAR_ERRORS";

export const stateKey = "ResetPassword";

const initialState = {
  email: "",
  passwordResetSuccess: false,
  errors: [],
  errorDetails: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_PASSWORD_RESET_DETAILS_RECEIVED:
      return {
        ...state,
        role: action.role,
        resetStatus: action.status
      };
    case ACTION_HANDLE_PASSWORD_RESET_ERROR:
      return {
        ...state,
        errors: [action.errors],
        errorDetails: action.feedback
      };
    case ACTION_HANDLE_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordResetSuccess: action.passwordResetSuccess
      };
    case ACTION_HANDLE_CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
        errorDetails: []
      };
    default:
      return state;
  }
};

//
// ACTION CREATORS
//
export const getPasswordResetById = (passwordResetId: string) => {
  return (dispatch) => {
    return axios
      .get(`/resetPassword/getPasswordReset?id=${passwordResetId}`)
      .then((res) => {
        dispatch(
          setPasswordResetData({
            status: res && res.data ? res.data.status : null,
            role: res && res.data ? res.data.role : null
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

const setPasswordResetData = ({ status, role }) => {
  return {
    type: "ACTION_PASSWORD_RESET_DETAILS_RECEIVED",
    status,
    role
  };
};

const handlePasswordResetError = ({ data }) => {
  return {
    type: "ACTION_HANDLE_PASSWORD_RESET_ERROR",
    errors: data.message,
    feedback: data.feedback.suggestions
  };
};

const handlePasswordResetSuccess = ({ passwordResetSuccess }) => {
  return {
    type: "ACTION_HANDLE_PASSWORD_RESET_SUCCESS",
    passwordResetSuccess: passwordResetSuccess
  };
};

const handleClearErrors = () => {
  return {
    type: "ACTION_HANDLE_CLEAR_ERRORS"
  };
};

export const requestUserPasswordReset = (email: string) => {
  return () => {
    return axios
      .post(`/resetPassword/requestUserPasswordReset?email=${email}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const resetUserPassword = ({ password, resetId }) => {
  return (dispatch) => {
    return axios
      .post(`/resetPassword/resetUserPassword`, {
        password,
        resetId
      })
      .then(() => {
        dispatch(
          handlePasswordResetSuccess({
            passwordResetSuccess: true
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 400 && err.response.data) {
          dispatch(
            handlePasswordResetError({
              data: err.response.data
            })
          );
        } else {
          console.error(err);
        }
      });
  };
};

export const clearErrors = () => {
  return (dispatch) => {
    dispatch(handleClearErrors());
  };
};

export default reducer;
