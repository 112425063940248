import React from "react";
import { uuidv4 } from "@suited/utils";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

import { InputProps } from "./index";
import {
  ChooseText,
  AllocationQuestionsContainer,
  PointsRemaining,
  PointsRemainingNumber,
  QuestionRow,
  QuestionText,
  StyledSuitedTextInput,
  ButtonRow
} from "./PointAllocation.style";

interface Props extends InputProps {
  value: string[];
  questions: string[];
  totalPoints: number;
  active: boolean;
  onChange(value: string[]): void;
  onSubmit(): void;
  disabled?: boolean;
}

interface State {
  values: string[];
  formKey: string;
}

class PointAllocation extends React.Component<Props, State> {
  state = {
    values: [],
    formKey: uuidv4()
  };

  componentDidMount() {
    // we manage the state of the inputs internally until they're submitted
    let values = this.props.value;
    if (!values || values.length === 0) {
      const length = (this.props.questions && this.props.questions.length) || 0;
      values = new Array(length).fill("");
    }
    this.setState({ values, formKey: uuidv4() });
  }

  handleChangePoints = (idx, value: string, min?: number, max?: number) => {
    const newValues: string[] = [...this.state.values];
    if (value === "" || isNaN(parseInt(value))) {
      newValues[idx] = "";
    } else {
      let localValue: number = parseInt(value);
      if (min !== undefined && localValue < min) {
        localValue = min;
        // if the value is out of bounds, rev the key to force the input to rerender the corrected in-bounds value
        this.setState({ formKey: uuidv4() });
      }
      if (max !== undefined && localValue > max) {
        localValue = max;
        this.setState({ formKey: uuidv4() });
      }
      newValues[idx] = localValue.toString();
    }
    this.setState({ values: newValues });
  };

  handleSubmit = () => {
    this.props.onChange(this.state.values);
    this.props.onSubmit();
  };

  submitValid = () => {
    // TODO: potential format exceptions here
    const valueTotal = this.state.values.reduce((accum, v) => accum + Number.parseInt(v), 0);
    return valueTotal === this.props.totalPoints;
  };

  render() {
    const { questions, totalPoints, disabled } = this.props;
    const values = this.state.values;
    const pointsRemaining = values
      ? values.reduce((accum, v: any) => {
          if (!v) return accum;
          else return accum - Number.parseInt(v);
        }, totalPoints)
      : totalPoints;
    return (
      <React.Fragment>
        <ChooseText>Allocate the total points among the following statements:</ChooseText>

        <AllocationQuestionsContainer disabled={disabled}>
          <PointsRemaining>
            Points Remaining:{" "}
            <PointsRemainingNumber warning={pointsRemaining < 0}>
              {pointsRemaining}
            </PointsRemainingNumber>
          </PointsRemaining>
          {questions.map((q, i) => {
            return (
              <QuestionRow key={`question_${i}`}>
                <QuestionText>{q}</QuestionText>
                <StyledSuitedTextInput
                  inputKey={this.state.formKey}
                  name={`question_${i}`}
                  type="number"
                  onInputChange={(value: any) =>
                    this.handleChangePoints(i, value, 0, totalPoints)
                  }
                  value={this.state.values[i] !== undefined ? this.state.values[i] : ""}
                  min={0}
                  max={10}
                />
              </QuestionRow>
            );
          })}
        </AllocationQuestionsContainer>
        <ButtonRow>
          <SuitedButton
            purpose="primary"
            onClick={this.handleSubmit}
            disabled={!this.submitValid()}
          >
            Done
          </SuitedButton>
        </ButtonRow>
      </React.Fragment>
    );
  }
}

export default PointAllocation;
