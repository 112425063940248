const errorRgba: string = "rgba(255, 0, 0, 0.8)";

export const hexToRgba = (hashHex: string, alpha: number): string => {
  if (hashHex.length !== 7 || !hashHex.startsWith("#")) return errorRgba;
  const hex = hashHex.split("#")[1];
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha || 1.0})`;
};
