import React from "react";
import { CSSTransition } from "react-transition-group";

import {
  Layout,
  TransitionEmpty,
  TransitionDragging,
  TransitionDraggingOver,
  TransitionProgress,
  TransitionComplete,
  TransitionError
} from "./SuitedUploaderLayoutManager.style";
import { SuitedUploaderLayoutMode } from "./common";

interface Props {
  mode: SuitedUploaderLayoutMode;
  errorCardMessage?: string;
  emptyCardMessage: string;
  errorCardError?: string;
  onClickUpload: () => void;
  draggingCardMessage: string;
  draggingOverCardMessage: string;
  progressCardMessage: string;
  progress: number;
  progressDone: boolean;
  progressError: boolean;
  onFinishedTransitionProgressToDone: () => void;
  onFinishedTransitionProgressToError: () => void;
  completeCardComponent: (props: any) => JSX.Element | Element;
  completeCardData: any;
  onCompleteClear: () => void;
  onCompleteDownload: () => void;
  primaryErrorMessage?: string;
  secondaryErrorMessage?: string;
  onErrorClear: () => void;
  onFinishedTransitionModeToComplete: () => void;
  onFinishedTransitionModeToEmpty: () => void;
}

const TRANSITION_DURATION = 300;
const BEAT_DURATION = 1000;

class SuitedUploaderLayoutManager extends React.Component<Props> {
  handleDidTransitionProgressToDone = () => {
    // give a beat between transitions so it sinks in
    setTimeout(() => {
      this.props.onFinishedTransitionProgressToDone();
    }, BEAT_DURATION);
  };

  handleDidTransitionProgressToError = () => {
    // give a beat between transitions so it sinks in
    setTimeout(() => {
      this.props.onFinishedTransitionProgressToError();
    }, BEAT_DURATION);
  };

  handleDidTransitionToComplete = () => {
    this.props.onFinishedTransitionModeToComplete();
  };

  handleDidTransitionToEmpty = () => {
    this.props.onFinishedTransitionModeToEmpty();
  };

  handleCompleteClear = () => {
    this.props.onCompleteClear();
  };

  handleCompleteDownload = () => {
    this.props.onCompleteDownload();
  };

  handleErrorClear = () => {
    this.props.onErrorClear();
  };

  render() {
    const {
      mode,
      emptyCardMessage,
      draggingCardMessage,
      draggingOverCardMessage,
      progressCardMessage,
      progress,
      progressDone,
      progressError,
      completeCardComponent,
      primaryErrorMessage,
      secondaryErrorMessage,
      onErrorClear,
      onClickUpload,
      onFinishedTransitionModeToComplete,
      onFinishedTransitionModeToEmpty,
      onFinishedTransitionProgressToDone,
      onFinishedTransitionProgressToError,
      onCompleteClear,
      onCompleteDownload,
      ...passthroughProps
    } = this.props;
    return (
      <Layout {...passthroughProps}>
        <CSSTransition
          unmountOnExit={true}
          in={mode === "empty"}
          onEntered={this.handleDidTransitionToEmpty}
          timeout={TRANSITION_DURATION}
        >
          <TransitionEmpty
            message={emptyCardMessage}
            onClickUpload={onClickUpload}
            timeout={TRANSITION_DURATION}
          />
        </CSSTransition>
        <CSSTransition unmountOnExit={true} in={mode === "dragging"} timeout={TRANSITION_DURATION}>
          <TransitionDragging message={draggingCardMessage} timeout={TRANSITION_DURATION} />
        </CSSTransition>
        <CSSTransition
          unmountOnExit={true}
          in={mode === "draggingOver"}
          timeout={TRANSITION_DURATION}
        >
          <TransitionDraggingOver message={draggingOverCardMessage} timeout={TRANSITION_DURATION} />
        </CSSTransition>
        <CSSTransition unmountOnExit={true} in={mode === "progress"} timeout={TRANSITION_DURATION}>
          <TransitionProgress
            message={progressCardMessage}
            progress={progress}
            done={progressDone}
            error={progressError}
            onDone={this.handleDidTransitionProgressToDone}
            onError={this.handleDidTransitionProgressToError}
            timeout={TRANSITION_DURATION}
          />
        </CSSTransition>
        <CSSTransition
          unmountOnExit={true}
          in={mode === "complete"}
          onEntered={this.handleDidTransitionToComplete}
          timeout={TRANSITION_DURATION}
        >
          <TransitionComplete
            onClear={this.handleCompleteClear}
            onDownload={this.handleCompleteDownload}
            timeout={TRANSITION_DURATION}
            layout={this.props.completeCardComponent}
            data={this.props.completeCardData}
          />
        </CSSTransition>
        <CSSTransition unmountOnExit={true} in={mode === "error"} timeout={TRANSITION_DURATION}>
          <TransitionError
            primaryErrorMessage={primaryErrorMessage}
            secondaryErrorMessage={secondaryErrorMessage}
            onClear={this.handleErrorClear}
            timeout={TRANSITION_DURATION}
          />
        </CSSTransition>
      </Layout>
    );
  }
}

export default SuitedUploaderLayoutManager;
