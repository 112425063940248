import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import { Stack } from "@suited/components";
import { colors } from "suited/constants/style-constants";
import SuitedLink from "./shared/buttons/SuitedLink";
import AuthenticationTemplate from "./AuthenticationTemplate/AuthenticationTemplate";

const EmailAlreadyConfirmed: React.FunctionComponent<{}> = () => {
  const [navigateToLogin, setNavigateToLogin] = useState<boolean>(false);

  const handleClickSignIn = () => {
    setNavigateToLogin(true);
  };

  if (navigateToLogin) {
    return <Redirect push to={"/login"} />;
  }

  const paragraphStyles = { color: colors.white };

  return (
    <AuthenticationTemplate
      headline="This Email Address Has Already Been Confirmed"
      ctaText="Sign In"
      ctaOnClick={handleClickSignIn}
      children={
        <Stack>
          <p style={paragraphStyles}>You've already used this link to confirm your email.</p>
          <p style={paragraphStyles}>
            Please proceed{" "}
            <SuitedLink href="/login" tooltip="Login">
              here
            </SuitedLink>{" "}
            to log in to your Suited account.
          </p>
          <p style={paragraphStyles}>
            If you're having difficulty logging in, please try{" "}
            <SuitedLink href="/user-request-password-reset" tooltip="Reset Password">
              resetting your password
            </SuitedLink>
            .
          </p>
          <p style={paragraphStyles}>
            If you cannot log in after resetting your password, please{" "}
            <SuitedLink
              href="mailto:support@wellsuited.com"
              target="_blank"
              tooltip="Email Support"
            >
              let us know.
            </SuitedLink>
          </p>
        </Stack>
      }
    />
  );
};

export default EmailAlreadyConfirmed;
