import React from "react";
import { Wrap } from "@suited/components";

import {
  ListItemBox,
  ListItemTitle,
  ListItemDetailContainer
} from "../../shared/InviteStates.style";

interface Props {
  title: React.ReactNode;
  detail: React.ReactNode;
}

export const ListItem = ({ title, detail }: Props) => {
  return (
    <ListItemBox padding="xs">
      <Wrap space="none">
        <ListItemTitle>{title}</ListItemTitle>
        <ListItemDetailContainer>{detail}</ListItemDetailContainer>
      </Wrap>
    </ListItemBox>
  );
};
