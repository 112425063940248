// @ts-nocheck
import { countries } from "countries-list";
import parsePhoneNumber, { CountryCode } from "libphonenumber-js";

// NOTE: This translates the iso2 code to the locale format required for validation on server.
// For example, the iso2 code for the US is "us". IfTranslate we obtain the language code,
// we can contruct the locale to "en-US".
// @ts-ignore
export const getLocaleFromIso2Code = (iso2Code: string) => {
  const formattedIso2Code = iso2Code?.toUpperCase();
  const language = countries[formattedIso2Code]?.languages?.find(Boolean);

  return `${language}-${formattedIso2Code}`;
};

export const getFormattedPhoneNumber = (phoneNumber: string, locale: CountryCode) => {
  // NOTE: Extracts country code from locale. I.e. "US" from en-US.
  const countryCode = locale?.slice(3);

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

  if (countryCode === "US") {
    return parsedPhoneNumber.formatNational();
  } else {
    return parsedPhoneNumber.formatInternational();
  }
};
