import React from "react";
import { EIndustryIds, TIndustryContextId } from "suited/components/CandidateConfig";
import { TIndustryContext } from "suited/components/CandidateConfig/CandidateConfig.types";
import { LawInstructions } from "../LawInstructions/LawInstructions";
import { InvestmentBankingInstructions } from "../InvestmentBankingInstructions/InvestmentBankingInstructions";

const educationInstructions: Record<TIndustryContextId, React.ReactElement | null> = {
  [EIndustryIds.LAW]: <LawInstructions />,
  [EIndustryIds.INVESTMENT_BANKING]: <InvestmentBankingInstructions />,
  // NO_CONTEXT is the legacy behavior during the feature transition
  [EIndustryIds.NO_CONTEXT]: <InvestmentBankingInstructions />
};

interface IInstructionsContentProps {
  industryContext: TIndustryContext;
}

export const EducationInstructions: React.FunctionComponent<IInstructionsContentProps> = (
  props
) => {
  const isValidIndustryContext = Object.keys(educationInstructions).find(
    (key) => key === props.industryContext.id
  );
  if (isValidIndustryContext) {
    return educationInstructions[props.industryContext.id];
  } else return null;
};
