import { IUserRegistrationState } from "../../UserRegistration.types";

export const initialState: IUserRegistrationState = {
  acceptTerms: false,
  firstName: "",
  lastName: "",
  email: "",
  industryContext: "",
  invalidEmail: false,
  validPassword: "",
  error: "",
  errorDetails: [] as string[],
  isProcessing: false,
  recaptchaKey: "",
  validIndustryContexts: [],
  hasInvalidIndustryContext: false
};

export const INVALID_INDUSTRY_CONTEXT_VALUE = "Please select an industry.";
