import { useEffect } from 'react';
import { useLatest } from './useLatest';

export const useInterval = (callback: () => void, step: number | null | undefined) => {
  const ref = useLatest(callback);

  useEffect(() => {
    if (!step) {
      return;
    }

    const timer = setInterval(() => {
      ref.current();
    }, step);

    return () => {
      clearTimeout(timer);
    };
  }, [step]);
};
