import React from "react";
import { Transition } from "react-transition-group";

import { timeouts } from "suited/constants/interaction-constants";

// The purpose of this component is to automatically mount and unmount a child Suited Dialog based on its `show` prop.
// This forces the child dialog component's state to be freshly mounted every time the dialog is invoked.
// What's a better name to convey this meaning?
interface Props {
  show: boolean;
  children: React.ReactNode;
  closeDuration?: number;
}

const SuitedDialogMountWrapper = (props: Props) => {
  const { closeDuration = timeouts.MODAL_DEFAULT_CLOSE_DURATION } = props;
  return (
    // Wrap modals inside this transition with the same animation timing so that it mounts and unmounts on show/hide.
    // This means we don't have to fuss with manually clearing its state between uses
    <Transition mountOnEnter={true} unmountOnExit={true} in={props.show} timeout={closeDuration}>
      {/* @ts-ignore */}
      {props.children}
    </Transition>
  );
};

export default SuitedDialogMountWrapper;
