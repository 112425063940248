import axios from "axios";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { setAuthToken } from "suited/components/Login";
import { useAppDispatch } from "suited/util/hooks/reduxHooks";

const STATUS_MESSAGES = {
  BAD_REQUEST: "Confirmation link is invalid, please contact support@wellsuited.com for further assistance.",
  SUCCESS: "Secondary email confirmed successfully, please close this tab and return to your original session window.",
  INVALID: "Confirmation link is malformed, please contact support@wellsuited.com for further assistance.",
};

export const UserEmailChangeSecondaryConfirmed = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const [state, setState] = useState<any>({ email: null, id: null, status: null });
  // @TODO - get this to pass linter for hooks dependencies
  useEffect(() => {
    const initialize = async () => {
      const email = queryString.parse(location.search).email;
      const id = queryString.parse(location.search).id;
      if (!email || !id) {
        return setState({ email, id, status: "BAD_REQUEST" });
      }

      const res = await axios.request({
        method: "POST",
        url: `auth/confirm-change-secondary-email-auth`,
        data: {
          email: decodeURIComponent(email as string),
          id
        }
      });

      // Using payload status since async/await will throw if the response isn't successful
      if (res.data.status === "success") {
        if (res.data.token) {
          // authenticate
          dispatch(setAuthToken(res.data.token));
          history.push("/home/user-settings");
        } else {
          setState({ status: "SUCCESS" });
        }
      } else {
        setState({ status: "INVALID" });
      }
    };

    initialize();
  }, []); // eslint-disable-line

  return (
    <div>
      {state.email ? <div>Confirmation link is missing email</div> : null}
      {state.id ? <div>Confirmation link is missing id</div> : null}
      {state.status && STATUS_MESSAGES[state.status] ? <div>{STATUS_MESSAGES[state.status]}</div> : null}
    </div>
  );
};
