import { ecaMockHandlers } from "suited/components/CandidateHome/eca/eca.mocks";
import { appBootstrapMockHandlers } from "suited/components/AppBootstrap/app-bootstrap.mocks";
import { userSettingsMockHandlers } from "suited/components/CandidateHome/UserSettings/userSettings.mocks";
import { educationMockHandlers } from "suited/components/CandidateHome/Education/education.mocks";
import { personalDataMockHandlers } from "suited/components/CandidateHome/PersonalData/personalDataView.mocks";
import { workPreferencesMockHandlers } from "suited/components/CandidateHome/workPreferences/workPreferences.mocks";
import { registrationMockHandlers } from "suited/components/UserRegistration/registration.mocks";
import { accountTypeSettingsMockHandlers } from "suited/components/CandidateHome/accountTypeSettings/accountTypeSettings.mocks";

export const handlers = [
  ...ecaMockHandlers,
  ...appBootstrapMockHandlers,
  ...userSettingsMockHandlers,
  ...educationMockHandlers,
  ...personalDataMockHandlers,
  ...workPreferencesMockHandlers,
  ...registrationMockHandlers,
  ...accountTypeSettingsMockHandlers
];
