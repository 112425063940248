import styled from "styled-components";
import { FileDrop, FileDropProps } from "react-file-drop";
import { sizes } from "../../../../constants/style-constants";

export const StyledFileDrop = styled((props: FileDropProps) => <FileDrop {...props} />)`
  position: absolute;
  width: ${sizes.cards.uploader.width};
  min-width: ${sizes.cards.uploader.minWidth};
  max-width: ${sizes.cards.uploader.maxWidth};
  height: ${sizes.cards.uploader.height};
  z-index: 2;
`;
