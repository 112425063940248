import React from "react";
import styled from "styled-components";

import { typography, responsiveTargets } from "../../../constants/style-constants";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  noMargin?: boolean;
  className?: string;
  children: React.ReactNode;
}

const SuitedCopyCaption = (props: Props) => {
  return (
    <SuitedCopyCaptionEl {...props} data-test="component-suited-copy-caption">
      {props.children}
    </SuitedCopyCaptionEl>
  );
};

const SuitedCopyCaptionEl = styled.div<Props>`
  ${typography.styles.body} /*  */
  color: ${typography.color.dark.hint};
  font-size: ${typography.sizes.copy.caption.base};
  line-height: ${typography.leading.fixed.multiLine};
  @media ${responsiveTargets.phone} {
    font-size: ${typography.sizes.copy.caption.phone};
    margin-bottom: ${typography.margins.caption.phone};
  }
  @media ${responsiveTargets.tablet} {
    font-size: ${typography.sizes.copy.caption.tablet};
    margin-bottom: ${typography.margins.caption.tablet};
  }
  @media ${responsiveTargets.laptop} {
    font-size: ${typography.sizes.copy.caption.laptop};
    margin-bottom: ${typography.margins.caption.laptop};
  }
  @media ${responsiveTargets.desktop} {
    font-size: ${typography.sizes.copy.caption.desktop};
    margin-bottom: ${typography.margins.caption.desktop};
  }
  @media ${responsiveTargets.jumbo} {
    font-size: ${typography.sizes.copy.caption.jumbo};
    margin-bottom: ${typography.margins.caption.jumbo};
  }
  ${(props) => (props.noMargin ? "margin-bottom: 0 !important;" : "")} /*  */

  strong,
  em {
    ${typography.styles.emphasis};
  }
`;

export default SuitedCopyCaption;
