import { css } from "styled-components";
import { animationCurves } from "./style-constants";

const crossFade = (duration: number) => {
  return css`
    &.appear {
      opacity: 0.01;
    }
    &.appear-active {
      opacity: 1;
      transition: opacity ${duration}ms ${animationCurves.easeInQuart};
    }
    &.enter {
      opacity: 0.01;
    }
    &.enter-active {
      opacity: 1;
      transition: opacity ${duration}ms ${animationCurves.easeInQuart};
    }
    &.exit {
      opacity: 1;
    }
    &.exit-active {
      opacity: 0.01;
      transition: opacity ${duration}ms ${animationCurves.easeOutQuart};
    }
  `;
};

const childWhiteFade = (duration: number) => {
  return css`
    &.enter > div {
      opacity: 0.01;
    }
    &.enter-active > div {
      opacity: 1;
      transition: opacity ${animationCurves.easeInQuart};
      transition-delay: ${duration / 2}ms;
      transition-duration: ${duration / 2}ms;
    }
    &.exit > div {
      opacity: 1;
    }
    &.exit-active > div {
      opacity: 0.01;
      transition: opacity ${animationCurves.easeOutQuart};
      transition-delay: 0;
      transition-duration: ${duration / 2}ms;
    }
  `;
};

const crossScaleBump = (duration: number) => {
  return css`
    &.enter {
      opacity: 0.01;
      transform: scale(0.66);
    }
    &.enter-active {
      opacity: 1;
      transform: scale(1);
      transition: opacity ${duration / 4}ms ${duration / 2}ms ${animationCurves.easeInQuart},
        transform ${duration / 2}ms ${duration / 2}ms ${animationCurves.easeOutBack};
    }
    &.exit {
      opacity: 1;
      transform: scale(1);
    }
    &.exit-active {
      opacity: 0.01;
      transform: scale(0.66);
      transition: opacity ${duration / 4}ms ${duration / 2}ms ${animationCurves.easeOutQuart},
        transform ${duration / 2}ms ${animationCurves.easeInBack};
    }
  `;
};

const simpleDisclosure = (duration: number) => {
  return css`
    &.appear {
      opacity: 0.01;
      transform: scale(0.8) translateY(-50%);
    }

    &.appear-active {
      opacity: 1;
      transform: scale(1) translateY(0%);
      transition: opacity ${duration}ms ${animationCurves.easeInQuart},
        transform ${(2 * duration) / 3}ms ${animationCurves.easeOutQuart};
    }

    &.enter {
      opacity: 0.01;
      transform: scale(0.8) translateY(-50%);
    }

    &.enter-active {
      opacity: 1;
      transform: scale(1) translateY(0%);
      transition: opacity ${duration}ms ${animationCurves.easeInQuart},
        transform ${(2 * duration) / 3}ms ${animationCurves.easeOutQuart};
    }

    &.exit {
      opacity: 1;
      transform: scale(1) translateY(-0%);
    }

    &.exit-active {
      opacity: 0.01;
      transform: scale(0.8) translateY(-50%);
      transition: opacity ${(2 * duration) / 3}ms ${duration / 3}ms ${animationCurves.easeOutQuart},
        transform ${duration}ms ${animationCurves.easeInQuart};
    }
  `;
};

export const transitions = {
  crossFade,
  crossScaleBump,
  childWhiteFade,
  simpleDisclosure
};
