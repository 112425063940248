import React from "react";

import { sizes } from "suited/constants/style-constants";

import SuitedCopyHeadline from "../../typography/SuitedCopyHeadline";
import BasicDialogHeader from "./BasicDialogHeader";
import { CompactSuitedDialogWindow } from "./common.style";

interface Props {
  show: boolean;
  title: string;
  message: React.ReactNode;
  onCancel: () => void;
  closeDuration?: number;
  shouldFocusAfterRender?: boolean;
  /** String indicating how the content container should be announced to screenreaders */
  contentLabel?: string;
  renderFooter: React.ReactNode;
  zIndex?: number;
}

const SuitedDialogConfirm = (props: Props) => {
  return (
    <CompactSuitedDialogWindow
      show={props.show}
      onCancel={props.onCancel}
      contentLabel={props.contentLabel || props.title}
      closeDuration={props.closeDuration}
      iconButtonRow={<BasicDialogHeader onCancel={props.onCancel} />}
      renderFooter={props.renderFooter}
      maxWidth={sizes.modalWidths.md}
      // TODO: enable overlay close on click after transition animation has finished.
      // https://github.com/TitanHire/titanhire-platform/pull/2045#discussion_r400196701
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
      zIndex={props?.zIndex}
    >
      <SuitedCopyHeadline>{props.title}</SuitedCopyHeadline>
      <p>{props.message}</p>
    </CompactSuitedDialogWindow>
  );
};

export default SuitedDialogConfirm;
