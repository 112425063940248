import React from "react";

interface Props {
  name: string;
  onChange: ((value: string) => void) | ((value: boolean) => void) | ((value: number) => void);
  children: React.ReactNode;
  selectedValue?: string | boolean | number;
  className?: string;
}

export interface ISuitedRadioGroupContext {
  name?: string;
  selectedValue?: string | boolean | number;
  onChange?: ((value: string) => void) | ((value: boolean) => void) | ((value: number) => void);
}

const defaultContext: ISuitedRadioGroupContext = {
  name: undefined,
  selectedValue: undefined,
  onChange: undefined
};
const context = React.createContext<ISuitedRadioGroupContext>(defaultContext);
const SuitedRadioGroupContextProvider = context.Provider;
export const SuitedRadioGroupContextConsumer = context.Consumer;

const SuitedRadioGroup = (props: Props) => {
  return (
    <SuitedRadioGroupContextProvider
      value={{
        name: props.name,
        selectedValue: props.selectedValue,
        onChange: props.onChange
      }}
      data-test="component-suited-radio-group"
    >
      <div className={props.className} data-test="radio-group-container">
        {props.children}
      </div>
    </SuitedRadioGroupContextProvider>
  );
};

export default SuitedRadioGroup;
