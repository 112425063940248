import styled from "styled-components";

import { sizes } from "suited/constants/style-constants";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";

export const StyledSuitedCopySubheadline = styled(SuitedCopySubheadline)`
  margin-bottom: ${sizes.gridUnits.times1};
`;

export const UserSettingsContent = styled.div`
  flex-basis: 95ch;
  margin-top: 2rem;
`;
