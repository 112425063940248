import Skeleton from "react-loading-skeleton";
import { Box, Stack } from "@suited/components";
import { QuestionCopy } from "../eca.styles";
import { QuestionRowSkeleton } from "./QuestionSkeleton.style";

export const QuestionSkeleton = () => {
  const style = { maxWidth: "100%" };
  return (
    <Stack>
      <QuestionCopy style={style}>
        <Skeleton containerTestId="question-loading-skeleton" count={2} />
      </QuestionCopy>
      <Box>
        <Stack space="xs">
          <QuestionRowSkeleton>
            <p style={style}>
              <Skeleton />
            </p>
            <p style={style}>
              <Skeleton />
            </p>
          </QuestionRowSkeleton>
          <QuestionRowSkeleton>
            <p style={style}>
              <Skeleton />
            </p>
            <p style={style}>
              <Skeleton />
            </p>
          </QuestionRowSkeleton>
        </Stack>
      </Box>
    </Stack>
  );
};
