import * as React from "react";
import styled from "styled-components";

import { typography, responsiveTargets, sizes } from "../../../constants/style-constants";

interface Props {
  noMargin?: boolean;
  className?: string;
  children: React.ReactNode;
}

const SuitedCalloutHeadline = (props: Props) => {
  return (
    <SuitedCalloutHeadlineEl {...props} data-test="component-suited-callout-subheadline">
      {props.children}
    </SuitedCalloutHeadlineEl>
  );
};

const SuitedCalloutHeadlineEl = styled.h3<Props>`
  ${typography.styles.emphasis} /*  */
  letter-spacing: ${typography.tracking.slight};
  font-size: ${typography.sizes.copy.subheading.base};
  margin-bottom: ${sizes.gridUnits.times1};
  @media ${responsiveTargets.phone} {
    font-size: ${typography.sizes.copy.subheading.phone};
  }
  @media ${responsiveTargets.tablet} {
    font-size: ${typography.sizes.copy.subheading.tablet};
  }
  @media ${responsiveTargets.laptop} {
    font-size: ${typography.sizes.copy.subheading.laptop};
  }
  @media ${responsiveTargets.desktop} {
    font-size: ${typography.sizes.copy.subheading.desktop};
  }
  @media ${responsiveTargets.jumbo} {
    font-size: ${typography.sizes.copy.subheading.jumbo};
  }
  ${(props) => (props.noMargin ? "margin-bottom: 0 !important;" : "")}/*  */
`;

export default SuitedCalloutHeadline;
