import styled from "styled-components";
import { typography } from "suited/constants/style-constants";

export const AssociationHelperText = styled.p`
  color: ${typography.color.dark.base};
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.form.base.button};
  font-weight: ${typography.weight.body.light};
  line-height: normal;
`;
