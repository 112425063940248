import React from "react";

import { SuitedListItemPrimaryValueEl } from "./SuitedListItemPrimaryValue.style";

export interface ISuitedListItemPrimaryValueProps {
  /** display light text on dark backgrounds */
  overDark?: boolean;
  /** React element that needs to be passed in to be displayed in the Label */
  children: React.ReactNode;
  /** Styled-Component compatible */
  className?: string;
  /** Style props */
  style?: React.CSSProperties;
}

/**
 * ## What
 * `<SuitedListItemPrimaryValue>` is a label-less value for a list item. It is for the "hero" value—the human-friendly primary identifier of a data object. It should be a self-evident property, since it has no label. This is usually the name of a thing.
 *
 * ## Where
 * Use only in list items. It is designed for compact display.
 *
 * ## Not
 * There should only be one of these per list item. Do not use in detail views.
 *
 * ## Example
 * In Recruiter App, on the Candidate List, the name of the candidate uses this component.
 */
export const SuitedListItemPrimaryValue: React.FunctionComponent<
  ISuitedListItemPrimaryValueProps
> = (props) => {
  return (
    <SuitedListItemPrimaryValueEl overDark={props.overDark} className={props.className} style={props.style}>
      {props.children}
    </SuitedListItemPrimaryValueEl>
  );
};
