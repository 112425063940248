import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";

import { Stack, Box, Flex } from "@suited/components";
import { isEmptyObject } from "@suited/utils";
import { timeouts } from "suited/constants/interaction-constants";
import { DEFAULT_REQUIRED_ERROR_MESSAGE } from "suited/constants/validation.constants";
import { GET_USER_SETTINGS } from "./GraphQL/queries";
import { UPDATE_USER_NAME } from "./GraphQL/mutations";
import { GET_USER_BOOTSTRAP } from "suited/components/CandidateHome";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { StyledSuitedCopySubheadline } from "./UserSettingsView.style";
import { StyledTextInput } from "suited/components/shared/inputs/StyledTextInput/StyledTextInput";
import { SuitedAltInputLabel } from "suited/components/shared/typography/SuitedAltInputLabel";
import { InputErrorMessage } from "suited/components/shared/inputs/InputErrorMessage/InputErrorMessage";

export const UserNameChangeForm = () => {
  const { data } = useQuery(GET_USER_SETTINGS);
  const [updateUserName] = useMutation(UPDATE_USER_NAME);
  const user = data?.GetUser;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm({
    mode: "all",
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName
    }
  });

  useEffect(() => {
    reset(user);
  }, [reset, user]);

  const formHasErrors = !isEmptyObject(errors);
  const isDisabled = !isDirty || formHasErrors;

  return (
    <form
      onSubmit={handleSubmit((values) => {
        updateUserName({
          variables: { firstName: values.firstName, lastName: values.lastName },
          refetchQueries: [{ query: GET_USER_BOOTSTRAP }]
        });
      })}
    >
      <Stack space="sm" data-test="name-change-form">
        <StyledSuitedCopySubheadline noMargin>Change Name</StyledSuitedCopySubheadline>
        <Stack space="sm">
          <Box>
            <Stack space="none">
              <SuitedAltInputLabel htmlFor="user-settings-first-name">
                First Name*
              </SuitedAltInputLabel>
              <StyledTextInput
                id="user-settings-first-name"
                {...register("firstName", { required: DEFAULT_REQUIRED_ERROR_MESSAGE })}
                error={errors?.firstName?.message}
              />
              <InputErrorMessage message={errors?.firstName?.message} />
            </Stack>
          </Box>
          <Box>
            <Stack space="none">
              <SuitedAltInputLabel htmlFor="user-settings-last-name">
                Last Name*
              </SuitedAltInputLabel>
              <StyledTextInput
                id="user-settings-last-name"
                {...register("lastName", { required: DEFAULT_REQUIRED_ERROR_MESSAGE })}
                error={errors?.lastName?.message}
              />
              <InputErrorMessage message={errors?.lastName?.message} />
            </Stack>
          </Box>
        </Stack>
        <Flex justify="flex-end">
          <Box>
            <SuitedButton
              alignRight
              formButton
              type="submit"
              purpose="primary"
              disabled={isDisabled}
              delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
            >
              Save
            </SuitedButton>
          </Box>
        </Flex>
      </Stack>
    </form>
  );
};
