import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HStack, SpacingOptions } from "@suited/components";
import { typography } from "suited/constants/style-constants";
import SuitedCopyCaption from "suited/components/shared/typography/SuitedCopyCaption";

type Props = {
  message: string;
  isChecked: boolean;
  space?: SpacingOptions;
};

export const Alert = ({ message, isChecked, space }: Props) => {
  const fontColor = isChecked ? "rgb(25, 135, 84)" : typography.color.dark.hint;
  const stackSpace = space || "xs";
  return (
    <HStack space={stackSpace}>
      <FontAwesomeIcon
        data-testid="requirement-alert-icon"
        style={{ color: fontColor }}
        size="lg"
        icon={["fal", "check"]}
      />
      <SuitedCopyCaption data-testid="requirement-alert-text" noMargin style={{ color: fontColor }}>
        {message}
      </SuitedCopyCaption>
    </HStack>
  );
};
