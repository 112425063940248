import { TCandidateVisibility } from "./Home.types";
import { PaddingBox } from "../CandidateHome.style";
import HomeWelcome from "./Welcome/HomeWelcome";

export interface ICandidateHomeProps {
  candidateVisibilitySetting: TCandidateVisibility;
}

const Home = () => {
  return (
    <PaddingBox id="nav-scroll-top">
      <HomeWelcome />
    </PaddingBox>
  );
};

export default Home;
