import * as React from "react";
import styled from "styled-components";

import { typography, sizes } from "../../../constants/style-constants";

export const DetailPrimaryValue = styled.div`
  flex: 1 1 auto;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: ${sizes.gridUnits.times1};
  padding-left: 0;
  color: ${typography.color.dark.base};
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.copy.value};
  font-weight: ${typography.weight.body.bold};
`;

type Props = {
  children: React.ReactNode;
};

const SuitedDetailPrimaryValue = (props: Props) => {
  return (
    <DetailPrimaryValue {...props} data-test="component-suited-detail-primary-label">
      {props.children}
    </DetailPrimaryValue>
  );
};

export default SuitedDetailPrimaryValue;
