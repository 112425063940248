import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HStack, SpacingOptions } from "@suited/components";
import { colors } from "suited/constants/style-constants";
import SuitedCopyCaption from "suited/components/shared/typography/SuitedCopyCaption";

type Props = {
  message: string;
  space?: SpacingOptions;
};

export const ErrorAlert = ({ message, space }: Props) => {
  const errorTextColor = colors.status.error.shade1;
  const stackSpace = space || "xs";
  return (
    <HStack space={stackSpace}>
      <FontAwesomeIcon
        data-testid="alert-error-icon"
        style={{ color: errorTextColor }}
        size="lg"
        icon={["fal", "circle-xmark"]}
      />
      <SuitedCopyCaption data-testid="alert-error-text" noMargin style={{ color: errorTextColor }}>
        {message}
      </SuitedCopyCaption>
    </HStack>
  );
};
