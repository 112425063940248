import { useEffect } from "react";

import { useAppSelector, useAppDispatch } from "suited/util/hooks/reduxHooks";
import { FocusMode } from "suited/components/shared/FocusMode/FocusMode";
import { AssessmentViews } from "../lateral.constants";
import { getCurrentAssessmentView, setCurrentAssessmentView } from "../lateral.slice";
import { LateralSectionInformation } from "../LateralSectionInformation/LateralSectionInformation";
import { LateralSectionQuestions } from "../LateralSectionQuestions/LateralSectionQuestions";
import { LateralEndOfAssessmentPage } from "../LateralEndOfAssessmentPage/LateralEndOfAssessmentPage";
import { LateralProgressBar } from "../LateralProgressBar/LateralProgressBar";

// We need to do dynamically render views via Redux rather than react-router
// so that focus mode doesn't re-render between routes and cause unwanted animations
export const LateralContent = () => {
  const currentAssessmentView = useAppSelector(getCurrentAssessmentView);
  const dispatch = useAppDispatch();

  const assessmentViewComponents = {
    [AssessmentViews.SECTION_INFO]: <LateralSectionInformation />,
    [AssessmentViews.SECTION_QUESTIONS]: <LateralSectionQuestions />,
    [AssessmentViews.END_OF_ASSESSMENT]: <LateralEndOfAssessmentPage />
  };
  const currentViewComponent = assessmentViewComponents[currentAssessmentView];

  useEffect(() => {
    return () => {
      dispatch(setCurrentAssessmentView(AssessmentViews.SECTION_INFO));
    };
  }, [dispatch]);

  return (
    <FocusMode>
      {currentViewComponent}
      <LateralProgressBar />
    </FocusMode>
  );
};
