import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-template-rows: auto;
`;

export const StyledRadioButton = styled.input`
  cursor: pointer;
  border: 0px;
  width: 100%;
  height: 1.4em;
`;

export const MatrixTableUnsearchableText = styled.span`
  &[data-content]:before {
    content: attr(data-content);
  }
  font-size: 15px;
`;
