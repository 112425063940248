import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { HStack, SpacingOptions } from "@suited/components";

import SuitedCopyCaption from "suited/components/shared/typography/SuitedCopyCaption";
import { colors } from "suited/constants/colors";

type Props = {
  message: string;
  isChecked: boolean;
  space?: SpacingOptions;
};

export const PasswordFeedback = ({ message, isChecked, space }: Props) => {
  const fontColor = isChecked ? "rgb(25, 135, 84)" : colors.englishRed;
  const stackSpace = space || "xs";
  return (
    <HStack space={stackSpace}>
      <FontAwesomeIcon
        data-testid="requirement-alert-icon"
        style={{ color: fontColor }}
        size="sm"
        icon={["fal", isChecked ? "check" : "xmark-large"]}
      />
      <SuitedCopyCaption
        data-testid="requirement-alert-text"
        noMargin
        style={{ color: fontColor, fontSize: "0.8rem" }}
      >
        {message}
      </SuitedCopyCaption>
    </HStack>
  );
};
