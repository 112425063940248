import React from "react";

import { timeouts } from "../../constants/interaction-constants";
import { typography } from "../../constants/style-constants";
import heroPlaceholderSrc from "../../img/hero/hero-authentication-placeholder.jpg";
import heroSrc from "../../img/hero/hero-authentication.jpg";
import iconSrc from "../../img/icon-white.svg";
import logoSrc from "../../img/logo-2020-light.svg";
import { SuitedExplainer } from "../UserRegistration/components/RegistrationForm/RegistrationForm.style";
import SuitedFullBleedImage from "../shared/layout/SuitedFullBleedImage";
import {
  StyledCopyrightNotice,
  SuitedSplashFooterLink
} from "../shared/layout/SuitedSplashPage.style";
import { LaptopAndAbove, PhoneAndTablet } from "../shared/responsive/Responsive";
import SuitedCopyHeadline from "../shared/typography/SuitedCopyHeadline";
import {
  AuthenticationTemplateEl,
  CallToAction,
  Content,
  Footer,
  Header,
  Icon,
  Image,
  InstructionsCopy,
  Logo,
  Spacer,
  Wrap
} from "./AuthenticationTemplate.style";

interface Props {
  /** the call to action button text */
  ctaText?: string;
  /** the call to action button behavior */
  ctaOnClick?: () => void;
  /** the headline text at the top of the content */
  headline: string;
  /** the text right below the headline and right above the conent */
  preContentCopy?: string | React.ReactNode;
  /** children are wrapped in a form by default. Child content should be updated to use `SuitedCopy` and `SuitedCopyHeading` as appropriate. */
  children?: React.ReactNode;
  /** determines if registration page */
  registrationPage?: boolean;
}

const AuthenticationTemplate = (props: Props) => {
  return (
    <AuthenticationTemplateEl>
      <Icon>
        <img src={iconSrc} title="Suited icon" alt="suited icon" />
      </Icon>
      <Image>
        <SuitedFullBleedImage
          src={heroSrc}
          placeholder={heroPlaceholderSrc}
          alt="luxurious office lobby"
        />
      </Image>
      <PhoneAndTablet>
        <React.Fragment>
          <Logo>
            <img src={logoSrc} title="Suited logo" alt="suited logo" />
          </Logo>
          {props.ctaText && props.ctaOnClick ? (
            <CallToAction
              onClick={props.ctaOnClick}
              purpose="default"
              overDark={true}
              delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
            >
              {props.ctaText}
            </CallToAction>
          ) : null}
        </React.Fragment>
      </PhoneAndTablet>
      <Content>
        <LaptopAndAbove>
          <Header>
            <Logo>
              <img src={logoSrc} title="Suited logo" alt="suited logo" />
            </Logo>
            {props.ctaText && props.ctaOnClick ? (
              <CallToAction
                onClick={props.ctaOnClick}
                purpose="default"
                overDark={true}
                alignRight
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
              >
                {props.ctaText}
              </CallToAction>
            ) : null}
          </Header>
        </LaptopAndAbove>
        <SuitedCopyHeadline noMargin>{props.headline}</SuitedCopyHeadline>
        {props.registrationPage && (
          <SuitedExplainer>
            Hiring intelligence to find the best talent, wherever they are.
          </SuitedExplainer>
        )}
        <InstructionsCopy>{props.preContentCopy}</InstructionsCopy>
        <Wrap>{props.children}</Wrap>
      </Content>
      <Spacer />
      <Footer>
        <StyledCopyrightNotice />
        <div>
          <SuitedSplashFooterLink
            target="_blank"
            href="http://wellsuited.com/terms"
            color={`${typography.color.light.disabled}`}
            data-test="terms"
          >
            Terms of Service
          </SuitedSplashFooterLink>
          <SuitedSplashFooterLink
            target="_blank"
            href="http://wellsuited.com/privacy"
            color={`${typography.color.light.disabled}`}
            data-test="privacy"
          >
            Privacy Policy
          </SuitedSplashFooterLink>
        </div>
      </Footer>
    </AuthenticationTemplateEl>
  );
};

export default AuthenticationTemplate;
