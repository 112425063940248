import React, { useState, useEffect } from "react";
import _debounce from "lodash/debounce";

import { SuitedTextInputContainerEl, SuitedTextInputEl } from "./SuitedAltTextInput.style";
import { SuitedAltInputLabel } from "suited/components/shared/typography/SuitedAltInputLabel";
import {
  HTMLInputAttributeTextType,
  IAllCommonTextInputHTMLAttributes,
  ILabelHTMLAttributes
} from "suited/components/shared/types/htmlAttributeTypes";
import {
  IHandleSanitizedEventArgs,
  IHandleRawEventArgs
} from "suited/components/shared/types/eventInterfaces";

export interface ISuitedTextInputProps
  extends IAllCommonTextInputHTMLAttributes,
    ILabelHTMLAttributes {
  /**
   * input name for the form, also populates `id`
   */
  name: string;
  /**
   * input type hint
   */
  type: HTMLInputAttributeTextType | undefined;
  /** parent's change handler. this should update `value` */
  onInputChange: (value: any, targetName: string) => void;
  /** When the input is over a dark background, it has a light appearance. */
  overDark?: boolean;
  /**
   * Use this to re-mount the input to refresh its value from `value`
   */
  inputKey?: string;
  /**
   * Optional initial value. Prop updates do nothing without a change to `inputKey`.
   */
  value?: string;
  /**
   * optional label to appear above the input
   */
  label?: string;
  /**
   * if present, delays `onChange` by x milliseconds
   * @default 0
   */
  debounceTime?: number;
  /**
   * optionally handle focus events
   */
  onInputFocus?: (value: any, targetName: string) => void;
  /**
   * optionally handle blur events
   */
  onInputBlur?: (value: any, targetName: string) => void;
  /**
   * for Styled Component compatibility
   */
  className?: string;
  /**
   * Override default margin
   */
  noMargin?: boolean;
}

/**
 * `SuitedTextInput` is a styled, uncontrolled component that renders a labeled input of type "text", "email", "number", "password", "search", or "tel".

It passes the input value to `onChange` on every change. The value of the input is not stored in state, so it is essentially a draft value until the parent's change handler passes it back down via the `value` prop.

We use the ["Fully Uncontrolled Component with a Key" Pattern](https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key) on every instance of `SuitedTextInput` so that resetting the input can be accomplished by mounting an entirely new instance when the controlled `key` is changed.

Although `<SuitedTextInput>` has no validation, it is intended to be used side-by-side with `<SuitedValidatedTextInput>`s which do have inline validation. As such, this component includes bottom whitespace for consistent layout when mixed in a form with its sister `<SuitedValidatedTextInput>`.
 */
export const SuitedAltTextInput: React.FunctionComponent<ISuitedTextInputProps> = (props) => {
  const {
    name,
    type,
    onInputChange = () => {},
    onInputFocus = () => {},
    onInputBlur = () => {},
    debounceTime = 0,
    overDark = false,
    inputKey,
    value,
    label,
    className,
    ...passthroughProps
  } = props;
  const { form, htmlFor, ...inputPassthroughProps } = passthroughProps;
  const labelPassthroughProps = {
    form,
    htmlFor
  };

  const [touched, setTouched] = useState<boolean>(props.value ? true : false);
  useEffect(() => {
    if (props.value) setTouched(true);
  }, [props.value]);

  const handleEvent: (args: IHandleRawEventArgs) => void = (args) => {
    const value = args.event.currentTarget.value;
    const name = args.event.currentTarget.name;
    const { eventHandler, debounce } = args;
    if (debounce && debounce > 0) debouncedHandleEvent({ value, name, eventHandler });
    else immediateHandleEvent({ value, name, eventHandler });
  };

  const immediateHandleEvent: (args: IHandleSanitizedEventArgs) => void = (args) => {
    args.eventHandler(args.value, args.name);
  };

  const debouncedHandleEvent = _debounce((args: IHandleSanitizedEventArgs) => {
    immediateHandleEvent(args);
  }, debounceTime);

  const handleFocus: (event: React.FormEvent<any>) => void = (event) => {
    handleEvent({ event, eventHandler: onInputFocus });
  };

  const handleBlur: (event: React.FormEvent<any>) => void = (event) => {
    handleEvent({ event, eventHandler: onInputBlur });
    setTouched(true);
  };

  const handleChange: (event: React.FormEvent<any>) => void = (event) => {
    handleEvent({ event, eventHandler: onInputChange, debounce: debounceTime });
  };

  return (
    <SuitedTextInputContainerEl
      className={props.className}
      data-testid="component-suited-text-input"
    >
      {props.label ? (
        <SuitedAltInputLabel {...labelPassthroughProps} htmlFor={name} overDark={overDark}>
          {props.label ? props.label : null}
          {props.required ? <span aria-label="required">*</span> : null}
        </SuitedAltInputLabel>
      ) : null}
      <SuitedTextInputEl
        {...inputPassthroughProps}
        key={props.inputKey}
        overDark={overDark}
        id={props.name}
        name={props.name}
        defaultValue={props.value ? props.value : ""}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        type={type}
        touched={touched}
        data-testid="suited-text-input-input"
      />
    </SuitedTextInputContainerEl>
  );
};
