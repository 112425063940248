import gql from "graphql-tag";

export const GET_USER_BOOTSTRAP = gql`
  query GetUserBootstrap {
    GetUserSurveyAnswers {
      status
    }
    GetUser {
      id
      firstName
      lastName
      email
      accountType
      userProfileCompletion {
        completedSchools
        completedPersonalInformation
        completedResume
        completedWorkPreferences
      }
      settings {
        visibility
      }
      industryContext {
        id
        name
      }
      accommodations
      secondaryEmail
      linkedInUrl
      phoneNumber
      phoneNumberLocale
      hasSecondaryContactInfo
    }
    GetCandidateAssessments {
      id
      status
    }
  }
`;
