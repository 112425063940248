import styled from "styled-components";

export const AssessmentPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
  flex: 1 1 auto;
  min-height: 0px;
`;
