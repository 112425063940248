import { IconName } from "@fortawesome/fontawesome-common-types";

export type IconWeight = "solid" | "regular" | "light";
export type IconSize =
  | "xs"
  | "sm"
  | "lg"
  | "2x"
  | "3x"
  | "4x"
  | "5x"
  | "6x"
  | "7x"
  | "8x"
  | "9x"
  | "10x";

const weightPrefixMap = {
  solid: "fas",
  regular: "far",
  light: "fal",
};

interface IconProps {
  className?: string;
  name: IconName;
  onClick?: any;
  size?: IconSize;
  spin?: boolean;
  pulse?: boolean;
  style?: any;
  title?: string;
  weight?: IconWeight;
  fixedWidth?: boolean;
}

export default function Icon({
  className,
  name,
  onClick,
  pulse,
  size,
  spin,
  style,
  title,
  weight,
  fixedWidth,
}: IconProps) {
  className = className ? `${className} ` : "";
  const faPrefix = weight ? weightPrefixMap[weight] : weightPrefixMap.solid;
  const faSize = size ? ` fa-${size}` : "";
  const faSpin = spin ? " fa-spin" : "";
  const faPulse = pulse ? " fa-pulse" : "";
  const faFw = fixedWidth ? " fa-fw" : "";
  return (
    <i
      className={`${className}${faPrefix} fa-${name}${faSize}${faSpin}${faPulse}${faFw}`}
      onClick={onClick}
      style={style}
      title={title}
      aria-hidden="true"
      data-test="component-icon"
    />
  );
}
