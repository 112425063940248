import React from "react";
import slugify from "slugify";
import { UseFormRegister } from "react-hook-form";

import { RadioItem } from "./SuitedRadioButton.style";
import { SuitedRadioGroupContextConsumer, ISuitedRadioGroupContext } from "./SuitedRadioGroup";

interface Props {
  value: string | boolean | number;
  children: React.ReactNode;
  name?: string;
  onSelect?: () => void;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  testId?: string;
  register?: UseFormRegister;
}

// we export render prop function for shallow unit testing
export const renderSuitedRadioButton = (
  context: ISuitedRadioGroupContext,
  props: Props
): React.ReactElement<any> => {
  const { register = (f) => f } = props;
  const labelId = slugify(`${props.value}`, { lower: true });
  return (
    <RadioItem className={props.className} data-test="component-suited-radio-button">
      <input
        type="radio"
        name={context.name || props.name}
        value={`${props.value}`}
        onChange={handleChange(context, props)}
        id={labelId}
        checked={props.value === context.selectedValue || props.checked || false}
        disabled={props.disabled}
        data-test="radio-input"
        data-testid={props.testId}
        {...register(props.name)}
      />
      <label htmlFor={labelId} data-test="radio-label">
        {props.children}
      </label>
    </RadioItem>
  );
};

const SuitedRadioButton = (props: Props) => {
  return (
    <SuitedRadioGroupContextConsumer>
      {(context) => renderSuitedRadioButton(context, props)}
    </SuitedRadioGroupContextConsumer>
  );
};

const handleChange = (context, props) => {
  if (props.disabled) return;
  return context.onChange !== undefined
    ? () => context.onChange && context.onChange(props.value)
    : props.onSelect;
};

export default SuitedRadioButton;
