const VITE_REDUX_LOGGING = import.meta.env.VITE_REDUX_LOGGING;
const VITE_REDUX_DEVTOOLS = import.meta.env.VITE_REDUX_DEVTOOLS;
const VITE_ENABLE_MSW_MOCK_API = import.meta.env.VITE_ENABLE_MSW_MOCK_API;
const VITE_LOG_MSW_UNHANDLED_REQUESTS = import.meta.env.VITE_LOG_MSW_UNHANDLED_REQUESTS;
const IS_DEV = import.meta.env.DEV;

export const AppConfig = {
  IS_DEV,
  VITE_REDUX_LOGGING,
  VITE_REDUX_DEVTOOLS,
  VITE_ENABLE_MSW_MOCK_API,
  VITE_LOG_MSW_UNHANDLED_REQUESTS
};
