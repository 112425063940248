import { Box, Stack, Flex } from "@suited/components";
import { MatrixTableItem } from "../MatrixTableItem/MatrixTableItem";
import { Grid } from "./MatrixTableItemGroup.style";
import { QuestionCopy } from "../eca.styles";
import type {
  MatrixTableItemHeader,
  MatrixTableItemProps,
  SubmitItemVariables
} from "../eca.types";

type Props = {
  leadingText: string;
  headers: MatrixTableItemHeader[];
  items: MatrixTableItemProps[];
  value?: string;
  onSubmit: (data: SubmitItemVariables) => void;
};

export const MatrixTableItemGroup = ({ leadingText, headers, items, onSubmit }: Props) => {
  return (
    <Box>
      <Stack space="lg">
        {leadingText && (
          <Box>
            <QuestionCopy>{leadingText}</QuestionCopy>
          </Box>
        )}
        <Box>
          <Stack space="lg">
            <Grid>
              <Box />
              {headers.map(({ label }) => (
                <Flex id={label} key={label} justify="center" align="center">
                  <p>{label}</p>
                </Flex>
              ))}
            </Grid>
            {items.map(({ id, label, value }) => (
              <MatrixTableItem
                id={id}
                key={label}
                label={label}
                headers={headers}
                value={value}
                onSubmit={onSubmit}
              />
            ))}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
