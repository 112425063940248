import styled, { css } from "styled-components";

import { typography, animationCurves, sizes, colors } from "suited/constants/style-constants";

interface StyleProps {
  touched?: boolean;
  overDark?: boolean;
  noMargin?: boolean;
}

export const SuitedTextInputContainerEl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
  flex: 0 0 auto;
  min-width: 0;
`;

export const SuitedTextInputEl = styled.input<StyleProps>`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: ${sizes.gridUnits.times5};
  box-sizing: border-box;
  padding: ${typography.margins.input};
  ${(props) => (props.noMargin ? null : `margin-bottom: ${sizes.gridUnits.times3};`)}
  background-color: ${(props) => (props.touched ? colors.tints.times4 : colors.shades.divide4)};
  border: 1px solid ${colors.shades.times1};
  box-shadow: none;
  color: ${typography.color.dark.base};
  font-size: ${typography.sizes.form.base.input};
  font-family: ${typography.family.body};
  font-weight: ${typography.weight.body.medium};
  letter-spacing: ${typography.tracking.loose};
  letter-spacing: 0.115em;
  z-index: 1;
  transition: background-color 300ms ${animationCurves.easeOutQuart},
    border-color 300ms ${animationCurves.easeOutQuart},
    box-shadow 300ms ${animationCurves.easeOutQuart};
  &::placeholder {
    color: ${typography.color.dark.disabled};
    font-family: ${typography.family.body};
    font-weight: ${typography.weight.body.light};
    letter-spacing: ${typography.tracking.loose};
  }
  &:hover,
  &:focus {
    background-color: ${colors.tints.times4};
    border-color: ${colors.shades.times2};
    box-shadow: inset 0px 0px 0px 2px ${colors.shades.times2};
  }
  &:focus {
    background-color: ${colors.background.light.primary};
    border-color: ${colors.accent1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.shades.divide2};
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 2px transparent;
    text-overflow: ellipsis;
    &::placeholder {
      color: transparent;
    }
    &:hover,
    &:focus {
      background-color: ${colors.shades.divide2};
      border-color: transparent;
      box-shadow: inset 0px 0px 0px 2px transparent;
    }
  }
  /* @TODO - use touched in a meaningful way even w/o validation */
  border-color: ${(props) => (props.touched ? colors.shades.times2 : colors.shades.times1)};
  ${(props) => getDarkStyles(props)}
`;

const getDarkStyles = (props: StyleProps) => {
  if (props.overDark)
    return css`
      color: ${typography.color.light.base};
      background-color: ${props.touched ? colors.shades.times1 : colors.tints.divide2};
      &::placeholder {
        color: ${typography.color.light.disabled};
      }
      &:hover,
      &:focus {
        background-color: ${colors.shades.times1};
        border-color: ${colors.tints.times3};
        box-shadow: inset 0px 0px 0px 2px ${colors.tints.times3};
      }
      &:focus {
        background-color: ${colors.shades.times2};
        border-color: ${colors.accent1.base};
        box-shadow: inset 0px 0px 0px 2px ${colors.accent1.base};
      }
      &:disabled {
        background-color: ${colors.tints.times1};
        &:hover,
        &:focus {
          background-color: ${colors.tints.times1};
        }
      }
      border-color: ${props.touched ? colors.tints.times2 : colors.tints.times1};
    `;
  return css``;
};
