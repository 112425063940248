import React from "react";

import { Box, Stack } from "@suited/components";

import SuitedFullBleedImage from "suited/components/shared/layout/SuitedFullBleedImage";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import welcomeHeroPlaceholder from "suited/img/hero/hero-welcome-placeholder.jpg";
import welcomeHero from "suited/img/hero/hero-welcome.jpg";

const HomeWelcome = () => {
  return (
    <React.Fragment>
      <SuitedFullBleedImage
        src={welcomeHero}
        placeholder={welcomeHeroPlaceholder}
        alt={welcomeHeroAlt}
      />
      <Box maxWidth="85ch" style={{ padding: "4rem" }}>
        <Stack space="xxl" data-testid="welcome-copy">
          <SuitedCopyHeadline noMargin>Welcome to Suited.</SuitedCopyHeadline>
          <Box>
            <Stack space="xl">
              <p>
                First, complete the sections for education, work preferences, and demographics to
                share more information about yourself for our partner firms. (These sections can be
                modified at any time.)
              </p>
              <p>
                Next, complete the two assessments. You may only complete the assessments once, so
                be sure you have read all the material about them before you begin.
              </p>
              <p>
                If you were invited by a firm to complete the assessment, your profile will only be
                seen by firms that have explicitly referred you to Suited. If you wish to be seen by
                all of our partner firms, select the option to “Share my Suited profile and
                assessment results with all partner firms so I can be seen for any opportunity” in
                User Settings. You can change your settings at any time in User Settings.
              </p>
              <p>
                You are always able to view and control which firms have access to your information.
                To confirm or change your settings, go to your profile in the top right navigation.
              </p>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

const welcomeHeroAlt =
  "welcome-hero-image: woman in a skirt-suit looking at an enormous ornate door";

export default HomeWelcome;
