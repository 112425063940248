import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../../configureStore";

export const USER_SETTINGS_STATE_KEY = "userSettings";

const userSettingsSlice = createSlice({
  name: USER_SETTINGS_STATE_KEY,
  initialState: {
    currentEmail: "",
    secondaryEmail: "",
    isEmailChangeModalOpen: false,
    isAddSecondaryEmailModalOpen: false
  },
  reducers: {
    setCurrentEmail: (state, action) => {
      state.currentEmail = action.payload;
    },
    setSecondaryEmail: (state, action) => {
      state.secondaryEmail = action.payload;
    },
    showEmailChangeModal: (state, action) => {
      state.isEmailChangeModalOpen = action.payload;
    },
    showAddSecondaryEmailModal: (state, action) => {
      state.isAddSecondaryEmailModalOpen = action.payload;
    }
  }
});

// Actions
export const { setCurrentEmail, showEmailChangeModal, showAddSecondaryEmailModal } =
  userSettingsSlice.actions;

// Selectors
export const getCurrentEmail = (state: RootState) => state.userSettings.currentEmail;
export const getSecondaryEmail = (state: RootState) => state.userSettings.secondaryEmail;
export const getIsEmailChangeModalOpen = (state: RootState) =>
  state.userSettings.isEmailChangeModalOpen;
export const getIsAddSecondaryEmailModalOpen = (state: RootState) =>
  state.userSettings.isAddSecondaryEmailModalOpen;

// Reducer
export const userSettingsReducer = userSettingsSlice.reducer;
