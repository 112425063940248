import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Box, Stack, Center } from "@suited/components";
import { getECAPercentComplete } from "../../eca/eca.utils";
import { DEFAULT_FILL } from "../LateralProgressBar/LateralProgressBar.constants";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import { LateralInProgressContent } from "./LateralInProgressPage.style";
import { ProgressBar } from "suited/components/shared/feedback/ProgressBar/ProgressBar";
import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";
import { useGetCandidateAssessmentById, useGetCandidateAssessments, useGetNextAssessmentSectionInformation } from "../../eca/eca.hooks";
import { getNumberOfCompletedSections, setNumberOfCompletedSections, setTotalNumberOfSections } from "../lateral.slice";
import { AssessmentIds } from "../lateral.constants";

export const LateralInProgressPage = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useAppDispatch();
  const numberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);
  const { assessments } = useGetCandidateAssessments();

  const assessmentID =
    assessments.find((assessment) => assessment.id === AssessmentIds.LATERAL_LAW)?.id || "";
  const { sections } = useGetCandidateAssessmentById(assessmentID, {
    fetchPolicy: "network-only"
  });

  const startAssessment = () => {
    const sectionContentRoute = `${match.url}/content`;
    history.push(sectionContentRoute);
  };

  const sectionInformationResponse = useGetNextAssessmentSectionInformation({ fetchPolicy: "network-only" }, assessmentID);
  const { totalSections } = sectionInformationResponse;

  const percentCompleted =
    totalSections == null ? 0 : getECAPercentComplete(numberOfCompletedSections, totalSections);
  const progress = percentCompleted === 0 ? DEFAULT_FILL : percentCompleted;
  const displayText = percentCompleted === 0 ? 0 : progress;

  useEffect(() => {
    // Used for cases when the page is refreshed and we need to "rehydrate"
    // the ECA progress bar's state
    const completedSections = sections?.findIndex((section) => section.id === sectionInformationResponse.id) || 0;
    // Prevent "jump" in progress bar when race condition occurs. Prevents number of completed from jumping for example, from 2 back to 1, then back to 2 again.
    if (completedSections > numberOfCompletedSections) {
      dispatch(setNumberOfCompletedSections(completedSections));
    }

    if (sections?.length) {
      dispatch(setTotalNumberOfSections(sections?.length));
    }
  }, [dispatch, sections, sectionInformationResponse.id, numberOfCompletedSections]);
  return (
    <Box padding="xl" overflow="auto">
      <Center>
        <LateralInProgressContent>
          <Stack space="xl">
            <SuitedCopyHeadline noMargin>Assessment In Progress</SuitedCopyHeadline>
            <p>
              Your Lateral Assessment is still in progress. To start where you left
              off, please click the "Continue Assessment" button below.
            </p>
            <p>
              You will resume the assessment at the section after the last one you finished. If you
              exited mid-section, you will return to that section.
            </p>
            <Box>
              <ProgressBar progress={progress} displayText={displayText} />
            </Box>
            <Center>
              <SuitedButton
                purpose="primary"
                data-analytics-id="lateral-assessment-continue"
                onClick={startAssessment}
              >
                Continue Assessment
              </SuitedButton>
            </Center>
          </Stack>
        </LateralInProgressContent>
      </Center>
    </Box>
  );
};
