import { LocationPreferences } from "./WorkPreferences.constants";

export const getDefaultLocationPreferenceValue = ({ workPreference, locations }) => {
  if (!workPreference) {
    return null
  }

  if (locations.length) {
    return LocationPreferences.CHOOSE_LOCATION
  }

  return LocationPreferences.NO_PREFERENCE
}

export const getLocationDropdownValue = ({ locationPreference }) => {
  if (!locationPreference) {
    return null;
  }

  if (locationPreference === LocationPreferences.CHOOSE_LOCATION) {
    return {
      label: LocationPreferences.CHOOSE_LOCATION,
      value: LocationPreferences.CHOOSE_LOCATION
    };
  }

  return { label: LocationPreferences.NO_PREFERENCE, value: LocationPreferences.NO_PREFERENCE };
};

export const isFormValid = ({ locationPreference, locations }) => {
  if (!locationPreference) {
    return false;
  }

  if (locationPreference === LocationPreferences.CHOOSE_LOCATION && locations.length === 0) {
    return false;
  }

  return true;
};
