type Props = {
  timeAlloted: string;
};

export const TimedSectionInstructionsCopy = ({ timeAlloted }: Props) => (
  <>
    <p>
      You will have <strong>{timeAlloted}</strong> to complete as many items as you can. Once you
      are ready, please click the button below to begin the assessment.
    </p>
    <p>
      <strong>The timer will start once you click the button below.</strong>
    </p>
  </>
);
