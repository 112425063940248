import styled from "styled-components";
import { Box } from "@suited/components";
import { typography } from "suited/constants/style-constants";

export const LegalCopy = styled(Box)`
  ${typography.styles.hint}
  color: ${typography.color.dark.cancel};
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.form.base.hint};
  font-weight: ${typography.weight.body.light};
  font-size: ${typography.sizes.copy.legal};
  line-height: 1.8;
  text-transform: uppercase;
  text-align: justify;
  strong {
    color: #000;
    font-family: 500;
    font-size: ${typography.sizes.copy.legal};
    text-transform: uppercase;
  }
`;
