import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { StoreEnhancer } from "redux";

import rootReducers from "./rootReducers";
import { AppConfig } from "./config";

export default function configureStore(initialState) {
  let middlewareArray = [thunkMiddleware];

  // uncomment to enable console-based action updates / notifications
  // change loggerConfig object to show/hide specific types of events, or whatever.
  // see: https://github.com/evgenyrodionov/redux-logger#log-everything-except-actions-with-certain-type
  const reduxAppLoggingSetting: string = AppConfig.VITE_REDUX_LOGGING || "";
  if (AppConfig.IS_DEV && reduxAppLoggingSetting.toLowerCase() === "true") {
    const loggerConfig = {
      predicate: (_, action) => action && action.type && action.type.indexOf("@@redux-form") === -1
    };
    const createLogger = require("redux-logger").createLogger;
    middlewareArray.push(createLogger(loggerConfig));
  }

  // build up the final reducer object as needed

  // enable the redux dev tools extension based on the environment
  const reduxDevToolsSetting: string = AppConfig.VITE_REDUX_DEVTOOLS || "";
  const devToolsExtension: StoreEnhancer =
    window["__REDUX_DEVTOOLS_EXTENSION__"] &&
    AppConfig.IS_DEV &&
    reduxDevToolsSetting.toLowerCase() === "true"
      ? window["__REDUX_DEVTOOLS_EXTENSION__"]()
      : (f) => f;

  return createStore(
    combineReducers({ ...rootReducers }),
    initialState,
    compose(applyMiddleware(...middlewareArray), devToolsExtension) as StoreEnhancer
  );
}

export const store = configureStore({});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
