import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps {
  children: React.ReactNode;
}

class ScrollToTop extends Component<Props, any> {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const scrollable = document.getElementById("nav-scroll-top");
      if (scrollable) {
        const supportsScrollTo = typeof scrollable.scrollTo === "function";
        // Edge browser does not support Element.scrollTo
        // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15534521/
        supportsScrollTo ? scrollable.scrollTo(0, 0) : (scrollable.scrollTop = 0);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

// @ts-ignore
export default withRouter(ScrollToTop);
